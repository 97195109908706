import React from 'react';
import Button from 'kingpin/atoms/Button';

const DashboardGadgetReportLinkButton = ({
  onClick,
  noLabel,
}: {
  onClick?: () => void;
  noLabel: boolean;
}) => (
  <div style={{ marginRight: 8 }}>
    <Button
      icon="chart-outlined"
      onClick={onClick}
      testId={`open-popup-report`}
      type="Ghost"
      size="Small"
    />
  </div>
);

export default DashboardGadgetReportLinkButton;
