import React, { useContext } from 'react';

import styled from 'styled-components';
import NavSideBarContext from '../../contexts/NavSideBarContext';
import NavItem from './NavItem';
import AppRoutes from '../../navigation/appRoutes';
import ImpersonatorBanner from '../../components/NavigationSideBar/ImpersonatorBanner';
import PERMISSIONS from '../../permissionDefinitions';
import PermissionGates from 'components/PermissionGates';
import NotificationsButton from './NotificationsButton';
import ProfileButton from './ProfileButton';
import Colors2 from '../../theme/Colors2';

const MainNavBottomDiv = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isOpen ? 'row' : 'column')};
  border-top: solid 1px ${Colors2.Border};
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  padding: 8px 0px;

  a {
    margin-right: ${(props) => (props.isOpen ? '8px' : '0px')};
  }
`;

const SETTINGS_PERMISSIONS = [
  PERMISSIONS.BILLING.VIEW_BILLING,
  PERMISSIONS.DATA_MANAGEMENT.INTEGRATIONS_CONFIG_VIEW,
  PERMISSIONS.DATA_MANAGEMENT.INTEGRATIONS_CONFIG_MODIFY,
  PERMISSIONS.USER_MANAGEMENT.VIEW_USER,
];

const MainNavBottom = () => {
  const { isOpen } = useContext(NavSideBarContext);

  return (
    <div>
      <ImpersonatorBanner isOpen={isOpen} />
      <MainNavBottomDiv isOpen={isOpen}>
        <PermissionGates.HasAny requiredPermissions={SETTINGS_PERMISSIONS}>
          <NavItem
            to={AppRoutes.loggedIn.settings}
            icon={'settings-empty'}
            iconColor={'#666666'}
          />
        </PermissionGates.HasAny>
        <NavItem
          to={'https://help.fleetops.com/en/'}
          isOpenedInNewTab
          icon={'help'}
          iconColor={'#666666'}
        />
        <NotificationsButton />
        <ProfileButton />
      </MainNavBottomDiv>
    </div>
  );
};

export default MainNavBottom;
