const isVariable = (filter: FilterPlate): filter is VariableFilterPlate =>
  filter.type === 'Variable';

const isFixed = (filter: FilterPlate): filter is FixedFilterPlate =>
  filter.type === 'Fixed';

const filterPlateTypeCheckers = {
  isVariable,
  isFixed,
};

export default filterPlateTypeCheckers;
