import React, { useContext } from 'react';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';

import Cell from './V5Gadget/Matrix/Cell';
import isGeneralBoard from '../isBoardWithSlideOut';
import useIsGridLinksDisabled from '../hooks/useIsGridLinksDisabled';
import Row from './Common/Row';
import BoardSlideOutContext from '../contexts/BoardSlideOutContext';
import BoardContext from '../contexts/BoardContext';

const DrillDownCell = ({
  fieldValue,
  selectFieldValue,
  node,
}: {
  fieldValue: string;
  selectFieldValue: (fieldValue: string) => void;
  node: IRowNode<any>;
}) => {
  const isLinksDisabled = useIsGridLinksDisabled();
  const onClick = (event: any) => {
    event.stopPropagation();
    selectFieldValue(fieldValue);
    node.setSelected(true, true);
  };

  if (isLinksDisabled) {
    return (
      <Cell>
        <Row centerAlign>
          <span>{fieldValue}</span>
        </Row>
      </Cell>
    );
  }

  return (
    <Cell onClick={onClick} style={{ cursor: 'pointer' }}>
      <Row centerAlign>
        <span style={{ color: '#0041ea' }}>{fieldValue}</span>
      </Row>
    </Cell>
  );
};

const Gate = ({ value, node }: ICellRendererParams) => {
  const boardSlideOutContext = useContext(BoardSlideOutContext);
  const { board } = useContext(BoardContext);
  if (
    !boardSlideOutContext ||
    typeof value !== 'string' ||
    !board ||
    !isGeneralBoard(board)
  ) {
    if (Array.isArray(value)) {
      return (
        <Cell>
          <span>{value.join(', ')}</span>
        </Cell>
      );
    } else {
      return (
        <Cell>
          <span>{value}</span>
        </Cell>
      );
    }
  }

  return (
    <DrillDownCell
      fieldValue={value as string}
      selectFieldValue={boardSlideOutContext.selectFieldValue}
      node={node}
    />
  );
};

export default Gate;
