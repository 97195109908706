import React from 'react';
import useExportUsers from './useExportUsers';
import Button from 'kingpin/atoms/Button';

const ExportUsersButton = () => {
  const onExportClicked = useExportUsers();

  return (
    <Button
      onClick={onExportClicked}
      label="Export Users"
      type="Primary"
      size="Small"
    />
  );
};

export default ExportUsersButton;
