import mapGraphqlDayNumberToString from '../../mapGraphqlDayNumberToString';

const buildTwoDimensionDayOfWeekTotals = ({
  groupedHistogram,
  histogram,
  metricId,
  metric,
  groupByField,
  formatMetric,
}: {
  groupedHistogram: MetricsResponse;
  histogram: MetricsResponse;
  metricId: string;
  metric: Metrics.Metric;
  groupByField: string;
  formatMetric: (args: { metricId: string; value: string }) => string;
}) => {
  const formatted = {};
  const rowCount = groupedHistogram.length;
  // @ts-ignore
  formatted[groupByField] = `Total (${rowCount})`;

  if (!histogram) {
    return [formatted];
  }

  ['1', '2', '3', '4', '5', '6', '7'].forEach((dayOfWeekNumber) => {
    const totalItem = histogram.find((h) => h['dayOfWeek'] === dayOfWeekNumber);
    if (!totalItem) {
      return;
    }

    const total = totalItem[metricId];
    const formattedTotal = formatMetric({ value: total, metricId: metric.id });

    // @ts-ignore
    formatted[mapGraphqlDayNumberToString(dayOfWeekNumber)] = formattedTotal;
  });

  return [formatted];
};

export default buildTwoDimensionDayOfWeekTotals;
