import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import toMetricInput from '../utils/metrics/toMetricInput';
import getQueryAlias from './getQueryAlias';

const buildQuery = (
  compoundMetric: Metrics.CompoundMetricWithMetricDefs,
  filterInput: FilterInput[],
  dateScope: DateRangeInput,
  alias: string,
  groupBy?: V5GroupBy,
  trendByCalendarInterval?: FleetOps.Interval,
  trendByFixedIntervalDays?: number,
  groupByDayOfWeek?: boolean,
) => {
  return {
    query: gql`
      query aggregateCompoundMetric(
        $metrics: [MetricInput]!
        $filters: [FilterInput]!
        $dateScope: [DateRangeInput!]!
        $expressions: [Expression2]!
        $groupBy: GroupBy
        $trendByCalendarInterval: Interval
        $trendByFixedIntervalDays: Int
        $groupByDayOfWeek: Boolean
      ) {
        ${alias}(
          metrics: $metrics
          filters: $filters
          dateScope: $dateScope
          expressions: $expressions
          groupBy: $groupBy
          trendByCalendarInterval: $trendByCalendarInterval
          trendByFixedIntervalDays: $trendByFixedIntervalDays
          groupByDayOfWeek: $groupByDayOfWeek
        )
      }
    `,
    variables: {
      metrics: compoundMetric.metrics.map(toMetricInput),
      filters: filterInput,
      dateScope,
      expressions: [
        {
          expression: compoundMetric.expression,
          id: compoundMetric.id,
        },
      ],
      groupBy,
      trendByCalendarInterval,
      trendByFixedIntervalDays,
      groupByDayOfWeek,
    },
  };
};

export const aggregateCompoundMetric = async (
  compoundMetric: Metrics.CompoundMetricWithMetricDefs,
  filterInput: FilterInput[],
  dateScope: DateRangeInput,
  client: ApolloClient<NormalizedCacheObject>,
  groupBy?: V5GroupBy,
  trendByCalendarInterval?: FleetOps.Interval,
  trendByFixedIntervalDays?: number,
  groupByDayOfWeek?: boolean,
) => {
  if (compoundMetric.metrics.length === 0) {
    throw new Error(
      `Cannot aggregate an empty metric list for compound metric: ${compoundMetric.id}`,
    );
  }
  const alias = getQueryAlias({
    groupBy,
    trendByCalendarInterval,
    trendByFixedIntervalDays,
    groupByDayOfWeek,
  });

  const query = buildQuery(
    compoundMetric,
    filterInput,
    dateScope,
    alias,
    groupBy,
    trendByCalendarInterval,
    trendByFixedIntervalDays,
    groupByDayOfWeek,
  );

  const response = await client.query(query);
  return response.data.aggregateCompoundMetric;
};

export default aggregateCompoundMetric;
