import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FleetOpsGridColDef } from 'types/agGrid';
import {
  buildShowEntityApp,
  buildShowEntityAppContentTab,
} from 'navigation/appRoutes';
import EntityDetailsContext from 'screens/EntityDetailsShow/EntityDetailsContext';

import { IRowNode } from 'ag-grid-community';
import EntityButtons from 'components/EntityButtons';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';
import ReportContext from '../../../../contexts/ReportContext';
import PopupContext from '../../../../contexts/PopupContext';
import BoardContext from '../../../../contexts/BoardContext';
import DashboardContext from '../../../../contexts/DashboardContext';

type TSource =
  | 'Popup Report'
  | 'Popup Board'
  | 'Dashboard'
  | 'Report'
  | 'Board'
  | 'NA';

const useSource = (): TSource => {
  const { report } = useContext(ReportContext);
  const { selectedBoard, selectedReport, isOpen } = useContext(PopupContext);
  const { board } = useContext(BoardContext);
  const { dashboard } = useContext(DashboardContext);

  const getSource = useCallback(() => {
    if (isOpen) {
      if (selectedReport) {
        return 'Popup Report';
      }
      if (selectedBoard) {
        return 'Popup Board';
      }
      return 'NA';
    }

    if (dashboard) {
      return 'Dashboard';
    }
    if (report) {
      return 'Report';
    }
    if (board) {
      return 'Board';
    }
    return 'NA';
  }, [board, dashboard, isOpen, report, selectedBoard, selectedReport]);
  const [source, setSource] = useState<TSource>(getSource());
  useEffect(() => {
    setSource(getSource());
  }, [getSource]);

  return source;
};

const EntityLink = ({
  colDef,
  value,
  node,
}: {
  colDef: FleetOpsGridColDef;
  value: string;
  node: IRowNode;
}) => {
  const { cellRendererParams } = colDef;
  const { entityDefinition } = cellRendererParams;
  const entityDetails = useContext(EntityDetailsContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const source = useSource();
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false);

  const onMouseEnter = useCallback(() => {
    setIsRowHovered(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setIsRowHovered(false);
  }, []);

  useEffect(() => {
    node.addEventListener('mouseEnter', onMouseEnter);
    node.addEventListener('mouseLeave', onMouseLeave);
    return () => {
      node.removeEventListener('mouseEnter', onMouseEnter);
      node.removeEventListener('mouseLeave', onMouseLeave);
    };
  }, [node, onMouseEnter, onMouseLeave]);

  const onClick = useCallback(() => {
    if (!entityDefinition) {
      return;
    }
    const entity = entityDefinition.entity;
    trackEvent('Entity Details - App Opened', {
      entityName: entity.name,
      entityPrimaryField: entity.primaryField,
      source,
    });
  }, [entityDefinition, source, trackEvent]);

  if (!entityDefinition) {
    return null;
  }

  if (
    entityDetails &&
    entityDetails.selectedEntity.type.id === entityDefinition.entity.id
  ) {
    return null;
  }

  if (!isRowHovered || value === undefined) {
    return null;
  }

  const linkTo = (() => {
    if (entityDefinition.app.tabs.length > 0) {
      return buildShowEntityAppContentTab({
        field: entityDefinition.entity.primaryField,
        value,
        tabId: entityDefinition.app.tabs[0].typeId,
        tabType: entityDefinition.app.tabs[0].type,
      });
    }

    return buildShowEntityApp({
      field: entityDefinition.entity.primaryField,
      value,
    });
  })();

  return (
    <div style={{ marginLeft: 8 }}>
      <EntityButtons.Link
        to={linkTo}
        tooltip={`${entityDefinition.entity.name} Details`}
        onClick={onClick}
      />
    </div>
  );
};

export default EntityLink;
