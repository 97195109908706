import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import useFieldDefinitions from '../../../hooks/useFieldDefinitions';
import _ from 'lodash';
import ReportDrillDownFormContext from '../../../contexts/ReportDrillDownFormContext';
import ReportDrillDownsContext from '../../../contexts/ReportDrillDownsContext';
import SingleItemPicker from '../../SingleItemPicker';
import ReportDrillDownOptionsContext from '../../../contexts/ReportDrillDownSettingsContext';
import toSentenceCase from '../../../services/toSentenceCase';
import VariableFiltersContext from '../../../contexts/VariableFiltersContext';
import { getDrillDownFieldName } from 'hooks/useDrillDownFieldName';
import BaseViewsContext from '../../../contexts/BaseViewsContext';
import DashboardContext from '../../../contexts/DashboardContext';
import isDashboardTemplate from '../../../types/isDashboardTemplate';
import Badge from 'components/Badge';

const dateOpts = [
  { field: 'day', type: 'dateText' },
  { field: 'week', type: 'dateText' },
  { field: 'month', type: 'dateText' },
  { field: 'quarter', type: 'dateText' },
  { field: 'year', type: 'dateText' },
] as DateBucketField[];

interface Option {
  key: string;
  label: string;
  value: any;
  renderRight?: JSX.Element;
}

export const isVariableDrillDown = (
  d: FleetOps.Field | DateBucketField | VariableDrillDownType,
): d is VariableDrillDownType => {
  // @ts-ignore
  if (!!d.value && !!d.value.field && !!d.value.fieldType && !!d.value.mode) {
    return true;
  } else {
    return false;
  }
};

const FieldPickerContainer = () => {
  const { baseViews } = useContext(BaseViewsContext);
  const { hideDateDrillDowns } = useContext(ReportDrillDownOptionsContext);
  const [searchText, setSearchText] = useState<string>('');
  const { dataTypes, drillDowns, scope } = useContext(ReportDrillDownsContext);
  const { variableFilters } = useContext(VariableFiltersContext);
  const { dashboard } = useContext(DashboardContext);
  const { fieldDefinitions, isLoading } = useFieldDefinitions(dataTypes);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);

  const getBaseField = useCallback(
    (f: FleetOps.Field) => {
      if (!f.dataType) {
        return undefined;
      }

      const bv = baseViews[f.dataType];
      if (!bv) {
        return undefined;
      }

      return bv.fields[f.field];
    },
    [baseViews],
  );

  useEffect(() => {
    const defsWithoutDates = fieldDefinitions.filter(
      (d) => d.type !== 'date' && d.field !== 'type' && d.field !== 'id',
    );

    const baseDefs = defsWithoutDates.filter((f) => {
      const bf = getBaseField(f);
      if (!bf) {
        return true;
      }

      return bf.isVisible;
    });

    const variableOptions = (variableFilters || []).map((v) => ({
      key: v.id,
      label: getDrillDownFieldName(v.value, baseViews),
      value: v,
      renderRight: <Badge text="Variable Filter" badgeType={'Warning'} />,
    }));

    const defOptions = baseDefs
      .filter(
        (def) =>
          !variableOptions.some((vo) => vo.value.value.field === def.field),
      )
      .map((def) => {
        const bf = getBaseField(def);
        const alias = bf ? bf.nameAlias : def.alias;

        return {
          key: `${toSentenceCase(def.field)} - Fixed`,
          label: alias ? alias : def.field,
          value: def,
        };
      });

    const dateOptions = dateOpts.map((def) => {
      return {
        key: `${toSentenceCase(def.field)} - Fixed`,
        label: def.alias ? def.alias : def.field,
        value: def,
      };
    });

    const baseOptions = _.uniqBy(
      hideDateDrillDowns ? [...defOptions] : [...defOptions, ...dateOptions],
      'key',
    );

    const unusedVariableOptions = variableOptions.filter(
      (vo) =>
        !drillDowns.some(
          (d) => d.type === 'Variable' && d.variableId === vo.value.id,
        ) &&
        !scope.some(
          (d) => d.type === 'Variable' && d.variableId === vo.value.id,
        ),
    );

    const pickerOptions = (() => {
      if (isDashboardTemplate(dashboard)) {
        return _.sortBy(
          [...baseOptions, ...unusedVariableOptions],
          'label',
        ) as Option[];
      } else {
        return _.sortBy([...baseOptions], 'label') as Option[];
      }
    })();

    const newFilteredOptions = (() => {
      if (searchText === '') {
        return pickerOptions;
      }

      return pickerOptions.filter((o) =>
        o.label.toLowerCase().includes(searchText.toLowerCase()),
      );
    })();

    setFilteredOptions(newFilteredOptions);
  }, [
    baseViews,
    fieldDefinitions,
    getBaseField,
    hideDateDrillDowns,
    dashboard,
    searchText,
    variableFilters,
    scope,
    drillDowns,
  ]);

  const { onFieldSelected, onVariableFilterAdded } = useContext(
    ReportDrillDownFormContext,
  );

  const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const onOptionSelected = (
    field: FleetOps.Field | DateBucketField | VariableDrillDownType,
  ) => {
    if (isVariableDrillDown(field)) {
      onVariableFilterAdded(field);
    } else {
      onFieldSelected(field);
    }
  };

  return (
    <SingleItemPicker
      testId="drill-down-field-picker"
      options={filteredOptions}
      onOptionSelected={onOptionSelected}
      searchText={searchText}
      onSearchTextChanged={onSearchTextChanged}
      isLoading={isLoading}
    />
  );
};

export default FieldPickerContainer;
