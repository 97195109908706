import React, { useCallback, useContext, useState } from 'react';
import _ from 'lodash';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';

import BaseViewsContext from '../../../../contexts/BaseViewsContext';
import usePopup from '../../../../hooks/usePopup';
import PermissionGates from '../../../../components/PermissionGates';
import PERMISSIONS from '../../../../permissionDefinitions';
import Typography from 'kingpin/atoms/Typography';
import ReportSelector from '../../../../components/ConfigureDashboardGadget/ReportSelector';
import Row from '../../../../components/Common/Row';

const AddValidationReportButton = ({
  baseView,
}: {
  baseView: FleetOps.BaseView;
}) => {
  const { updateBaseView } = useContext(BaseViewsContext);
  const { isOpen, open, close } = usePopup();
  const [reportId, setReportId] = useState<string | undefined>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onSave = useCallback(() => {
    if (!reportId) {
      return;
    }

    const newBaseView = {
      ...baseView,
      validationReportIds: _.uniq([
        ...(baseView.validationReportIds || []),
        reportId,
      ]),
    };

    setIsSaving(true);
    updateBaseView(newBaseView);
    setTimeout(() => {
      setIsSaving(false);
      close();
    }, 1000);
  }, [baseView, close, reportId, updateBaseView]);

  return (
    <PermissionGates.Has
      requiredPermission={PERMISSIONS.DATA_MANAGEMENT.ADD_VALIDATION_REPORT}
    >
      <Row style={{ height: 52 }} centerAlign>
        <Button
          type="Ghost"
          size="Small"
          testId="add-validation-report"
          onClick={open}
          icon={'add'}
        />
      </Row>

      <ModalTransition>
        {isOpen && (
          <Modal
            width={400}
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <div style={{ padding: 16 }}>
              <div style={{ marginBottom: 24 }}>
                <Typography.Header type="H5">
                  Add Validation Report
                </Typography.Header>
              </div>

              <div style={{ marginBottom: 32 }}>
                <ReportSelector
                  reportId={reportId}
                  setReportId={setReportId}
                  setBoardId={window.tokenFunction}
                />
              </div>
              <Row style={{ justifyContent: 'flex-end' }}>
                <Button
                  isDisabled={reportId === undefined || isSaving}
                  onClick={onSave}
                  isLoading={isSaving}
                  label="Add"
                  type="Primary"
                  size="Small"
                />
              </Row>
            </div>
          </Modal>
        )}
      </ModalTransition>
    </PermissionGates.Has>
  );
};

export default AddValidationReportButton;
