import React from 'react';
import Row from '../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Loading from '../../Loading';
import FilterToggle from '../FilterToggle';
import styled from 'styled-components';
import Card from '../../Common/Card/index';
import toSentenceCase from '../../../services/toSentenceCase';

const List = styled.div`
  max-height: 150px;
  overflow-y: auto;
`;

const Col = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 80px;

  svg {
    fill: white;
  }
`;

const BoardDataGridSection = ({
  label,
  grid,
  isLoading,
  filterLabel,
  isV2FilterOn,
  isFilterToggle,
  onFilterToggled,
}: {
  label: string;
  grid: { [_field: string]: string | number }[];
  isLoading: boolean;
  filterLabel?: string;
  isV2FilterOn: boolean;
  isFilterToggle?: boolean;
  onFilterToggled: (newStatus: boolean) => void;
}) => (
  <div>
    <Row spaceBetween centerAlign>
      <Typography.Header type="H5">{label}</Typography.Header>
      {isFilterToggle && (
        <FilterToggle
          label={filterLabel}
          isV2FilterOn={isV2FilterOn}
          onIsV2FilterOnChanged={onFilterToggled}
        />
      )}
    </Row>
    {isLoading && (
      <div style={{ marginBottom: 22 }}>
        <Loading isSmall />
      </div>
    )}
    {!isLoading && grid.length > 0 && (
      <Card style={{ marginBottom: 22 }}>
        <div style={{ padding: '0px 8px', backgroundColor: 'white' }}>
          <Row
            style={{
              borderBottom: `1px solid #E6E6EA`,
              color: '#5c5c5c',
              height: 34,
            }}
            centerAlign
          >
            {Object.keys(grid[0]).map((field) => (
              <Col key={field}>{toSentenceCase(field)}</Col>
            ))}
          </Row>
          <List className="hiding-scrollbar">
            {grid.map((row) => (
              <Row
                key={Math.random()}
                style={{
                  minHeight: 34,
                  padding: '4px 0px',
                }}
                centerAlign
              >
                {Object.values(row).map((value) => (
                  <Col key={value}>
                    <Typography.Body type="Body 12">{value}</Typography.Body>
                  </Col>
                ))}
              </Row>
            ))}
          </List>
        </div>
      </Card>
    )}
  </div>
);

export default BoardDataGridSection;
