import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import getIdentifier from 'getIdentifier';

const Portal = ({
  children,
  elementId,
  isTestModeDisabled,
}: {
  children: JSX.Element | JSX.Element[];
  elementId: string;
  isTestModeDisabled?: boolean;
}) => {
  const [, setKey] = useState<string>(getIdentifier());
  const [retries, setRetries] = useState<number>(0);
  const retryLimit = 3;

  useEffect(() => {
    const domNode = document.getElementById(elementId);
    if (!domNode) {
      const to = setTimeout(
        () => {
          if (retries < retryLimit) {
            setRetries(retries + 1);
            setKey(getIdentifier());
          }
        },
        200 * 2 ** retries,
      );

      return () => {
        clearTimeout(to);
      };
    } else {
      setRetries(0);
    }
  }, [elementId, retries]);

  if (process.env.NODE_ENV === 'test' && !isTestModeDisabled) {
    return <>{children}</>;
  }

  const domNode = document.getElementById(elementId);
  if (domNode) {
    return ReactDOM.createPortal(children, domNode);
  }
  return null;
};

export default Portal;
