import React from 'react';
import styled, { css } from 'styled-components';

import ReportCanvas from '../ReportCanvas';
import Row from '../Common/Row';
import ReportEditingButtons from '../ReportEditingButtons';
import ReportMenu from '../ReportMenu';
import WidgetGallery from '../WidgetGallery';
import WidgetGalleryProvider from '../../contextProviders/WidgetGalleryProvider/WidgetGalleryProvider';
import ResetReportButton from './ResetReportButton';
import ReportDrillDowns from '../ReportDrillDowns';
import ComparisonSelector from './ComparisonSelector';
import ExportReportButton from '../ExportReportButton';
import DateInput from '../DateInput';
import WeekStartsOnOverrideSelector from './WeekStartsOnOverrideSelector';
import {
  CANVAS_ID,
  EXCLUDE_HEIGHT,
  MODAL_MARGIN,
  TOP_BAR_HEIGHT,
} from '../../constants';

import ResourceTopBar, { ResourceFavouriteButton } from '../ResourceTopBar';
import Typography from 'kingpin/atoms/Typography';
import ReportInfoIcon from '../ReportInfoIcon';
import PermissionGates from '../PermissionGates';
import PERMISSIONS from '../../permissionDefinitions';
import PublishMetricsButton2 from './PublishMetricsButton';
import Colors2 from '../../theme/Colors2';

const FILTER_ROW_EXTRA_HEIGHT = 10;

const FiltersRow = styled(Row)`
  justify-content: space-between;
  padding: 0px 24px;
  top: 0px;
  z-index: 11;
  margin-left: 0px;
  align-items: center;
  position: sticky;
  margin-bottom: 1px;

  // Giving this a extra bit of height to get it to align with the account picker
  // on report show screen
  height: ${TOP_BAR_HEIGHT + FILTER_ROW_EXTRA_HEIGHT}px;

  background-color: white;
`;

const PopupCss = css`
  height: calc(100vh - ${MODAL_MARGIN * 2}px);
  max-height: calc(100vh - ${MODAL_MARGIN * 2}px);
`;

const ValidationReportCss = css`
  height: calc(100vh - ${65}px);
  max-height: calc(100vh - ${65}px);
`;

const TargetAppReportCss = css`
  height: calc(100vh - ${TOP_BAR_HEIGHT * 2}px);
  max-height: calc(100vh - ${TOP_BAR_HEIGHT * 2}px);
`;

const Content = styled.div<{
  isPopup?: boolean;
  isValidationReport?: boolean;
  isTargetsApp?: boolean;
}>`
  width: 100%;
  position: relative;
  height: 100vh;
  overflow-y: scroll;
  ${(props) => props.isValidationReport && ValidationReportCss}
  ${(props) => props.isTargetsApp && TargetAppReportCss}
  ${(props) => props.isPopup && PopupCss}
`;

const DrillDowns = ({ isEditing }: { isEditing: boolean }) => {
  return (
    <Row style={{ flexWrap: 'wrap', marginLeft: 0 }}>
      <div style={{ marginRight: 8 }}>
        <DateInput isReport />
      </div>
      {isEditing && <ReportDrillDowns isScope />}
      {!isEditing && <ReportDrillDowns isReport />}
    </Row>
  );
};

const CONTEXT_MENU_PERMISSIONS = [
  PERMISSIONS.REPORT_MANAGEMENT.EDIT_LAYOUT,
  PERMISSIONS.REPORT_MANAGEMENT.ADD_REMOVE_VISUALISATIONS,
  PERMISSIONS.REPORT_MANAGEMENT.DELETE,
  PERMISSIONS.REPORT_MANAGEMENT.COPY,
  PERMISSIONS.CONTENT_ACCESS.SET_ANY_AVAILABLE_TO_USERS_OR_PUBLIC,
];

const AdminStuff = ({
  isEditing,
  startEditing,
  exportReport,
  saveReportAs,
  onDelete,
  hideMenu,
}: {
  isEditing: boolean;
  startEditing: () => void;
  exportReport: (userIds: string[], notes: string) => Promise<void>;
  saveReportAs: (name: string) => Promise<any>;
  saveEditAs: (name: string) => Promise<any>;
  onDelete: () => void;
  hideMenu?: boolean;
}) => (
  <div
    style={{
      marginLeft: 12,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    }}
  >
    {!isEditing && (
      <Row>
        <ExportReportButton exportReport={exportReport} />

        {!hideMenu && (
          <PermissionGates.HasAny
            requiredPermissions={CONTEXT_MENU_PERMISSIONS}
          >
            <>
              <div style={{ marginRight: 12 }} />
              <ReportMenu
                startEditing={startEditing}
                saveReportAs={saveReportAs}
                onDelete={onDelete}
              />
            </>
          </PermissionGates.HasAny>
        )}
      </Row>
    )}
  </div>
);

const Report = ({
  report,
  reportId,
  currentCanvas,
  setCurrentCanvas,
  isEditing,
  onEditSave,
  onEditCancel,
  exportReport,
  startEditing,
  saveReportAs,
  saveEditAs,
  onDelete,
  hasLeftDefaultState,
  createAndAddDashboardWidget,
  hideMenu,
  weekStartsOnOverride,
  setWeekStartsOnOverride,
  isMobile,
  isPopup,
  onScroll,
  isValidationReport,
  isWorkspace,
  isTargetsApp,
}: {
  report: PersistedReportType;
  reportId: string;
  currentCanvas: Canvas;
  setCurrentCanvas: React.Dispatch<React.SetStateAction<Canvas>>;
  isEditing: boolean;
  onEditSave: () => void;
  onEditCancel: () => void;
  exportReport: (userIds: string[], notes: string) => Promise<void>;
  startEditing: () => void;
  saveReportAs: (name: string) => Promise<any>;
  saveEditAs: (name: string) => Promise<any>;
  onDelete: () => void;
  hasLeftDefaultState: boolean;
  createAndAddDashboardWidget: (
    gadget: DashboardGadget,
    card: CanvasCard.Card,
  ) => Promise<void>;
  hideMenu?: boolean;
  weekStartsOnOverride?: WeekStartsOn;
  setWeekStartsOnOverride: React.Dispatch<
    React.SetStateAction<WeekStartsOn | undefined>
  >;
  isMobile: boolean;
  isPopup?: boolean;
  onScroll: () => void;
  isValidationReport?: boolean;
  isWorkspace: boolean;
  isTargetsApp: boolean;
}) => (
  <WidgetGalleryProvider
    startEditing={startEditing}
    currentCanvas={currentCanvas}
    setCurrentCanvas={setCurrentCanvas}
    createAndAddDashboardWidget={createAndAddDashboardWidget}
  >
    <Content
      id="report"
      onScroll={onScroll}
      className="hiding-scrollbar"
      isPopup={isPopup}
      isValidationReport={isValidationReport}
      isTargetsApp={isTargetsApp}
    >
      <div id={`${CANVAS_ID}-${reportId}`} style={{ position: 'relative' }}>
        {!isPopup && !isWorkspace && !isTargetsApp && (
          <ResourceTopBar isCanvas className={`${EXCLUDE_HEIGHT}-${reportId}`}>
            <Row
              centerAlign
              style={{ height: '100%', width: '100%' }}
              spaceBetween
            >
              <Row centerAlign style={{ height: '100%' }}>
                <Typography.Header type="H3">
                  {report ? report.name : '...'}
                </Typography.Header>
                {report && (
                  <div style={{ marginLeft: 8, display: 'flex' }}>
                    <ResourceFavouriteButton
                      type="report"
                      typeId={report.id}
                      name={report.name}
                    />
                  </div>
                )}
                {report && (
                  <div style={{ marginLeft: 8, display: 'flex' }}>
                    <ReportInfoIcon report={report} />
                  </div>
                )}
              </Row>
              {isValidationReport && <PublishMetricsButton2 />}
            </Row>
          </ResourceTopBar>
        )}

        {!isMobile && isEditing && (
          <>
            <Row
              centerAlign
              style={{ justifyContent: 'flex-end', padding: isMobile ? 0 : 8 }}
              className={`${EXCLUDE_HEIGHT}-${reportId}`}
            >
              <ReportEditingButtons
                onSave={onEditSave}
                onCancel={onEditCancel}
                saveEditAs={saveEditAs}
                isEditing={isEditing}
              />
            </Row>
          </>
        )}

        <FiltersRow
          style={{
            borderBottom: `1px solid ${Colors2.Border}`,
          }}
          className={`${EXCLUDE_HEIGHT}-${reportId}`}
          id={'report-filters'}
        >
          <div>
            <DrillDowns isEditing={isEditing} />
          </div>
          <Row centerAlign>
            {isEditing && (
              <div style={{ marginRight: 8 }}>
                <WeekStartsOnOverrideSelector
                  weekStartsOnOverride={weekStartsOnOverride}
                  setWeekStartsOnOverride={setWeekStartsOnOverride}
                />
              </div>
            )}
            {!isEditing && hasLeftDefaultState && (
              <div style={{ marginRight: 8 }}>
                <ResetReportButton />
              </div>
            )}
            <ComparisonSelector isReport />
            {!isMobile && !isPopup && (
              <AdminStuff
                isEditing={isEditing}
                saveReportAs={saveReportAs}
                saveEditAs={saveEditAs}
                exportReport={exportReport}
                startEditing={startEditing}
                onDelete={onDelete}
                hideMenu={hideMenu}
              />
            )}
          </Row>
        </FiltersRow>
        <ReportCanvas
          isEditing={isEditing}
          currentCanvas={currentCanvas}
          setCurrentCanvas={setCurrentCanvas}
          startEditing={startEditing}
        />

        <WidgetGallery />
      </div>
    </Content>
  </WidgetGalleryProvider>
);

export default Report;
