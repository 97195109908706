import { useContext, useEffect, useState } from 'react';
import Timeline from '../../../../components/Timeline';
import CompoundMetricsContext from '../../../../contexts/CompoundMetricsContext';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';

const useMetricSources = (metric?: Metrics.NormalMetric) => {
  const [sources, setSources] = useState<Timeline.EventSource[]>([]);
  useEffect(() => {
    if (!metric) {
      setSources([]);
      return;
    }

    setSources([
      {
        type: 'Metric',
        id: metric.id,
      },
    ]);
  }, [metric]);

  return sources;
};

const useCompoundSources = (metric?: Metrics.CompoundMetric) => {
  const { compoundMetricInputsLookup } = useContext(CompoundMetricsContext);
  const [sources, setSources] = useState<Timeline.EventSource[]>([]);
  useEffect(() => {
    if (!metric) {
      setSources([]);
      return;
    }

    const inputs = compoundMetricInputsLookup[metric.id];
    const newSources = [
      {
        type: 'CompoundMetric' as 'CompoundMetric',
        id: metric.id,
      },
    ];
    if (inputs) {
      inputs.forEach((m) => {
        newSources.push({
          type: 'Metric' as 'CompoundMetric',
          id: m.id,
        });
      });
    }

    setSources(newSources);
  }, [compoundMetricInputsLookup, metric]);

  return sources;
};

const useMetricTimelineSources = (
  metric: Metrics.NormalMetric | Metrics.CompoundMetric | undefined,
) => {
  const normal = useMetricSources(
    metricTypeCheckers.isNormalMetric(metric) ? metric : undefined,
  );
  const compound = useCompoundSources(
    metricTypeCheckers.isCompoundMetric(metric) ? metric : undefined,
  );

  if (metricTypeCheckers.isNormalMetric(metric)) {
    return normal;
  }

  return compound;
};

export default useMetricTimelineSources;
