import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Colors2 from '../theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import Row from './Common/Row';
import Badge from './Badge';
import AccessUsers from './AccessUsers';
import useFavouriteToggle from '../hooks/useFavouriteToggle';
import LinkWrapper from './LinkWrapper';
import ShowOnMouseOverUnlessTour from './ShowOnMouseOverUnlessTour';
import LastViewedAtContext from '../contexts/LastViewedAtContext';
import Button from '../kingpin/atoms/Button';
import moment from 'moment';
import Tooltip from './Tooltip';

export const ListItemWrapper = styled.div`
  height: 48px;
  min-height: 48px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid ${Colors2.Border};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;

  .showOnMouseOver {
    visibility: hidden;
  }

  &:hover {
    background-color: ${Colors2.Grey['9']};

    .showOnMouseOver {
      visibility: visible;
    }
  }
`;

const Left = styled.div`
  display: flex;
  flex: 5;
  flex-direction: row;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  flex: 4;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FavouriteButton = ({
  isFavourite,
  onClick,
}: {
  isFavourite: boolean;
  onClick: (event: React.MouseEvent<any>) => void;
}) => {
  return (
    <Tooltip content={isFavourite ? 'Unfavorite' : 'Click to favorite'}>
      <Button
        size={'Small'}
        type={'Ghost'}
        onClick={onClick}
        icon={isFavourite ? 'start - filled' : 'star - empty'}
      />
    </Tooltip>
  );
};

export const ListHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  border-radius: 8px;

  background-color: #f8f8f8;
  margin: 0px 28px 0px 24px;
  padding: 0px 8px;
`;

export const IndexListHeader = () => (
  <ListHeaderDiv>
    <Left>
      <div style={{ width: 32 }} />
      <Typography.Body type="Label">Name</Typography.Body>
    </Left>
    <Right>
      <Row style={{ flex: 1 }} />
      <div style={{ flex: 1 }} />
      <Row centerAlign spaceBetween style={{ flex: 1 }}>
        <Typography.Body type="Label">Last Viewed</Typography.Body>
        <div />
      </Row>
    </Right>
  </ListHeaderDiv>
);

const IndexListItem = ({
  title,
  link,
  badges,
  access,
  contextMenu,
  type,
  typeId,
  isLastViewedAtDisabled,
}: {
  title: string;
  link: string;
  badges: {
    text: string;
  }[];
  access?: ResourceAccess;
  contextMenu?: JSX.Element;
  type: 'report' | 'dashboard' | 'wallboard' | 'workspace' | 'scorecard';
  typeId: string;
  isFirstItem?: boolean;
  isLastViewedAtDisabled?: boolean;
}) => {
  const { isFavourite, onFavouriteToggled } = useFavouriteToggle({
    type,
    typeId,
    name: title,
  });
  const { getLastViewedAt } = useContext(LastViewedAtContext);
  const lastViewedAt = getLastViewedAt(type, typeId);

  return (
    <ListItemWrapper>
      <Left>
        <div style={{ width: 32 }}>
          <ShowOnMouseOverUnlessTour>
            <Button
              size={'Small'}
              type={'Ghost'}
              icon={isFavourite ? 'start - filled' : 'star - empty'}
              onClick={onFavouriteToggled}
            />
          </ShowOnMouseOverUnlessTour>
        </div>

        <LinkWrapper>
          <Link to={link} className={`${type}-link`}>
            <Typography.Body type="Link">{title}</Typography.Body>
          </Link>
        </LinkWrapper>
      </Left>
      <Right>
        <Row style={{ flex: 1 }}>
          {badges.map((b) => (
            <div style={{ width: 150 }} key={b.text}>
              {b && <Badge text={b.text} badgeType="Default" />}
            </div>
          ))}
        </Row>
        <div style={{ flex: 1 }}>
          {access && <AccessUsers access={access} />}
        </div>
        <Row centerAlign spaceBetween style={{ flex: 1 }}>
          {!isLastViewedAtDisabled && (
            <Typography.Body type="Body 12">
              {lastViewedAt
                ? `${moment.utc(lastViewedAt).format("MMM DD 'YY")}`
                : '-'}
            </Typography.Body>
          )}
          {contextMenu}
        </Row>
      </Right>
    </ListItemWrapper>
  );
};

export default IndexListItem;
