import React from 'react';

interface ReportDrillDownSettingsType {
  hideDateDrillDowns: boolean;
}

const ReportDrillDownOptionsContext =
  React.createContext<ReportDrillDownSettingsType>({
    hideDateDrillDowns: false,
  });

export default ReportDrillDownOptionsContext;
