import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';
import Colors from '../../../theme/colors';
import Row from '../../Common/Row';
import isDateOptionEqual from '../isDateOptionEqual';
import aguid from 'aguid';
import TextInput from '../../../kingpin/atoms/TextInput';
import Typography from 'kingpin/atoms/Typography';
import Icon from '../../../kingpin/atoms/Icon';

const FieldItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  width: 100%;
  cursor: pointer;
  padding-left: 4px;
  padding-right: 8px;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const FieldList = styled.div`
  margin-top: 12px;
  height: 192px;
  overflow-y: auto;
`;
interface SelectedListProps {
  isSearching: boolean;
}
const SelectedList = styled.div<SelectedListProps>`
  margin-bottom: ${(props) => (props.isSearching ? '0px' : '16px')};
`;

const ClearSelected = styled.div`
  color: ${Colors.TITLE_TEXT};
  padding-left: 4px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const PreSelected = ({
  alreadySelectedOptions,
  onKeywordSelected,
  onKeywordDeselected,
  selectedOptions,
  isSearching,
  clearSelectedOptions,
  canClearSelectedOptions,
}: {
  alreadySelectedOptions: DateOption[];
  selectedOptions: DateOption[];
  onKeywordSelected: (k: DateOption) => void;
  onKeywordDeselected: (k: DateOption) => void;
  isSearching: boolean;
  canClearSelectedOptions: boolean;
  clearSelectedOptions: () => void;
}) => (
  <SelectedList isSearching={isSearching}>
    {canClearSelectedOptions && (
      <ClearSelected onClick={clearSelectedOptions}>
        Clear selected items
      </ClearSelected>
    )}
    {alreadySelectedOptions.map((o) => (
      <FieldItem
        key={aguid(o)}
        onClick={() => {
          if (
            selectedOptions.some((selectedO) => isDateOptionEqual(selectedO, o))
          ) {
            onKeywordDeselected(o);
          } else {
            onKeywordSelected(o);
          }
        }}
      >
        <Typography.Body type="Body 12">{o.label}</Typography.Body>
        {selectedOptions.includes(o) && <Icon icon="checkmark" />}
      </FieldItem>
    ))}
  </SelectedList>
);

const DatePicker = ({
  isSearching,
  canClearSelectedOptions,
  alreadySelectedOptions,
  options,
  selectedOptions,
  searchText,
  onSearchTextChanged,
  onKeywordSelected,
  onKeywordDeselected,
  onConfirm,
  isEditing,
  clearSelectedOptions,
  isValid,
}: {
  isSearching: boolean;
  canClearSelectedOptions: boolean;
  alreadySelectedOptions?: DateOption[];
  options: DateOption[];
  selectedOptions: DateOption[];
  searchText: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeywordSelected: (k: DateOption) => void;
  onKeywordDeselected: (k: DateOption) => void;
  onConfirm: () => void;
  isEditing: boolean;
  clearSelectedOptions: () => void;
  isValid: boolean;
}) => (
  <div style={{ padding: 8 }}>
    <TextInput
      value={searchText}
      onChange={onSearchTextChanged}
      icon="search"
      inputSize="Small"
      placeholder="Search"
    />
    <FieldList>
      {alreadySelectedOptions && (
        <PreSelected
          isSearching={isSearching}
          alreadySelectedOptions={alreadySelectedOptions}
          canClearSelectedOptions={canClearSelectedOptions}
          onKeywordDeselected={onKeywordDeselected}
          onKeywordSelected={onKeywordSelected}
          selectedOptions={selectedOptions}
          clearSelectedOptions={clearSelectedOptions}
        />
      )}
      {options.map((o) => (
        <FieldItem
          key={o.label}
          onClick={() => {
            if (
              selectedOptions.some((selectedO) =>
                isDateOptionEqual(o, selectedO),
              )
            ) {
              onKeywordDeselected(o);
            } else {
              onKeywordSelected(o);
            }
          }}
        >
          {o.label}
          {selectedOptions.some((selectedO) =>
            isDateOptionEqual(o, selectedO),
          ) && <Icon icon="checkmark" />}
        </FieldItem>
      ))}
      {options.length === 0 && <span>No options found</span>}
    </FieldList>
    <Row centerAlign style={{ justifyContent: 'flex-end', marginTop: 16 }}>
      <Button
        isDisabled={!isValid}
        onClick={onConfirm}
        label={isEditing ? 'Update' : 'Add'}
        type="Primary"
        size="Small"
      />
    </Row>
  </div>
);

export default DatePicker;
