import React, { useContext } from 'react';
import FeatureGatesContext from '../contexts/FeatureGatesContext';
import CurrentUserContext from '../contexts/CurrentUserContext';
import Tooltip from './Tooltip';
import Icon from '../kingpin/atoms/Icon';
import Colors2 from '../theme/Colors2';

const GOALS: FleetOps.FeatureGate = 'goals';
const SELF_SERVE: FleetOps.FeatureGate = 'selfServe';
const MICROSOFT_SSO: FleetOps.FeatureGate = 'microsoftSso';
const ENTITY_DETAILS: FleetOps.FeatureGate = 'entityDetails';
const ACTIVITY_COSTS: FleetOps.FeatureGate = 'activityCosts';
const FILE_UPLOAD: FleetOps.FeatureGate = 'fileUpload';
const CUSTOMER_AWARDS_TRACKER: FleetOps.FeatureGate = 'customerAwardsTracker';
const DRIVER_BONUS: FleetOps.FeatureGate = 'driverBonus';
const PERFORMANCE: FleetOps.FeatureGate = 'performance';
const TEMPLATES: FleetOps.FeatureGate = 'templates';
const VIEWER_ROLE_ASSIGNMENT: FleetOps.FeatureGate = 'viewerRoleAssignment';

export const FEATURE_GATES = {
  GOALS,
  SELF_SERVE,
  MICROSOFT_SSO,
  ENTITY_DETAILS,
  ACTIVITY_COSTS,
  FILE_UPLOAD,
  CUSTOMER_AWARDS_TRACKER,
  DRIVER_BONUS,
  PERFORMANCE,
  TEMPLATES,
  VIEWER_ROLE_ASSIGNMENT,
};

const FeatureGate = ({
  featureName,
  children,
  fallback,
}: {
  featureName: FleetOps.FeatureGate;
  children: JSX.Element | JSX.Element[];
  fallback?: JSX.Element | JSX.Element[];
}) => {
  const { isFleetOpsStaff } = useContext(CurrentUserContext);
  const {
    isGoalsEnabled,
    isSelfServeEnabled,
    isMicrosoftSsoEnabled,
    isEntityDetailsEnabled,
    isActivityCostsEnabled,
    isFileUploadEnabled,
    isCustomerAwardsTrackerEnabled,
    isDriverBonusEnabled,
    isPerformanceEnabled,
    isTemplatesEnabled,
    isViewerRoleAssignmentEnabled,
  } = useContext(FeatureGatesContext);

  if (featureName === FEATURE_GATES.GOALS && isGoalsEnabled) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  if (featureName === FEATURE_GATES.SELF_SERVE && isSelfServeEnabled) {
    return <>{children}</>;
  }

  if (featureName === FEATURE_GATES.MICROSOFT_SSO && isMicrosoftSsoEnabled) {
    return <>{children}</>;
  }

  if (featureName === FEATURE_GATES.ENTITY_DETAILS && isEntityDetailsEnabled) {
    return <>{children}</>;
  }

  if (featureName === FEATURE_GATES.ACTIVITY_COSTS && isActivityCostsEnabled) {
    return <>{children}</>;
  }

  if (featureName === FEATURE_GATES.FILE_UPLOAD) {
    if (!isFileUploadEnabled && isFleetOpsStaff) {
      return (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Tooltip
              isFlexAnchor
              content="Please note that you can only see this content as you are FleetOps
              Staff"
            >
              <Icon icon={'warning'} color={Colors2.Secondary.warning} />
            </Tooltip>
          </div>
          {children}
        </>
      );
    }
    if (isFileUploadEnabled) {
      return <>{children}</>;
    }
    return null;
  }

  if (
    featureName === FEATURE_GATES.CUSTOMER_AWARDS_TRACKER &&
    isCustomerAwardsTrackerEnabled
  ) {
    return <>{children}</>;
  }

  if (featureName === FEATURE_GATES.DRIVER_BONUS && isDriverBonusEnabled) {
    return <>{children}</>;
  }

  if (featureName === FEATURE_GATES.PERFORMANCE && isPerformanceEnabled) {
    return <>{children}</>;
  }

  if (featureName === FEATURE_GATES.TEMPLATES && isTemplatesEnabled) {
    return <>{children}</>;
  }

  if (
    featureName === FEATURE_GATES.VIEWER_ROLE_ASSIGNMENT &&
    isViewerRoleAssignmentEnabled
  ) {
    return <>{children}</>;
  }

  if (fallback) {
    return <>{fallback}</>;
  }

  return null;
};

export default FeatureGate;
