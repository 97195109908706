import { useCallback, useContext, useEffect, useState } from 'react';
import DashboardsContext from 'contexts/DashboardsContext';
import EntityDefinitionsContext from 'contexts/EntityDefinitionsContext';
import {
  buildShowEntityApp,
  buildShowEntityAppContentTab,
} from 'navigation/appRoutes';

const useEntityLink = ({
  entity,
  value,
}: {
  entity: EntityDetails.Entity;
  value: string;
}) => {
  const { allDashboards } = useContext(DashboardsContext);
  const { entityDetailsApps } = useContext(EntityDefinitionsContext);
  const buildLink = useCallback(() => {
    const app = entityDetailsApps.find((a) => a.entityId === entity.id);
    if (!app) {
      return undefined;
    }

    const firstTab = app.tabs.find(
      (t) =>
        !!allDashboards.find(
          (d) => d.id === t.typeId && t.type === 'dashboard',
        ),
    );
    if (firstTab) {
      return buildShowEntityAppContentTab({
        field: entity.primaryField,
        value,
        tabType: firstTab.type,
        tabId: firstTab.typeId,
      });
    }
    return buildShowEntityApp({ field: entity.primaryField, value });
  }, [allDashboards, entity.id, entity.primaryField, entityDetailsApps, value]);
  const [link, setLink] = useState<string | undefined>(buildLink);
  useEffect(() => {
    setLink(buildLink());
  }, [buildLink]);

  return link;
};

export default useEntityLink;
