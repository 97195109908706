import React from 'react';
import Row from 'components/Common/Row';
import DragHandle from 'components/DragAndDropList/DragHandle';
import Typography from 'kingpin/atoms/Typography';
import InlineDialog from 'components/InlineDialog';
import Button from 'kingpin/atoms/Button';

import { isQuickFilter } from 'components/CustomerLaneCommitsBoard/QuickFilters';
import Column from 'screens/DataManager/DatasetDefinitions/Column';
import Colors2 from 'theme/Colors2';
import usePopup from 'hooks/usePopup';
import { List } from 'components/PerformanceBoards/PerformanceBoardSlideOut/SlideOutHeader/SlideOutHomeHeader';
import {
  ACTIVE_QUICK_FILTER_ID,
  CLOSED_QUICK_FILTER_ID,
} from 'hooks/useQuickFilters';
import { Col } from '../../BoardFieldsSettings/FieldsGrid/FieldsGrid';

const EditOptions = ({
  handleDelete,
  handleEdit,
  filter,
}: {
  handleDelete: (f: SavedFilter) => void;
  handleEdit: (f: SavedFilter) => void;
  filter: SavedFilter;
}) => {
  return (
    <List>
      <div style={{ marginBottom: 4, width: '100%', height: 32 }}>
        <Button
          type="Ghost"
          size="Small"
          label={'Edit'}
          onClick={() => handleEdit(filter)}
          icon="edit-filled"
        />
      </div>
      <div style={{ marginBottom: 4, width: '100%', height: 32 }}>
        <Button
          type="Ghost"
          size="Small"
          label={'Delete Filter'}
          onClick={() => handleDelete(filter)}
          icon="cross"
        />
      </div>
    </List>
  );
};

const QuickFiltersListItem = ({
  filter,
  deleteFilter,
  editFilter,
}: {
  filter: SavedFilter | QuickFilter | undefined;
  deleteFilter: (f: SavedFilter) => void;
  editFilter: (f: SavedFilter) => void;
}) => {
  const { isOpen, open, close } = usePopup();

  if (!filter || !filter.name) {
    return null;
  }

  return (
    <Row
      centerAlign
      spaceBetween
      style={{
        border: `1px solid ${Colors2.Grey['8']}`,
        margin: '-1px 0',
        height: '42px',
      }}
    >
      <Column>
        <DragHandle testId="testField" />
        <Typography.Body type="Body 12">{filter.name}</Typography.Body>
      </Column>
      {![ACTIVE_QUICK_FILTER_ID, CLOSED_QUICK_FILTER_ID].includes(filter.id) &&
        !isQuickFilter(filter) && (
          <Col width={40}>
            <InlineDialog
              isOpen={isOpen}
              onClose={close}
              content={
                <EditOptions
                  filter={filter}
                  handleDelete={deleteFilter}
                  handleEdit={editFilter}
                />
              }
              placement="bottom-end"
            >
              <div style={{ width: 'fit-content' }}>
                <Button
                  type="Ghost"
                  size="Small"
                  onClick={isOpen ? close : open}
                  icon="dots-menu"
                  testId={`${filter.id}-testId`}
                />
              </div>
            </InlineDialog>
          </Col>
        )}
    </Row>
  );
};

export default QuickFiltersListItem;
