import { getMetricLinkForDataset } from '../SpecificSet/routes';
import metricTypeCheckers from '../../../types/metricTypeCheckers';

const BASE = '/data-manager';

export const getMetricLink = (metric: Metrics.Metric, dataset?: string) => {
  if (dataset) {
    return getMetricLinkForDataset(metric, dataset);
  }

  if (metricTypeCheckers.isCompoundMetric(metric)) {
    return `${BASE}/compound-metrics?metricId=${metric.id}`;
  } else if (metricTypeCheckers.isNormalMetric(metric)) {
    return `${BASE}/metrics?metricId=${metric.id}`;
  } else {
    return '';
  }
};

const ALL_SETS_ROUTES = {
  BASE,
  DATASETS: BASE,
  METRICS: `${BASE}/metrics`,
  SHOW_METRIC: `${BASE}/metrics/:metricId`,
  COMPOUND_METRICS: `${BASE}/compound-metrics`,
  COSTS: `${BASE}/costs`,
  SHOW_COMPOUND_METRIC: `${BASE}/compound-metrics/:compoundMetricId`,
  SEARCH: `${BASE}/search`,
  ENTITIES: `${BASE}/entities`,
};

export default ALL_SETS_ROUTES;
