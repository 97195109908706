import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';

import LandingPage from '../../components/LandingPage';
import Branding from '../../components/Branding';
import Card from '../../components/Common/Card';
import Row from '../../components/Common/Row';
import Inputs from '../../components/Inputs';
import InputError from '../../components/InputError';
import ShowPassword from '../../components/ShowPassword';

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 34px;
`;

export const PasswordInput = ({
  password,
  onPasswordChanged,
  passwordErrors,
  passwordConfirm,
  onPasswordConfirmChanged,
  passwordConfirmError,
  isHidingPassword,
  setIsHidingPassword,
  firstLabel,
  secondLabel,
}: {
  password: string;
  onPasswordChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  passwordConfirm: string;
  passwordErrors?: string[];
  onPasswordConfirmChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  passwordConfirmError?: string;
  isHidingPassword: boolean;
  setIsHidingPassword: React.Dispatch<React.SetStateAction<boolean>>;
  firstLabel: string;
  secondLabel: string;
}) => (
  <>
    <div style={{ marginBottom: 16 }}>
      <Typography.Body type="Label">{firstLabel}</Typography.Body>
      <div style={{ position: 'relative' }}>
        <Inputs.TextInput
          value={password}
          onChange={onPasswordChanged}
          type={isHidingPassword ? 'password' : 'text'}
          name="password"
        />
        <ShowPassword
          isHiding={isHidingPassword}
          setIsHiding={setIsHidingPassword}
        />
      </div>
      {passwordErrors && (
        <div style={{ marginTop: 4 }}>
          {passwordErrors.map((pe) => (
            <InputError text={pe} key={Math.random()} />
          ))}
        </div>
      )}
    </div>
    <div style={{ marginBottom: 16 }}>
      <Typography.Body type="Label">{secondLabel}</Typography.Body>
      <div style={{ position: 'relative' }}>
        <Inputs.TextInput
          value={passwordConfirm}
          onChange={onPasswordConfirmChanged}
          type={isHidingPassword ? 'password' : 'text'}
        />
        <ShowPassword
          isHiding={isHidingPassword}
          setIsHiding={setIsHidingPassword}
        />
      </div>
      {passwordConfirmError && (
        <div style={{ marginTop: 4 }}>
          <InputError text={passwordConfirmError} />
        </div>
      )}
    </div>
  </>
);

const AcceptInvite = ({
  email,
  displayName,
  onDisplayNameChanged,
  onDisplayNameFocused,
  firstName,
  onFirstNameChanged,
  lastName,
  onLastNameChanged,
  password,
  onPasswordChanged,
  passwordErrors,
  passwordConfirm,
  onPasswordConfirmChanged,
  passwordConfirmError,
  onSubmit,
  isLoading,
  isValid,
  errorMessage,
  isHidingPassword,
  setIsHidingPassword,
}: {
  email: string;
  displayName: string;
  onDisplayNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  onDisplayNameFocused: () => void;
  firstName: string;
  onFirstNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  lastName: string;
  onLastNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  password: string;
  onPasswordChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  passwordConfirm: string;
  passwordErrors?: string[];
  onPasswordConfirmChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  passwordConfirmError?: string;
  onSubmit: () => void;
  isLoading: boolean;
  isValid: boolean;
  errorMessage?: string;
  isHidingPassword: boolean;
  setIsHidingPassword: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <LandingPage style={{ width: 'unset' }}>
    <Branding />
    <Card
      style={{ width: 466, padding: '31px 44px 31px 32px', marginBottom: 36 }}
    >
      <Heading>
        <Typography.Header type="H4">{`Welcome ${
          email.split('@')[0]
        }`}</Typography.Header>
        <Typography.Body type="Body 12">
          Please complete your profile details below
        </Typography.Body>
      </Heading>
      <Row centerAlign spaceBetween style={{ marginBottom: 16 }}>
        <div style={{ marginRight: 20, flex: 1 }}>
          <Typography.Body type="Label">First Name</Typography.Body>
          <Inputs.TextInput value={firstName} onChange={onFirstNameChanged} />
        </div>
        <div style={{ flex: 1 }}>
          <Typography.Body type="Label">Last Name</Typography.Body>
          <Inputs.TextInput value={lastName} onChange={onLastNameChanged} />
        </div>
      </Row>
      <div style={{ marginBottom: 16 }}>
        <Typography.Body type="Label">Display Name</Typography.Body>
        <Inputs.TextInput
          value={displayName}
          onChange={onDisplayNameChanged}
          onFocus={onDisplayNameFocused}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Body type="Label">Email</Typography.Body>
        <Inputs.TextInput
          value={email}
          readOnly
          onChange={window.tokenFunction}
          state={'Disabled'}
        />
      </div>
      <PasswordInput
        password={password}
        onPasswordChanged={onPasswordChanged}
        passwordErrors={passwordErrors}
        passwordConfirm={passwordConfirm}
        onPasswordConfirmChanged={onPasswordConfirmChanged}
        passwordConfirmError={passwordConfirmError}
        isHidingPassword={isHidingPassword}
        setIsHidingPassword={setIsHidingPassword}
        firstLabel="Create Password"
        secondLabel="Re-enter Password"
      />
      {errorMessage && <InputError text={errorMessage} />}
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={onSubmit}
          isDisabled={!isValid || isLoading}
          isLoading={isLoading}
          label="Sign Up"
          type="Primary"
          size="Small"
        />
      </Row>
    </Card>
  </LandingPage>
);

export default AcceptInvite;
