import React, { useContext, useState } from 'react';
import QuickFilters from './QuickFilters';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';
import BoardContext from '../../../../contexts/BoardContext';
import { DropResult } from 'react-beautiful-dnd';
import updateBoard from '../../../../api/boards/updateBoard';
import deleteSavedFilter from '../../../../api/deleteSavedFilter';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import isPerformanceBoard from '../../../../isPerformanceBoard';
import STORE from '../../../../store';

const QuickFiltersContainer = ({ close }: { close: () => void }) => {
  const { accountRef, selectedAccountId } = useContext(AccountPickerContext);
  const { board, quickFilters } = useContext(BoardContext);
  const { quickFilterOrder } = board;
  const [isCreatingNewFilter, setIsCreatingNewFilter] =
    useState<boolean>(false);

  const orderedFilters = quickFilterOrder
    .map((filterId) => quickFilters.find((f) => f.id === filterId))
    .filter((f) => !!f) as (SavedFilter | QuickFilter)[];
  const filtersNotInOrder = quickFilters.filter(
    (f) => !quickFilterOrder.includes(f.id),
  );
  orderedFilters.push(...filtersNotInOrder);

  const onDragEnd = (result: DropResult) => {
    const { draggableId, destination } = result;
    if (!destination) {
      return;
    }

    const itemsWithoutDropped = orderedFilters.filter(
      (f) => f.id !== draggableId,
    );

    const newOrder = [
      ...itemsWithoutDropped.slice(0, destination.index).map((f) => f.id),
      draggableId,
      ...itemsWithoutDropped
        .slice(destination.index, itemsWithoutDropped.length)
        .map((f) => f.id),
    ];

    const newBoard = {
      ...board,
      quickFilterOrder: newOrder,
    };

    if (isPerformanceBoard(newBoard)) {
      STORE.contentDistributions
        .getPerformanceBoardsRef({
          accountId: selectedAccountId,
        })
        .doc(newBoard.id)
        .set(newBoard);
    } else {
      updateBoard(newBoard, accountRef);
    }
  };

  const deleteFilter = (f: SavedFilter) => {
    deleteSavedFilter(f, accountRef).then(() => {
      const orderWithoutDeleted = quickFilterOrder.filter(
        (fid) => !fid.includes(f.id),
      );
      const newBoard = {
        ...board,
        quickFilterOrder: orderWithoutDeleted,
      };

      if (isPerformanceBoard(newBoard)) {
        STORE.contentDistributions
          .getPerformanceBoardsRef({
            accountId: selectedAccountId,
          })
          .doc(newBoard.id)
          .set(newBoard);
      } else {
        updateBoard(newBoard, accountRef);
      }
    });
  };

  return (
    <QuickFilters
      isCreatingNewFilter={isCreatingNewFilter}
      setIsCreatingNewFilter={setIsCreatingNewFilter}
      orderedFilters={orderedFilters}
      onDragEnd={onDragEnd}
      deleteFilter={deleteFilter}
      close={close}
    />
  );
};

const Gate = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => (
  <ModalTransition>
    {isOpen && (
      <ModalDialog
        onClose={close}
        shouldScrollInViewport={false}
        autoFocus={false}
      >
        <QuickFiltersContainer close={close} />
      </ModalDialog>
    )}
  </ModalTransition>
);

export default Gate;
