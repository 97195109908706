import userTypeCheckers from './userTypeCheckers';

const getUserDisplayName = (user: UserManagement.User): string => {
  if (userTypeCheckers.isWallboardUser(user)) {
    return '-';
  }

  return user.displayName
    ? user.displayName
    : `${user.firstName} ${user.lastName}`;
};

export default getUserDisplayName;
