import React, { useContext } from 'react';
import Button from 'kingpin/atoms/Button';
import FlexCentered from '../../../../components/Common/FlexCentered';
import { Img } from '../../../Home/RecentsCard/RecentsCard';
import NoIndexItemsFoundSrc from '../../../../images/no-index-items-found.svg';
import Typography from 'kingpin/atoms/Typography';
import MetricListStyles from './styles';
import DataManagerSpecificSetContext from '../../../../contexts/DataManagerSpecificSetContext';

const NoMetrics = ({ onCreateClicked }: { onCreateClicked: () => void }) => {
  const { isOnSpecificSet } = useContext(DataManagerSpecificSetContext);
  const offset = isOnSpecificSet
    ? MetricListStyles.SPECIFIC_SET_LIST_HEIGHT_OFFSET
    : MetricListStyles.ALL_SETS_LIST_HEIGHT_OFFSET;

  return (
    <FlexCentered
      style={{
        height: `calc(100vh - ${offset}px - 10px)`,
      }}
    >
      <Img src={NoIndexItemsFoundSrc} />
      <div style={{ marginBottom: 24 }}>
        <Typography.Body type="Body 12">No metrics to show yet</Typography.Body>
      </div>
      <Button
        type="Primary"
        size="Small"
        onClick={onCreateClicked}
        label={'Create Metric'}
        icon={'add'}
      />
    </FlexCentered>
  );
};

export default NoMetrics;
