import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

import toMetricInput from '../utils/metrics/toMetricInput';
import runAggregateCompoundMetricQuery from './runAggregateCompoundMetric';
import metricTypeCheckers from '../types/metricTypeCheckers';
import getQueryAlias from './getQueryAlias';

export const buildAggregateCompoundMetricQuery = (
  metrics: MetricInput[],
  expressions: Expression2[],
  filterInput: FilterInput[],
  dateScope: DateRangeInput,
  query: string,
  alias: string,
  groupBy?: V5GroupBy,
  trendByCalendarInterval?: FleetOps.Interval,
  trendByFixedIntervalDays?: number,
  groupByDayOfWeek?: boolean,
  groupByRanges?: V5RangeAggregation,
  groupByInterval?: V5Histogram,
) => {
  return {
    query: gql`
      query ${alias}(
        $metrics: [MetricInput]!
        $filters: [FilterInput]!
        $dateScope: [DateRangeInput!]!
        $expressions: [Expression2]!
        $groupBy: GroupBy
        $trendByCalendarInterval: Interval
        $trendByFixedIntervalDays: Int
        $groupByDayOfWeek: Boolean
        $groupByRanges: RangeAggregation
        $groupByInterval: Histogram
      ) {
        ${query}(
          metrics: $metrics
          filters: $filters
          dateScope: $dateScope
          expressions: $expressions
          groupBy: $groupBy
          trendByCalendarInterval: $trendByCalendarInterval
          trendByFixedIntervalDays: $trendByFixedIntervalDays
          groupByDayOfWeek: $groupByDayOfWeek
          groupByRanges: $groupByRanges
          groupByInterval: $groupByInterval
        )
      }
    `,
    variables: {
      metrics,
      filters: filterInput,
      dateScope,
      expressions,
      groupBy,
      trendByCalendarInterval,
      trendByFixedIntervalDays,
      groupByDayOfWeek,
      groupByRanges,
      groupByInterval,
    },
  };
};

const aggregateMetricMatrix = async ({
  client,
  metrics,
  expressions,
  filterInput,
  dateScope,
  groupBy,
  trendByCalendarInterval,
  trendByFixedIntervalDays,
  groupByDayOfWeek,
  groupByRange,
  groupByInterval,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  metrics: (Metrics.NormalMetric | Metrics.SpecialMetric)[];
  expressions: Expression2[];
  filterInput: FilterInput[];
  dateScope: DateRangeInput;
  groupBy?: V5GroupBy;
  trendByCalendarInterval?: FleetOps.Interval;
  trendByFixedIntervalDays?: number;
  groupByDayOfWeek?: boolean;
  groupByRange?: V5RangeAggregation;
  groupByInterval?: V5Histogram;
}): Promise<MetricsResponse> => {
  const maybeSpecialMetric = metrics.find(metricTypeCheckers.isSpecialMetric);
  const alias = maybeSpecialMetric
    ? maybeSpecialMetric.query
    : getQueryAlias({
        groupBy,
        trendByCalendarInterval,
        trendByFixedIntervalDays,
        groupByDayOfWeek,
        groupByRange,
        groupByInterval,
      });

  const queryName = maybeSpecialMetric
    ? maybeSpecialMetric.query
    : 'aggregateCompoundMetric';
  const sanitizedMetrics = metrics
    .filter(metricTypeCheckers.isNormalMetric)
    .map(toMetricInput);

  const query = buildAggregateCompoundMetricQuery(
    maybeSpecialMetric ? [] : sanitizedMetrics,
    expressions,
    filterInput,
    dateScope,
    queryName,
    alias,
    groupBy,
    trendByCalendarInterval,
    trendByFixedIntervalDays,
    groupByDayOfWeek,
    groupByRange,
    groupByInterval,
  );

  const request = runAggregateCompoundMetricQuery(query, client);
  const response = await request;
  const data = response.data[queryName] as MetricsResponse;
  return data.map((d) => ({
    ...d,
    filterInput: filterInput[0],
    dateScope,
  }));
};

export default aggregateMetricMatrix;
