import React from 'react';

interface PerformanceBoardSettingsType {
  navState: PerformanceBoardTypes.SettingsNavState;
  setNavState: React.Dispatch<PerformanceBoardTypes.SettingsNavState>;
  board: PerformanceBoardTypes.Board;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: React.Dispatch<boolean>;
}

const SettingsContext = React.createContext<PerformanceBoardSettingsType>(
  {} as PerformanceBoardSettingsType,
);

export default SettingsContext;
