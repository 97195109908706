import React from 'react';

interface AccountContextType {
  unitsLocale: string;
  id: string;
  carrierName: string;
  status?: 'initialized' | 'pending' | 'active' | 'error';
  error?: string;
  isLoadingCarrierName: boolean;
  tier?: ProductTier;
  weekStartsOn: WeekStartsOn;
  driverScoreDataSet?: string;
  isPendingManualAchVerification?: boolean;
  isPendingAutomaticAchVerification?: boolean;
  automaticAchVerificationFailed?: boolean;
  achVerificationStartedOn?: string;
  isBillingSetUp?: boolean;
  isTmsSetUp?: boolean;
  tms?: string;
  adfRequired?: boolean;
  isLoading: boolean;
  isDemoAccount?: boolean;
  demoAccountNow?: string;
  isAccountOnBoarded: boolean;
  timezone?: string;
  isTemplateAccount: boolean;
}

export const DEFAULT_ACCOUNT_CONTEXT = {
  id: 'MOCK_ACCOUNT_ID',
  unitsLocale: 'imperial',
  weekStartsOn: process.env.NODE_ENV === 'test' ? 'MON' : 'SUN',
  isLoading: process.env.NODE_ENV === 'test' ? false : true,
  status: process.env.NODE_ENV === 'test' ? 'active' : 'error',
  carrierName: process.env.NODE_ENV === 'test' ? 'testCarrierName' : 'error',
} as AccountContextType;

const AccountContext = React.createContext<AccountContextType>(
  DEFAULT_ACCOUNT_CONTEXT,
);

export default AccountContext;
