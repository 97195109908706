import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const getFilterValues = async ({
  field,
  value = '',
  filterInput,
  dateScope,
  limit = 1000,
  accountId,
  client,
}: {
  field: string;
  value?: string;
  filterInput?: FilterInput;
  dateScope: DateRangeInput;
  limit?: number;
  accountId: string;
  client: ApolloClient<NormalizedCacheObject>;
}) => {
  const query = {
    query: gql`
      query getFilterValues(
        $field: String!
        $value: String
        $limit: Int
        $filters: [FilterInput]
        $dateScope: [DateRangeInput!]!
        $accountId: String
      ) {
        getFilterValues(
          field: $field
          value: $value
          limit: $limit
          filters: $filters
          accountId: $accountId
          dateScope: $dateScope
        )
      }
    `,
    variables: {
      field,
      value,
      limit,
      filters: [filterInput],
      accountId,
      dateScope,
    },
  };

  const response = await client.query(query);
  return response.data.getFilterValues as string[];
};

export default getFilterValues;
