import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';

import CreateComment from './CreateComment';
import CommentsContext from '../../../contexts/CommentsContext';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import usePopup from '../../../hooks/usePopup';
import userDisplayName from '../../../services/userDisplayName';
import moment from 'moment';
import apiCreateComment from 'api/createComment';
import { ContentEditableEvent } from 'react-contenteditable';
import {
  currentWord,
  isMention,
  removeSpecialCharacters,
  words,
} from '../regexs';
import withoutNulls from '../../../api/search/withoutNulls';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import NotificationFactory from '../../../NotificationFactory';
import NotificationsContext from '../../../contexts/NotificationsContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import useUsers from '../../../hooks/useUsers';
import getStorageRef from './getStorageRef';
import LocalTimelineContext from '../../../contexts/Timeline/LocalTimelineContext';
import TaskContext from 'contexts/TaskContext';
import getIdentifier from '../../../getIdentifier';

const aguid = require('aguid');

const CreateCommentContainer = ({
  disableBottomBorder,
  mentionedEmailBody,
  watcherEmailBody,
  emailBodyLn1Extra,
  link,
  sendButtonText,
}: {
  disableBottomBorder?: boolean;
  mentionedEmailBody: string;
  watcherEmailBody: string;
  emailBodyLn1Extra?: string;
  link: string;
  sendButtonText?: string;
}) => {
  const { sendNotification } = useContext(NotificationsContext);
  const users = useUsers(false);
  const mentionableUsers = useUsers(true);
  const { accountRef, selectedAccount } = useContext(AccountPickerContext);
  const { commentableType, commentableId, linkTo, watchers } =
    useContext(CommentsContext);
  const { task } = useContext(TaskContext);
  const currentUser = useContext(CurrentUserContext);
  const {
    isOpen: isSuggestionsOpen,
    open: openSuggestions,
    close: closeSuggestions,
  } = usePopup();

  const [commentId, setCommentId] = useState<string>(() => aguid());
  const [isCreatingComment, setIsCreatingComment] = useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [commentHtml, setCommentHtml] = useState<string>('');
  const [mentionInput, setMentionInput] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [attachedFiles, setAttachedFiles] = useState<
    {
      fileName: string;
      isUploading: boolean;
      downloadUrl?: string;
    }[]
  >([]);
  const [isUploadingFiles, setIsUploadingFiles] = useState<boolean>(false);
  const { trackEvent } = useContext(AnalyticsContext);
  const { addEvent } = useContext(LocalTimelineContext);

  const getPath = useCallback(
    (fileName: string) =>
      `accounts/${selectedAccount.accountId}/comments/${commentId}/${fileName}`,
    [commentId, selectedAccount.accountId],
  );

  const convertDataUrlToFileUrl = useCallback(
    async (dataUrlSrc: string) => {
      // const encodedImg = dataUrlSrc.split(',')[1].split('"')[0];
      // const imageType = dataUrlSrc.split(',')
      const convertBase64ToBlob = () => {
        // Split into two parts
        const parts = dataUrlSrc.split(';base64,');

        // Hold the content type
        const imageType = parts[0].split(':')[1];

        // Decode Base64 string
        const decodedData = window.atob(parts[1].split('"')[0]);

        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);

        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
          uInt8Array[i] = decodedData.charCodeAt(i);
        }

        // Return BLOB image after conversion
        return new Blob([uInt8Array], { type: imageType });
      };

      // const blob = new Blob([decodedImg], { type: 'image/png' });
      const blob = convertBase64ToBlob();
      const ref = getStorageRef(
        `accounts/${
          selectedAccount.accountId
        }/commentInlineImages/${commentId}/${getIdentifier()}.png`,
      );
      await ref.put(blob, { cacheControl: 'private, max-age=86400' });
      const url = await ref.getDownloadURL();
      if (typeof url !== 'string') {
        const error = new Error('');
        error.name = 'Unexpected url for image';
      }

      return String.raw`src="${url}"`;
    },
    [commentId, selectedAccount.accountId],
  );

  const sanitiseCommentHtml = useCallback(
    async (html: string) => {
      async function replaceAsync(
        str: string,
        regex: RegExp,
        asyncFn: (str: string) => Promise<string>,
      ) {
        const matches = str.match(regex);
        if (matches) {
          const replacement = await asyncFn(matches[0]);
          str = str.replace(matches[0], replacement);
          str = await replaceAsync(str, regex, asyncFn);
        }
        return str;
      }

      const clean = await replaceAsync(
        html,
        /src="data:.*?"/,
        convertDataUrlToFileUrl,
      );

      return clean.replaceAll('"', "'");
    },
    [convertDataUrlToFileUrl],
  );

  const onFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    setIsUploadingFiles(true);
    setAttachedFiles((currentFiles) => {
      if (!event.target.files) {
        return currentFiles;
      }

      const newFiles = {} as {
        [fileName: string]: {
          fileName: string;
          isUploading: boolean;
          downloadUrl?: string;
        };
      };
      currentFiles.forEach((cf) => {
        newFiles[cf.fileName] = cf;
      });

      for (let i = 0; i < event.target.files.length; i++) {
        const f = event.target.files[i];
        const file = {
          fileName: f.name,
          isUploading: true,
          downloadUrl: undefined,
        };
        newFiles[file.fileName] = file;
      }

      return Object.values(newFiles);
    });
    const uploadRequests = [] as Promise<{
      fileName: string;
      downloadUrl: string;
      isUploading: boolean;
    }>[];
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      const filePath = getPath(file.name);
      const ref = getStorageRef(filePath);
      const request = ref
        .put(file, { cacheControl: 'private, max-age=86400' })
        .then(async () => {
          const downloadUrl = await ref.getDownloadURL();
          const fileName = file.name;
          return {
            fileName,
            downloadUrl,
            isUploading: false,
          };
        });
      uploadRequests.push(request);
    }

    Promise.all(uploadRequests).then((attachedFile) => {
      setAttachedFiles((currentFiles) => {
        if (!event.target.files) {
          return currentFiles;
        }

        const newFiles = {} as {
          [fileName: string]: {
            fileName: string;
            isUploading: boolean;
            downloadUrl?: string;
          };
        };
        currentFiles.forEach((cf) => {
          newFiles[cf.fileName] = cf;
        });

        attachedFile.forEach((newAttachedFile) => {
          newFiles[newAttachedFile.fileName] = newAttachedFile;
        });

        return Object.values(newFiles);
      });
      setIsUploadingFiles(false);
    });
  };

  const onFileDeleted = useCallback(
    (fileName: string) => {
      setIsUploadingFiles(true);
      const deletePath = getPath(fileName);

      getStorageRef(deletePath)
        .delete()
        .then(() => {
          setAttachedFiles((attached) =>
            attached.filter((a) => a.fileName !== fileName),
          );
          setIsUploadingFiles(false);
        });
    },
    [getPath],
  );

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && isSuggestionsOpen && !event.ctrlKey) {
      event.preventDefault();
    }
  };

  const onTextChange = (event: ContentEditableEvent) => {
    const text = event.target.value;
    if (commentHtml === '' && text !== '') {
      trackEvent('Comments - Began Writing', { commentableType });
    }
    setCommentHtml(text);
    if (isMention(currentWord(text))) {
      if (!isSuggestionsOpen) {
        openSuggestions();
      }
      setMentionInput(removeSpecialCharacters(currentWord(text)));
    } else if (isSuggestionsOpen) {
      closeSuggestions();
    }
  };

  const onMentionButtonClick = () => {
    setMentionInput('');
    openSuggestions();
  };

  const onSuggestionSelected = (
    user:
      | UserManagement.FleetOpsStaffUser
      | UserManagement.SignedUpUser
      | UserManagement.PendingUser,
  ) => {
    const displayName = userDisplayName(user);

    const mentionInputIndex = commentHtml.lastIndexOf(`@${mentionInput}`);
    const inputUpToMention = commentHtml.slice(0, mentionInputIndex);
    const newInput = `${inputUpToMention}@${displayName} `;
    setIsInputFocused(true);
    setCommentHtml(newInput);
    closeSuggestions();
  };

  useEffect(() => {
    if (!isSuggestionsOpen) {
      setMentionInput('');
    }
  }, [isSuggestionsOpen]);

  const cancelComment = () => {
    setCommentHtml('');
    closeSuggestions();
    setIsInputFocused(false);
    setIsCreatingComment(false);
    attachedFiles.forEach((f) => {
      onFileDeleted(f.fileName);
    });
    trackEvent('Comments - Cancelled', { commentableType });
  };

  const createComment = useCallback(async () => {
    if (isSaving) {
      return;
    }
    if (isUploadingFiles) {
      alert('Please wait while we upload your files');
      return;
    }

    setIsSaving(true);
    const cleanCommentHtml = await sanitiseCommentHtml(commentHtml);
    const mentions = words(cleanCommentHtml).filter(isMention);
    const twoWordMentions = mentions.map((mention) => {
      try {
        const wordIndex = words(cleanCommentHtml).findIndex(
          (w) => w === mention,
        );
        return `${mention} ${words(cleanCommentHtml)[wordIndex + 1]}`;
      } catch (ex) {
        return '';
      }
    });
    const mentionedUsers = _.uniq(
      mentionableUsers
        .filter((user) =>
          mentions
            .concat(twoWordMentions)
            .map(removeSpecialCharacters)
            .includes(removeSpecialCharacters(userDisplayName(user))),
        )
        .map((user) => user.id),
    );

    const comment = {
      id: commentId,
      commentableType,
      commentableId,
      createdBy: currentUser.id,
      text: cleanCommentHtml,
      mentionedUsers,
      createdOn: moment.utc().toISOString(),
      linkTo,
      attachedFiles: attachedFiles.map((a) => ({
        fileName: a.fileName,
        path: getPath(a.fileName),
      })),
    } as CommentType;

    trackEvent('Comments - Created', { commentableType });
    setIsCreatingComment(true);
    apiCreateComment(withoutNulls(comment), accountRef).then(() => {
      const addEventPromise = (() => {
        if (addEvent && !!task) {
          return addEvent({
            actionText: 'added a comment',
            contentText: comment.text,
            contextText: `to ${task.title}`,
            isContentTextRich: true,
            interaction: {
              type: 'Show All Comments',
              taskId: task.id,
            },
          });
        }

        return Promise.resolve();
      })();

      const mentionedNotification = NotificationFactory.mentionedInComment({
        commenter: currentUser.displayName,
        emailBodyLn1: mentionedEmailBody,
        emailBodyLn1Extra,
        link,
        comment,
      });
      const watcherNotification = NotificationFactory.newComment({
        commenter: currentUser.displayName,
        emailBodyLn1: watcherEmailBody,
        emailBodyLn1Extra,
        link,
        comment,
      });

      const mentionedRequests = mentionedUsers.map((uid) =>
        sendNotification(mentionedNotification, uid),
      );
      const watcherRequests = watchers
        .filter((w) => users.some((u) => u.id === w)) // restrict @fleetops.com
        .filter((w) => !mentionedUsers.includes(w))
        .map((uid) => sendNotification(watcherNotification, uid));
      Promise.all([
        ...mentionedRequests,
        ...watcherRequests,
        addEventPromise,
      ]).then(() => {
        setCommentHtml('');
        setAttachedFiles([]);
        setIsInputFocused(false);
        setIsCreatingComment(false);
        setCommentId(aguid());
        setIsSaving(false);
      });
    });
  }, [
    isSaving,
    isUploadingFiles,
    sanitiseCommentHtml,
    commentHtml,
    mentionableUsers,
    commentId,
    commentableType,
    commentableId,
    currentUser.id,
    currentUser.displayName,
    linkTo,
    attachedFiles,
    trackEvent,
    accountRef,
    getPath,
    mentionedEmailBody,
    emailBodyLn1Extra,
    link,
    watcherEmailBody,
    watchers,
    addEvent,
    task,
    sendNotification,
    users,
  ]);

  return (
    <CreateComment
      key={commentId}
      commentId={commentId}
      createComment={createComment}
      onChange={onTextChange}
      onKeyDown={onKeyDown}
      onFocus={() => setIsInputFocused(true)}
      onBlur={() => setIsInputFocused(false)}
      commentHtml={commentHtml}
      isFocused={isInputFocused}
      onMentionButtonClick={onMentionButtonClick}
      onSuggestionSelected={onSuggestionSelected}
      mentionInput={mentionInput}
      isSuggestionsOpen={isSuggestionsOpen}
      closeSuggestions={closeSuggestions}
      isCreatingComment={isCreatingComment}
      cancelComment={cancelComment}
      disableBottomBorder={disableBottomBorder}
      isUploadingFiles={isUploadingFiles}
      onFilesChange={onFilesChange}
      attachedFiles={attachedFiles}
      onFileDeleted={onFileDeleted}
      sendButtonText={sendButtonText}
    />
  );
};

export default CreateCommentContainer;
