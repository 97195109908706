import { useCallback, useContext, useEffect, useState } from 'react';
import BoardContext from '../contexts/BoardContext';
import getBaseViewFields from '../components/Grid/getBaseViewFields';

export const isPerformanceColumn = (
  col: { field: string; visible: boolean } | PerformanceBoardTypes.Column,
): col is PerformanceBoardTypes.Column => {
  return 'isProgressVis' in col;
};

// Override & inject the base view to reflect the board configuration
const useBoardBaseView = (baseView: FleetOps.BaseView) => {
  const { board } = useContext(BoardContext);
  const buildView = useCallback(() => {
    const { columnOrder } = board;
    const unConfiguredFields = getBaseViewFields(baseView).filter(
      (f) => !columnOrder.some((co) => co.field === f.field),
    );
    const newFields = {} as { [field: string]: FleetOps.BaseViewField };

    columnOrder
      .filter((col) => {
        if (isPerformanceColumn(col)) {
          return !col.isProgressVis;
        }
        return true;
      })
      .forEach((co) => {
        const f = baseView.fields[co.field];
        if (f) {
          newFields[co.field] = {
            ...f,
            isVisible: co.visible,
          };
        }
      });
    unConfiguredFields.forEach((f) => {
      newFields[f.field] = {
        ...f,
        isVisible: false,
      };
    });

    const newOrder = [
      ...columnOrder.map((c) => c.field),
      ...baseView.fieldOrder.filter((fO) =>
        unConfiguredFields.some((f) => f.field === fO),
      ),
    ];

    return {
      ...baseView,
      fields: newFields,
      fieldOrder: newOrder,
    };
  }, [baseView, board]);

  const [boardBaseView, setBoardBaseView] =
    useState<FleetOps.BaseView>(buildView());

  useEffect(() => {
    setBoardBaseView(buildView());
  }, [buildView]);

  return boardBaseView;
};

export default useBoardBaseView;
