import { useCallback, useContext } from 'react';
import GqlClientContext from '../../contexts/GqlClientContext';
import { DateTime } from 'luxon';
import aggregateMetric from '../../api/aggregateMetric';
import toMetricInput from '../../utils/metrics/toMetricInput';
import captureException from '../../services/captureException';

const useGetDatasetLastUpdatedAt = () => {
  const { client } = useContext(GqlClientContext);

  const getDatasetLastUpdatedAt = useCallback(
    async (dataType: string) => {
      if (process.env.NODE_ENV === 'test') {
        return undefined;
      }

      const metricDef = {
        id: `lastUpdatedAt`,
        name: 'Last updated at',
        formatting: {},
        filters: {},
        dataType: dataType,
        field: 'recordTimeStamp',
        aggFunc: 'max',
      } as MetricInput;
      const dateRange = {
        startDate: DateTime.utc().minus({ year: 1 }).toISODate(),
        endDate: DateTime.utc().toISODate(),
        dateField: 'recordTimeStamp',
      };

      try {
        const result = await aggregateMetric(
          toMetricInput(metricDef),
          [{ keywords: [], ranges: [], booleanFilters: [] }],
          dateRange,
          client,
        );

        return result[0].lastUpdatedAt as number;
      } catch (ex) {
        captureException(ex);
        return undefined;
      }
    },
    [client],
  );

  return getDatasetLastUpdatedAt;
};

export default useGetDatasetLastUpdatedAt;
