import { useContext, useEffect, useState } from 'react';
import ScorecardContext from '../../../contexts/ScorecardContext';
import { isKpiRow } from './kpiTypeCheckers';
import metricTypeCheckers from 'types/metricTypeCheckers';
import useMetric from 'hooks/useMetric';

const useKpiTrend = (
  kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow,
) => {
  const { data } = useContext(ScorecardContext);
  const [trend, setTrend] = useState<Goals.TrendResult[] | undefined>();

  const metric = useMetric(isKpiRow(kpi) ? kpi.metricId : undefined);
  const isSpecial = metricTypeCheckers.isSpecialMetric(metric);

  useEffect(() => {
    if (isKpiRow(kpi)) {
      if (!data) {
        setTrend(undefined);
        return;
      }

      const newResult = isSpecial
        ? data.find((d) => d.id === kpi.metricId)
        : data.find((d) => d.id === kpi.id);
      if (!newResult) {
        setTrend(undefined);
        return;
      }

      setTrend(newResult.trend);
    } else {
      setTrend(kpi.data);
    }
  }, [data, isSpecial, kpi, kpi.id]);

  return trend;
};

export default useKpiTrend;
