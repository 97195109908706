import React, { useCallback, useContext } from 'react';
import StepHeader from './StepHeader';
import { AccordionContent } from './ConfigSteps';
import CostsWizardContext from '../context/CostsWizardContext';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import Checkbox from 'components/Inputs/Checkbox';

const OutputsStep = () => {
  const { wizardState, setWizardState, hasEditPermission } =
    useContext(CostsWizardContext);

  const toggleCheckbox = useCallback(() => {
    setWizardState({
      ...wizardState,
      config: {
        ...wizardState.config,
        outputsStep: {
          isFuelExcluded: !wizardState.config.outputsStep.isFuelExcluded,
        },
      },
    });
  }, [setWizardState, wizardState]);

  return (
    <>
      <StepHeader headerStep={'outputs'} headerText="4. Outputs" />
      <AccordionContent
        isActive={wizardState.currentStep === 'outputs'}
        style={{
          height: wizardState.currentStep === 'outputs' ? '144px' : '0',
          transition: 'height: .2s  ease-out',
        }}
      >
        <div style={{ padding: '32px' }}>
          <div style={{ marginBottom: '24px' }}>
            <Typography.Header type="H5">
              Activities will be enriched with margin, margin calculation is
              based on total revenue.
            </Typography.Header>
          </div>
          <Row centerAlign style={{ marginBottom: '24px' }}>
            <div style={{ marginRight: '8px' }}>
              <Checkbox
                isChecked={wizardState.config.outputsStep.isFuelExcluded}
                onToggled={toggleCheckbox}
                isDisabled={!hasEditPermission}
                testId="checkbox-outputs"
              />
            </div>

            <Typography.Body type="Body 14">
              Exclude fuel costs from margin calculation
            </Typography.Body>
          </Row>
        </div>
      </AccordionContent>
    </>
  );
};

export default OutputsStep;
