import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from 'kingpin/atoms/Typography';
import Row from '../../components/Common/Row';
import ALL_SETS_ROUTES from './AllSets/routes';
import Button from 'kingpin/atoms/Button';
import DatasetDefinitionsContext from '../../contexts/DatasetDefinitionsContext';
import ErrorBadge from './DatasetDefinitions/Badges/ErrorBadge';
import UpdatingBadge from './DatasetDefinitions/Badges/UpdatingBadge';
import { TOP_BAR_HEIGHT } from '../../constants';

const usePerformanceConfig = (baseView?: FleetOps.BaseView) => {
  const [performanceConfig, setPerformanceConfig] = useState<
    FleetOps.PerformanceDatasetConfig | undefined
  >();
  const { getPerformanceConfig } = useContext(DatasetDefinitionsContext);

  // setPerformanceConfig
  useEffect(() => {
    if (!baseView) {
      setPerformanceConfig(undefined);
      return;
    }

    setPerformanceConfig(getPerformanceConfig(baseView.type));
  }, [baseView, getPerformanceConfig]);

  return performanceConfig;
};

const PerformanceConfigStatusBadge = ({
  baseView,
}: {
  baseView?: FleetOps.BaseView;
}) => {
  const performanceConfig = usePerformanceConfig(baseView);

  if (!performanceConfig) {
    return null;
  }

  if (performanceConfig.status === 'error') {
    return <ErrorBadge />;
  }

  if (performanceConfig.status === 'updating') {
    return <UpdatingBadge />;
  }

  return null;
};

const TopBarBackRow = ({ baseView }: { baseView?: FleetOps.BaseView }) => {
  const navigate = useNavigate();
  const goBack = useCallback(() => {
    navigate(ALL_SETS_ROUTES.BASE);
  }, [navigate]);

  if (!baseView) {
    return null;
  }

  const breadcrumbPrefix = 'Datasets';

  const name = baseView.nameAlias ? baseView.nameAlias : baseView.type;

  return (
    <Row
      centerAlign
      style={{
        padding: '0px 16px',
        borderBottom: '1px solid #EEEFF1',
        height: TOP_BAR_HEIGHT,
      }}
    >
      <div style={{ marginRight: 8 }}>
        <Button
          label="Back"
          icon={'arrow-left'}
          onClick={goBack}
          type="Ghost"
          size="Small"
        />
      </div>
      <Typography.Body type="Body 12">{`${breadcrumbPrefix} / ${name}`}</Typography.Body>
      <div style={{ marginRight: 8 }}>
        <PerformanceConfigStatusBadge baseView={baseView} />
      </div>
    </Row>
  );
};

export default TopBarBackRow;
