import React, { useContext } from 'react';
import { PerformanceContainer } from 'components/BoardSlideOut/Performance';
import useFullDataInterval from 'components/PerformanceBoards/hooks/useFullDataInterval';
import usePerformanceSlideOutFilterInput from 'components/PerformanceBoards/hooks/usePerformanceSlideOutFilterInput';

import useGetFieldLabel from 'hooks/useGetFieldLabel';
import SlideOutContext from 'components/PerformanceBoards/contexts/SlideOutContext';
import usePerformanceFieldsOptions from 'components/PerformanceBoards/hooks/usePerformanceFieldsOptions';
import EmptyVis from '../EmptyVis';

const PerformanceSectionContainer = ({
  section,
}: {
  section: PerformanceBoardTypes.SlideOut.PerformanceSection;
}) => {
  const { board, navState } = useContext(SlideOutContext);
  const filterInput = usePerformanceSlideOutFilterInput();
  const { dateScope } = useFullDataInterval({
    mode: section.dateRange.mode,
    n: section.dateRange.n,
    isToDate: section.dateRange.isToDate,
  });
  const { getFieldLabel } = useGetFieldLabel();
  const { isPerformanceFieldRemoved } = usePerformanceFieldsOptions({
    initialSelectedField: section.performanceField,
  });

  const config = {
    title: section.title,
    titleLabel: section.title,
    totalField: section.performanceField,
    targetField: `${section.performanceField}Target`,
    differenceField: `${section.performanceField}Difference`,
    cadenceTotalField: section.performanceField || '',
    cadenceTargetField: `${section.performanceField}Target`,
    cadenceDifferenceField: `${section.performanceField}Difference`,
    targetHeader: `${getFieldLabel({
      field: section.performanceField || '',
      dataType: board.dataType,
    })} Target`,
    totalHeader: `${getFieldLabel({
      field: section.performanceField || '',
      dataType: board.dataType,
    })}`,
    differenceHeader: `${getFieldLabel({
      field: section.performanceField || '',
      dataType: board.dataType,
    })} Difference`,
    showReasonCode: section.isReasonCodeEnabled,
  };

  if (!filterInput || !dateScope) {
    return null;
  }

  if (isPerformanceFieldRemoved) {
    return <EmptyVis isFieldRemoved />;
  }

  if (navState.slideoutMode === 'Creating' || navState.editingSection) {
    return (
      <div
        style={{
          marginBottom: '24px',
          padding: '16px',
          backgroundColor: '#f5f5f5',
          borderRadius: '4px',
        }}
      >
        <PerformanceContainer
          filterInput={filterInput}
          dateScope={dateScope}
          config={config}
          isPerformanceBoard
        />
      </div>
    );
  }

  return (
    <PerformanceContainer
      filterInput={filterInput}
      dateScope={dateScope}
      config={config}
      isPerformanceBoard
      homeViz
    />
  );
};

export default PerformanceSectionContainer;
