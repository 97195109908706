import React from 'react';

import Row from './Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Inputs from './Inputs';

const ShowPassword = ({
  isHiding,
  setIsHiding,
}: {
  isHiding: boolean;
  setIsHiding: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Row centerAlign style={{ justifyContent: 'flex-end', marginTop: 4 }}>
      <div
        style={{ marginRight: 4, userSelect: 'none', cursor: 'pointer' }}
        onClick={() => setIsHiding(!isHiding)}
      >
        <Typography.Body type="Body 12">Show password</Typography.Body>
      </div>

      <Inputs.Checkbox
        isChecked={!isHiding}
        onToggled={(x) => setIsHiding(!x)}
      />
    </Row>
  );
};

export default ShowPassword;
