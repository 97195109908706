import React, { useCallback, useEffect, useState } from 'react';
import Scorecard from './Scorecard';
import { buildShowGlobalKpiList } from '../../navigation/appRoutes';
import ScorecardProvider from '../../contextProviders/ScorecardProvider';
import TasksProvider from '../../contextProviders/TasksProvider/LocalTasksProvider';
import { SCORECARD_RESOURCE } from '../../constants';

const useScorecardLink = (scorecard: Scorecards.Scorecard) => {
  const buildLink = useCallback(() => {
    return buildShowGlobalKpiList(scorecard.id);
  }, [scorecard.id]);
  const [link, setLink] = useState(buildLink());

  useEffect(() => {
    setLink(buildLink());
  }, [buildLink]);

  return link;
};

const ScorecardContainer = ({
  scorecard,
  isAddKpiEnabled,
  isKpisDisabled,
  isMenuEnabled,
}: {
  scorecard: Scorecards.Scorecard;
  isAddKpiEnabled: boolean;
  isKpisDisabled?: boolean;
  isMenuEnabled?: boolean;
}) => {
  const link = useScorecardLink(scorecard);
  const [taskSources, setTaskSources] = useState<Tasks.TaskSource[]>([]);
  const [taskDestination, setTaskDestination] = useState<
    Tasks.TaskSource | undefined
  >();

  useEffect(() => {
    setTaskSources([
      {
        type: SCORECARD_RESOURCE,
        id: scorecard.id,
      },
    ]);

    setTaskDestination({
      type: SCORECARD_RESOURCE,
      id: scorecard.id,
    });
  }, [scorecard.id]);

  return (
    <TasksProvider sources={taskSources} destination={taskDestination}>
      <ScorecardProvider
        scorecardId={scorecard.id}
        isAddKpiEnabled={isAddKpiEnabled}
        isKpisDisabled={isKpisDisabled}
        isMenuEnabled={isMenuEnabled}
      >
        <Scorecard scorecard={scorecard} link={link} />
      </ScorecardProvider>
    </TasksProvider>
  );
};

export default ScorecardContainer;
