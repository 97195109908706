import React from 'react';

import Typography from 'kingpin/atoms/Typography';
import ListItemWrapper from '../../../DatasetDefinitions/ListItemWrapper';
import Column from '../../../DatasetDefinitions/Column';
import Card from '../../../../../components/Common/Card';

const PerformanceUsage = ({
  performanceConfigs,
  metric,
}: {
  performanceConfigs: { id: string; name: string }[];
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
}) => (
  <>
    {performanceConfigs.length > 0 && (
      <div style={{ marginBottom: 24 }}>
        <Typography.Header type="H5">
          {`Datasets (${performanceConfigs.length})`}
        </Typography.Header>
        <Card>
          {performanceConfigs.map((cd, index) => (
            <ListItemWrapper
              key={`${cd.id}-${metric.id}`}
              isLast={performanceConfigs.length - 1 === index}
            >
              <Column
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <Typography.Body type="Body 12" color="#0041ea">
                  {cd.name}
                </Typography.Body>
              </Column>
            </ListItemWrapper>
          ))}
        </Card>
      </div>
    )}
  </>
);

export default PerformanceUsage;
