import React from 'react';

import metricTypeCheckers from '../../../types/metricTypeCheckers';
import Row from '../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import getMetricInfoLabel from './getMetricInfoLabel';
import CoreBadge from '../../../screens/DataManager/Metrics/CoreBadge';
import useCompoundMetricWithMetricDefs from '../../../hooks/useCompoundMetricWithMetricDefs';
import Expression from '../Expression';
import { MetricLink } from './MetricCard';
import { isMetricInfo } from 'components/Scorecard/Kpis/kpiTypeCheckers';
import Button from '../../../kingpin/atoms/Button';
import MetricInfoConstants from '../constants';

const CompoundInfoHeading = ({
  metricInfo,
  compoundMetric,
}: {
  metricInfo: MetricsInfoButton.MetricInfo;
  compoundMetric: Metrics.CompoundMetric;
}) => {
  const compoundMetricWithDefs = useCompoundMetricWithMetricDefs(
    compoundMetric.id,
  );
  if (!compoundMetricWithDefs) {
    return null;
  }

  return (
    <>
      <Row
        centerAlign
        spaceBetween
        style={{ height: MetricInfoConstants.TOP_BAR_HEIGHT }}
      >
        <Row centerAlign>
          <div style={{ marginRight: 8 }}>
            <Typography.Header type="H5">
              {getMetricInfoLabel(metricInfo)}
            </Typography.Header>
          </div>

          <CoreBadge metric={compoundMetric} />
        </Row>
        <div className={'showOnMouseOver'}>
          <MetricLink metric={compoundMetric} />
        </div>
      </Row>
      {compoundMetric.name !== getMetricInfoLabel(metricInfo) && (
        <Typography.Body type="Body 12">{compoundMetric.name}</Typography.Body>
      )}
      <Expression
        expression={compoundMetric.expression}
        usedMetrics={compoundMetricWithDefs.metrics}
      />
      {compoundMetric.description !== '' && (
        <div style={{ marginTop: '8px' }}>
          <div style={{ marginRight: 4 }}>
            <Typography.Body type="Label">Description:</Typography.Body>
          </div>
          <Typography.Body type="Body 12">
            {compoundMetric.description}
          </Typography.Body>
        </div>
      )}
    </>
  );
};

const InfoHeading = ({
  metricInfo,
  close,
}: {
  metricInfo: MetricsInfoButton.MetricInfo | MetricsInfoButton.ManualKpiInfo;
  close: () => void;
}) => {
  const hasAliasSubText = isMetricInfo(metricInfo)
    ? !!metricInfo.metricAlias &&
      metricInfo.metricAlias !== metricInfo.metric.name
    : false;
  if (
    isMetricInfo(metricInfo) &&
    metricTypeCheckers.isCompoundMetric(metricInfo.metric)
  ) {
    return (
      <CompoundInfoHeading
        metricInfo={metricInfo}
        compoundMetric={metricInfo.metric}
      />
    );
  }

  return (
    <>
      <Row
        centerAlign
        spaceBetween
        style={{ height: MetricInfoConstants.TOP_BAR_HEIGHT }}
      >
        <Row centerAlign>
          <div style={{ marginRight: 4 }}>
            <Typography.Header type="H4">
              {getMetricInfoLabel(metricInfo)}
            </Typography.Header>
          </div>
          {isMetricInfo(metricInfo) && <CoreBadge metric={metricInfo.metric} />}
        </Row>
        <Button
          size={'Small'}
          type={'Secondary'}
          onClick={close}
          icon="cross"
        />
      </Row>
      {hasAliasSubText && (
        <div>
          <Typography.Body type="Body 12">
            {isMetricInfo(metricInfo)
              ? metricInfo.metric.name
              : metricInfo.label}
          </Typography.Body>
        </div>
      )}
    </>
  );
};

export default InfoHeading;
