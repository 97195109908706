import React from 'react';

interface PerformanceBoardSlideOutContextType {
  board: PerformanceBoardTypes.Board;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filterInput: FilterInput | undefined;
  setFilterInput: React.Dispatch<React.SetStateAction<FilterInput | undefined>>;
  navState: PerformanceBoardTypes.SlideOut.NavState;
  setNavState: React.Dispatch<PerformanceBoardTypes.SlideOut.NavState>;
  flash: {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    flashMessage: string;
    setFlashMessage: React.Dispatch<React.SetStateAction<string>>;
  };
  performanceFields: string[];
}

const SlideOutContext =
  React.createContext<PerformanceBoardSlideOutContextType>(
    {} as PerformanceBoardSlideOutContextType,
  );

export default SlideOutContext;
