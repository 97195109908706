import getPerformanceBoardsRef from './getPerformanceBoardsRef';
import getTargetsApps from './getTargetsApps';
import getEntityDetailsApps from './getEntityDetailsApps';

const contentDistributions = {
  getPerformanceBoardsRef,
  getTargetsApps,
  getEntityDetailsApps,
};

export default contentDistributions;
