import firebase from 'firebase/compat/app';
import 'firebase/auth';
import * as Sentry from '@sentry/browser';
import { ApolloClient, from, Operation } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { InMemoryCache } from '@apollo/client/cache';
import { ServerError } from '@apollo/client/link/utils';
import { ServerParseError } from '@apollo/client/link/http';
import * as aguid from 'aguid';

import env from '../.env.json';
import { GraphQLBreadcrumb, SentryLink } from 'apollo-link-sentry';
import { AUTH_ERROR_MSG_KEY } from '../screens/Login/ErrorBanner';
import { NetworkError } from '@apollo/client/errors';
import {
  BreadcrumbData,
  makeBreadcrumb,
} from 'apollo-link-sentry/lib-esm/breadcrumb';
import { NonEmptyArray } from 'apollo-link-sentry/lib-esm/options';

const CLOUD_TRACE = 'x-cloud-trace-context';
const SERVED_BY = 'x-served-by';

interface FleetOpsGqlBreadcrumbData extends BreadcrumbData {
  [CLOUD_TRACE]?: string;
  [SERVED_BY]?: string;
  bodyText?: string;
  statusCode?: number;
  visName?: string;
}

interface FleetOpsGqlBreadcrumb extends GraphQLBreadcrumb {
  data: FleetOpsGqlBreadcrumbData;
}

const INCLUDE_CONTEXT = [
  'x-canvas-type',
  'x-carrier-name',
  'x-trace-id',
  'x-vis-id',
  'x-vis-name',
  'x-vis-type',
  'x-vis-url',
  'x-build-number',
  'x-dashboard-id',
  'x-report-id',
  'x-board-id',
  'x-wallboard-id',
  'x-time-zone',
] as NonEmptyArray<string>;

const getAccessToken = async () => {
  const user = firebase.auth().currentUser;
  if (!user) {
    const error = new Error();
    error.name = 'User not found';
    throw error;
  }

  const token = await user.getIdToken();
  return token;
};

const addFleetOpsGqlBreadcrumb = ({
  operation,
  networkError,
  visName,
}: {
  operation: Operation;
  networkError:
    | NetworkError
    | Error
    | ServerError
    | ServerParseError
    | undefined;
  visName?: string;
}) => {
  const breadCrumb = makeBreadcrumb(operation, {
    setTransaction: false,
    setFingerprint: false,
    uri: undefined,
    shouldHandleOperation: undefined,
    attachBreadcrumbs: {
      includeQuery: true,
      includeVariables: true,
      includeFetchResult: false,
      includeError: true,
      includeCache: false,
      includeContext: INCLUDE_CONTEXT,
      transform: undefined,
    },
  }) as FleetOpsGqlBreadcrumb;

  if (networkError) {
    if (
      'result' in networkError &&
      typeof networkError.result !== 'string' &&
      'error' in networkError.result &&
      (networkError.result.error === 'Unauthenticated: Token revoked' ||
        networkError.result.error ===
          'Forbidden: Session identity provider not permitted')
    ) {
      const errorMessage =
        'Your organization has enabled SSO. Please sign in with Microsoft';
      window.localStorage.setItem(AUTH_ERROR_MSG_KEY, errorMessage);
      firebase.auth().signOut();

      return;
    }

    if ('response' in networkError) {
      const cloudTrace = networkError.response.headers.get(CLOUD_TRACE);
      const servedBy = networkError.response.headers.get(SERVED_BY);
      if (cloudTrace) {
        breadCrumb.data[CLOUD_TRACE] = cloudTrace;
      }
      if (servedBy) {
        breadCrumb.data[SERVED_BY] = servedBy;
      }
    }

    if ('bodyText' in networkError) {
      breadCrumb.data['bodyText'] = networkError.bodyText;
    }

    if ('statusCode' in networkError) {
      breadCrumb.data['statusCode'] = networkError.statusCode;
    }
  }

  if (visName) {
    breadCrumb.data['visName'] = visName;
  }

  Sentry.addBreadcrumb(breadCrumb);
};

const buildApolloClient = ({
  visId,
  visName,
  visType,
  source,
  sourceId,
  batchMax,
  startOfWeek,
  buildNumber,
  accountId,
  carrierName,
  timezone,
}: {
  visId?: string;
  visName?: string;
  visType?: string;
  source?: 'report' | 'dashboard' | 'wallboard' | 'board' | 'goal';
  sourceId?: string;
  batchMax?: number;
  startOfWeek?: WeekStartsOn;
  buildNumber?: number;
  accountId: string;
  carrierName: string;
  timezone?: string;
}) => {
  const link = from([
    new SentryLink(),
    onError(({ networkError, response, operation }) => {
      addFleetOpsGqlBreadcrumb({ networkError, operation, visName });
      if (networkError) {
        const query = operation.operationName;
        if (networkError.name === 'TypeError') {
          networkError.name = `${carrierName} - ${query}`;
        }
      }
      if (response && response.errors) {
        response.errors.forEach((e) => {
          const query = operation.operationName;
          // @ts-ignore
          const code = e.extensions['code'];
          if (code) {
            e.name = `${carrierName} - ${query}: ${code}`;
          } else {
            e.name = `${carrierName} - ${query}`;
          }
        });
      }
    }),
    setContext(async (_, prevContext) => {
      const newHeaders = {
        ...prevContext.headers,
      };

      if (process.env.NODE_ENV === 'test') {
        console.error(`${_.operationName} has been called in test env`);
      }

      const token = await getAccessToken();

      newHeaders.authorization = `Bearer ${token}`;
      if (startOfWeek !== undefined) {
        newHeaders['start-of-week'] = startOfWeek;
      }
      newHeaders['x-account-id'] = accountId;
      if (timezone) {
        newHeaders['x-time-zone'] = timezone;
      }
      if (source && sourceId) {
        switch (source) {
          case 'board':
            newHeaders['x-board-id'] = sourceId;
            newHeaders['x-canvas-type'] = 'board';
            break;
          case 'dashboard':
            newHeaders['x-dashboard-id'] = sourceId;
            newHeaders['x-canvas-type'] = 'dashboard';
            break;
          case 'report':
            newHeaders['x-report-id'] = sourceId;
            newHeaders['x-canvas-type'] = 'report';
            break;
          case 'wallboard':
            newHeaders['x-wallboard-id'] = sourceId;
            newHeaders['x-canvas-type'] = 'wallboard';
            break;
          default:
        }
      }

      newHeaders['x-carrier-name'] = carrierName;

      if (buildNumber) {
        newHeaders['x-build-number'] = buildNumber;
      }

      if (visId) {
        newHeaders['x-vis-id'] = visId;
      }

      if (visName) {
        newHeaders['x-vis-name'] = visName;
        newHeaders['x-vis-url'] = window.location.href;
      }

      if (visType) {
        newHeaders['x-vis-type'] = visType;
      }

      newHeaders['x-trace-id'] = aguid();

      const contextToAdd = {} as { [key: string]: string | undefined };
      INCLUDE_CONTEXT.forEach((key) => {
        if (newHeaders[key]) {
          contextToAdd[key] = newHeaders[key];
        }
      });

      return {
        ...prevContext,
        ...contextToAdd,
        headers: newHeaders,
      };
    }),
    new BatchHttpLink({
      uri: env.GRAPHQL_API,
      batchInterval: 200,
      batchMax: batchMax ? batchMax : 4,
    }),
  ]);

  return new ApolloClient({
    link,
    // remove __typename to make comparisons easier
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};

export default buildApolloClient;
