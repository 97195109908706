import React, { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import Inputs from 'components/Inputs';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';

import { Label } from '../../../MyAccount/Profile/styles';
import ReportDrillDowns from '../../../ReportDrillDowns';
import Row from '../../../Common/Row';
import ReportSelector from '../../../ConfigureDashboardGadget/ReportSelector';
import FleetOpsStaffOnly from '../../../Common/FleetOpsStaffOnly';
import { getMetricLink } from '../../../../screens/DataManager/AllSets/routes';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';
import TextInput from '../../../../kingpin/atoms/TextInput';
import Form from '../../../../kingpin/forms/Form';
import FormHeader from '../../../../kingpin/forms/FormHeader';
import FormContent from '../../../../kingpin/forms/FormContent';
import EntityFilterToggle from '../../../ConfigureDashboardGadget/EntityFilterToggle';

const MetricItemEditForm = ({
  name,
  onNameChanged,
  metricId,
  setMetricId,
  onSave,
  close,
  reportDrillDownId,
  setReportDrillDownId,
  boardDrillDownId,
  setBoardDrillDownId,
  metric,
  isEntityFilterEnabled,
  setIsEntityFilterEnabled,
}: {
  name: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  metricId: string;
  setMetricId: React.Dispatch<React.SetStateAction<string | undefined>>;
  onSave: () => void;
  close: () => void;
  reportDrillDownId?: string;
  setReportDrillDownId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  boardDrillDownId?: string;
  setBoardDrillDownId: React.Dispatch<React.SetStateAction<string | undefined>>;
  metric: Metrics.Metric;
  isEntityFilterEnabled: boolean;
  setIsEntityFilterEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <Form>
    <FormHeader title={'Edit Metric'} onClose={close} />
    <FormContent>
      <div style={{ marginBottom: 16 }}>
        <TextInput label="Name" value={name} onChange={onNameChanged} />
      </div>
      <ReportSelector
        key={metricId || Math.random()}
        label="Drill down"
        reportId={reportDrillDownId}
        setReportId={setReportDrillDownId}
        isBlockInput
        boardId={boardDrillDownId}
        setBoardId={setBoardDrillDownId}
        metricId={metricId}
      />
      <Row spaceBetween>
        <Label>
          <Typography.Body type="Label">Metric</Typography.Body>
        </Label>
        <FleetOpsStaffOnly>
          <>
            {(metricTypeCheckers.isCompoundMetric(metric) ||
              metricTypeCheckers.isNormalMetric(metric)) && (
              <Link
                to={getMetricLink(metric)}
                target={'_blank'}
                key={metric.id}
              >
                <Typography.Body type="Link">{`${metric.name} definition`}</Typography.Body>
              </Link>
            )}
          </>
        </FleetOpsStaffOnly>
      </Row>
      <div style={{ marginBottom: 16 }}>
        <Inputs.MetricPicker setMetricId={setMetricId} metricId={metricId} />
      </div>
      <EntityFilterToggle
        setIsEntityFilterEnabled={setIsEntityFilterEnabled}
        isEntityFilterEnabled={isEntityFilterEnabled}
      />
      <div style={{ marginBottom: 16 }}>
        <Label>
          <Typography.Body type="Label">Run time filters:</Typography.Body>
        </Label>
        <ReportDrillDowns />
      </div>
      <div style={{ marginBottom: 16 }}>
        <Label>
          <Typography.Body type="Label">Scope:</Typography.Body>
        </Label>
        <ReportDrillDowns isScope />
      </div>
    </FormContent>

    <Row centerAlign spaceBetween>
      <Button size="Small" type="Secondary" onClick={close} label="Cancel" />
      <Button size="Small" type="Primary" onClick={onSave} label="Save" />
    </Row>
  </Form>
);

export default MetricItemEditForm;
