import React, { memo } from 'react';

import FlexCentered from '../Common/FlexCentered';
import Colors from '../../theme/colors';
import ErrorBoundary from '../Common/ErrorBoundary';
import Card from '../Common/Card';
import styled from 'styled-components';
import Row from '../Common/Row';
import isV5ChartDef from '../../types/visTypeCheckers/isV5ChartDef';
import V5Gadget from '../V5Gadget';
import DashboardGadgetProvider from '../../contextProviders/DashboardGadgetProvider';
import IFrameGadget from '../IFrameGadget';
import ImageGadget from '../ImageGadget';
import VideoGadget from '../VideoGadget';
import TextGadget from '../TextGadget';
import MetricListGadget from '../MetricListGadget';
import SingleMetricDateMatrixContainer from '../SingleMetricDateMatrix';
import isSingleMetricDateMatrix from '../../types/visTypeCheckers/isSingleMetricDateMatrix';
import { Link } from 'react-router-dom';
import { getGadgetLink } from '../../navigation/appRoutes';
import isGauge from '../../types/visTypeCheckers/isGauge';
import Gauge from '../Gauge';
import isRemindersGadget from '../../types/visTypeCheckers/isRemindersGadget';
import RemindersGadget from '../RemindersGadget';
import DashboardGadgetDateScopeProvider from '../../contextProviders/DashboardGadgetDateScopeProvider';
import cardTypeCheckers from '../../types/cardTypeCheckers';
import Tooltip from '../Tooltip';
import isRankingMatrix from '../../types/visTypeCheckers/isRankingMatrix';
import RankingMatrix from '../RankingMatrix';
import ScorecardGadget from '../Scorecard/ScorecardGadget';
import PermissionGates from '../PermissionGates';
import PERMISSIONS from '../../permissionDefinitions';
import FeatureGate from '../FeatureGate';
import GoalGadget from 'components/Goals/GoalCard/GoalGadget';
import Icon from '../../kingpin/atoms/Icon';
import Button from '../../kingpin/atoms/Button';
import Loading from '../Loading/Loading';
import isPaceMatrix from '../../types/visTypeCheckers/isPaceMatrix';
import PaceMatrix from '../PaceMatrix';

const RemoveWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  pointer-events: none;
`;

const RemoveInner = styled.div`
  pointer-events: auto;
  cursor: pointer;
`;

const DragWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;

  background-color: ${Colors.BLUE_GREY};
  &:hover {
    opacity: 0.7;
  }
`;

const DetailsLinkWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 45px;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;

  background-color: ${Colors.BLUE_GREY};
  &:hover {
    opacity: 0.7;
  }
`;

const CardDiv = styled(Card)<{ isEditing: boolean }>`
  padding: 0;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  padding-top: ${(props) => (props.isEditing ? 40 : 0)}px;
  box-shadow: unset;
  border: 1px solid #ededed;
  overflow: hidden;

  .opaque-unless-mouse-over {
    transition: opacity 0.3s;
    opacity: 0.6;
  }

  &:hover {
    .opaque-unless-mouse-over {
      opacity: 1;
    }
  }
`;

const RenderGadget = ({ gadget }: { gadget: VisualisationDefinition }) => {
  return (
    <React.Fragment>
      {isV5ChartDef(gadget) && <V5Gadget chartDefinition={gadget} />}
      {isSingleMetricDateMatrix(gadget) && (
        <SingleMetricDateMatrixContainer gadget={gadget} />
      )}
      {isGauge(gadget) && <Gauge gauge={gadget} />}
      {isRemindersGadget(gadget) && <RemindersGadget gadget={gadget} />}
      {isRankingMatrix(gadget) && <RankingMatrix gadget={gadget} />}
      {isPaceMatrix(gadget) && <PaceMatrix gadget={gadget} />}
    </React.Fragment>
  );
};

const RenderGadgetMemo = memo(RenderGadget);

const ReportCanvasCard = ({
  isEditing,
  onRemoveClicked,
  card,
  chartDefinition,
  dashboardGadget,
  isExporting,
  isDragging,
}: {
  isEditing: boolean;
  onRemoveClicked: () => void;
  card: CanvasCard.Card;
  chartDefinition?: VisualisationDefinition;
  dashboardGadget?: DashboardGadget;
  isExporting: boolean;
  isDragging: boolean;
}) => (
  <CardDiv data-testid={card.layout.i} isEditing={isEditing}>
    {isEditing && (
      <div>
        <PermissionGates.Has
          requiredPermission={PERMISSIONS.DATA_MANAGEMENT.GADGETS}
        >
          <FeatureGate featureName={'selfServe'}>
            <>
              {cardTypeCheckers.isChartDefinition(card) &&
                !!chartDefinition && (
                  <Link to={getGadgetLink(chartDefinition)} target={'_blank'}>
                    <DetailsLinkWrapper>
                      <Icon icon="edit-filled" />
                    </DetailsLinkWrapper>
                  </Link>
                )}
            </>
          </FeatureGate>
        </PermissionGates.Has>
        <DragWrapper className={'draggableHandle'}>
          <Tooltip
            content={`${card.layout.w} x ${card.layout.h}`}
            position="right"
          >
            <Icon icon="drag" />
          </Tooltip>
        </DragWrapper>
        <RemoveWrapper>
          <RemoveInner>
            <Row>
              <Button
                onClick={() => {
                  onRemoveClicked();
                }}
                icon={'cross'}
                type={'Secondary'}
                size={'Small'}
              />
            </Row>
          </RemoveInner>
        </RemoveWrapper>
      </div>
    )}
    <ErrorBoundary>
      <>
        {isDragging && (
          <FlexCentered>
            <Loading />
          </FlexCentered>
        )}
        {!isDragging && (
          <React.Fragment>
            {cardTypeCheckers.isText(card) && (
              <TextGadget textContentId={card.content.textContentId} />
            )}
            {cardTypeCheckers.isVideo(card) && (
              <VideoGadget storagePath={card.content.videoSrc} />
            )}
            {cardTypeCheckers.isImage(card) && (
              <ImageGadget card={card} isEditing={isEditing} />
            )}
            {cardTypeCheckers.isIFrame(card) && (
              <IFrameGadget snippet={card.content.iFrameSnippet} />
            )}
            {cardTypeCheckers.isMetricList(card) && (
              <MetricListGadget
                metricListGadgetId={card.content.metricListId}
              />
            )}
            {cardTypeCheckers.isChartDefinition(card) && !!chartDefinition && (
              <RenderGadgetMemo gadget={chartDefinition} />
            )}
            {cardTypeCheckers.isScorecard(card) && (
              <ScorecardGadget scorecardId={card.content.scorecardId} />
            )}
            {cardTypeCheckers.isGoal(card) && (
              <GoalGadget goalId={card.content.goalId} />
            )}
            {cardTypeCheckers.isDashboardGadget(card) && !!dashboardGadget && (
              <DashboardGadgetDateScopeProvider
                dashboardGadget={dashboardGadget}
              >
                <DashboardGadgetProvider
                  dashboardGadget={dashboardGadget}
                  key={`${dashboardGadget.id}-${
                    dashboardGadget.comparison
                      ? dashboardGadget.comparison.compareType
                      : ''
                  }`}
                >
                  <RenderGadget gadget={dashboardGadget.chartDef} />
                </DashboardGadgetProvider>
              </DashboardGadgetDateScopeProvider>
            )}
          </React.Fragment>
        )}
      </>
    </ErrorBoundary>
  </CardDiv>
);

export default ReportCanvasCard;
