import mapGraphqlDayNumberToString from '../../mapGraphqlDayNumberToString';
import isCustomRangeInput from '../../isCustomRangeInput';

const buildOneDimensionMetricTotals = ({
  chartDef,
  unitsLocale,
  groupByField,
  usedMetrics,
  currentTotal,
  previousTotal,
  formatMetric,
}: {
  chartDef: V5ChartDefinition;
  unitsLocale: string;
  groupByField: string;
  usedMetrics: Metrics.Metric[];
  currentTotal: MetricsResponse;
  previousTotal: MetricsResponse;
  formatMetric: (args: { metricId: string; value: number }) => string;
}) => {
  const total = Object.values(currentTotal).map((r) => {
    const previous = previousTotal.find(
      (pR) => pR[groupByField] === r[groupByField],
    );
    const current = currentTotal.find(
      (cR) => cR[groupByField] === r[groupByField],
    );
    const formatted = { ...r };
    formatted[groupByField] = 'Total';
    Object.entries(r).forEach(([key, value]) => {
      const metric = usedMetrics.find((m) => m.id === key);
      if (metric) {
        const formattedValue = formatMetric({ value, metricId: metric.id });
        formatted[key] = formattedValue;
        const s = chartDef.series.find((s) => s.metricId === metric.id);
        if (s) {
          const currentDateRange = (() => {
            try {
              // @ts-ignore
              return current.filterInput.dateScope[0] as DateRangeInput;
            } catch (ex) {
              return undefined;
            }
          })();
          const previousDateRange = (() => {
            try {
              // @ts-ignore
              return previous.filterInput.dateScope[0] as DateRangeInput;
            } catch (ex) {
              return undefined;
            }
          })();

          if (s.matrixCellType) {
            const deltaCol = {
              value,
              previous: previous ? previous[key] : 0,
              formatting: metric.formatting,
              unitsLocale,
              type: 'delta' as MatrixCellType,
              currentDateRange,
              previousDateRange,
              metric,
            };
            const percentDeltaCol = {
              value,
              previous: previous ? previous[key] : 0,
              formatting: metric.formatting,
              unitsLocale,
              type: 'percentDelta' as MatrixCellType,
              currentDateRange,
              previousDateRange,
              metric,
            };
            if (s.matrixCellType === 'both') {
              // @ts-ignore
              formatted[`${metric.id}-delta`] = deltaCol;
              // @ts-ignore
              formatted[`${metric.id}-percentDelta`] = percentDeltaCol;
            } else if (s.matrixCellType === 'delta') {
              // @ts-ignore
              formatted[`${metric.id}-delta`] = deltaCol;
            } else {
              // @ts-ignore
              formatted[`${metric.id}-percentDelta`] = percentDeltaCol;
            }
            formatted[metric.id] = formattedValue;
          } else {
            formatted[metric.id] = formattedValue;
          }
        }
      } else {
        if (key === 'dayOfWeek') {
          formatted[key] = mapGraphqlDayNumberToString(value.toString());
        } else if (key === 'date' && groupByField === 'day') {
          formatted['day'] = value;
        } else if (
          chartDef.dimensionA &&
          chartDef.dimensionA.rangeInput &&
          key === 'key'
        ) {
          const rangeInput = chartDef.dimensionA.rangeInput;
          if (isCustomRangeInput(rangeInput)) {
            formatted[chartDef.dimensionA.field] = value;
          } else {
            const { fixed } = rangeInput;
            if (fixed !== 1) {
              formatted[chartDef.dimensionA.field] = `${value} - ${
                Number(value) + fixed
              }`;
            } else {
              formatted[chartDef.dimensionA.field] = `${value}`;
            }
          }
        } else {
          formatted[key] = value;
        }
      }
    });
    return formatted;
  });

  return total;
};

export default buildOneDimensionMetricTotals;
