import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Loading from './Loading';
import TextInput from '../kingpin/atoms/TextInput';
import Typography from '../kingpin/atoms/Typography';
import Icon from '../kingpin/atoms/Icon';
import Colors2 from '../theme/Colors2';
import Row from './Common/Row';

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  width: calc(100% - 4px);
  cursor: pointer;
  padding-left: 4px;

  &:hover {
    background-color: #f2f2f2;
  }
`;
const List = styled.div`
  margin-top: 12px;
  height: 192px;
  overflow-y: auto;
`;

interface Option {
  key: string;
  label: string;
  value: any;
  renderRight?: JSX.Element;
}

const SingleItemPicker = ({
  searchText,
  onSearchTextChanged,
  isLoading,
  options,
  onOptionSelected,
  testId,
  onClearClicked,
}: {
  searchText: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  options: Option[];
  onOptionSelected: (x: any) => void;
  testId?: string;
  onClearClicked?: () => void;
}) => (
  <div data-testid={testId} style={{ padding: 8 }}>
    <TextInput
      value={searchText}
      onChange={onSearchTextChanged}
      icon="search"
      inputSize="Small"
      placeholder="Search"
      autoFocus
    />
    <List>
      {isLoading && <Loading isSmall />}
      {!isLoading && options.length === 0 && (
        <Typography.Body type="Body 12">No options found</Typography.Body>
      )}
      {!!onClearClicked && (
        <Item onClick={onClearClicked}>
          <Row centerAlign>
            <div style={{ marginRight: 4, display: 'flex' }}>
              <Icon icon={'cross'} color={Colors2.Grey['4']} />
            </div>
            <Typography.Body type="Body 12" color={Colors2.Grey['4']}>
              None
            </Typography.Body>
          </Row>
        </Item>
      )}
      {options.map((d) => (
        <Item
          data-testid={`select-${d.label}`}
          key={d.key}
          onClick={() => {
            onOptionSelected(d.value);
          }}
        >
          <Typography.Body type="Body 12" isEllipsis>
            {d.label}
          </Typography.Body>
          {d.renderRight && (
            <div style={{ marginLeft: 4 }}>{d.renderRight}</div>
          )}
        </Item>
      ))}
    </List>
  </div>
);

export default SingleItemPicker;
