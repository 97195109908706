import isSingleMetricDateMatrix from '../types/visTypeCheckers/isSingleMetricDateMatrix';
import SPECIFIC_SET_ROUTES from '../screens/DataManager/SpecificSet/routes';
import ALL_SETS_ROUTES from '../screens/DataManager/AllSets/routes';

const home = '/';
const logIn = '/logIn';
const resetPassword = '/reset-password';
const welcome = '/welcome';
const acceptInvite = '/invitations/accept';
const expiredInvitation = '/expiredInvitation';
const alreadyAcceptedInvitation = '/alreadyAcceptedInvitation';
const forgotPassword = '/forgot-password';
const wallboardSetup = '/wallboard-setup';
const signOut = '/signOut';
const myAccount = '/my-account';

const reports = '/reports';
const showReport = '/reports/:reportId';
export const buildReportShow = (id, isEditing) =>
  isEditing ? `/reports/${id}?editing=true` : `/reports/${id}`;

const dashboards = '/dashboards';
const showDashboard = '/dashboards/:dashboardId';
export const buildDashboardShow = (id, isEditing) =>
  isEditing ? `/dashboards/${id}?editing=true` : `/dashboards/${id}`;

const settings = '/settings';
const allUsersSettings = '/settings/users';
export const buildShowUser = (id) => `/settings/users/${id}`;
const showUserSettings = '/settings/users/:userId';
const billingSettings = '/settings/billing';
const integrationsSettings = '/settings/connectors';
const providerSettings = '/settings/connectors/:provider';
const irGuide = '/settings/connectors/ir-guide';
const allTargetCategories = '/settings/slas';
const targetCategoryShow = '/settings/slas/:category';
const ssoConfigurationsSettings = '/settings/sso';
const microsoftSsoRedirect = '/settings/sso/microsoft_consent_redirect';
const goals = '/goals';
const showGlobalGoal = '/goals/:goalId';
export const buildShowGlobalGoal = (goalId) => `/goals/${goalId}`;
const kpiLists = '/kpi-lists';
const showGlobalKpiList = '/kpi-lists/:scorecardId';
export const buildShowGlobalKpiList = (
  scorecardId,
  kpiId,
  periodStart,
  periodEnd,
) => {
  if (kpiId && periodStart && periodEnd) {
    return `/kpi-lists/${scorecardId}?kpiId=${kpiId}&periodStart=${periodStart}&periodEnd=${periodEnd}`;
  }

  return `/kpi-lists/${scorecardId}`;
};
const showCampaignGoal = '/workspaces/:workspaceId/goals/:goalTabId/:goalId';
export const buildShowCampaignGoal = (goalId, campaignId, goalsTabId) =>
  `/workspaces/${campaignId}/goals/${goalsTabId}/${goalId}`;
const showCampaignKpiList =
  '/workspaces/:workspaceId/kpi-lists/:scorecardTabId/:scorecardId';
const advancedSettings = '/settings/advanced';
export const buildTargetCategoryShow = (category) =>
  `/settings/slas/${category}`;
const targetShow = '/settings/slas/:category/:target';
export const buildTargetShow = (category, target) =>
  `/settings/slas/${category}/${target}`;
const localesSettings = '/settings/locale';

export const getGadgetLink = (chart) => {
  if (isSingleMetricDateMatrix(chart)) {
    return buildGadgetShow(chart.id);
  } else {
    return buildV5GadgetShow(chart.id);
  }
};

const v5GadgetBuilder = '/gadgets';
const v5GadgetBuilderShow = '/gadgets/v5/:gadgetId';
export const buildV5GadgetShow = (id) => `/gadgets/v5/${id}`;
const gadgetBuilderNew = '/gadgets/new';
const gadgetBuilderShow = '/gadgets/:gadgetId';
export const buildGadgetShow = (id) => `/gadgets/${id}`;

export const buildProviderShow = (provider) =>
  `/settings/connectors/${provider}`;

const showOrderDetails = '/details/:orderNumber';
export const buildShowOrderDetails = (id) => `/details/${id}`;

const boards = '/boards';
const showBoard = '/boards/:boardId';
export const buildShowBoard = (id) => `/boards/${id}`;
export const buildCustomerLaneSlideOutSearchQuery = ({
  lane,
  customer,
  customerType,
  documentId,
}) => {
  if (customerType === 'customer') {
    return `lane=${lane}&customerType=${customerType}&customer=${customer}&documentId=${documentId}`;
  } else {
    return `lane=${lane}&customerType=${customerType}&billTo=${customer}&documentId=${documentId}`;
  }
};
export const buildCustomerLaneSlideOutSearch = ({
  boardId,
  lane,
  customer,
  customerType,
  documentId,
}) =>
  `${buildShowBoard(boardId)}?${buildCustomerLaneSlideOutSearchQuery({
    lane,
    customer,
    customerType,
    documentId,
  })}`;

const wallBoardConfigs = '/wallBoardConfig';
const showWallBoardConfig = `/wallBoardConfig/:wallBoardId`;
export const buildShowWallBoardConfig = (id) => `/wallBoardConfig/${id}`;

const showWallBoard = `/wallBoard/:wallBoardId`;
export const buildShowWallBoard = (id) => `/wallBoard/${id}`;

// Workspaces
const workspaces = '/workspaces';
const showWorkSpace = `/workspaces/:workSpaceId`;
const showWorkSpaceTab = `/workspaces/:workSpaceId/:tabType/:tabId`;
export const buildShowWorkSpace = (id) => `/workspaces/${id}`;
export const buildShowWorkSpaceTab = ({ workSpaceId, tabType, tabId }) =>
  `/workspaces/${workSpaceId}/${tabType}/${tabId}`;

// Performance Apps
const performanceApps = '/performance-apps';
const showPerformanceApp = `/performance-apps/:workSpaceId`;
const showPerformanceAppTab = `/performance-apps/:workSpaceId/:tabType/:tabId`;
export const buildShowPerformanceApp = (id) => `/performance-apps/${id}`;
export const buildShowPerformanceAppTab = ({ workSpaceId, tabType, tabId }) =>
  `/performance-apps/${workSpaceId}/${tabType}/${tabId}`;

// Target Apps
const showTargetApp = `/target-apps/:targetAppId`;
const showTargetAppTasksTab = `/target-apps/:targetAppId/tasks`;
const showTargetAppContentTab = `/target-apps/:targetAppId/:tabType/:tabId`;
export const buildShowTargetApp = (id) => `/target-apps/${id}`;
const buildShowTargetAppTasksTab = (id) => `/target-apps/${id}/tasks`;
export const buildShowTargetAppContentTab = ({
  targetAppId,
  tabType,
  tabId,
}) => {
  if (tabType === 'tasks') {
    return buildShowTargetAppTasksTab(targetAppId);
  }
  return `/target-apps/${targetAppId}/${tabType}/${tabId}`;
};

// Entity Details
const showEntityApp = `/entities/:field/:value`;
const showEntityAppContentTab = `/entities/:field/:value/:tabType/:tabId`;
export const buildShowEntityApp = ({ field, value }) =>
  `/entities/${field}/${encodeURIComponent(value)}`;
export const buildShowEntityAppContentTab = ({
  field,
  value,
  tabType,
  tabId,
}) => {
  return encodeURI(
    `/entities/${field}/${encodeURIComponent(value)}/${tabType}/${tabId}`,
  );
};

const driverBonus = '/driverBonus';

const datasetDefinitions = {
  home: ALL_SETS_ROUTES.BASE,
  dataset: SPECIFIC_SET_ROUTES.BASE,
  field: `${SPECIFIC_SET_ROUTES.BASE}?field=:field`,
  buildShowDataset: SPECIFIC_SET_ROUTES.buildFieldSettings,
  buildShowField: (type, field) =>
    `${SPECIFIC_SET_ROUTES.buildFieldSettings(type)}?field=${field}`,
};

// On-boarding
const onBoardingLanding = '/';
const onBoardingBilling = '/billing';
const onBoardingTms = '/tms';

const commissions = '/settings/commissions';

const copyContent = '/settings/copy-content';
const copyContentHistory = '/settings/copy-content-history';

const AppRoutes = {
  home,
  logIn,
  welcome,
  acceptInvite,
  forgotPassword,
  expiredInvitation,
  alreadyAcceptedInvitation,
  resetPassword,
  wallboardSetup,
  onBoarding: {
    landing: onBoardingLanding,
    billing: onBoardingBilling,
    tms: onBoardingTms,
  },
  loggedIn: {
    reports,
    showReport,
    dashboards,
    showDashboard,
    signOut,
    myAccount,
    allUsersSettings,
    showUserSettings,
    localesSettings,
    allTargetCategories,
    targetCategoryShow,
    targetShow,
    settings,
    billingSettings,
    v5GadgetBuilder,
    v5GadgetBuilderShow,
    gadgetBuilderNew,
    gadgetBuilderShow,
    integrationsSettings,
    irGuide,
    providerSettings,
    showOrderDetails,
    boards,
    showBoard,
    wallBoardConfigs,
    showWallBoardConfig,
    showWallBoard,
    workspaces,
    showWorkSpace,
    showWorkSpaceTab,
    performanceApps,
    showPerformanceApp,
    showPerformanceAppTab,
    showTargetApp,
    showTargetAppTasksTab,
    showTargetAppContentTab,
    showEntityApp,
    showEntityAppContentTab,
    driverBonus,
    goals,
    showCampaignGoal,
    showCampaignKpiList,
    showGlobalGoal,
    datasetDefinitions,
    advancedSettings,
    commissions,
    kpiLists,
    showGlobalKpiList,
    ssoConfigurationsSettings,
    microsoftSsoRedirect,
    copyContent,
    copyContentHistory,
  },
};

export default AppRoutes;
