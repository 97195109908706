import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Colors from 'theme/colors';

import userDisplayName from '../../../services/userDisplayName';
import UserIcon from '../../Common/UserIcon';
import Colors2 from '../../../theme/Colors2';
import { TOP_BAR_HEIGHT } from '../../../constants';

const Header = styled.div`
  height: ${TOP_BAR_HEIGHT}px;
  background-color: ${Colors.BLUE_GREY};
  border-bottom: 1px solid ${Colors2.Border};
  display: flex;
  align-items: center;
  padding: 0px 16px;
`;

const Content = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

const SuggestionRow = styled.div<{ focused: boolean }>`
  cursor: pointer;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;

  background-color: ${(props) => (props.focused ? Colors.LAVENDER : undefined)};
`;

const MentionSuggestions = ({
  onSuggestionSelected,
  suggestions,
  focusIndex,
  setFocusIndex,
  mentionInput,
}: {
  onSuggestionSelected: (
    u:
      | UserManagement.PendingUser
      | UserManagement.SignedUpUser
      | UserManagement.FleetOpsStaffUser,
  ) => void;
  suggestions: (
    | UserManagement.PendingUser
    | UserManagement.SignedUpUser
    | UserManagement.FleetOpsStaffUser
  )[];
  focusIndex: number;
  setFocusIndex: (n: number) => void;
  mentionInput: string;
}) => (
  <div>
    <Header>
      <Typography.Body type="Body 14" color={Colors2.Grey['5']}>
        {mentionInput ? `People matching ${mentionInput}` : 'Mention someone'}
      </Typography.Body>
    </Header>
    <Content>
      {suggestions.map((suggestion, index) => (
        <SuggestionRow
          key={userDisplayName(suggestion)}
          onClick={() => onSuggestionSelected(suggestion)}
          onMouseOver={() => setFocusIndex(index)}
          onFocus={() => setFocusIndex(index)}
          focused={index === focusIndex}
        >
          <UserIcon {...suggestion} />
          <Typography.Body type="Body 12">
            {suggestion.displayName}
          </Typography.Body>
        </SuggestionRow>
      ))}
    </Content>
  </div>
);

export default MentionSuggestions;
