import React, { useState } from 'react';
import ImpersonatorContext from 'contexts/ImpersonatorContext';

const ImpersonatorProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [impersonatorId, setImpersonatorId] = useState<string | undefined>();

  return (
    <ImpersonatorContext.Provider
      value={{
        impersonatorId,
        setImpersonatorId,
      }}
    >
      {children}
    </ImpersonatorContext.Provider>
  );
};

export default ImpersonatorProvider;
