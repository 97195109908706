import React, { useContext } from 'react';
import Typography from 'kingpin/atoms/Typography';
import InlineDialog from '../InlineDialog';

import styled, { css } from 'styled-components';
import Row from '../Common/Row';
import ReportDrillDownForm from '../ReportDrillDownForm';
import Icon from '../../kingpin/atoms/Icon';
import Colors2 from '../../theme/Colors2';
import Badge from '../Badge';
import ReportContext from 'contexts/ReportContext';

export const FILTER_PLATE_HEIGHT = 32;

const isDisabledStyle = css`
  cursor: not-allowed;
`;

const InlineButton = styled.div<{ isDisabled?: boolean }>`
  border-radius: 8px;

  height: ${FILTER_PLATE_HEIGHT - 2}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${(props) => props.isDisabled && isDisabledStyle};

  &:hover {
    opacity: 0.7;
  }

  svg {
    height: 16px;
  }
`;

const InlineRemoveButton = styled(InlineButton)`
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`;

const InlineChevronButton = styled(InlineButton)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`;

const OuterDiv = styled.div<{ hasVariableToggles?: boolean; isOpen: boolean }>`
  max-width: 298px;
  height: ${FILTER_PLATE_HEIGHT}px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  padding: 0px 4px;
  background-color: ${(props) => (props.isOpen ? '#E7EDFF' : '#F6F6F6')};
`;

const InnerDiv = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  padding: 0px 4px;
`;

const LabelDiv = styled.div<{
  onClick?: () => void;
  hasVariableToggles: boolean;
}>`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${(props) => (props.hasVariableToggles ? '125px' : 'unset')};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'not-allowed')};
  padding-right: ${(props) => (props.hasVariableToggles ? '4px' : '24px')};
`;

const VariableToggleDiv = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const VariableToggleButton = ({
  isVariable,
  onSetToVariable,
  onSetToFixed,
}: {
  isVariable: boolean;
  onSetToVariable: () => void;
  onSetToFixed: () => void;
}) => {
  const onClick = isVariable ? onSetToFixed : onSetToVariable;

  return (
    <div className="visible" style={{ pointerEvents: 'none', marginLeft: 24 }}>
      <VariableToggleDiv style={{ pointerEvents: 'auto' }} onClick={onClick}>
        <Badge
          text={'Is Variable'}
          badgeType={isVariable ? 'Warning' : 'Info'}
        />
      </VariableToggleDiv>
    </div>
  );
};

interface Props {
  label: string;
  fieldValue: string;
  onRemoved: () => void;
  drillDown: ReportDrillDownType;
  isOpen: boolean;
  open: () => void;
  close: () => void;
  isScope: boolean;
  isVariable: boolean;
  onSetToVariable: () => void;
  onSetToFixed: () => void;
  isInVariableFilterList?: boolean;
  onUpdated?: (newDrillDown: ReportDrillDownType) => void;
  isTemplate: boolean;
  onManualFilterChanged?: () => void;
}

const Interactive = ({
  label,
  fieldValue,
  onRemoved,
  drillDown,
  isOpen,
  open,
  close,
  isScope,
  isVariable,
  onSetToVariable,
  onSetToFixed,
  isInVariableFilterList,
  onUpdated,
  isTemplate,
  onManualFilterChanged,
}: Props) => {
  const { report } = useContext(ReportContext);
  const hasVariableToggles = !isInVariableFilterList && isTemplate && !report;
  const onOpenClicked = isVariable && hasVariableToggles ? undefined : open;
  const color = isOpen ? Colors2.Secondary.info : '#333333';
  return (
    <InlineDialog
      isOpen={isOpen}
      onClose={close}
      content={
        <React.Fragment>
          <ReportDrillDownForm
            drillDown={drillDown}
            close={close}
            isScope={isScope}
            onUpdated={onUpdated}
            onManualFilterChanged={onManualFilterChanged}
          />
        </React.Fragment>
      }
    >
      <OuterDiv hasVariableToggles={hasVariableToggles} isOpen={isOpen}>
        {!isInVariableFilterList && (
          <InlineRemoveButton onClick={onRemoved}>
            <Icon icon={'cross'} color={color} />
          </InlineRemoveButton>
        )}
        <InnerDiv>
          <LabelDiv
            onClick={isOpen ? close : onOpenClicked}
            hasVariableToggles={hasVariableToggles}
            data-testid={`open-filter-input-${drillDown.field}`}
          >
            <Row centerAlign style={{ overflow: 'hidden' }}>
              {!isInVariableFilterList && (
                <div style={{ marginRight: 4 }}>
                  <Typography.Body type="Button Text" color={color} isEllipsis>
                    {`${label}:`}
                  </Typography.Body>
                </div>
              )}

              <Typography.Body type="Button Text" isEllipsis color={color}>
                {fieldValue}
              </Typography.Body>
            </Row>
          </LabelDiv>
          {hasVariableToggles && (
            <VariableToggleButton
              isVariable={isVariable}
              onSetToVariable={onSetToVariable}
              onSetToFixed={onSetToFixed}
            />
          )}
        </InnerDiv>

        {onOpenClicked && (
          <InlineChevronButton onClick={onOpenClicked}>
            <Icon icon={'chevron-down'} color={color} />
          </InlineChevronButton>
        )}
      </OuterDiv>
    </InlineDialog>
  );
};

export default Interactive;
