import { useContext, useEffect, useState } from 'react';
import GqlClientContext from 'contexts/GqlClientContext';
import getFieldsWhichHaveTargets from '../api/getFieldsWhichHaveTargets';

const useGetFieldsWhichHaveTargets = ({ dataType }: { dataType: string }) => {
  const { client } = useContext(GqlClientContext);
  const [fields, setFields] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);

    getFieldsWhichHaveTargets({
      dataType,
      client,
    }).then((newFields) => {
      if (!isActive) {
        return;
      }
      setFields(newFields);
      setIsLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, [client, dataType]);

  return {
    fields,
    isLoading,
  };
};

export default useGetFieldsWhichHaveTargets;
