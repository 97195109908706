/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import Spinner from 'components/AtlassianTemp/Spinner';

import ResetPasswordForm from './ResetPasswordForm';
import InvalidCode from './InvalidCode';
import captureException from '../../services/captureException';
import appRoutes from '../../navigation/appRoutes';
import { useNavigate } from 'react-router-dom';
import {
  useOnPasswordChanged,
  useOnPasswordConfirmChanged,
} from '../../screens/AcceptInvite';
import CloudFunctionClientContext from '../../contexts/CloudFunctionClientContext';

const parseQueryParams = () => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const keys = Array.from(params.keys());
  const whitelist = ['oobCode', 'name', 'email'];
  const permittedKeys = keys.filter((key) => whitelist.includes(key));
  const result = {};
  permittedKeys.forEach((key) => {
    // @ts-ignore
    result[key] = params.get(key);
  });

  return result;
};

const ResetPasswordFormContainer = ({
  isOnBoardingFlow,
}: {
  isOnBoardingFlow: boolean;
}) => {
  const { api } = useContext(CloudFunctionClientContext);
  const navigate = useNavigate();
  // @ts-ignore
  const { oobCode, name, email } = parseQueryParams();
  const [hasVerifiedCode, setHasVerifiedCode] = useState(false);
  const [isHidingPassword, setIsHidingPassword] = useState<boolean>(true);
  const [passwordErrors, setPasswordErrors] = useState<string[] | undefined>();
  const [reEnterPasswordError, setReEnterPasswordError] = useState<
    string | undefined
  >();
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');

  const onPasswordChanged = useOnPasswordChanged({
    passwordConfirm,
    setReEnterPasswordError,
    setPasswordErrors,
    setPassword,
  });

  const onPasswordConfirmChanged = useOnPasswordConfirmChanged({
    password,
    setReEnterPasswordError,
    setPasswordConfirm,
  });

  const [isResetCodeValid, setIsResetCodeValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const goBack = () => navigate(appRoutes.home);

  useEffect(() => {
    if (!oobCode) {
      setIsResetCodeValid(false);
      setHasVerifiedCode(true);
      return;
    }

    firebase
      .auth()
      .verifyPasswordResetCode(oobCode)
      .then(() => {
        setIsResetCodeValid(true);
        setHasVerifiedCode(true);
      })
      .catch((ex) => {
        console.warn(ex.message);
        setIsResetCodeValid(false);
        setHasVerifiedCode(true);
      });
  }, []);

  const isValid =
    password !== '' &&
    password === passwordConfirm &&
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(password);

  const onSubmit = () => {
    if (!isValid) {
      return;
    }

    setIsLoading(true);
    firebase
      .auth()
      .confirmPasswordReset(oobCode, password)
      .then(() => {
        navigate(appRoutes.home);
        firebase
          .auth()
          .signInWithEmailAndPassword(decodeURIComponent(email), password)
          .then(({ user }) => {
            if (user) {
              api
                .post(`resetPassword/${user.uid}/sendPasswordChangedEmail`, {})
                .then(() => {
                  alert(
                    isOnBoardingFlow
                      ? 'Your password has been set'
                      : 'Your password has been reset',
                  );
                  setIsLoading(false);
                });
            }
          });
      })
      .catch((ex) => {
        captureException(ex);
        alert('Something went wrong!');
        setIsLoading(false);
      });
  };

  if (!hasVerifiedCode) {
    return <Spinner />;
  }

  if (!isResetCodeValid) {
    return <InvalidCode isOnBoardingFlow={isOnBoardingFlow} goBack={goBack} />;
  }
  return (
    <ResetPasswordForm
      email={email}
      isOnBoardingFlow={isOnBoardingFlow}
      name={decodeURIComponent(name)}
      isLoading={isLoading}
      onSubmit={onSubmit}
      password={password}
      onPasswordChanged={onPasswordChanged}
      passwordConfirm={passwordConfirm}
      onPasswordConfirmChanged={onPasswordConfirmChanged}
      isHidingPassword={isHidingPassword}
      setIsHidingPassword={setIsHidingPassword}
      isValid={isValid}
      passwordConfirmError={reEnterPasswordError}
      passwordErrors={passwordErrors}
    />
  );
};

export default ResetPasswordFormContainer;
