import React, { useContext, useEffect, useState } from 'react';
import BaseViewsContext from '../../../../../../contexts/BaseViewsContext';
import AnalyticsContext from '../../../../../../contexts/AnalyticsContext';

const useDataSourceOptions = ({
  setDataSource,
  onDatasetChanged,
}: {
  setDataSource: React.Dispatch<React.SetStateAction<string | undefined>>;
  onDatasetChanged: () => void;
}) => {
  const { dataTypeOptions } = useContext(BaseViewsContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const [dataSourceOptions, setDataSourceOptions] = useState<DropdownOption[]>(
    [],
  );
  useEffect(() => {
    setDataSourceOptions(
      dataTypeOptions.map(({ label, value }) => {
        return {
          label,
          value,
          onSelected: () => {
            setDataSource(value);
            trackEvent('Metric Builder - Datasource selected', {
              dataset: value,
            });
            onDatasetChanged();
          },
        };
      }),
    );
  }, [dataTypeOptions, onDatasetChanged, setDataSource, trackEvent]);

  return dataSourceOptions;
};

export default useDataSourceOptions;
