import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import CopyGadgetModal from './CopyGadgetModal';
import CopyGadgetContext from '../../contexts/CopyGadgetContext';
import DashboardsContext from '../../contexts/DashboardsContext';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import WallboardsContext from 'contexts/WallboardsContext';
import useHasAccess from '../../hooks/useHasAccess';
import PERMISSIONS from '../../permissionDefinitions';
import RolesContext from '../../contexts/RolesContext';

const useDashboardToCopyTo = () => {
  const { allDashboards } = useContext(DashboardsContext);
  const hasAccess = useHasAccess();
  const getDashboards = useCallback(() => {
    return allDashboards
      .filter((d) => !d.isWallboardSlide)
      .filter((d) => d.templateId === undefined)
      .filter((d) => hasAccess({ access: d.access, resource: d }));
  }, [allDashboards, hasAccess]);
  const [dashboards, setDashboards] = useState<PersistedDashboardType[]>(() =>
    getDashboards(),
  );
  useEffect(() => {
    setDashboards(getDashboards());
  }, [getDashboards]);

  return dashboards;
};

const useCopyGadgetToOptions = () => {
  const { copyGadgetTo, setCopyGadgetTo } = useContext(CopyGadgetContext);
  const [options, setOptions] = useState<RadioOption[]>([]);
  const { currentPermissions } = useContext(RolesContext);

  useEffect(() => {
    const hasWallboardCreatePermission = currentPermissions.includes(
      PERMISSIONS.USER_MANAGEMENT.CREATE_WALLBOARD,
    );

    const newOptions: RadioOption[] = [];
    newOptions.push({
      key: 'new dashboard',
      label: 'New Dashboard',
      onSelected: () => {
        setCopyGadgetTo('new dashboard');
      },
      isSelected: copyGadgetTo === 'new dashboard',
    });
    newOptions.push({
      key: 'existing dashboard',
      label: 'Existing Dashboard',
      onSelected: () => {
        setCopyGadgetTo('existing dashboard');
      },
      isSelected: copyGadgetTo === 'existing dashboard',
    });
    if (hasWallboardCreatePermission) {
      newOptions.push({
        key: 'wallboard',
        label: 'Wallboard',
        onSelected: () => {
          setCopyGadgetTo('wallboard');
        },
        isSelected: copyGadgetTo === 'wallboard',
      });
    }
    setOptions(newOptions);
  }, [copyGadgetTo, currentPermissions, setCopyGadgetTo]);

  return options;
};

const CopyGadgetModalContainer = () => {
  const {
    isLoading,
    copyGadgetTo,
    setCopyGadgetTo,
    createDashboard,
    copyToDashboard,
    close,
    copyToWallboard,
  } = useContext(CopyGadgetContext);
  const copyGadgetToOptions = useCopyGadgetToOptions();
  const dashboardsToCopyTo = useDashboardToCopyTo();
  const { trackEvent } = useContext(AnalyticsContext);
  const { wallBoards } = useContext(WallboardsContext);

  const [step, setStep] = useState<1 | 2>(1);
  // Step 1
  const onCopyClicked = useCallback(() => {
    if (copyGadgetTo === undefined) {
      return;
    }

    trackEvent('Copy Gadget - Mode Selected', {
      mode:
        copyGadgetTo === 'wallboard'
          ? 'Wallboard'
          : copyGadgetTo === 'existing dashboard'
            ? 'Existing Dashboard'
            : 'New Dashboard',
    });
    setStep(2);
  }, [copyGadgetTo, trackEvent]);

  // Step 2
  const [filteredDashboards, setFilteredDashboards] =
    useState<PersistedDashboardType[]>(dashboardsToCopyTo);
  const [title, setTitle] = useState<string>('');
  const [access, setAccess] = useState<ResourceAccess>({
    version: '2',
    type: 'Private',
  });
  const [searchText, setSearchText] = useState<string>('');
  const [selectedDashboard, setSelectedDashboard] = useState<
    PersistedDashboardType | undefined
  >();
  const [selectedWallboard, setSelectedWallboard] = useState<
    Wallboard | undefined
  >();

  const filteredWallboards = wallBoards.filter((w) => {
    if (searchText === '') {
      return w;
    } else {
      return w.name.toLowerCase().includes(searchText.toLowerCase());
    }
  });

  const onTitleChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  }, []);

  const onSearchTextChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  const onBackClicked = useCallback(() => {
    setTitle('');
    setAccess({
      version: '2',
      type: 'Private',
    });
    setSearchText('');
    setSelectedDashboard(undefined);
    setCopyGadgetTo(undefined);
    setStep(1);
  }, [setCopyGadgetTo]);

  const onSaveClicked = useCallback(() => {
    if (isLoading) {
      return;
    }
    if (copyGadgetTo === 'new dashboard') {
      createDashboard(title, access).then(() => {
        setCopyGadgetTo(undefined);
      });
    } else if (copyGadgetTo === 'existing dashboard') {
      if (selectedDashboard) {
        copyToDashboard(selectedDashboard).then(() => {
          setCopyGadgetTo(undefined);
        });
      } else {
        const e = new Error();
        e.name = 'CopyGadgetModal - Dashboard not selected';
        throw e;
      }
    } else if (copyGadgetTo === 'wallboard') {
      if (selectedWallboard) {
        copyToWallboard(selectedWallboard).then(() => {
          setCopyGadgetTo(undefined);
        });
        setCopyGadgetTo(undefined);
      } else {
        const e = new Error();
        e.name = 'CopyGadgetModal - Wallboard not selected';
        throw e;
      }
    } else {
      const e = new Error();
      e.name = 'CopyGadgetModal - Mode not set';
      throw e;
    }
  }, [
    isLoading,
    copyGadgetTo,
    createDashboard,
    title,
    access,
    setCopyGadgetTo,
    selectedDashboard,
    copyToDashboard,
    selectedWallboard,
    copyToWallboard,
  ]);

  useEffect(() => {
    if (searchText === '') {
      setFilteredDashboards(dashboardsToCopyTo);
      return;
    }

    setFilteredDashboards(
      dashboardsToCopyTo.filter((d) =>
        d.name.toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [searchText, dashboardsToCopyTo]);

  return (
    <CopyGadgetModal
      step={step}
      isLoading={isLoading}
      // Step 1
      copyGadgetTo={copyGadgetTo}
      copyGadgetToOptions={copyGadgetToOptions}
      close={close}
      onCopyClicked={onCopyClicked}
      // Step 2
      onSaveClicked={onSaveClicked}
      onBackClicked={onBackClicked}
      // Step 2 - new dashboard
      title={title}
      onTitleChanged={onTitleChanged}
      access={access}
      setAccess={setAccess}
      // Step 2 - existing dashboard
      searchText={searchText}
      onSearchTextChanged={onSearchTextChanged}
      filteredDashboards={filteredDashboards}
      selectedDashboard={selectedDashboard}
      setSelectedDashboard={setSelectedDashboard}
      selectedWallboard={selectedWallboard}
      setSelectedWallboard={setSelectedWallboard}
      filteredWallboards={filteredWallboards}
    />
  );
};

export default CopyGadgetModalContainer;
