import 'firebase/compat/firestore';
import { useContext, useEffect, useState } from 'react';
import migrateScope, { migrateDrillDowns } from '../migrateScope';
import AccountPickerContext from '../contexts/AccountPickerContext';
import STORE from '../store';

const migrate = (sf: SavedFilter): SavedFilter => {
  return {
    ...sf,
    scope: migrateScope(sf.scope),
    drillDowns: sf.drillDowns ? migrateDrillDowns(sf.drillDowns) : [],
  };
};

const useSavedFilter = (id: string | undefined) => {
  const [isLoading, setIsLoading] = useState<boolean>(!!id);
  const [savedFilter, setSavedFilter] = useState<SavedFilter | undefined>();
  const { selectedAccountId, accountRef } = useContext(AccountPickerContext);

  useEffect(() => {
    if (!id) {
      setIsLoading(false);
      setSavedFilter(undefined);
    } else {
      setIsLoading(true);
      const listener = STORE.getSavedFiltersRef({
        accountId: selectedAccountId,
      })
        .doc(id)
        .onSnapshot((snapshot) => {
          const sf = migrate(snapshot.data() as SavedFilter);
          setSavedFilter(sf);
          setIsLoading(false);
        });

      return () => {
        listener();
      };
    }
  }, [accountRef, id, selectedAccountId]);

  return {
    savedFilter,
    isLoading,
  };
};

export default useSavedFilter;
