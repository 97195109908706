import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import Portal from './Portal';
import { SLIDE_OUT_ELEMENT_ID, Z_INDEX } from '../constants';
const DURATION = 300;
const SLIDE_OUT_WIDTH = 632;
export const SLIDE_OUT_VERTICAL_PADDING = 20;

const Overlay = styled(motion.div)<{ $isOpen: boolean }>`
  display: block;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  position: absolute;
  background-color: #000000;
  top: 0px;
  left: 0px;
  z-index: ${Z_INDEX.SLIDE_OUT_OVERLAY_OPENED};
  pointer-events: ${(props) => (props.$isOpen ? 'auto' : 'none')};
`;

const SlideOutDiv = styled(motion.div)<{ $noPadding?: boolean }>`
  height: 100vh;
  background-color: white;
  z-index: ${Z_INDEX.SLIDE_OUT};
  position: fixed;
  width: ${SLIDE_OUT_WIDTH}px;
  right: 0px;

  box-shadow: 0px 1px 12px 5px #3f3f441a;

  border-radius: 2px;

  padding: ${(props) =>
    props.$noPadding ? '0px' : `${SLIDE_OUT_VERTICAL_PADDING}px 40px`};
`;

const overlayVariants = {
  opened: {
    opacity: 0.32,
  },
  closing: {
    opacity: 0,
  },
  closed: {
    opacity: 0,
  },
};

const slideOutVariants = {
  opened: {
    right: 0,
  },
  closing: {
    right: -SLIDE_OUT_WIDTH,
  },
  closed: {
    right: -SLIDE_OUT_WIDTH,
  },
};

const SlideOut = ({
  isOpen,
  close,
  noPadding,
  children,
}: {
  isOpen: boolean;
  close: () => void;
  noPadding?: boolean;
  children: JSX.Element | JSX.Element[] | false;
}) => {
  const [isCloseComplete, setIsCloseComplete] = useState<boolean>(true);

  useEffect(() => {
    if (isOpen) {
      setIsCloseComplete(false);
      return;
    }
    const t = setTimeout(() => {
      setIsCloseComplete(true);
    }, DURATION);

    return () => {
      clearTimeout(t);
    };
  }, [isOpen]);

  return (
    <Portal elementId={SLIDE_OUT_ELEMENT_ID}>
      <Overlay
        initial="closed"
        variants={overlayVariants}
        animate={isOpen ? 'opened' : isCloseComplete ? 'closed' : 'closing'}
        onClick={close}
        $isOpen={isOpen}
      />
      <SlideOutDiv
        initial="closed"
        variants={slideOutVariants}
        $noPadding={noPadding}
        animate={isOpen ? 'opened' : isCloseComplete ? 'closed' : 'closing'}
      >
        {children}
      </SlideOutDiv>
    </Portal>
  );
};

export default SlideOut;
