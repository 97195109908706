import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import DatePicker from './DatePicker';
import ReportDrillDownFormContext from '../../../contexts/ReportDrillDownFormContext';
import useDateFilterValues from '../../../hooks/useDateFilterValues';
import isDateOptionEqual from '../isDateOptionEqual';
import doesArrayContainDateOption from '../doesArrayContainDateOption';
import drilldownTypeCheckers from '../drilldownTypeCheckers';
import getIdentifier from '../../../getIdentifier';

const useEditingDateFilter = () => {
  const { drillDown } = useContext(ReportDrillDownFormContext);

  if (!drillDown) {
    return undefined;
  }
  if (drilldownTypeCheckers.isDateDrillDown(drillDown)) {
    return drillDown;
  }

  return undefined;
};

const DatePickerContainer = () => {
  const editingDateFilter = useEditingDateFilter();
  const [searchText, setSearchText] = useState('');
  const { field, fieldType, isEditing, dataset, onDrillDownConfirmed } =
    useContext(ReportDrillDownFormContext);
  const [alreadySelectedOptions] = useState<DateOption[]>(
    editingDateFilter ? editingDateFilter.dateRangeValues || [] : [],
  );
  const [dateRangeValues, setDateRangeValues] = useState<DateOption[]>(
    editingDateFilter ? editingDateFilter.dateRangeValues || [] : [],
  );
  const { options } = useDateFilterValues(field, 'dateText');
  const optionsWithoutPreSelected = alreadySelectedOptions
    ? options.filter(
        (o) => !doesArrayContainDateOption(alreadySelectedOptions, o),
      )
    : options;
  const filteredOptions = (() => {
    if (searchText === '') {
      return optionsWithoutPreSelected;
    }

    return optionsWithoutPreSelected.filter((o) =>
      o.label.toLowerCase().includes(searchText.toLowerCase()),
    );
  })();

  const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const onKeywordSelected = (dateRange: DateOption) => {
    setDateRangeValues([...(dateRangeValues || []), dateRange]);
  };

  const onKeywordDeselected = (dateRange: DateOption) => {
    setDateRangeValues([
      ...(dateRangeValues || []).filter(
        (o) => !isDateOptionEqual(dateRange, o),
      ),
    ]);
  };

  const onConfirm = useCallback(() => {
    if (!field) {
      return;
    }
    if (!(fieldType === 'dateText' || fieldType === 'date')) {
      return;
    }
    const newDrillDown: DateDrillDown = (() => {
      const base = {
        field,
        fieldType,
        dataset,
        dateRangeValues,
        mode: 'editing' as 'editing',
      };
      if (editingDateFilter) {
        return {
          ...editingDateFilter,
          ...base,
        };
      }

      return {
        id: getIdentifier(),
        ...base,
      };
    })();
    onDrillDownConfirmed(newDrillDown);
  }, [
    dataset,
    dateRangeValues,
    editingDateFilter,
    field,
    fieldType,
    onDrillDownConfirmed,
  ]);

  const clearSelectedOptions = () => {
    setDateRangeValues([]);
  };

  const isSearching = searchText !== '';
  const selectedOptions = dateRangeValues || [];
  const canClearSelectedOptions =
    !isSearching &&
    alreadySelectedOptions &&
    selectedOptions.filter((o) => alreadySelectedOptions.includes(o)).length >
      1;

  return (
    <DatePicker
      isSearching={isSearching}
      canClearSelectedOptions={!!canClearSelectedOptions}
      alreadySelectedOptions={alreadySelectedOptions}
      options={filteredOptions}
      selectedOptions={selectedOptions}
      searchText={searchText}
      onSearchTextChanged={onSearchTextChanged}
      onKeywordSelected={onKeywordSelected}
      onKeywordDeselected={onKeywordDeselected}
      onConfirm={onConfirm}
      isEditing={isEditing}
      clearSelectedOptions={clearSelectedOptions}
      isValid={dateRangeValues.length !== 0}
    />
  );
};

export default DatePickerContainer;
