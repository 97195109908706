import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import BoardContext from 'contexts/BoardContext';
import Typography from 'kingpin/atoms/Typography';
import Loading from 'components/Loading/Loading';
import useGetFieldLabel from 'hooks/useGetFieldLabel';
import useColumnOrder from '../hooks/useColumnOrder';
import BoardSettingsHeader from '../BoardSettingsHeader/BoardSettingsHeader';
import FieldsGrid from './FieldsGrid/FieldsGrid';
import TextInput from '../../../../../kingpin/atoms/TextInput';

const BoardFieldsSettings = ({
  columnOrder,
  updateColumnOrder,
}: {
  columnOrder: PerformanceBoardTypes.Column[];
  updateColumnOrder: ({
    newOrder,
  }: {
    newOrder: PerformanceBoardTypes.Column[];
  }) => void;
}) => {
  const { board } = useContext(BoardContext);
  const [searchText, setSearchText] = useState<string>('');
  const [isFieldsFound, setIsFieldsFound] = useState<boolean>(true);
  const { getFieldLabel } = useGetFieldLabel();

  const getFieldDisplayName = (column: PerformanceBoardTypes.Column) => {
    return column.isProgressVis
      ? `${getFieldLabel({
          field: column.field,
          dataType: board.dataType,
        })} (Progress Field)`
      : getFieldLabel({
          field: column.field,
          dataType: board.dataType,
        });
  };

  const filteredColumns = columnOrder.filter((c) =>
    getFieldDisplayName(c).toLowerCase().includes(searchText.toLowerCase()),
  );

  useEffect(() => {
    setIsFieldsFound(filteredColumns.length !== 0);
  }, [filteredColumns.length, setIsFieldsFound]);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ height: '141px' }}>
        <div style={{ paddingRight: '48px' }}>
          <BoardSettingsHeader
            title="Field Order"
            description="Configure your table order or fields"
          />
        </div>
        <div style={{ marginBottom: '16px', paddingRight: '48px' }}>
          <TextInput
            value={searchText}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setSearchText(event.target.value);
            }}
            placeholder="Search Fields"
            icon="search"
            width="100%"
            autoFocus
          />
        </div>
      </div>
      <div style={{ marginRight: '45px', flex: 1, overflowY: 'scroll' }}>
        {isFieldsFound ? (
          <FieldsGrid
            searchText={searchText}
            filteredColumns={filteredColumns}
            columnOrder={columnOrder}
            updateColumnsOrder={updateColumnOrder}
          />
        ) : (
          <Typography.Body type="Body 12">There is no fields</Typography.Body>
        )}
      </div>
    </div>
  );
};

const Gate = () => {
  const { isLoading, columnOrder, updateColumnOrder } = useColumnOrder();

  if (isLoading) {
    return (
      <div style={{ position: 'absolute', left: 'calc(50% + 48px)' }}>
        <Loading />
      </div>
    );
  }
  if (!columnOrder) {
    return null;
  }
  return (
    <BoardFieldsSettings
      columnOrder={columnOrder}
      updateColumnOrder={updateColumnOrder}
    />
  );
};

export default Gate;
