import React, { useContext } from 'react';

import styled from 'styled-components';
import NavSideBarContext from '../../contexts/NavSideBarContext';
import MainNavTop from './MainNavTop';
import NavSection from './NavSection';
import NavItem from './NavItem';
import AppRoutes from '../../navigation/appRoutes';
import MainNavBottom from './MainNavBottom';
import FeatureGate, { FEATURE_GATES } from '../../components/FeatureGate';
import GlobalSearch from '../../components/GlobalSearch';
import MainNavFavouritesSection from './MainNavFavouritesSection';
import AccountPicker from '../../components/AccountPicker';
import NAVIGATION from './constants';
import Colors2 from '../../theme/Colors2';
import PermissionGates from 'components/PermissionGates';
import PERMISSIONS from '../../permissionDefinitions';
import Row from 'components/Common/Row';
import FavoritesSection from './FavoritesSection';

const MainNavDiv = styled.div<{ isCollapsed: boolean }>`
  width: ${(props) =>
    props.isCollapsed
      ? NAVIGATION.COLLAPSED_NAV_WIDTH
      : NAVIGATION.OPENED_NAV_WIDTH}px;
  min-width: ${(props) =>
    props.isCollapsed
      ? NAVIGATION.COLLAPSED_NAV_WIDTH
      : NAVIGATION.OPENED_NAV_WIDTH}px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fbfbfb;
  justify-content: space-between;
  border-right: 1px solid ${Colors2.Border};
`;

const MainNavPrimaryContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const BUILD_PERMISSIONS = [
  PERMISSIONS.DATA_MANAGEMENT.GADGETS,
  PERMISSIONS.DATA_MANAGEMENT.DATA_MANAGER_ACCESS,
];

const MainNav = () => {
  const { isOpen } = useContext(NavSideBarContext);

  return (
    <Row>
      <MainNavDiv isCollapsed={!isOpen}>
        <MainNavPrimaryContent>
          <MainNavTop />
          <NavSection>
            <AccountPicker />
            <NavItem to={AppRoutes.home} label={'Home'} icon={'home'} />
            <FeatureGate featureName={FEATURE_GATES.ENTITY_DETAILS}>
              <GlobalSearch />
            </FeatureGate>
            <MainNavFavouritesSection />
          </NavSection>
          <NavSection label="View">
            <NavItem
              to={AppRoutes.loggedIn.dashboards}
              label={'Dashboards'}
              tooltip={'Dashboards'}
              icon={'dashboard'}
              onClickEvent={'Navigation Sidebar - Clicked Dashboards'}
            />
            <NavItem
              to={AppRoutes.loggedIn.reports}
              label={'Reports'}
              tooltip={'Reports'}
              icon={'find-in-page'}
              onClickEvent={'Navigation Sidebar - Clicked Reports'}
            />
            <NavItem
              to={AppRoutes.loggedIn.workspaces}
              label={'Workspaces'}
              tooltip={'Workspaces'}
              icon={'workspace'}
              onClickEvent={'Navigation Sidebar - Clicked Workspaces'}
            />
            <PermissionGates.Has
              requiredPermission={PERMISSIONS.USER_MANAGEMENT.CREATE_WALLBOARD}
            >
              <NavItem
                to={AppRoutes.loggedIn.wallBoardConfigs}
                label={'Wallboards'}
                tooltip={'Wallboards'}
                icon={'keyboard'}
              />
            </PermissionGates.Has>
          </NavSection>
          <NavSection label="Perform">
            <FeatureGate featureName={FEATURE_GATES.PERFORMANCE}>
              <NavItem
                to={AppRoutes.loggedIn.performanceApps}
                label={'Apps'}
                tooltip={'Performance'}
                icon={'navigation-metrics'}
                onClickEvent={'Navigation Sidebar - Clicked Performance Apps'}
              />
            </FeatureGate>
            <NavItem
              to={AppRoutes.loggedIn.kpiLists}
              label={'KPI Lists'}
              tooltip={'KPI Lists'}
              icon={'navigation-goals'}
              onClickEvent={'Navigation Sidebar - Clicked KPI Lists'}
            />
          </NavSection>
          <PermissionGates.HasAny requiredPermissions={BUILD_PERMISSIONS}>
            <NavSection label="Build">
              <PermissionGates.Has
                requiredPermission={
                  PERMISSIONS.DATA_MANAGEMENT.DATA_MANAGER_ACCESS
                }
              >
                <NavItem
                  to={AppRoutes.loggedIn.datasetDefinitions.home}
                  label={'Data Manager'}
                  tooltip={'Data Manager'}
                  icon={'database'}
                  onClickEvent={'Navigation Sidebar - Clicked Data Manager'}
                />
              </PermissionGates.Has>
              <PermissionGates.Has
                requiredPermission={PERMISSIONS.DATA_MANAGEMENT.GADGETS}
              >
                <NavItem
                  to={AppRoutes.loggedIn.v5GadgetBuilder}
                  label={'Gadgets'}
                  tooltip={'Gadgets'}
                  icon={'note-stack-add'}
                  onClickEvent={'Navigation Sidebar - Clicked Gadgets'}
                />
              </PermissionGates.Has>
            </NavSection>
          </PermissionGates.HasAny>
        </MainNavPrimaryContent>
        <MainNavBottom />
      </MainNavDiv>
      <FavoritesSection />
    </Row>
  );
};

export default MainNav;
