import { useCallback, useContext, useState } from 'react';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import useRecents from '../useRecents';
import 'firebase/compat/firestore';
import buildContentViewId, {
  buildContentViewIdAlt,
} from '../../buildContentViewId';
import trackRecent from './trackRecent';
import trackLastViewedAt from './trackLastViewedAt';
import FavouritesContext from '../../contexts/FavouritesContext';
import WallboardContext from '../../contexts/WallboardContext';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import useNetworkingEffect from '../useNetworkingEffect';

const useContentViewTracking = ({
  type,
  typeId,
  name,
}: {
  type:
    | 'report'
    | 'dashboard'
    | 'wallboard'
    | 'workspace'
    | 'board'
    | 'scorecard'
    | 'targetsApp';
  typeId?: string;
  name?: string;
}) => {
  const { accountRef } = useContext(AccountPickerContext);
  const { id: userId, isWallboardUser } = useContext(CurrentUserContext);
  const { isWallboard } = useContext(WallboardContext);
  const [hasTrackedRecent, setHasTrackedRecent] = useState<boolean>(false);
  const [hasTrackedLastViewed, setHasTrackedLastViewed] =
    useState<boolean>(false);
  const { recents, isLoading: isLoadingRecents } = useRecents();
  const { favourites, isLoading: isLoadingFavourites } =
    useContext(FavouritesContext);

  const baseShouldTrack = useCallback(() => {
    if (
      isWallboardUser ||
      !typeId ||
      !name ||
      isLoadingRecents ||
      isLoadingFavourites ||
      isWallboard
    ) {
      return false;
    }

    if (
      window.location.href.toLowerCase().includes('/workspaces/') &&
      type !== 'workspace'
    ) {
      if (type !== 'board') {
        return false;
      }
    }

    if (
      window.location.href.toLowerCase().includes('/wallboard/') &&
      type !== 'wallboard'
    ) {
      return false;
    }

    if (
      window.location.href.toLowerCase().includes('/wallBoardConfig/') &&
      type !== 'wallboard'
    ) {
      return false;
    }

    if (
      window.location.href.toLowerCase().includes('/dashboards/') &&
      type === 'report'
    ) {
      return false;
    }

    if (
      window.location.href.toLowerCase().includes('/kpi-lists/') &&
      (type === 'report' || type === 'board')
    ) {
      return false;
    }

    if (
      window.location.href.toLowerCase().includes('/target-apps/') &&
      type !== 'targetsApp'
    ) {
      return false;
    }

    if (window.location.href.toLowerCase().includes('/entities/')) {
      return false;
    }

    return true;
  }, [
    isLoadingFavourites,
    isLoadingRecents,
    isWallboard,
    isWallboardUser,
    name,
    type,
    typeId,
  ]);

  // Track recents
  useNetworkingEffect(() => {
    if (!baseShouldTrack() || hasTrackedRecent || !typeId) {
      return;
    }

    if (
      favourites.find(
        (f) =>
          buildContentViewId(f) === buildContentViewIdAlt({ type, typeId }),
      )
    ) {
      // Do not track favourites
      return;
    }

    let isActive = true;
    setTimeout(() => {
      if (!isActive) {
        return;
      }
      trackRecent({ userId, typeId, type, name, accountRef }).then(() => {
        setHasTrackedRecent(true);
      });
    }, 3000);

    return () => {
      isActive = false;
    };
  }, [
    accountRef,
    baseShouldTrack,
    favourites,
    hasTrackedRecent,
    isLoadingFavourites,
    isLoadingRecents,
    isWallboardUser,
    name,
    recents,
    type,
    typeId,
    userId,
  ]);

  // Track last viewed at
  useNetworkingEffect(() => {
    if (!baseShouldTrack() || hasTrackedLastViewed || !typeId) {
      return;
    }

    let isActive = true;
    setTimeout(() => {
      if (!isActive) {
        return;
      }
      trackLastViewedAt({
        userId,
        type,
        typeId,
        accountRef,
      }).then(() => {
        setHasTrackedLastViewed(true);
      });
    }, 3000);

    return () => {
      isActive = false;
    };
  }, [accountRef, baseShouldTrack, hasTrackedLastViewed, type, typeId, userId]);

  return { hasTrackedRecent, hasTrackedLastViewed };
};

export default useContentViewTracking;
