import { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import useDashboardGadgetDataTypes from '../useDashboardGadgetDataTypes';
import GqlClientContext from '../../contexts/GqlClientContext';
import useNetworkingEffect from '../useNetworkingEffect';
import BaseViewsContext from '../../contexts/BaseViewsContext';

export const useDataTypeLastUpdatedAt = (dataType?: string) => {
  const { client } = useContext(GqlClientContext);
  const { baseViewsLastUpdatedAtLookup } = useContext(BaseViewsContext);

  // State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastUpdatedAt, setLastUpdatedAt] = useState<number | undefined>();
  const [timeAgo, setTimeAgo] = useState<string | undefined>();
  const [timeAgoShort, setTimeAgoShort] = useState<string | undefined>();

  // fetch the lastUpdatedAt
  useNetworkingEffect(() => {
    if (!dataType) {
      setTimeAgo(undefined);
      setTimeAgoShort(undefined);
      return;
    }

    const lastUpdate = baseViewsLastUpdatedAtLookup[dataType];
    setLastUpdatedAt(lastUpdate);
    if (lastUpdate) {
      setIsLoading(false);
    }
  }, [baseViewsLastUpdatedAtLookup, client, dataType]);

  // Keep timeAgo up to date
  useEffect(() => {
    if (!lastUpdatedAt) {
      return undefined;
    }

    const updateTimeAgo = () => {
      const now = DateTime.utc();
      const lastUpdatedDt = DateTime.fromMillis(lastUpdatedAt, { zone: 'utc' });
      const diff = now.diff(lastUpdatedDt, [
        'days',
        'hours',
        'minutes',
        'seconds',
      ]);
      const { days, hours, minutes } = diff;
      if (days === 0) {
        if (hours === 0) {
          if (minutes === 0) {
            setTimeAgo(`Updated just now`);
            setTimeAgoShort('Just now');
          } else {
            setTimeAgo(`Updated ${diff.minutes} minutes ago`);
            setTimeAgoShort(`${diff.minutes} minutes ago`);
          }
        } else {
          setTimeAgo(`Updated ${diff.hours} hours ago`);
          setTimeAgoShort(`${diff.hours} hours ago`);
        }
      } else if (days < 5) {
        setTimeAgo(`Updated ${diff.days} days and ${diff.hours} hours ago`);
        setTimeAgoShort(`${diff.days} days and ${diff.hours} hours ago`);
      } else {
        setTimeAgo(`Updated ${diff.days} days ago`);
        setTimeAgoShort(`${diff.days} days ago`);
      }
    };
    updateTimeAgo();
    const interval = setInterval(updateTimeAgo, 1000 * 60 * 5);
    return () => {
      clearInterval(interval);
    };
  }, [lastUpdatedAt]);

  return {
    timeAgo,
    timeAgoShort,
    isLoading,
  };
};

const useLastUpdatedAt = (dashboardGadget: DashboardGadget) => {
  // State
  const [firstDataType, setFirstDataType] = useState<string | undefined>();

  // Hooks
  const usedDataTypes = useDashboardGadgetDataTypes(dashboardGadget);
  const { isLoading, timeAgo } = useDataTypeLastUpdatedAt(firstDataType);

  // Find the first metric's data type
  useEffect(() => {
    if (usedDataTypes.length > 0) {
      setFirstDataType(usedDataTypes[0]);
    }
  }, [usedDataTypes]);

  return {
    isLoading,
    timeAgo,
  };
};

export default useLastUpdatedAt;
