import React, { ChangeEvent } from 'react';

import Inputs from 'components/Inputs';
import Row from 'components/Common/Row';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Dropdown from 'components/Inputs/Dropdown';
import ReportDrillDown from 'components/ReportDrillDown';
import { LabelDiv } from 'screens/Boards/BoardCreationWizard';
import BoardSettingsHeader from '../BoardSettingsHeader/BoardSettingsHeader';
import AddFilterButton from '../AddBoardFilterButton';
import ReportDrillDownsProvider from 'contextProviders/ReportDrillDownsProvider';

const BoardGeneralSettings = ({
  board,
  baseView,
  title,
  onTitleChanged,
  fieldOptions,
  sortOptions,
  drillDowns,
  setDrillDowns,
  hasUnsavedChanges,
  handleSave,
}: {
  board: PerformanceBoardTypes.Board;
  baseView: FleetOps.BaseView | undefined;
  title: string;
  onTitleChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  fieldOptions:
    | {
        label: string;
        value: string;
        isSelected: boolean;
        onSelected: () => void;
      }[]
    | undefined;
  sortOptions: {
    label: string;
    value: string;
    isSelected: boolean;
    onSelected: () => void;
  }[];
  drillDowns: FilterPlate[];
  setDrillDowns: React.Dispatch<React.SetStateAction<FilterPlate[]>>;
  hasUnsavedChanges: boolean;
  handleSave: () => void;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <div>
        <BoardSettingsHeader
          title="General"
          description={`Dataset: ${
            baseView && baseView.nameAlias ? baseView.nameAlias : board.dataType
          }`}
        />
        <div style={{ marginBottom: '16px' }}>
          <LabelDiv>
            <Typography.Body type="Label">Board Name</Typography.Body>
          </LabelDiv>
          <Inputs.TextInput
            value={title}
            onChange={onTitleChanged}
            maxLength={40}
            data-testid={'title'}
            inputSize="Small"
          />
        </div>
        <div style={{ marginBottom: '16px' }}>
          <LabelDiv>
            <Typography.Body type="Label">Order Table By</Typography.Body>
          </LabelDiv>
          {fieldOptions && (
            <Row>
              <div style={{ marginRight: '8px', flex: '1' }}>
                <Dropdown options={fieldOptions} />
              </div>
              <div style={{ flex: '1' }}>
                <Dropdown options={sortOptions} />
              </div>
            </Row>
          )}
        </div>
        <LabelDiv>
          <Typography.Body type="Label">Define Scope</Typography.Body>
        </LabelDiv>

        <ReportDrillDownsProvider
          drillDowns={drillDowns}
          setDrillDowns={setDrillDowns}
          dataTypes={board ? [board.dataType] : window.emptyArray}
        >
          <Row style={{ marginBottom: '16px' }}>
            <AddFilterButton text="Add Filter" />
          </Row>
        </ReportDrillDownsProvider>
      </div>

      <div style={{ flex: 1, overflowY: 'scroll' }}>
        {drillDowns.length !== 0 && (
          <ReportDrillDownsProvider
            drillDowns={drillDowns}
            setDrillDowns={setDrillDowns}
            dataTypes={board ? [board.dataType] : window.emptyArray}
          >
            <div
              style={{
                marginBottom: '32px',
                paddingTop: '1px',
                paddingLeft: '1px',
              }}
            >
              {drillDowns.map((d) => (
                <div key={d.id} style={{ marginBottom: '8px' }}>
                  <ReportDrillDown drillDown={d} isScope={false} />
                </div>
              ))}
            </div>
          </ReportDrillDownsProvider>
        )}
      </div>

      <Row
        style={{ padding: '8px 0', height: '46px', justifyContent: 'flex-end' }}
      >
        <Button
          type="Primary"
          size="Small"
          isDisabled={title.trim() === '' || !hasUnsavedChanges}
          onClick={handleSave}
          label="Save"
        />
      </Row>
    </div>
  );
};

export default BoardGeneralSettings;
