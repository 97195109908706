import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import getMetrics from './getMetrics';
import getLimitSetKeywordFilter from './getLimitSetKeyworkFilter';

const getTermedHistogramResponses = async ({
  chartDef,
  filterInput,
  usedMetrics,
  isDashboardGadget,
  currentDateScope,
  client,
  groupByField,
}: {
  chartDef: V5ChartDefinition;
  filterInput: FilterInput;
  usedMetrics: Metrics.Metric[];
  isDashboardGadget?: boolean;
  currentDateScope: DateRangeInput;
  client: ApolloClient<NormalizedCacheObject>;
  groupByField: string;
}) => {
  const termedHistograms = await getMetrics({
    chartDef,
    filterInput,
    usedMetrics,
    isDashboardGadget,
    dateScope: currentDateScope,
    client,
  });
  const limitedKeywordFilter = getLimitSetKeywordFilter(
    termedHistograms,
    groupByField,
    chartDef.dimensionA,
  );
  const histogramForTerms = await getMetrics({
    chartDef,
    filterInput,
    getTotals: true,
    usedMetrics,
    isDashboardGadget,
    dateScope: currentDateScope,
    client,
    limitedKeywordFilter,
  });

  return [termedHistograms, histogramForTerms];
};

export default getTermedHistogramResponses;
