import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import AnalyticsContext from 'contexts/AnalyticsContext';
import SlideOutContext from 'components/PerformanceBoards/contexts/SlideOutContext';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import Tooltip from 'components/Tooltip';
import useCreateCommentsSection from 'components/PerformanceBoards/hooks/useCreateCommentsSection';

import PermissionGates from 'components/PermissionGates';
import PERMISSIONS from 'permissionDefinitions';
import ContextMenu, { Option } from '../../../../kingpin/atoms/ContextMenu';

export const List = styled.div`
  display: flex;
  flex-direction: column;

  button {
    width: 100%;
  }
`;

const WizardItem = ({
  label,
  isDisabled,
  disabledMessage,
}: {
  label: PerformanceBoardTypes.SlideOut.SectionType;
  isDisabled?: boolean;
  disabledMessage?: string;
}) => {
  const { setNavState } = useContext(SlideOutContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const createCommentsSection = useCreateCommentsSection();

  const openWidget = useCallback(
    (type: PerformanceBoardTypes.SlideOut.SectionType) => {
      if (type === 'Comments') {
        createCommentsSection();
        trackEvent('Board - Slide out - Comments Widget Added');
      } else {
        setNavState({
          slideoutMode: 'Creating',
          creatingSectionType: type,
        });
        trackEvent('Board - Slide out - Widget type selected', {
          widgetType: type,
        });
      }
    },
    [createCommentsSection, setNavState, trackEvent],
  );

  return (
    <Option
      label={label}
      onClick={() => openWidget(label)}
      isDisabled={isDisabled}
      disabledMessage={disabledMessage}
    />
  );
};

const WizardsList = () => {
  const { performanceFields, board } = useContext(SlideOutContext);

  const isCommentsSectionExists = useCallback(() => {
    return board.slideout.some((widget) => widget.sectionType === 'Comments');
  }, [board.slideout]);

  return (
    <>
      {performanceFields.length !== 0 && (
        <WizardItem label={'Performance Graph'} />
      )}
      <WizardItem label={'Metric Widget'} />
      <WizardItem label={'Simple Grid'} />
      <WizardItem label={'Properties'} />
      {performanceFields.length === 0 && (
        <WizardItem
          label={'Performance Graph'}
          isDisabled
          disabledMessage={'Your Dataset has no performance fields'}
        />
      )}
      <WizardItem
        label={'Comments'}
        isDisabled={isCommentsSectionExists()}
        disabledMessage={'Your Slideout already has Comments Widget'}
      />
    </>
  );
};

const SlideOutHomeHeader = ({
  title,
  close,
}: {
  title: string;
  close: () => void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);

  const onAddWidgetClicked = useCallback(() => {
    trackEvent('Board - Slide out - Add Widget Clicked');
  }, [trackEvent]);

  return (
    <Row centerAlign spaceBetween style={{ height: '100%' }}>
      <Row centerAlign style={{ height: '100%' }}>
        <Typography.Header type="H5">{title}</Typography.Header>
      </Row>
      <Row centerAlign>
        <PermissionGates.Has
          requiredPermission={PERMISSIONS.PERFORMANCE_BOARD.MODIFY_WIDGETS}
        >
          <div style={{ marginRight: 8 }}>
            <ContextMenu
              buttonType="Ghost"
              icon="add"
              tooltip="Add Widget"
              onOpen={onAddWidgetClicked}
            >
              <WizardsList />
            </ContextMenu>
          </div>
        </PermissionGates.Has>

        <Tooltip content="Close">
          <Button onClick={close} icon="cross" type="Secondary" size="Small" />
        </Tooltip>
      </Row>
    </Row>
  );
};

export default SlideOutHomeHeader;
