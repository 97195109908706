import React, { useContext, useState } from 'react';
import SettingsContext from '../contexts/PerformanceBoardSettingsContext';
import BoardContext from 'contexts/BoardContext';
import isPerformanceBoard from 'isPerformanceBoard';

const SettingsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [navState, setNavState] =
    useState<PerformanceBoardTypes.SettingsNavState>({ mode: 'General' });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const { board } = useContext(BoardContext);

  if (!isPerformanceBoard(board)) {
    return null;
  }

  return (
    <SettingsContext.Provider
      value={{
        navState,
        setNavState,
        hasUnsavedChanges,
        setHasUnsavedChanges,
        board,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
