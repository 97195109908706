import { useEffect, useState } from 'react';
import STORE from '../../store';

const useStaffUsers = () => {
  const [staffUsers, setStaffUsers] = useState<
    UserManagement.FleetOpsStaffUser[]
  >([]);
  const [isLoadingStaffUsers, setIsLoadingStaffUsers] = useState<boolean>(true);

  useEffect(() => {
    let isActive = true;
    setIsLoadingStaffUsers(true);
    STORE.users
      .getFleetOpsStaffUsersRef({})
      .get()
      .then((usersSnapshot) => {
        if (!isActive) {
          return;
        }

        const users = usersSnapshot.docs.map((d) => d.data());
        setStaffUsers(users);
        setIsLoadingStaffUsers(false);
      });

    return () => {
      isActive = false;
    };
  }, []);

  return {
    staffUsers,
    isLoadingStaffUsers,
  };
};

export default useStaffUsers;
