import React, { useContext } from 'react';
import styled from 'styled-components';
import NavSideBarContext from '../../contexts/NavSideBarContext';
import NAVIGATION from './constants';
import Colors2 from '../../theme/Colors2';

const NavSectionDiv = styled.div<{ isFavourites?: boolean }>`
  padding: 8px;
  background-color: #fbfbfb;
  border-bottom: ${(props) =>
    props.isFavourites ? 'unset' : `solid 1px ${Colors2.Border}`};
  ${(props) => props.isFavourites && 'flex: 1;'}
`;

const NavSectionLabelDiv = styled.div`
  padding-left: 8px;
  margin-bottom: 4px;

  span {
    color: #8e8e8e;
    font-family: Inter;
    font-weight: 500;
    font-size: 11px;
    font-style: normal;
    line-height: 14px; /* 107.692% */
    letter-spacing: -0.156px;
    text-transform: capitalize;
  }
`;

const NavSection = ({
  label,
  children,
  isFavourites,
}: {
  label?: string;
  children?: JSX.Element | JSX.Element[];
  isFavourites?: boolean;
}) => {
  const { isOpen } = useContext(NavSideBarContext);
  const isCollapsed = !isOpen;
  return (
    <NavSectionDiv
      isFavourites={isFavourites}
      id={isFavourites ? NAVIGATION.FAVOURITES_SECTION_ID : undefined}
    >
      {label && !isCollapsed && (
        <NavSectionLabelDiv
          id={isFavourites ? NAVIGATION.FAVOURITES_SECTION_LABEL_ID : undefined}
        >
          <span>{label}</span>
        </NavSectionLabelDiv>
      )}
      {children}
    </NavSectionDiv>
  );
};

export default NavSection;
