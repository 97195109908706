import React, { forwardRef, useCallback } from 'react';
import styled from 'styled-components';
import { Z_INDEX } from '../../constants';
import {
  autoUpdate,
  offset,
  useDismiss,
  UseDismissProps,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { flip, shift } from '@floating-ui/dom';
import Portal from '../Portal';
import InlineDialogContext from './InlineDialogContext';

export const INLINE_DIALOG_PORTAL_DIV_ID = 'INLINE_DIALOG_PORTAL_DIV_ID';

export type Placement =
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';

interface Props {
  /** The elements that the InlineDialog will be positioned relative to. */
  children: React.ReactElement;
  /** The elements to be displayed within the InlineDialog. */
  content: React.ReactElement;
  /** Sets whether to show or hide the dialog. */
  isOpen?: boolean;
  /** Function called when the dialog is open and a click occurs anywhere outside
   the dialog. */
  onClose?: () => void;
  /** Where the dialog should appear, relative to the contents of the children. */
  placement?: Placement;
  isClickOutsideToCloseDisabled?: boolean;
  isAnchorDisplayFlex?: boolean;
}

const Container = styled.div<{
  fixedHeight?: boolean;
  ref?: any;
}>`
  background-color: white;
  border-radius: 8px;
  box-sizing: content-box; /* do not set this to border-box or it will break the overflow handling */

  border: 1px solid #ededed;

  max-width: unset;
  padding: 0px;
  max-height: unset;
  height: ${(props) => (props.fixedHeight ? '230px' : 'unset !important')};
  z-index: ${Z_INDEX.INLINE_DIALOG};

  &:focus {
    outline: none;
  }
`;

const Anchor = forwardRef<
  HTMLDivElement,
  {
    children: JSX.Element | JSX.Element[];
    isAnchorDisplayFlex?: boolean;
  }
>(
  (
    {
      children,
      isAnchorDisplayFlex,
    }: {
      children: JSX.Element | JSX.Element[];
      isAnchorDisplayFlex?: boolean;
    },
    ref,
  ) => (
    <div ref={ref} style={isAnchorDisplayFlex ? { width: '100%' } : undefined}>
      {children}
    </div>
  ),
);

const TEST_DISMISS_PROPS: UseDismissProps = {
  capture: true,
  referencePress: true,
  referencePressEvent: 'click',
  outsidePress: true,
  outsidePressEvent: 'click',
};

const InlineDialog = (props: Props) => {
  const { placement, isOpen, onClose, children, content, isAnchorDisplayFlex } =
    props;
  const onOpenChange = useCallback(
    (newStatus: boolean) => {
      if (newStatus === false && onClose) {
        onClose();
      }
    },
    [onClose],
  );

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange,
    placement: placement || 'bottom-start',
    strategy: 'absolute',
    middleware: [flip(), shift(), offset(4)],
  });
  const dismiss = useDismiss(
    context,
    process.env.NODE_ENV === 'test' ? TEST_DISMISS_PROPS : undefined,
  );
  const { getReferenceProps, getFloatingProps } = useInteractions(
    props.isClickOutsideToCloseDisabled ? [] : [dismiss],
  );

  return (
    <>
      <Anchor
        ref={refs.setReference}
        {...getReferenceProps()}
        isAnchorDisplayFlex={isAnchorDisplayFlex}
      >
        {children}
      </Anchor>
      {isOpen && (
        <Portal elementId={INLINE_DIALOG_PORTAL_DIV_ID}>
          <Container
            data-testid="inline-dialog-container"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <InlineDialogContext.Provider value={{ isInlineDialog: true }}>
              {content}
            </InlineDialogContext.Provider>
          </Container>
        </Portal>
      )}
    </>
  );
};

export default InlineDialog;
