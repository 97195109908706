import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';

import { getMetricLink } from '../../screens/DataManager/AllSets/routes';
import getTermColor from './getTermColor';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Expression = ({
  isLinks,
  expression,
  usedMetrics,
}: {
  isLinks?: boolean;
  expression: string;
  usedMetrics: Metrics.NormalMetric[];
}) => {
  // @ts-ignore
  const terms = expression
    .replaceAll('(', '( ')
    .replaceAll(')', ' )')
    .replaceAll('/', ' / ')
    .replaceAll('*', ' * ')
    .replaceAll('+', ' + ')
    .split(' ');
  return (
    <Wrapper>
      {terms.map((t) => {
        const metric = usedMetrics.find((m) => m.id === t);
        if (metric) {
          const metricIndex = usedMetrics.findIndex((m) => m.id === t);
          if (isLinks) {
            return (
              <div key={Math.random()} style={{ marginRight: 4 }}>
                <Link to={getMetricLink(metric)} target="_blank">
                  <Typography.Body type="Link">{metric.name}</Typography.Body>
                </Link>
              </div>
            );
          } else {
            const color = getTermColor(metricIndex);
            return (
              <div style={{ marginRight: 4 }} key={Math.random()}>
                <Typography.Body type="Body 12" color={color}>
                  {metric.name}
                </Typography.Body>
              </div>
            );
          }
        } else {
          return (
            <div style={{ marginRight: 4 }}>
              <Typography.Body type="Body 12">{` ${t} `}</Typography.Body>
            </div>
          );
        }
      })}
    </Wrapper>
  );
};

export default Expression;
