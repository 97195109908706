import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Row from '../../Common/Row';
import MetricInfoConstants from '../constants';
import Button from 'kingpin/atoms/Button';
import Sidebar from './Sidebar';
import MetricInformation from './MetricInformation';
import InfoHeading from './InfoHeading';
import HideUnlessMouseOver from '../../HideUnlessMouseOver';
import { isMetricInfo } from 'components/Scorecard/Kpis/kpiTypeCheckers';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from '../../../theme/Colors2';

const RightDiv = styled.div`
  height: ${MetricInfoConstants.POPUP_HEIGHT};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;
export const ContentDiv = styled.div`
  padding: 24px;

  flex: 1;
  overflow-y: auto;
`;

const InfoHeadingDiv = styled.div`
  padding: 12px 24px;

  border-bottom: 1px solid ${Colors2.Border};
`;
const BottomBar = styled.div`
  padding: 12px 24px;

  border-top: 1px solid ${Colors2.Border};
`;

const MetricInfoPopup = ({
  isSidebarVisible,
  metricInfos,
  selectedMetricInfo,
  onMetricInfoSelected,
  searchText,
  onSearchTextChanged,
  close,
}: {
  isSidebarVisible: boolean;
  metricInfos: (
    | MetricsInfoButton.MetricInfo
    | MetricsInfoButton.ManualKpiInfo
  )[];
  selectedMetricInfo:
    | MetricsInfoButton.MetricInfo
    | MetricsInfoButton.ManualKpiInfo;
  onMetricInfoSelected: (
    m: MetricsInfoButton.MetricInfo | MetricsInfoButton.ManualKpiInfo,
  ) => void;
  searchText: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  close: () => void;
}) => (
  <Row style={{ position: 'relative' }}>
    <Sidebar
      metricInfos={metricInfos}
      searchText={searchText}
      onSearchTextChanged={onSearchTextChanged}
      onMetricInfoSelected={onMetricInfoSelected}
      isSidebarVisible={isSidebarVisible}
      selectedMetricInfo={selectedMetricInfo}
    />
    <RightDiv>
      <InfoHeadingDiv>
        <HideUnlessMouseOver>
          <InfoHeading metricInfo={selectedMetricInfo} close={close} />
        </HideUnlessMouseOver>
      </InfoHeadingDiv>
      <ContentDiv>
        {isMetricInfo(selectedMetricInfo) ? (
          <MetricInformation metricInfo={selectedMetricInfo} />
        ) : (
          <Typography.Body type="Body 12">
            This KPI was entered manualy
          </Typography.Body>
        )}
      </ContentDiv>
      <BottomBar>
        <Row centerAlign style={{ justifyContent: 'flex-end' }}>
          <Button label="Close" onClick={close} type="Primary" size="Small" />
        </Row>
      </BottomBar>
    </RightDiv>
  </Row>
);

export default MetricInfoPopup;
