import React from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';

import NewQuickFilterForm from './NewQuickFilterForm';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import Row from '../../../Common/Row';
import {
  ACTIVE_QUICK_FILTER_ID,
  CLOSED_QUICK_FILTER_ID,
} from '../../../../hooks/useQuickFilters';
import { isQuickFilter } from '../../QuickFilters';
import Form from '../../../../kingpin/forms/Form';
import FormHeader from '../../../../kingpin/forms/FormHeader';
import FormContent from '../../../../kingpin/forms/FormContent';

export const Item = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  background-color: #ececf5;
  margin-bottom: 8px;
  border-radius: 18px;

  .showOnMouseOver {
    visibility: hidden;
    width: 0px;
  }

  &:hover {
    .showOnMouseOver {
      visibility: visible;
      width: unset;
    }
  }
`;

const QuickFilters = ({
  isCreatingNewFilter,
  setIsCreatingNewFilter,
  orderedFilters,
  onDragEnd,
  deleteFilter,
  close,
}: {
  isCreatingNewFilter: boolean;
  setIsCreatingNewFilter: React.Dispatch<React.SetStateAction<boolean>>;
  orderedFilters: (SavedFilter | QuickFilter)[];
  onDragEnd: (result: DropResult) => void;
  deleteFilter: (f: SavedFilter) => void;
  close: () => void;
}) => {
  if (isCreatingNewFilter) {
    return <NewQuickFilterForm close={() => setIsCreatingNewFilter(false)} />;
  } else {
    return (
      <Form>
        <FormHeader title={'Filters'} onClose={close} />
        <FormContent>
          <div style={{ minHeight: 240 }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={'quick-filters-drop'}>
                {(provided) => (
                  <div ref={provided.innerRef}>
                    {orderedFilters.map((f, index) => (
                      <Draggable draggableId={f.id} index={index} key={f.id}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Item>
                              <Typography.Body type="Body 12">
                                {f.name}
                              </Typography.Body>
                              {![
                                ACTIVE_QUICK_FILTER_ID,
                                CLOSED_QUICK_FILTER_ID,
                              ].includes(f.id) &&
                                !isQuickFilter(f) && (
                                  <Button
                                    onClick={() => deleteFilter(f)}
                                    type="Secondary"
                                    size="Small"
                                    icon="cross"
                                  />
                                )}
                            </Item>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </FormContent>
        <Row style={{ justifyContent: 'space-between' }}>
          <Button onClick={close} type="Ghost" size="Small" label="Close" />
          <Button
            type="Primary"
            size="Small"
            onClick={() => {
              setIsCreatingNewFilter(true);
            }}
            label="Create new filter"
          />
        </Row>
      </Form>
    );
  }
};

export default QuickFilters;
