const toMetricInput = (
  metric: Metrics.NormalMetric | MetricInput,
): MetricInput => ({
  id: metric.id,
  field: metric.field,
  filters: metric.filters,
  aggFunc: metric.aggFunc,
  dataType: metric.dataType,
});

export default toMetricInput;
