import { Link } from 'react-router-dom';
import React from 'react';

import Typography from 'kingpin/atoms/Typography';
import ListItemWrapper from '../../../DatasetDefinitions/ListItemWrapper';
import Column from '../../../DatasetDefinitions/Column';
import { buildReportShow } from '../../../../../navigation/appRoutes';
import Card from '../../../../../components/Common/Card';

const ReportUsage = ({
  reports,
  metric,
}: {
  reports: FleetOps.MetricReportUsage[];
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
}) => (
  <>
    {reports.length > 0 && (
      <div style={{ marginBottom: 24 }}>
        <Typography.Header type="H5">{`Reports (${reports.length})`}</Typography.Header>
        <Card>
          {reports.map((r, index) => (
            <ListItemWrapper
              key={`${r.id}-${metric.id}`}
              isLast={reports.length - 1 === index}
            >
              <Column
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <Link to={buildReportShow(r.reportId)} target="_blank">
                    <Typography.Body type="Body 12" color="#0041ea">
                      {r.reportName}
                    </Typography.Body>
                  </Link>
                </div>
                <div>
                  <Link to={buildReportShow(r.reportId)} target="_blank">
                    <Typography.Body type="Body 12" color="#0041ea">
                      {r.gadgetName}
                    </Typography.Body>
                  </Link>
                </div>
              </Column>
            </ListItemWrapper>
          ))}
        </Card>
      </div>
    )}
  </>
);

export default ReportUsage;
