import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import Loading from 'components/Loading/Loading';
import Colors2 from 'theme/Colors2';
import clockSvg from './icons/clock.svg';
import truckSvg from './icons/truck.svg';
import COSTS_SET_ROUTES from '../CostsSet/routes';
import useGetCostModel from './CostsShow/hooks/useGetCostModel';
import AnalyticsContext from 'contexts/AnalyticsContext';

const CostModelWrapper = styled.div`
  width: 478px;
  padding: 16px;
  background: #fff;
  border: 1px solid ${Colors2.Primary['8']};
  border-radius: 8px;
`;

const IconWrapper = styled.div`
  display: inline-block;
  background: ${Colors2.Grey['8']};
  padding: 0 6px 4px 6px;
  border-radius: 4px;
  margin-bottom: 20px;
`;

const CostsContainer = ({
  activityCosts,
}: {
  activityCosts?: Costs.PersistedCostModel;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '42px',
      }}
    >
      <div style={{ width: '988px' }}>
        <div style={{ marginBottom: '8px' }}>
          <Typography.Header type="H5">Cost Models</Typography.Header>
        </div>
        <div style={{ display: 'flex' }}>
          <CostModelWrapper style={{ marginRight: '16px' }}>
            <IconWrapper>
              <img src={clockSvg} alt="" />
            </IconWrapper>
            <Typography.Header type="H4">
              Activity Based Costs
            </Typography.Header>
            <div style={{ marginBottom: 16 }}>
              <Typography.Body type="Body 14" color={Colors2.Grey['2']}>
                Enter weekly / monthly costs for different categories like fuel
                & tolls to understand total costs and cost per mile on each
                activity
              </Typography.Body>
            </div>

            <Link
              to={
                activityCosts
                  ? COSTS_SET_ROUTES.ACTIVITY_BASE
                  : COSTS_SET_ROUTES.ACTIVITY_BASE_NEW
              }
            >
              <Button
                type="Tertiary"
                size="Small"
                label={activityCosts ? 'View Costs' : 'Set Up'}
                onClick={() => {
                  trackEvent('Costs - Opened Activity Based Costs');
                }}
              />
            </Link>
          </CostModelWrapper>
          <CostModelWrapper
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconWrapper>
              <img src={truckSvg} alt="" />
            </IconWrapper>
            <div style={{ marginBottom: 8 }}>
              <Typography.Header type="H4">Asset Level Costs</Typography.Header>
            </div>

            <Button
              size="Small"
              type="Tertiary"
              label="Coming Soon"
              isDisabled
              icon="info"
            />
          </CostModelWrapper>
        </div>
      </div>
    </div>
  );
};

const Gate = () => {
  const { costModel, isLoading } = useGetCostModel('activity');
  if (isLoading) {
    return <Loading />;
  } else {
    return <CostsContainer activityCosts={costModel} />;
  }
};

export default Gate;
