import React, { useContext } from 'react';
import styled from 'styled-components';

import NoRecentsSrc from './no-recents.svg';
import CardTitleWrapper from '../CardTitle';
import Typography from 'kingpin/atoms/Typography';
import Loading from 'components/Loading';
import FlexCentered from '../../../components/Common/FlexCentered';
import {
  buildDashboardShow,
  buildReportShow,
  buildShowGlobalKpiList,
  buildShowPerformanceApp,
  buildShowTargetApp,
  buildShowWallBoardConfig,
  buildShowWorkSpace,
} from '../../../navigation/appRoutes';
import { Link } from 'react-router-dom';

import { FavouriteButton } from '../../../components/IndexListItem';
import Colors2 from '../../../theme/Colors2';
import Row from '../../../components/Common/Row';
import buildContentViewId from '../../../buildContentViewId';
import LinkWrapper from '../../../components/LinkWrapper';
import Tooltip from '../../../components/Tooltip';
import useFavouriteToggle from '../../../hooks/useFavouriteToggle';
import Divider from '../../../components/Common/Card/Divider';
import WorkSpacesContext from '../../../contexts/WorkSpacesContext';
import Icon from '../../../kingpin/atoms/Icon';

export const Img = styled.img`
  width: 104px;
  height: 88px;
  margin-bottom: 20px;
`;

export const List = styled.div`
  max-height: calc(100% - 45px);
  overflow-y: auto;
`;

export const Item = styled.div`
  height: 48px;
  padding: 0 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);

  .showOnMouseOver {
    opacity: 0;
  }

  .hideOnMouseOver {
    opacity: 1;
  }

  &:hover {
    background-color: ${Colors2.Grey['9']};
    .showOnMouseOver {
      opacity: 1;
    }

    .hideOnMouseOver {
      opacity: 0;
    }
  }
`;

export const buildLink = ({
  item,
  availableWorkSpaces,
}: {
  item: ContentView;
  availableWorkSpaces: (WorkSpace | TargetsApp.App)[];
}): string => {
  if (item.type === 'dashboard') {
    return buildDashboardShow(item.typeId);
  }
  if (item.type === 'report') {
    return buildReportShow(item.typeId);
  }
  if (item.type === 'wallboard') {
    return buildShowWallBoardConfig(item.typeId);
  }
  if (item.type === 'workspace') {
    const ws = availableWorkSpaces.find((w) => w.id === item.typeId);
    if (!!ws && ws.campaignType !== 'general') {
      return buildShowPerformanceApp(item.typeId);
    } else {
      return buildShowWorkSpace(item.typeId);
    }
  }
  if (item.type === 'targetsApp') {
    return buildShowTargetApp(item.typeId);
  }
  if (item.type === 'scorecard') {
    return buildShowGlobalKpiList(item.typeId);
  }

  return '#';
};

export const RecentIcon = ({ contentView }: { contentView: ContentView }) => {
  if (contentView.type === 'report') {
    return (
      <Tooltip content="Report" isFlexAnchor>
        <Icon icon="find-in-page" />
      </Tooltip>
    );
  }

  if (contentView.type === 'dashboard') {
    return (
      <Tooltip content="Dashboard" isFlexAnchor>
        <Icon icon="dashboard" />
      </Tooltip>
    );
  }

  if (contentView.type === 'workspace') {
    return (
      <Tooltip content="Workspace" isFlexAnchor>
        <Icon icon="workspace" />
      </Tooltip>
    );
  }

  if (contentView.type === 'targetsApp') {
    return (
      <Tooltip content="Targets App" isFlexAnchor>
        <Icon icon="workspace" />
      </Tooltip>
    );
  }

  if (contentView.type === 'wallboard') {
    return (
      <Tooltip content="Wallboard" isFlexAnchor>
        <Icon icon="keyboard" />
      </Tooltip>
    );
  }

  if (contentView.type === 'scorecard') {
    return (
      <Tooltip content="Scorecard" isFlexAnchor>
        <Icon icon="navigation-metrics" />
      </Tooltip>
    );
  }

  return null;
};

export const ItemContainer = ({
  item,
  hasDragHandle,
}: {
  item: ContentView;
  hasDragHandle?: boolean;
}) => {
  const { availableWorkSpaces } = useContext(WorkSpacesContext);
  const { isFavourite, onFavouriteToggled } = useFavouriteToggle({
    type: item.type,
    typeId: item.typeId,
    name: item.name,
  });

  return (
    <div>
      <Item>
        <Row centerAlign>
          {hasDragHandle && (
            <div style={{ marginRight: 4 }}>
              <FavouriteButton
                isFavourite={isFavourite}
                onClick={onFavouriteToggled}
              />
            </div>
          )}
          {!hasDragHandle && (
            <div>
              <div style={{ marginRight: 4 }} className={'showOnMouseOver'}>
                <FavouriteButton
                  isFavourite={isFavourite}
                  onClick={onFavouriteToggled}
                />
              </div>
            </div>
          )}
          <LinkWrapper>
            <Link
              to={buildLink({
                item,
                availableWorkSpaces,
              })}
            >
              <Row centerAlign>
                <div style={{ marginRight: 8, display: 'flex' }}>
                  <RecentIcon contentView={item} />
                </div>
                <Typography.Body type="Link">{item.name}</Typography.Body>
              </Row>
            </Link>
          </LinkWrapper>
        </Row>
        <div>
          {hasDragHandle && (
            <div className={'showOnMouseOver'}>
              <Icon icon="drag" color={Colors2.Grey['2']} />
            </div>
          )}
        </div>
      </Item>
    </div>
  );
};

const RecentsCard = ({
  recents,
  isLoading,
}: {
  recents: ContentView[];
  isLoading: boolean;
}) => {
  if (isLoading) {
    return (
      <React.Fragment>
        <CardTitleWrapper>
          <Typography.Header type="H5">Recents</Typography.Header>
        </CardTitleWrapper>
        <Divider noMargin />
        <FlexCentered style={{ height: 'calc(100% - 78px)' }}>
          <Loading />
        </FlexCentered>
      </React.Fragment>
    );
  } else if (recents.length === 0) {
    return (
      <React.Fragment>
        <CardTitleWrapper>
          <Typography.Header type="H5">Recents</Typography.Header>
        </CardTitleWrapper>

        <FlexCentered style={{ height: '100%' }}>
          <Img src={NoRecentsSrc} />
          <Typography.Body type="Body 14">No activity yet</Typography.Body>
        </FlexCentered>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <CardTitleWrapper>
          <Typography.Header type="H5">Recents</Typography.Header>
        </CardTitleWrapper>
        <Divider noMargin />
        <List className="hiding-scrollbar">
          {recents.map((r) => (
            <ItemContainer key={buildContentViewId(r)} item={r} />
          ))}
        </List>
      </React.Fragment>
    );
  }
};

export default RecentsCard;
