import { DateTime } from 'luxon';

import { getEndOf } from '../../removePartialIntervals';
import captureException from '../../services/captureException';

const buildTwoDimensionDateTotals = ({
  chartDef,
  rangeBuckets,
  groupedHistogram,
  histogram,
  metricId,
  unitsLocale,
  metric,
  groupByField,
  s,
  formatMetric,
}: {
  chartDef: V5ChartDefinition;
  rangeBuckets: string[];
  groupedHistogram: MetricsResponse;
  histogram: MetricsResponse;
  metricId: string;
  unitsLocale: string;
  metric: Metrics.Metric;
  groupByField: string;
  s?: V5ChartDefinitionSeries;
  formatMetric: (args: { metricId: string; value: number }) => string;
}) => {
  const formatted = {};

  try {
    const rowCount = groupedHistogram.length;
    // @ts-ignore
    formatted[groupByField] = `Total (${rowCount})`;
    rangeBuckets.forEach((isoDateCol, index) => {
      const totalTrendItem = histogram.find((r) => r['date'] === isoDateCol);
      if (!totalTrendItem) {
        return; // Skip to next
      }
      const total = totalTrendItem[metricId];
      const formattedTotal = formatMetric({ value: total, metricId });
      // @ts-ignore
      formatted[isoDateCol] = formattedTotal;

      // comparison columns
      const previousIsoDate = index === 0 ? undefined : rangeBuckets[index - 1];
      if (
        s &&
        index > 0 &&
        chartDef.trendByCalendarInterval &&
        chartDef.trendByCalendarInterval !== 'auto' &&
        previousIsoDate
      ) {
        const previousTotalTrendItem = histogram.find(
          (r) => r['date'] === previousIsoDate,
        );
        if (!previousTotalTrendItem) {
          return; // Skip to next
        }
        const previousTotal = previousTotalTrendItem[metricId];

        if (s.matrixCellType) {
          const currentDateRange = (() => {
            const endDate = DateTime.fromMillis(
              getEndOf(
                DateTime.fromISO(isoDateCol).toMillis(),
                chartDef.trendByCalendarInterval,
              ),
            )
              .minus({ day: 1 })
              .toISODate();
            return {
              startDate: isoDateCol,
              endDate,
            } as DateRangeInput;
          })();
          const previousDateRange = (() => {
            const endDate = DateTime.fromMillis(
              getEndOf(
                DateTime.fromISO(previousIsoDate).toMillis(),
                chartDef.trendByCalendarInterval,
              ),
            )
              .minus({ day: 1 })
              .toISODate();
            return {
              startDate: previousIsoDate,
              endDate,
            } as DateRangeInput;
          })();
          const deltaCol = {
            value: total,
            previous: previousTotal,
            formatting: metric.formatting,
            unitsLocale,
            type: 'delta' as MatrixCellType,
            currentDateRange,
            previousDateRange,
            metric,
          };
          const percentDeltaCol = {
            value: total,
            previous: previousTotal,
            formatting: metric.formatting,
            unitsLocale,
            type: 'percentDelta' as MatrixCellType,
            currentDateRange,
            previousDateRange,
            metric,
          };
          if (s.matrixCellType === 'both') {
            // @ts-ignore
            formatted[`${isoDateCol}-delta`] = deltaCol;
            // @ts-ignore
            formatted[`${isoDateCol}-percentDelta`] = percentDeltaCol;
          } else if (s.matrixCellType === 'delta') {
            // @ts-ignore
            formatted[`${isoDateCol}-delta`] = deltaCol;
          } else if (s.matrixCellType === 'percentDelta') {
            // @ts-ignore
            formatted[`${isoDateCol}-percentDelta`] = percentDeltaCol;
          }
        }
      }
    });
  } catch (ex) {
    captureException(ex);
  }

  return [formatted];
};

export default buildTwoDimensionDateTotals;
