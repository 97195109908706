import React, { useState } from 'react';
import styled from 'styled-components';

import Loading from '../../Loading';
import Colors2 from '../../../theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import moment from 'moment';
import Row from '../../Common/Row';
import FlexCentered from '../../Common/FlexCentered';
import { Img } from '../../../screens/Home/RecentsCard/RecentsCard';
import NoNotificationsSrc from '../../NotificationsPanel/notifications.svg';
import { HEADER_SPAN_CSS, SPAN_CSS } from 'components/Grid/StyleOverrides';

const List = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
`;

const DateRow = styled.div<{ isToday?: boolean; isOverdue?: boolean }>`
  ${(props) =>
    props.isToday &&
    `background-color: ${Colors2.AvatarColors['1'].background};`}
  ${(props) =>
    !props.isToday &&
    `background-color: ${Colors2.AvatarColors['1'].background}40;`}
      ${(props) => props.isOverdue && `background-color: #E7E4F740;`}
    
  padding: 4px 16px;
  margin-bottom: 8px;
  display: flex;
  justifycontent: space-between;
  align-items: center;

  span {
    ${HEADER_SPAN_CSS}
  }
`;

const ItemRow = styled.div`
  padding: 0px 16px;

  &:not(:last-of-type) {
    margin-bottom: 8px;
    border-bottom: 1px solid ${Colors2.Border};
    padding-bottom: 8px;
  }

  span {
    ${SPAN_CSS}
  }
`;

const Col = styled.div<{ flex: number }>`
  display: flex;
  flex: ${(props) => props.flex};
  flex-grow: 1;
  overflow: hidden;
`;

const BadgeDiv = styled.div<{
  borderColor: string;
  backgroundColor: string;
}>`
  display: inline-flex;
  max-width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;

  background: ${(props) => props.backgroundColor};
  border-radius: 16px;
  border: 1px solid ${(props) => props.borderColor};
`;

const NoEvents = () => (
  <FlexCentered style={{ height: '100%' }}>
    <Img src={NoNotificationsSrc} />
    <Typography.Body type="Body 12">No events found</Typography.Body>
  </FlexCentered>
);

const getBackgroundColor = (eventName: string) => {
  if (eventName === 'Driver License Expiry') {
    return Colors2.AvatarColors['1'].background;
  } else if (eventName === 'Next Physical') {
    return Colors2.AvatarColors['2'].background;
  } else if (eventName === 'Driver Birthday') {
    return Colors2.AvatarColors['3'].background;
  } else if (eventName === 'Work Anniversary') {
    return Colors2.AvatarColors['4'].background;
  } else if (eventName === 'Review Date') {
    return Colors2.AvatarColors['5'].background;
  } else if (eventName === 'Projected Home Date') {
    return Colors2.AvatarColors['6'].background;
  } else if (eventName === 'Projected Return Date') {
    return Colors2.AvatarColors['7'].background;
  } else {
    return Colors2.AvatarColors['8'].background;
  }
};

const getBorderColor = (eventName: string) => {
  if (eventName === 'Driver License Expiry') {
    return Colors2.AvatarColors['1'].text;
  } else if (eventName === 'Next Physical') {
    return Colors2.AvatarColors['2'].text;
  } else if (eventName === 'Driver Birthday') {
    return Colors2.AvatarColors['3'].text;
  } else if (eventName === 'Work Anniversary') {
    return Colors2.AvatarColors['4'].text;
  } else if (eventName === 'Review Date') {
    return Colors2.AvatarColors['5'].text;
  } else if (eventName === 'Projected Home Date') {
    return Colors2.AvatarColors['6'].text;
  } else if (eventName === 'Projected Return Date') {
    return Colors2.AvatarColors['7'].text;
  } else {
    return Colors2.AvatarColors['8'].text;
  }
};

const getMiddleColumn = (r: Reminder) => {
  if (r.driverName) {
    return r.driverName;
  } else if (r.driver) {
    return r.driver;
  } else if (r.employeeName) {
    return r.employeeName;
  } else if (r.truck) {
    return r.truck;
  } else {
    return '-';
  }
};

const ReminderBucket = ({ reminder }: { reminder: SortedReminders }) => {
  const [isToday] = useState(
    () => moment().format('YYYY-MM-DD') === reminder.date,
  );
  const [isTomorrow] = useState(
    () => moment().add({ day: 1 }).format('YYYY-MM-DD') === reminder.date,
  );
  const [formattedDate] = useState(moment(reminder.date).format('ddd, MM/DD'));
  const left = formattedDate;
  const right = isToday ? ` | Today` : isTomorrow ? ` | Tomorrow` : '';

  return (
    <div style={{ marginBottom: 12 }}>
      <DateRow isToday={isToday}>
        <span>{`${left}${right}`}</span>
      </DateRow>
      <div>
        {reminder.reminders.map((r) => (
          <ItemRow key={r.key}>
            <Row centerAlign spaceBetween style={{ gap: 8 }}>
              <Col flex={1}>
                <BadgeDiv
                  borderColor={getBorderColor(r.name)}
                  backgroundColor={getBackgroundColor(r.name)}
                  style={{
                    overflow: 'hidden',
                    textWrap: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <span>{r.name}</span>
                </BadgeDiv>
              </Col>
              <Col flex={1}>
                <span style={{ color: Colors2.Grey['2'] }}>
                  {getMiddleColumn(r)}
                </span>
              </Col>
              <Col flex={1}>
                <span>{r.description}</span>
              </Col>
            </Row>
          </ItemRow>
        ))}
      </div>
    </div>
  );
};

const OverdueBucket = ({ reminders }: { reminders: Reminder[] }) => {
  if (reminders.length === 0) {
    return null;
  }

  return (
    <div style={{ marginBottom: 12 }}>
      <DateRow isOverdue>
        <span>{`Overdue`}</span>
      </DateRow>
      <div>
        {reminders.map((r) => (
          <ItemRow key={r.key}>
            <Row centerAlign spaceBetween style={{ gap: 8 }}>
              <Col flex={1}>
                <BadgeDiv
                  borderColor={getBorderColor(r.name)}
                  backgroundColor={getBackgroundColor(r.name)}
                  style={{
                    overflow: 'hidden',
                    textWrap: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <span>{r.name}</span>
                </BadgeDiv>
              </Col>
              <Col
                flex={1}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <span style={{ color: Colors2.Grey['2'] }}>
                  {getMiddleColumn(r)}
                </span>
              </Col>
              <Col flex={1}>
                <span>
                  {r.description
                    ? r.description
                    : moment(r.date).format('ddd, MM/DD')}
                </span>
              </Col>
            </Row>
          </ItemRow>
        ))}
      </div>
    </div>
  );
};

const RemindersGadgetContent = ({
  pastReminders,
  futureReminders,
  isLoading,

  overdueReminders,
  hasEvents,
}: {
  pastReminders: SortedReminders[];
  futureReminders: SortedReminders[];
  isLoading: boolean;

  overdueReminders: Reminder[];
  hasEvents: boolean;
}) => {
  return (
    <List className={'hiding-scrollbar'}>
      {isLoading && (
        <FlexCentered style={{ height: '100%' }}>
          <Loading />
        </FlexCentered>
      )}
      {!isLoading && (
        <div style={{ width: '100%', height: '100%' }}>
          {hasEvents && (
            <>
              {pastReminders.map((r) => (
                <ReminderBucket reminder={r} key={r.date} />
              ))}
              <OverdueBucket reminders={overdueReminders} />
              {futureReminders.map((r) => (
                <ReminderBucket reminder={r} key={r.date} />
              ))}
            </>
          )}
          {!hasEvents && <NoEvents />}
        </div>
      )}
    </List>
  );
};

export default RemindersGadgetContent;
