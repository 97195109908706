import { useCallback, useContext, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import GqlClientContext from '../contexts/GqlClientContext';

const query = {
  query: gql`
    query getFieldDefinitions {
      getFieldDefinitions {
        fieldType
        aggFuncs
      }
    }
  `,
};

const useFieldAggFuncDefinitions = () => {
  const { client } = useContext(GqlClientContext);
  const [fieldAggFuncDefinitions, setFieldAggFuncDefinitions] = useState<
    FleetOps.FieldAggFuncDefinition[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getDefinitions = useCallback(() => {
    setIsLoading(true);
    client.query(query).then((response) => {
      const newDefs = response.data
        .getFieldDefinitions as FleetOps.FieldAggFuncDefinition[];
      setFieldAggFuncDefinitions(newDefs);
      setIsLoading(false);
    });
  }, [client]);

  useEffect(() => {
    if (fieldAggFuncDefinitions.length === 0) {
      const i = setInterval(getDefinitions, 2000);
      return () => {
        clearInterval(i);
      };
    }
  }, [fieldAggFuncDefinitions.length, getDefinitions]);

  return {
    isLoading,
    fieldAggFuncDefinitions,
  };
};

export default useFieldAggFuncDefinitions;
