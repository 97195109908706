import React from 'react';
import { Link } from 'react-router-dom';

import Typography from 'kingpin/atoms/Typography';
import ListItemWrapper from '../../../DatasetDefinitions/ListItemWrapper';
import Column from '../../../DatasetDefinitions/Column';
import { buildShowGlobalGoal } from '../../../../../navigation/appRoutes';
import Card from '../../../../../components/Common/Card';

const GoalsUsage = ({
  goals,
  metric,
}: {
  goals: GeneralGoal[];
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
}) => (
  <>
    {goals.length > 0 && (
      <div style={{ marginBottom: 24 }}>
        <Typography.Header type="H5">{`Goals (${goals.length})`}</Typography.Header>
        <Card>
          {goals.map((g, index) => (
            <ListItemWrapper
              key={`${g.id}-${metric.id}`}
              isLast={goals.length - 1 === index}
            >
              <Column
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <Link to={buildShowGlobalGoal(g.id)} target="_blank">
                  <Typography.Body type="Body 12" color="#0041ea">
                    {g.title}
                  </Typography.Body>
                </Link>
              </Column>
            </ListItemWrapper>
          ))}
        </Card>
      </div>
    )}
  </>
);

export default GoalsUsage;
