import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';

import { Item } from '../QuickFilters/QuickFilters';
import Row from '../../../Common/Row';
import MetricPickerPopup from '../../../Inputs/MetricPicker/MetricPickerPopup';
import FleetOpsStaffOnly from '../../../Common/FleetOpsStaffOnly';
import Colors2 from '../../../../theme/Colors2';
import { getMetricLink } from '../../../../screens/DataManager/AllSets/routes';

const Metrics = ({
  metrics,
  onDragEnd,
  close,
  isAddingNewMetric,
  setIsAddingNewMetric,
  removeMetric,
  onAddMetric,
}: {
  metrics: Metrics.Metric[];
  onDragEnd: (result: DropResult) => void;
  close: () => void;
  isAddingNewMetric: boolean;
  setIsAddingNewMetric: React.Dispatch<React.SetStateAction<boolean>>;
  removeMetric: (metric: Metrics.Metric) => void;
  onAddMetric: (metric: Metrics.Metric) => void;
}) => {
  return (
    <React.Fragment>
      <MetricPickerPopup
        isOpen={isAddingNewMetric}
        close={() => setIsAddingNewMetric(false)}
        setSelectedMetric={onAddMetric}
      />
      <div style={{ padding: '16px 24px' }}>
        <div style={{ marginBottom: 24 }}>
          <Typography.Header type={'H5'}>Metrics</Typography.Header>
        </div>
        <div style={{ minHeight: 240 }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'metrics-drop'}>
              {(provided) => (
                <div ref={provided.innerRef}>
                  {metrics.map((f, index) => (
                    <Draggable draggableId={f.id} index={index} key={f.id}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Item>
                            <Typography.Body type={'Body 12'}>
                              {f.name}
                            </Typography.Body>
                            <Button
                              onClick={() => removeMetric(f)}
                              type="Secondary"
                              size="Small"
                              icon="cross"
                            />
                          </Item>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <FleetOpsStaffOnly>
          <div style={{ marginBottom: 24 }}>
            <Typography.Header type="H5">Metric definitions</Typography.Header>
            {metrics.map((m, index) => (
              <Link to={getMetricLink(m)} target={'_blank'} key={m.id}>
                <div
                  style={{
                    marginBottom: 4,
                    backgroundColor:
                      index % 2 === 0 ? 'white' : Colors2.Grey['8'],
                  }}
                >
                  <div>
                    <Typography.Body type="Link">{m.name}</Typography.Body>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </FleetOpsStaffOnly>
        <Row style={{ justifyContent: 'space-between' }}>
          <Button onClick={close} type="Ghost" size="Small" label="Close" />
          <Button
            type="Primary"
            size="Small"
            onClick={() => {
              setIsAddingNewMetric(true);
            }}
            label="Add metric"
          />
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Metrics;
