import React, { useCallback, useState } from 'react';
import NavSideBarContext from '../contexts/NavSideBarContext';

const NavSidebarProvider = ({
  children,
  initialIsOpen = true,
}: {
  children: JSX.Element | JSX.Element[];
  initialIsOpen?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const open = useCallback(() => {
    setIsOpen(true);
  }, []);
  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <NavSideBarContext.Provider value={{ isOpen, open, close }}>
      {children}
    </NavSideBarContext.Provider>
  );
};

export default NavSidebarProvider;
