import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import RemindersGadgetForm from './RemindersGadgetForm';
import { useNavigate } from 'react-router-dom';
import useEventTypes from './useEventTypes';
import withDateFilter from '../../hocs/withDateFIlter';
import { buildGadgetShow } from '../../navigation/appRoutes';
import saveRemindersGadget from './saveRemindersGadget';
import AccountPickerContext from '../../contexts/AccountPickerContext';

const aguid = require('aguid');

const RemindersGadgetFormContainer = ({
  gadget,
  isGadgetBuilder,
  onChange,
}: {
  gadget?: VisualisationDefinitions.Reminders;
  isGadgetBuilder?: boolean;
  onChange?: React.Dispatch<
    React.SetStateAction<undefined | VisualisationDefinition>
  >;
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);

  const navigate = useNavigate();
  const availableEventTypes = useEventTypes();

  // Base
  const [id] = useState(gadget ? gadget.id : aguid());
  const [name, setName] = useState<string>(gadget ? gadget.name : '');
  const [description, setDescription] = useState<string>(
    gadget ? gadget.description : '',
  );

  // Reminders Specific
  const [events, setEvents] = useState<string[]>(gadget ? gadget.events : []);
  // Misc
  const [draftGadget, setDraftGadget] = useState<
    VisualisationDefinitions.Reminders | undefined
  >(gadget ? { ...gadget } : undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onDescriptionChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const isValid = name !== '' && events.length > 0;

  // Set the draft gadget
  useEffect(() => {
    setDraftGadget({
      type: 'Reminders',
      id,
      name,
      description,
      events,
    });
  }, [description, events, id, name]);

  useEffect(() => {
    if (draftGadget && onChange) {
      onChange(draftGadget);
    }
  }, [draftGadget, onChange]);

  const onSave = useCallback(() => {
    if (draftGadget && isValid && isGadgetBuilder) {
      setIsLoading(true);
      saveRemindersGadget(draftGadget, selectedAccountId).then(() => {
        setIsLoading(false);
        navigate(buildGadgetShow(id));
      });
    }
  }, [draftGadget, navigate, id, isGadgetBuilder, isValid, selectedAccountId]);

  const eventOptions = availableEventTypes.map((e) => ({
    label: e,
    onSelected: () => {
      setEvents((currentEvents) => {
        if (currentEvents.includes(e)) {
          return currentEvents.filter((ce) => ce !== e);
        } else {
          return [...currentEvents, e];
        }
      });
    },
    isSelected: events.includes(e),
  }));

  const selectedEventLabels = (() => {
    if (events.length === 0) {
      return 'None';
    } else if (events.length < 3) {
      return events.length === 0 ? 'None' : events.join(', ');
    } else {
      const additional = events.length - 2;
      if (additional === 1) {
        return `${events[0]}, ${events[1]} and ${events.length - 2} other`;
      } else {
        return `${events[0]}, ${events[1]} and ${events.length - 2} others`;
      }
    }
  })();

  return (
    <RemindersGadgetForm
      isGadgetBuilder={isGadgetBuilder}
      isLoading={isLoading}
      name={name}
      onNameChanged={onNameChanged}
      description={description}
      onDescriptionChanged={onDescriptionChanged}
      eventOptions={eventOptions}
      isValid={isValid}
      onSave={onSave}
      draftGadget={draftGadget}
      selectedEventLabels={selectedEventLabels}
    />
  );
};

export default withDateFilter(RemindersGadgetFormContainer, {});
