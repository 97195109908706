import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import React from 'react';
import Colors2 from '../../theme/Colors2';

const FormHeaderDiv = styled.div`
  margin-bottom: 16px;
  border-bottom: 1px solid ${Colors2.Border};
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FormHeader = ({
  title,
  titleExtra,
  onClose,
}: {
  title: string;
  titleExtra?: JSX.Element;
  onClose?: () => void;
}) => {
  return (
    <FormHeaderDiv>
      <TitleRow>
        <Typography.Header type={'H4'}>{title}</Typography.Header>
        {titleExtra ? <div style={{ marginLeft: 16 }}>{titleExtra}</div> : null}
      </TitleRow>
      {onClose ? (
        <Button
          size={'Small'}
          type={'Secondary'}
          icon="cross"
          onClick={onClose}
          testId="close"
        />
      ) : (
        <div />
      )}
    </FormHeaderDiv>
  );
};

export default FormHeader;
