import isDefined from '../../isDefined';

const toReportDrillDown = ({
  plate,
  variableDrillDowns,
}: {
  plate: FilterPlate;
  variableDrillDowns: VariableDrillDownType[];
}): ReportDrillDownType | undefined => {
  if (plate.type === 'Fixed') {
    return plate.fixedValue;
  } else {
    const varId = plate.variableId;
    if (varId) {
      const variableDrill = variableDrillDowns.find((d) => d.id === varId);
      if (variableDrill) {
        return variableDrill.value;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }
};

const toDrillDowns = ({
  plates,
  variableDrillDowns,
}: {
  plates: FilterPlate[];
  variableDrillDowns?: VariableDrillDownType[];
}): ReportDrillDownType[] =>
  plates
    ? plates
        .map((plate) =>
          toReportDrillDown({
            plate,
            variableDrillDowns: variableDrillDowns ? variableDrillDowns : [],
          }),
        )
        .filter(isDefined)
    : [];

export const toFixedPlates = ({
  plates,
  variableDrillDowns,
}: {
  plates: FilterPlate[];
  variableDrillDowns: VariableDrillDownType[];
}): FilterPlate[] => {
  return plates.map((p) => {
    if (p.type === 'Fixed') {
      return p;
    } else {
      const v = variableDrillDowns.find((d) => d.id === p.variableId);
      if (v) {
        return {
          id: p.id,
          type: 'Fixed',
          fixedValue: v.value,
        };
      } else {
        return p;
      }
    }
  });
};

export default toDrillDowns;
