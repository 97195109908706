import { useContext, useEffect, useState } from 'react';
import CostsWizardContext from '../../context/CostsWizardContext';
import useGetLastQuarter from './useGetLastQuarter';

const useStartDateOptions = () => {
  const { wizardState, setWizardState } = useContext(CostsWizardContext);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const getLastQuarter = useGetLastQuarter({ wizardState });

  useEffect(() => {
    const intervalModes = getLastQuarter().reverse();

    const newOptions = intervalModes.map((mode) => ({
      label: mode.label,
      value: mode.value,
      isSelected: wizardState.config.basicStep.startDate === mode.value,
      onSelected: () => {
        setWizardState({
          ...wizardState,
          config: {
            ...wizardState.config,
            basicStep: {
              ...wizardState.config.basicStep,
              startDate: mode.value,
            },
          },
        });
      },
    }));

    setOptions(newOptions);
  }, [getLastQuarter, setWizardState, wizardState]);

  return options;
};

export default useStartDateOptions;
