import React from 'react';
import { MOCK_USERS } from '../__mocks__/mockUsers';

export interface CurrentUserContextType {
  id: string;
  displayName: string;
  createdOn?: string;
  email?: string;
  isAdmin?: boolean;
  isSuperAdmin?: boolean;
  isFleetOpsStaff?: boolean;
  isWallboardUser?: boolean;
  wallBoardId?: string;
}

export const MOCK_CURRENT_USER = {
  id: MOCK_USERS[0].id,
  displayName: MOCK_USERS[0].displayName,
  email: 'joe@blogs.com',
  isAdmin: true,
  isSuperAdmin: false,
  isFleetOpsStaff: false,
  isWallboardUser: false,
};

const CurrentUserContext = React.createContext<CurrentUserContextType>(
  process.env.NODE_ENV === 'test'
    ? MOCK_CURRENT_USER
    : ({} as CurrentUserContextType),
);

export default CurrentUserContext;
