import { useContext, useEffect, useState } from 'react';
import CurrentUserContext from '../contexts/CurrentUserContext';
import AccountPickerContext from '../contexts/AccountPickerContext';
import ImpersonatorContext from 'contexts/ImpersonatorContext';
import { FAVOURITES_ORDER_DOC_ID } from '../constants';

const useFavouritesOrder = () => {
  const currentUser = useContext(CurrentUserContext);
  const { accountRef } = useContext(AccountPickerContext);
  const { impersonatorId } = useContext(ImpersonatorContext);

  const [favouritesOrder, setFavouritesOrder] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test' || !currentUser) {
      return () => {};
    }
    const listener = accountRef
      .collection('users')
      .doc(impersonatorId || currentUser.id)
      .collection('favourites')
      .doc(FAVOURITES_ORDER_DOC_ID)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const docData = snapshot.data();
          if (docData) {
            setFavouritesOrder(docData.order);
          }
        }
        setIsLoading(false);
      });
    return () => {
      listener();
    };
  }, [accountRef, currentUser, impersonatorId]);

  return {
    favouritesOrder,
    isLoading,
  };
};

export default useFavouritesOrder;
