import React from 'react';
import { RADIO_OPTIONS_MAP } from '../consts';

export const DEFAULT_STATE: Costs.WizardState = {
  currentStep: 'base' as 'base',
  config: {
    basicStep: {
      interval: 'month' as 'month',
    },
    groupStep: {
      type: RADIO_OPTIONS_MAP.evenly,
      field: undefined,
      currentGroup: [],
      groups: [],
    },
    categoriesStep: {
      categories: [],
    },
    outputsStep: {
      isFuelExcluded: false,
    },
  },
  allCategories: [],
  isValid: false,
  isDraft: true,
  hasBreakingChanges: false,
};

interface CostsWizardContextType {
  wizardState: Costs.WizardState;
  setWizardState: React.Dispatch<React.SetStateAction<Costs.WizardState>>;
  isEditing: boolean;
  persistedWizardState: Costs.WizardState | undefined;
  defaultWizardState: Costs.WizardState;
  hasEditPermission: boolean;
}

const CostsWizardContext = React.createContext<CostsWizardContextType>({
  // @ts-ignore
  wizardState: undefined,
  setWizardState: () => {},
  isEditing: false,
  persistedWizardState: undefined,
  defaultWizardState: DEFAULT_STATE,
  hasEditPermission: false,
});

export default CostsWizardContext;
