import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import ScorecardForm from './ScorecardForm';
import { useCollaboratorNames } from '../../../components/Goals/GeneralGoalForm';
import useStartDateOptions from './useStartDateOptions';
import updateScorecardDoc from '../updateScorecardDoc';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import LocalTimelineProvider from '../../../contextProviders/TimelineProvider/LocalTimelineProvider';
import LocalTimelineContext from '../../../contexts/Timeline/LocalTimelineContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import usePopup from '../../../hooks/usePopup';
import ConfirmationModal from '../../../components/ConfirmationModal';
import Typography from 'kingpin/atoms/Typography';
import { Link } from 'react-router-dom';
import { buildDashboardShow } from '../../../navigation/appRoutes';
import useScorecardUsageReport from '../../../components/Scorecard/useScorecardUsageReport';
import targetDateToString from '../../../types/scorecardDates/targetDateToString';
import getIdentifier from '../../../getIdentifier';
import getTimeStamp from '../../../getTimeStamp';
import {
  CURRENT_SCORECARD_VERSION,
  SCORECARD_RESOURCE,
} from '../../../constants';
import useCopyVisHelpers from '../../../hooks/useCopyVisHelpers';
import {
  isKpiRow,
  isManualKpiRow,
} from 'components/Scorecard/Kpis/kpiTypeCheckers';
import useContentSharedEmails from '../../../hooks/useContentSharedEmails';

const ConfirmationBody = ({ usage }: { usage: Scorecards.UsageReport }) => {
  return (
    <div style={{ marginBottom: 32 }}>
      {usage.dashboards.length === 0 && (
        <Typography.Body type="Body 12">
          This KPI List does not appear in any dashboards.
        </Typography.Body>
      )}
      {usage.dashboards.length >= 1 && (
        <>
          <Typography.Body type="Body 12">
            This KPI List appears in the following dashboards:
          </Typography.Body>
          {usage.dashboards.map((d) => (
            <div key={d.id}>
              <Link to={buildDashboardShow(d.id)} target="_blank">
                <Typography.Body type="Link">{`- ${d.name}`}</Typography.Body>
              </Link>
            </div>
          ))}
        </>
      )}

      <Typography.Body type="Body 12">
        By making this change, the targets for this KPI List will be lost and
        need to be reset
      </Typography.Body>
    </div>
  );
};

const ScorecardFormContainer = ({
  id,
  close,
  scorecard,
  isCopyingScorecard,
  onScorecardCreated,
}: {
  id: string;
  close: () => void;
  scorecard?: Scorecards.Scorecard;
  isCopyingScorecard?: boolean;
  onScorecardCreated?: (newScorecardId: string) => void;
}) => {
  const {
    isOpen: isSaveConfirmationOpen,
    open: openSaveConfirmation,
    close: closeSaveConfirmation,
  } = usePopup();
  const { trackEvent } = useContext(AnalyticsContext);
  const { addEvent } = useContext(LocalTimelineContext);
  const currentUser = useContext(CurrentUserContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isEditing] = useState(!!scorecard);
  const { usage } = useScorecardUsageReport(scorecard);
  const { sendContentSharedEmails } = useContentSharedEmails();
  const { getMetricIdForNewVis } = useCopyVisHelpers();
  const [areKpisSet] = useState<boolean>(
    scorecard ? scorecard.kpis.length > 0 : false,
  );
  const [mustResetTargets, setMustResetTargets] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(
    scorecard
      ? isCopyingScorecard
        ? `${scorecard.title} (Copy)`
        : scorecard.title
      : '',
  );
  const [assigneeId, setAssigneeId] = useState<string[]>(
    scorecard && !isCopyingScorecard ? scorecard.assigneeId : [],
  );

  const [weekStartsOnOverride, setWeekStartsOnOverride] = useState<
    WeekStartsOn | undefined
  >(scorecard ? scorecard.weekStartsOnOverride : undefined);
  const [startDate, setStartDate] = useState<
    Scorecards.IsoDate | Scorecards.WeekDate | undefined
  >(scorecard ? scorecard.startDate : undefined);

  const [cadence, setCadence] = useState<'week' | 'month'>(
    scorecard ? scorecard.cadence : 'week',
  );
  const [cadenceOptions, setCadenceOptions] = useState<RadioOption[]>([]);
  useEffect(() => {
    setCadenceOptions([
      {
        key: 'week',
        label: 'Week',
        onSelected: () => {
          setCadence('week');
          setStartDate(undefined);
        },
        isSelected: cadence === 'week',
      },
      {
        key: 'month',
        label: 'Month',
        onSelected: () => {
          setCadence('month');
          setStartDate(undefined);
        },
        isSelected: cadence === 'month',
      },
    ]);
  }, [cadence]);

  const [scoringBandId, setScoringBandId] = useState<Scorecards.ScoringBandId>(
    scorecard ? scorecard.scoringBandId : 1,
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    setIsValid(startDate !== undefined && title !== '');
  }, [startDate, title]);

  useEffect(() => {
    setMustResetTargets(
      !!areKpisSet &&
        !!scorecard &&
        (scorecard.scoringBandId !== scoringBandId ||
          scorecard.cadence !== cadence),
    );
  }, [areKpisSet, cadence, scorecard, scoringBandId]);

  const assigneeName = useCollaboratorNames(assigneeId);
  const { startDateOptions, startDateLabel, todayOption } = useStartDateOptions(
    {
      cadence,
      startDate,
      setStartDate,
      weekStartsOnOverride,
    },
  );

  const onTitleChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  }, []);

  const trackSaved = useCallback(
    ({
      event,
      newScorecard,
    }: {
      event: Analytics.EventType;
      newScorecard: Scorecards.Scorecard;
    }) => {
      const numAssigned = newScorecard.assigneeId.length.toString();
      const interval = newScorecard.cadence;
      const startFrom = newScorecard.startDate;
      const scoringBandId = newScorecard.scoringBandId.toString();
      trackEvent(event, {
        numAssigned,
        interval,
        startFrom: targetDateToString(startFrom),
        scoringBandId,
      });
    },
    [trackEvent],
  );

  const onSaveConfirmed = useCallback(async () => {
    if (!isValid || startDate === undefined) {
      return;
    }

    setIsSaving(true);
    if (isEditing && !!scorecard && !isCopyingScorecard) {
      const updatedScorecard = {
        ...scorecard,
        title,
        assigneeId,
        cadence,
        weekStartsOnOverride,
        startDate,
        scoringBandId,
        updatedBy: currentUser.id,
        updatedOn: getTimeStamp(),
        kpis: !mustResetTargets
          ? scorecard.kpis
          : scorecard.kpis.map((kpi) => {
              if (isKpiRow(kpi) || isManualKpiRow(kpi)) {
                return {
                  ...kpi,
                  targets:
                    cadence === 'week'
                      ? ({
                          mode: 'Week',
                          targets: {},
                        } as Scorecards.WeekTargets)
                      : ({
                          mode: 'ISO',
                          targets: {},
                        } as Scorecards.IsoTargets),
                  isTargetsDisabled: true,
                };
              }
              return kpi;
            }),
      };
      await updateScorecardDoc(updatedScorecard, selectedAccountId);
      await sendContentSharedEmails({
        type: 'Assigned List',
        initialAssigned: scorecard.assigneeId,
        newAssigned: assigneeId,
      });
      trackSaved({
        event: 'KPI List - Edit Saved',
        newScorecard: updatedScorecard,
      });
      setIsSaving(false);
      close();
    } else {
      const newKpis = await (async () => {
        if (scorecard && isCopyingScorecard) {
          const kpis = await Promise.all(
            scorecard.kpis.map(async (kpi) => {
              if (isKpiRow(kpi)) {
                return {
                  ...kpi,
                  id: getIdentifier(),
                  metricId: await getMetricIdForNewVis(kpi.metricId),
                };
              }
              return kpi;
            }),
          );

          if (!mustResetTargets) {
            return kpis;
          }

          return kpis.map((kpi) => ({
            ...kpi,
            targets:
              cadence === 'week'
                ? ({ mode: 'Week', targets: {} } as Scorecards.WeekTargets)
                : ({ mode: 'ISO', targets: {} } as Scorecards.IsoTargets),
            weeklyTargets: undefined,
            isTargetsDisabled: true,
          }));
        }

        return [];
      })();

      const newScorecard = {
        type: 'Scorecard' as 'Scorecard',
        version: CURRENT_SCORECARD_VERSION,
        id,
        title,
        assigneeId,
        cadence,
        weekStartsOnOverride,
        startDate,
        scoringBandId,
        kpis: newKpis,
        createdBy: currentUser.id,
        updatedBy: currentUser.id,
        createdOn: getTimeStamp(),
        updatedOn: getTimeStamp(),
      };

      await updateScorecardDoc(newScorecard, selectedAccountId);
      await sendContentSharedEmails({
        type: 'Assigned List',
        initialAssigned: undefined,
        newAssigned: assigneeId,
      });
      if (addEvent) {
        await addEvent({
          actionText: 'created the KPI List',
          contextText: title,
        });
      }

      trackSaved({
        event: isCopyingScorecard
          ? 'KPI List - Copy Saved'
          : 'KPI List - Created',
        newScorecard,
      });
      setIsSaving(false);
      if (onScorecardCreated) {
        onScorecardCreated(newScorecard.id);
      }
      close();
    }
  }, [
    addEvent,
    assigneeId,
    cadence,
    close,
    currentUser.id,
    getMetricIdForNewVis,
    id,
    isCopyingScorecard,
    isEditing,
    isValid,
    mustResetTargets,
    onScorecardCreated,
    scorecard,
    scoringBandId,
    selectedAccountId,
    sendContentSharedEmails,
    startDate,
    title,
    trackSaved,
    weekStartsOnOverride,
  ]);

  const onSaveClicked = useCallback(() => {
    if (mustResetTargets) {
      openSaveConfirmation();
    } else {
      onSaveConfirmed();
    }
  }, [mustResetTargets, onSaveConfirmed, openSaveConfirmation]);

  return (
    <>
      <ScorecardForm
        isEditing={isEditing}
        close={close}
        title={title}
        onTitleChanged={onTitleChanged}
        assigneeId={assigneeId}
        setAssigneeId={setAssigneeId}
        assigneeName={assigneeName}
        cadence={cadence}
        cadenceOptions={cadenceOptions}
        startDateLabel={startDateLabel}
        startDateOptions={startDateOptions}
        scoringBandId={scoringBandId}
        setScoringBandId={setScoringBandId}
        isValid={isValid}
        onSaveClicked={onSaveClicked}
        isSaving={isSaving}
        isCopyingScorecard={isCopyingScorecard}
        scorecard={scorecard}
        weekStartsOnOverride={weekStartsOnOverride}
        setWeekStartsOnOverride={setWeekStartsOnOverride}
        todayOption={todayOption}
      />
      <ConfirmationModal
        isOpen={isSaveConfirmationOpen}
        close={closeSaveConfirmation}
        title={'Remove targets?'}
        confirmText={'Update and remove targets'}
        bodyComponent={<ConfirmationBody usage={usage} />}
        onConfirmed={onSaveConfirmed}
      />
    </>
  );
};

const Gate = ({
  close,
  scorecard,
  isCopyingScorecard,
  onScorecardCreated,
}: {
  close: () => void;
  scorecard?: Scorecards.Scorecard;
  isCopyingScorecard?: boolean;
  onScorecardCreated?: (newScorecardId: string) => void;
}) => {
  const [id] = useState<string>(
    scorecard && !isCopyingScorecard ? scorecard.id : getIdentifier(),
  );

  const [sources, setSources] = useState<Timeline.EventSource[]>([]);
  const [destination, setDestination] = useState<Timeline.EventSource>();

  useEffect(() => {
    setSources([{ type: SCORECARD_RESOURCE, id }]);
    setDestination({ type: SCORECARD_RESOURCE, id });
  }, [id]);

  return (
    <LocalTimelineProvider sources={sources} destination={destination}>
      <ScorecardFormContainer
        id={id}
        close={close}
        scorecard={scorecard}
        isCopyingScorecard={isCopyingScorecard}
        onScorecardCreated={onScorecardCreated}
      />
    </LocalTimelineProvider>
  );
};

export default Gate;
