import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PeriodsContext from 'contexts/PeriodsContext';
import useQuickFilters from '../../../hooks/useQuickFilters';
import useDateScope from '../../../hooks/useDateScope';
import toFilterInput from '../../../toFilterInput';
import { PERIOD_QUERY_PARAM } from '../providers/SelectedEntityProvider';
import useQueryParams from 'hooks/useQueryParams';
import { platesToFilterInput } from 'screens/GoalShow/useGoalData';

const useSelectInitialPeriod = () => {
  const urlParams = useQueryParams();

  const queriedStartDate = urlParams.has(PERIOD_QUERY_PARAM)
    ? (urlParams.get(PERIOD_QUERY_PARAM) as string)
    : undefined;

  return queriedStartDate;
};

// There is a lot of noise here.
// We will refactor / tidy when taking on Boards v3 project
const useFilteringState = ({
  board,
  popupFilters,
  scopeFilters,
}: {
  board: PerformanceBoardTypes.Board;
  popupFilters?: FilterPlate[];
  scopeFilters?: FilterPlate[];
}) => {
  const [dataTypes] = useState([board.dataType]);
  const initialPeriod = useSelectInitialPeriod();
  const { setSelectedPeriod, periods, selectedPeriod } =
    useContext(PeriodsContext);
  const navigate = useNavigate();
  const urlParams = useQueryParams();

  const dateRange = useDateScope({});

  const quickFilters = useQuickFilters(
    `${board.dataType} - board: ${board.id}`,
  );
  const [variableDrillDowns] = useState<VariableDrillDownType[]>([]);
  const [drillDowns, setDrillDowns] = useState<FilterPlate[]>(
    () => popupFilters || window.emptyArray,
  );
  const [selectedQuickFilters, setSelectedQuickFilters] = useState<
    SavedFilter[]
  >([]);
  const [netQuickFilters, setNetQuickFilters] = useState<FilterInput[]>([]);

  const selectInitialPeriod = useCallback(
    (newValue: string) => {
      const queriedPeriod = periods.find(
        (period) => period.startDate === newValue,
      );
      if (queriedPeriod) {
        setSelectedPeriod(queriedPeriod);
      }
    },
    [periods, setSelectedPeriod],
  );

  useEffect(() => {
    if (initialPeriod && selectedPeriod) {
      selectInitialPeriod(initialPeriod);
      urlParams.delete(PERIOD_QUERY_PARAM);
      navigate({ search: urlParams.toString() });
    }
  }, [initialPeriod, navigate, selectInitialPeriod, selectedPeriod, urlParams]);
  useEffect(() => {
    setNetQuickFilters([
      ...selectedQuickFilters.map((qf) =>
        toFilterInput(qf, variableDrillDowns),
      ),
      ...(board.filterInput ? [board.filterInput] : []),
      ...(scopeFilters ? [platesToFilterInput(scopeFilters)] : []),
    ]);
  }, [
    board.filterInput,
    scopeFilters,
    selectedQuickFilters,
    variableDrillDowns,
  ]);

  return {
    dataTypes,
    dateRange,
    quickFilters,
    drillDowns,
    setDrillDowns,
    selectedQuickFilters,
    setSelectedQuickFilters,
    netQuickFilters,
  };
};

export default useFilteringState;
