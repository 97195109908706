import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Colors from '../../theme/colors';
import Notification from './Notification';

import FlexCentered from '../Common/FlexCentered';
import { Img } from '../../screens/Home/RecentsCard/RecentsCard';
import NoNotificationsSrc from './notifications.svg';
import Typography from 'kingpin/atoms/Typography';
import Row from '../Common/Row';
import Colors2 from '../../theme/Colors2';
import NotificationsContext from '../../contexts/NotificationsContext';
import Button from '../../kingpin/atoms/Button';

const Container = styled.div<{ isCard?: boolean }>`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  ${(props) =>
    !props.isCard &&
    `
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.07),
      -2px 1px 3px 0 rgba(63, 63, 68, 0.12),
      -1px 1px 12px 5px rgba(63, 63, 68, 0.1);
    padding: 16px 24px;
    background-color: ${Colors.BLUE_GREY};
  `}
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Notifications = styled.div`
  height: calc(100% - 50px);
  position: relative;
`;

const NotificationGroupHeader = styled.div<{ isCardHeader?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  ${(props) =>
    props.isCardHeader &&
    `
    padding: 0px 16px;
  `}
`;

const WhatsNewButton = styled.button`
  padding: 0px;
  background-color: unset;
  border: none;
`;

const NotificationGroup = styled.div`
  margin-bottom: 20px;
`;

const WIDTH = '486px';

const Position = styled.div<{ isOpen: boolean }>`
  position: absolute;
  height: 100vh;
  width: ${WIDTH};
  top: 0;
  left: ${(props) => (props.isOpen ? '0px' : '-486px')};
  transition: left ease 0.3s;
  z-index: 99999999;
`;

const Anchor = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

// width: calc(100% - ${WIDTH});
const ClickHandlingOverlay = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.3;
  cursor: pointer;
  z-index: 100;
`;

const CannyStyleOverride = styled.div`
  .Canny_BadgeContainer .Canny_Badge {
    position: absolute;
    top: -1px;
    right: -1px;
    border-radius: 10px;
    background-color: ${Colors2.Primary['1']};
    padding: 3px;
    border: 1px solid transparent;
    cursor: pointer;
  }
`;

const NoNotifications = () => (
  <div
    style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    }}
  >
    <FlexCentered style={{ height: '100%' }}>
      <Img src={NoNotificationsSrc} />
      <Typography.Body type="Body 12">
        No notifications right now
      </Typography.Body>
    </FlexCentered>
  </div>
);

const setFavicon = (hasUnseenNotifications: boolean) => {
  const href = hasUnseenNotifications
    ? `${window.location.origin}/favicon-notification.ico`
    : `${window.location.origin}/favicon.ico`;
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement('link');
  // @ts-ignore
  link.type = 'image/x-icon';
  // @ts-ignore
  link.rel = 'shortcut icon';
  // @ts-ignore
  link.href = href;
  document.getElementsByTagName('head')[0].appendChild(link);
};

const NotificationsPanel = ({ isCard }: { isCard: boolean }) => {
  const {
    isPanelOpen,
    selectedNotificationId,
    hasUnReadNotifications,
    hasUnSeenNotifications,
    closePanel,
    groupedNotifications,
    markAllNotificationsAsRead,
    markAllNotificationsAsSeen,
    deleteAllNotifications,
  } = useContext(NotificationsContext);

  const shouldRenderReadAll = (index: number) => {
    return index === 0 && hasUnReadNotifications;
  };

  const shouldRenderClearAll = (index: number) => {
    return index === 0 && !hasUnReadNotifications;
  };

  useEffect(() => {
    if (isCard) {
      markAllNotificationsAsSeen();
    }
  }, [isCard, markAllNotificationsAsSeen]);

  useEffect(() => {
    setFavicon(hasUnSeenNotifications);
  }, [hasUnSeenNotifications]);

  if (isCard) {
    return (
      <Container className="hiding-scrollbar" isCard>
        <Notifications>
          {groupedNotifications.length === 0 && <NoNotifications />}
          {groupedNotifications.map((notificationGroup, index) => (
            <NotificationGroup key={notificationGroup.title}>
              <NotificationGroupHeader isCardHeader>
                <div>
                  <Typography.Body type={'Body 12'} color={Colors2.Grey['5']}>
                    {notificationGroup.title}
                  </Typography.Body>
                </div>
                {shouldRenderReadAll(index) && (
                  <div>
                    <Typography.Body
                      type={'Body 12'}
                      color={Colors2.Grey['5']}
                      onClick={markAllNotificationsAsRead}
                    >
                      Mark all as read
                    </Typography.Body>
                  </div>
                )}
                {shouldRenderClearAll(index) && (
                  <div>
                    <Typography.Body
                      type={'Body 12'}
                      color={Colors2.Grey['5']}
                      onClick={deleteAllNotifications}
                    >
                      Clear all
                    </Typography.Body>
                  </div>
                )}
              </NotificationGroupHeader>
              <div>
                {notificationGroup.notifications.map((notification) => (
                  <Notification
                    selected={notification.id === selectedNotificationId}
                    notification={notification}
                    key={notification.id}
                  />
                ))}
              </div>
            </NotificationGroup>
          ))}
        </Notifications>
      </Container>
    );
  } else {
    return (
      <div>
        <Position id="NotificationsPanel" isOpen={isPanelOpen}>
          <Anchor>
            <Container className="hiding-scrollbar">
              <Header>
                <Typography.Header type="H4">Notifications</Typography.Header>
                <Row centerAlign>
                  <CannyStyleOverride style={{ marginRight: 8 }}>
                    <WhatsNewButton data-canny-changelog>
                      <Button
                        size={'Medium'}
                        type={'Secondary'}
                        label={"What's New"}
                        icon={'updates'}
                      />
                    </WhatsNewButton>
                  </CannyStyleOverride>
                  <Button
                    size={'Medium'}
                    type={'Secondary'}
                    icon={'cross'}
                    onClick={closePanel}
                  />
                </Row>
              </Header>
              <Notifications>
                {groupedNotifications.length === 0 && <NoNotifications />}
                {groupedNotifications.map((notificationGroup, index) => (
                  <NotificationGroup key={notificationGroup.title}>
                    <NotificationGroupHeader>
                      <div>
                        <Typography.Body
                          type={'Body 12'}
                          color={Colors2.Grey['5']}
                        >
                          Notifications
                        </Typography.Body>
                      </div>
                      {shouldRenderReadAll(index) && (
                        <div>
                          <Typography.Body
                            type={'Body 12'}
                            color={Colors2.Grey['5']}
                            onClick={markAllNotificationsAsRead}
                          >
                            Mark all as read
                          </Typography.Body>
                        </div>
                      )}
                      {shouldRenderClearAll(index) && (
                        <div>
                          <Typography.Body
                            type={'Body 12'}
                            color={Colors2.Grey['5']}
                            onClick={deleteAllNotifications}
                          >
                            Clear all
                          </Typography.Body>
                        </div>
                      )}
                    </NotificationGroupHeader>
                    <div>
                      {notificationGroup.notifications.map((notification) => (
                        <Notification
                          selected={notification.id === selectedNotificationId}
                          notification={notification}
                          key={notification.id}
                        />
                      ))}
                    </div>
                  </NotificationGroup>
                ))}
              </Notifications>
            </Container>
          </Anchor>
        </Position>
        {isPanelOpen && <ClickHandlingOverlay onClick={closePanel} />}
      </div>
    );
  }
};
export default NotificationsPanel;
