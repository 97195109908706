import React from 'react';
import PaceMatrixForm from './PaceMatrixForm';
import useFormState from './useFormState';
import useFormActions from './useFormActions';

const PaceMatrixFormContainer = ({
  editingPaceGadget,
  onDraftChanged,
  isGadgetBuilder,
}: {
  editingPaceGadget?: VisualisationDefinitions.PaceMatrix;
  onDraftChanged?: React.Dispatch<
    React.SetStateAction<VisualisationDefinition | undefined>
  >;
  isGadgetBuilder: boolean;
}) => {
  const formState = useFormState({ editingPaceGadget, onDraftChanged });
  const { onSave, onSaveAs, onDelete, isLoading } = useFormActions({
    formState,
    editingPaceGadget,
  });

  return (
    <PaceMatrixForm
      formState={formState}
      isEditing={!!editingPaceGadget}
      isLoading={isLoading}
      onSave={onSave}
      onSaveAs={onSaveAs}
      onDelete={onDelete}
      isGadgetBuilder={isGadgetBuilder}
    />
  );
};

export default PaceMatrixFormContainer;
