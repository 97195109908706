import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataDictionaryContext from 'contexts/DataDictionaryContext';
import appRoutes from 'navigation/appRoutes';

import FieldSlideOut from './FieldSlideOut';
import useUpdateView from './useUpdateView';
import useTrackSettingsChanged from './useTrackFieldSettingsChanged';

const DATE_FORMATS = [
  'MM/DD/YY',
  'MMM/DD/YY',
  'MM/DD-DD',
  'MMM/DD/YY HH:mm',
  'MM/DD/YY HH:mm',
  'HH:mm',
] as FleetOps.DateFormat[];

const FieldSlideOutContainer = ({
  field,
  fieldView,
  baseView,
}: {
  field: FleetOps.Field;
  fieldView: FleetOps.BaseViewField;
  baseView: FleetOps.BaseView;
}) => {
  const navigate = useNavigate();
  const updateView = useUpdateView({ field, baseView });

  const [showInGrid, setShowInGrid] = useState<boolean>(fieldView.isVisible);
  const [displayName, setDisplayName] = useState<string | undefined>(
    fieldView.nameAlias,
  );
  const [description, setDescription] = useState<string | undefined>(
    fieldView.description,
  );
  const [totalsAggFunc, setTotalsAggFunc] = useState<AggFunc | undefined>(
    fieldView.aggFunc,
  );
  const [positiveDeltaIsGood, setPositiveDeltaIsGood] = useState<boolean>(
    !!fieldView.formatting.positiveDeltaIsGood,
  );
  const [isCommasDisabled, setIsCommasDisabled] = useState<boolean>(
    !!fieldView.formatting.isCommasDisabled,
  );
  const [dateFormat, setDateFormat] = useState<FleetOps.DateFormat | undefined>(
    fieldView.dateFormat,
  );
  const [precision, setPrecision] = useState<number | undefined>(
    fieldView.precision,
  );
  const trackSettingsChanged = useTrackSettingsChanged({ field, baseView });

  const onClose = () => {
    navigate(
      appRoutes.loggedIn.datasetDefinitions.buildShowDataset(baseView.type),
    );
  };

  const onShowInGridChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newShowInGrid = event.target.checked;
    setShowInGrid(newShowInGrid);

    const newView = {
      ...fieldView,
      isVisible: newShowInGrid,
    };

    updateView(newView);
    trackSettingsChanged();
  };

  const onDisplayNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(event.target.value);

    const newView = {
      ...fieldView,
      nameAlias: event.target.value,
    };

    updateView(newView);
  };

  const onDescriptionChanged = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const newDescription = event.target.value;
    setDescription(newDescription);

    const newView = {
      ...fieldView,
      description: newDescription,
    };

    updateView(newView);
  };

  const onPositiveDeltaIsGoodChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newStatus = event.target.checked;
    setPositiveDeltaIsGood(newStatus);

    const newView = {
      ...fieldView,
      positiveDeltaIsGood: newStatus,
      formatting: {
        ...fieldView.formatting,
        positiveDeltaIsGood: newStatus,
      },
    };

    updateView(newView);
    trackSettingsChanged();
  };

  const onIsCommasDisabledChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newStatus = !event.target.checked;
    setIsCommasDisabled(newStatus);

    const newView = {
      ...fieldView,
      formatting: {
        ...fieldView.formatting,
        isCommasDisabled: newStatus,
      },
    };

    updateView(newView);
    trackSettingsChanged();
  };

  const onPrecisionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPrecision = Number.parseInt(event.target.value);
    setPrecision(newPrecision);

    const newView = {
      ...fieldView,
      precision: isNaN(newPrecision) ? 0 : newPrecision,
      formatting: {
        ...fieldView.formatting,
        precision: isNaN(newPrecision) ? 0 : newPrecision,
      },
    };

    updateView(newView);
    trackSettingsChanged();
  };

  const totalOptions = [
    {
      label: 'None',
      value: undefined,
      onSelected: () => {
        setTotalsAggFunc(undefined);

        const newView = {
          ...fieldView,
          aggFunc: undefined,
        };

        updateView(newView);
        trackSettingsChanged();
      },
    },
    ...field.aggFuncs.map((f) => ({
      label: f,
      value: f,
      onSelected: () => {
        setTotalsAggFunc(f);

        const newView = {
          ...fieldView,
          aggFunc: f,
        };

        updateView(newView);
        trackSettingsChanged();
      },
    })),
  ];

  const dateFormatOptions = [
    {
      label: 'None',
      value: undefined,
      onSelected: () => {
        setDateFormat(undefined);

        const newView = {
          ...fieldView,
          dateFormat: undefined,
        };

        updateView(newView);
        trackSettingsChanged();
      },
    },
    ...DATE_FORMATS.map((format) => ({
      label: format,
      value: format,
      onSelected: () => {
        setDateFormat(format);

        const newView = {
          ...fieldView,
          dateFormat: format,
        };

        updateView(newView);
        trackSettingsChanged();
      },
    })),
  ];

  return (
    <FieldSlideOut
      fieldDef={field}
      fieldView={fieldView}
      baseView={baseView}
      field={field.field}
      displayName={displayName}
      onDisplayNameChanged={onDisplayNameChanged}
      showInGrid={showInGrid}
      onShowInGridChanged={onShowInGridChanged}
      description={description}
      onDescriptionChanged={onDescriptionChanged}
      positiveDeltaIsGood={positiveDeltaIsGood}
      onPositiveDeltaIsGoodChanged={onPositiveDeltaIsGoodChanged}
      totalOptions={totalOptions}
      totalsAggFunc={totalsAggFunc}
      cellType={fieldView.cellType}
      dateFormatOptions={dateFormatOptions}
      dateFormat={dateFormat}
      precision={precision}
      onPrecisionChanged={onPrecisionChanged}
      onClose={onClose}
      isCommasDisabled={isCommasDisabled}
      onIsCommasDisabledChanged={onIsCommasDisabledChanged}
      updateView={updateView}
    />
  );
};

const Gate = () => {
  const { selectedBaseFieldView, selectedBaseView, selectedField } = useContext(
    DataDictionaryContext,
  );

  if (!selectedBaseFieldView || !selectedBaseView || !selectedField) {
    return null;
  }

  return (
    <FieldSlideOutContainer
      key={selectedField.field}
      fieldView={selectedBaseFieldView}
      field={selectedField}
      baseView={selectedBaseView}
    />
  );
};

export default Gate;
