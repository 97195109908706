import { GridApi, RowSelectedEvent } from 'ag-grid-community';
import toDrillDowns from '../components/Report/toReportDrillDown';
import { useCallback, useContext, useEffect } from 'react';
import VariableFiltersContext from '../contexts/VariableFiltersContext';
import ReportDrillDownsContext from '../contexts/ReportDrillDownsContext';
import captureException from '../services/captureException';
import drillDownTypeCheckers from '../components/ReportDrillDownForm/drilldownTypeCheckers';

const useRowHighlighter = ({
  gridApi,
  drillField,
  visId,
}: {
  gridApi?: GridApi;
  drillField: string;
  visId?: string;
}) => {
  const { variableFilters } = useContext(VariableFiltersContext);
  const { drillDowns } = useContext(ReportDrillDownsContext);

  const highlightRows = useCallback(() => {
    if (gridApi && !gridApi.isDestroyed()) {
      gridApi.forEachNode((node) => {
        if (drillDowns.length > 0) {
          const lastDrillPlate = drillDowns[drillDowns.length - 1];
          const asDrills = toDrillDowns({
            plates: [lastDrillPlate],
            variableDrillDowns: variableFilters,
          });
          const lastDrill = asDrills.length > 0 ? asDrills[0] : undefined;
          if (!lastDrill) {
            return;
          }
          const isLastForField =
            drillDowns.length > 0 && lastDrill.field === drillField;
          if (
            isLastForField &&
            drillDownTypeCheckers.isTextDrillDown(lastDrill) &&
            lastDrill.keywordValues &&
            lastDrill.keywordValues.includes(node.data[drillField])
          ) {
            node.setSelected(true);
          } else if (
            isLastForField &&
            drillDownTypeCheckers.isTextDrillDown(lastDrill) &&
            lastDrill.keywordsExists !== undefined &&
            lastDrill.keywordsExists === false &&
            node.data[drillField] === '(Blank)'
          ) {
            node.setSelected(true);
          } else if (
            isLastForField &&
            drillDownTypeCheckers.isDateDrillDown(lastDrill) &&
            lastDrill.dateRangeValues &&
            lastDrill.dateRangeValues.some(
              (v) =>
                v.value.startDate &&
                v.value.startDate === node.data['raw-date'],
            )
          ) {
            node.setSelected(true);
          } else {
            node.setSelected(false);
          }
        } else {
          node.setSelected(false);
        }
      });
    }
  }, [drillDowns, drillField, gridApi, variableFilters]);

  // Yes - this insanity is required.
  // api.getDisplayedRows will also return invisible rows
  // which are in the buffer.
  // I cannot find a clear way via the API to get the
  // actually displayed fields...
  const onRowSelected = useCallback(
    (event: RowSelectedEvent) => {
      if (!visId) {
        return;
      }
      try {
        if (event.node.isSelected() && event.rowIndex !== null) {
          const rowTop = event.node.rowTop;
          const gridElem = document.getElementById(visId);
          if (!gridElem || rowTop === null) {
            return;
          }
          const scrollWindows =
            gridElem.getElementsByClassName('ag-body-viewport');
          if (scrollWindows.length === 0) {
            return;
          }
          const scrollWindow = scrollWindows[0];
          const isBelowScrollPosition =
            scrollWindow.clientHeight + scrollWindow.scrollTop < rowTop;
          const isAboveScrollPosition =
            scrollWindow.scrollTop > rowTop + (event.node.rowHeight || 0);
          if (isBelowScrollPosition || isAboveScrollPosition) {
            event.api.ensureIndexVisible(event.rowIndex, 'top');
          }
        }
      } catch (ex) {
        captureException(ex);
      }
    },
    [visId],
  );

  useEffect(() => {
    if (!gridApi) {
      return;
    }
    gridApi.addEventListener('rowSelected', onRowSelected);

    return () => {
      if (gridApi.isDestroyed()) {
        return;
      }
      gridApi.removeEventListener('rowSelected', onRowSelected);
    };
  }, [gridApi, onRowSelected]);

  useEffect(() => {
    if (!gridApi) {
      return;
    }
    gridApi.addEventListener('rowDataUpdated', highlightRows);
    highlightRows();

    return () => {
      if (gridApi.isDestroyed()) {
        return;
      }
      gridApi.removeEventListener('rowDataUpdated', highlightRows);
    };
  }, [gridApi, highlightRows]);

  return highlightRows;
};

export default useRowHighlighter;
