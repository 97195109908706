import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import UserPicker from './UserPicker';
import useUsers from '../../hooks/useUsers';
import CurrentUserContext from '../../contexts/CurrentUserContext';

const UserPickerContainer = ({
  memberUserIds,
  setMemberUserIds,
  excludeCurrentUser,
  label,
  isLarge,
  placeholder,
  excludeInvites,
}: {
  memberUserIds: string[];
  setMemberUserIds: React.Dispatch<React.SetStateAction<string[]>>;
  excludeCurrentUser: boolean;
  label?: string;
  isLarge?: boolean;
  placeholder?: string;
  excludeInvites?: boolean;
}) => {
  const [searchText, setSearchText] = useState('');
  const onSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const allUsers = useUsers();
  const users = excludeInvites
    ? allUsers.filter((u) => !!u.firstName)
    : allUsers;
  const { id: currentUserId } = useContext(CurrentUserContext);
  const [userOptions, setUserOptions] = useState<
    {
      isSelected: boolean;
      onSelected: () => void;
      user:
        | UserManagement.SignedUpUser
        | UserManagement.PendingUser
        | UserManagement.FleetOpsStaffUser;
    }[]
  >([]);

  const addMember = useCallback(
    (userId: string) => {
      setMemberUserIds(memberUserIds.concat(userId));
    },
    [memberUserIds, setMemberUserIds],
  );

  const removeMember = useCallback(
    (userId: string) => {
      setMemberUserIds(memberUserIds.filter((id) => id !== userId));
    },
    [memberUserIds, setMemberUserIds],
  );

  const onUserClicked = useCallback(
    (userId: string) => {
      if (memberUserIds.includes(userId)) {
        removeMember(userId);
      } else {
        addMember(userId);
      }
    },
    [addMember, memberUserIds, removeMember],
  );

  useEffect(() => {
    const filteredUsers = _.sortBy(
      users
        .filter(
          (u) =>
            u.displayName &&
            u.displayName.toLowerCase().includes(searchText.toLowerCase()),
        )
        .filter((u) => {
          if (excludeCurrentUser) {
            return u.id !== currentUserId;
          }
          return true;
        }),
      ['displayName'],
    );

    const newOptions = filteredUsers.map((u) => ({
      user: u,
      isSelected: memberUserIds.includes(u.id),
      onSelected: () => onUserClicked(u.id),
    }));

    setUserOptions(newOptions);
  }, [
    currentUserId,
    excludeCurrentUser,
    memberUserIds,
    onUserClicked,
    searchText,
    users,
  ]);

  return (
    <UserPicker
      userOptions={userOptions}
      searchText={searchText}
      onSearchTextChange={onSearchTextChange}
      label={label}
      isLarge={isLarge}
      placeholder={placeholder}
    />
  );
};

export default UserPickerContainer;
