import styled, { css } from 'styled-components';
import useFavouriteToggle from '../hooks/useFavouriteToggle';
import React from 'react';
import { FavouriteButton } from './IndexListItem';
import { TOP_BAR_HEIGHT } from '../constants';

export const TOP_BAR_BOX_SHADOW_CSS = css`
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
`;

const ResourceTopBar = styled.div<{ isCanvas?: boolean }>`
  min-height: ${TOP_BAR_HEIGHT}px;
  height: ${TOP_BAR_HEIGHT}px;
  ${TOP_BAR_BOX_SHADOW_CSS};

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0px 24px;
`;

export const ResourceFavouriteButton = ({
  type,
  typeId,
  name,
}: {
  type:
    | 'report'
    | 'dashboard'
    | 'wallboard'
    | 'workspace'
    | 'scorecard'
    | 'targetsApp';
  typeId: string;
  name: string;
}) => {
  const { isFavourite, onFavouriteToggled } = useFavouriteToggle({
    type,
    typeId,
    name,
  });

  return (
    <FavouriteButton isFavourite={isFavourite} onClick={onFavouriteToggled} />
  );
};

export default ResourceTopBar;
