import { useContext, useEffect, useState } from 'react';
import GqlClientContext from 'contexts/GqlClientContext';
import getBaseViewFields from 'components/Grid/getBaseViewFields';
import getGridTotalsRow from 'api/getGridTotalsRow';
import { TOTALS_QUERY } from 'components/Grid/gridConstants';
import BoardContext from 'contexts/BoardContext';
import useDateScope from 'hooks/useDateScope';
import isPerformanceBoard from 'isPerformanceBoard';
import ReportDrillDownsContext from 'contexts/ReportDrillDownsContext';
import { platesToFilterInput } from 'screens/GoalShow/useGoalData';

const useGetFieldsWhichHaveTargets = ({
  baseView,
}: {
  baseView: FleetOps.BaseView | undefined;
}) => {
  const { client } = useContext(GqlClientContext);
  const { board } = useContext(BoardContext);
  const { quickFilters, scope, drillDowns } = useContext(
    ReportDrillDownsContext,
  );
  const dateScope = useDateScope({});
  const [totalsRow, setTotalsRow] = useState<any>();

  useEffect(() => {
    if (!baseView || !isPerformanceBoard(board)) {
      return;
    }
    const fields = getBaseViewFields(baseView)
      .filter((c) => c.aggFunc)
      .map((c) => ({
        field: c.field,
        aggFunc: c.aggFunc,
      })) as GridAggregateField[];

    let isActive = true;

    getGridTotalsRow({
      query: TOTALS_QUERY,
      dateScope,
      dataType: board.dataType,
      filters: [
        board.filterInput,
        platesToFilterInput(scope),
        platesToFilterInput(drillDowns),
        ...quickFilters,
      ],
      fields,
      client,
    }).then((totals: any) => {
      if (!isActive) {
        return;
      }
      const row = totals[0];
      if (!row) {
        setTotalsRow(undefined);
        return;
      }

      const fields = Object.keys(row) as string[];
      fields.forEach((f) => {
        const parts = f.split('.');
        if (parts.length === 2) {
          if (!row[parts[0]]) {
            row[parts[0]] = {};
          }
          row[parts[0]][parts[1]] = row[f];
        }
      });

      setTotalsRow([row]);
    });

    return () => {
      isActive = false;
    };
  }, [baseView, board, client, dateScope, drillDowns, quickFilters, scope]);

  return totalsRow;
};

export default useGetFieldsWhichHaveTargets;
