import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import getIdentifier from 'getIdentifier';
import Inputs from 'components/Inputs';
import Button from 'kingpin/atoms/Button';

import useScorecardPopupCommentId from 'hooks/useScorecardPopupCommentId';
import CurrentUserContext from 'contexts/CurrentUserContext';
import moment from 'moment';
import withoutNulls from 'api/search/withoutNulls';
import AccountPickerContext from 'contexts/AccountPickerContext';
import apiCreateComment from 'api/createComment';
import Row from 'components/Common/Row';
import LocalTimelineContext from 'contexts/Timeline/LocalTimelineContext';
import AnalyticsContext from 'contexts/AnalyticsContext';
import ScorecardContext from 'contexts/ScorecardContext';
import { isKpiRow } from '../kpiTypeCheckers';

const NoteWizard = ({
  lastComment,
  period,
  kpi,
  name,
  setIsEditing,
}: {
  lastComment?: CommentType;
  period: Period;
  kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
  name: string;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const currentUser = useContext(CurrentUserContext);
  const { accountRef } = useContext(AccountPickerContext);
  const { addEvent } = useContext(LocalTimelineContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { scorecard } = useContext(ScorecardContext);
  const [note, setNote] = useState<string>(lastComment ? lastComment.text : '');
  const { commentableId, commentableType } = useScorecardPopupCommentId({
    period,
    kpi,
  });

  const onNoteChanged = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setNote(event.target.value);
    },
    [],
  );

  const onCancelClicked = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const onSaveClicked = useCallback(() => {
    const comment = {
      id: lastComment ? lastComment.id : getIdentifier(),
      commentableId,
      commentableType,
      createdBy: currentUser.id,
      text: note,
      createdOn: moment().utc().toISOString(),
      linkTo: '',
      mentionedUsers: window.emptyArray,
      attachedFiles: window.emptyArray,
    } as CommentType;

    apiCreateComment(withoutNulls(comment), accountRef).then(() => {
      const eventPromise = (() => {
        if (addEvent) {
          return addEvent({
            actionText: 'added a note',
            contentText: note,
            contextText: `to ${name}`,
            isContentTextRich: true,
            interaction: {
              type: 'Show Note',
              kpiId: kpi.id,
              period: period,
            },
          });
        }

        return Promise.resolve();
      })();

      const eventType = lastComment
        ? 'KPI List - Note - Updated'
        : 'KPI List - Note - Added';
      trackEvent(eventType, {
        scorecardName: scorecard.title,
        scorecardId: scorecard.id,
        kpiId: kpi.id,
        kpiMetricId: isKpiRow(kpi) ? kpi.metricId : 'manual kpi',
        kpiDisplayName: name,
        selectedPeriodStart: period.startDate,
        selectedPeriodLabel: period.label,
      });

      eventPromise.then(() => {
        setIsEditing(false);
      });
    });
  }, [
    accountRef,
    addEvent,
    commentableId,
    commentableType,
    currentUser.id,
    kpi,
    lastComment,
    name,
    note,
    period,
    scorecard.id,
    scorecard.title,
    setIsEditing,
    trackEvent,
  ]);
  return (
    <div style={{ marginBottom: '16px' }}>
      <div style={{ marginBottom: '8px' }}>
        <Inputs.TextArea
          value={note}
          onChange={onNoteChanged}
          autoFocus
          style={{ minHeight: '116px' }}
        />
      </div>

      <Row style={{ justifyContent: 'flex-end' }} centerAlign>
        <div style={{ marginRight: '16px' }}>
          <Button
            label={'Cancel'}
            onClick={onCancelClicked}
            type="Secondary"
            size="Small"
          />
        </div>
        <Button
          onClick={onSaveClicked}
          isDisabled={note === ''}
          label="Save Note"
          type="Primary"
          size="Small"
        />
      </Row>
    </div>
  );
};

export default NoteWizard;
