import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import usePopup from '../../../hooks/usePopup';
import ScorecardForm from '../../ScorecardsIndex/ScorecardForm';
import { buildShowGlobalKpiList } from '../../../navigation/appRoutes';
import ScorecardDeletionConfirmation from '../../../components/Scorecard/ScorecardDeletionConfirmation';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import ContextMenu, { Option, Info, Break } from 'kingpin/atoms/ContextMenu';

const ScorecardMoreMenuContainer = ({
  scorecard,
  isEditOnly,
  isEditHidden,
}: {
  scorecard: Scorecards.Scorecard;
  isEditOnly?: boolean;
  isEditHidden?: boolean;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);

  const navigate = useNavigate();
  const { isOpen, open, close } = usePopup();
  const {
    isOpen: isShowingDeleteConfirmation,
    open: openDeleteConfirmation,
    close: closeDeleteConfirmation,
  } = usePopup();
  const { isOpen: isCopyOpen, open: openCopy, close: closeCopy } = usePopup();
  const {
    isOpen: isEditFormOpen,
    open: openEditForm,
    close: closeEditForm,
  } = usePopup();

  const onEditClicked = useCallback(() => {
    openEditForm();
    trackEvent('KPI List - Edit Clicked', {
      scorecardName: scorecard.title,
      scorecardId: scorecard.id,
    });
  }, [openEditForm, scorecard.id, scorecard.title, trackEvent]);

  const onCopyClicked = useCallback(() => {
    openCopy();
    trackEvent('KPI List - Copy Clicked', {
      scorecardName: scorecard.title,
      scorecardId: scorecard.id,
    });
  }, [openCopy, scorecard.id, scorecard.title, trackEvent]);

  const onDeleteClicked = useCallback(() => {
    openDeleteConfirmation();
    trackEvent('KPI List - Delete Clicked', {
      scorecardName: scorecard.title,
      scorecardId: scorecard.id,
    });
  }, [openDeleteConfirmation, scorecard.id, scorecard.title, trackEvent]);

  const onScorecardCreated = useCallback(
    (newScorecardId: string) => {
      navigate(buildShowGlobalKpiList(newScorecardId));
    },
    [navigate],
  );

  return (
    <div className={isOpen ? '' : 'showOnMouseOver'}>
      <ContextMenu testId="scorecard-menu" onOpen={open} onClose={close}>
        <>
          {!isEditHidden && (
            <Option onClick={onEditClicked} label="Edit KPI List" />
          )}
          {!isEditOnly && (
            <>
              <Option onClick={onCopyClicked} label={'Copy KPI List'} />
              <Info {...scorecard} />
              <Break />
              <Option
                onClick={onDeleteClicked}
                label={'Delete KPI List'}
                isDanger
              />
            </>
          )}
        </>
      </ContextMenu>
      <ScorecardDeletionConfirmation
        scorecard={scorecard}
        isOpen={isShowingDeleteConfirmation}
        close={closeDeleteConfirmation}
      />
      <ModalTransition>
        {isCopyOpen && (
          <Modal shouldScrollInViewport={false} autoFocus={false}>
            <ScorecardForm
              scorecard={scorecard}
              isCopyingScorecard
              close={closeCopy}
              onScorecardCreated={onScorecardCreated}
            />
          </Modal>
        )}
      </ModalTransition>
      <ModalTransition>
        {isEditFormOpen && (
          <Modal shouldScrollInViewport={false} autoFocus={false}>
            <ScorecardForm close={closeEditForm} scorecard={scorecard} />
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default ScorecardMoreMenuContainer;
