import React, { useContext, useEffect, useState } from 'react';
import Tooltip from '../Tooltip';
import Typography from 'kingpin/atoms/Typography';
import useDataTypesOnReportCanvas from '../Report/useDataTypesOnReportCanvas';
import { getDrillDownDescription } from '../ReportDrillDown';
import { getDrillDownFieldName } from '../../hooks/useDrillDownFieldName';
import BaseViewsContext from '../../contexts/BaseViewsContext';
import { buildWeekStartsOnLabel } from '../Report/WeekStartsOnOverrideSelector';
import AccountContext from '../../contexts/AccountContext';
import { InfoIconNoTooltip } from '../InfoIcon';
import useGetDatasetLabel from '../Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';
import filterPlateTypeCheckers from '../../types/filterPlateTypeCheckers';

const TooltipInner = ({
  datasets,
  scope,
  queryDate,
  reportingWeek,
}: {
  datasets: string;
  scope: string;
  queryDate: string;
  reportingWeek: string;
}) => (
  <div>
    <div style={{ marginBottom: 4 }}>
      <div>
        <Typography.Body type="Label">Datasets used: </Typography.Body>
      </div>
      <Typography.Body type="Body 12">{datasets}</Typography.Body>
    </div>
    <div style={{ marginBottom: 4 }}>
      <div>
        <Typography.Body type="Label">Scope: </Typography.Body>
      </div>
      <Typography.Body type="Body 12">{scope}</Typography.Body>
    </div>
    <div style={{ marginBottom: 4 }}>
      <div>
        <Typography.Body type="Label">Query date: </Typography.Body>
      </div>
      <Typography.Body type="Body 12">{queryDate}</Typography.Body>
    </div>
    <div style={{ marginBottom: 4 }}>
      <div>
        <Typography.Body type="Label">Reporting week: </Typography.Body>
      </div>
      <Typography.Body type="Body 12">{reportingWeek}</Typography.Body>
    </div>
  </div>
);

const ReportInfoIcon = ({
  report,
  isSmall,
}: {
  report: PersistedReportType;
  isSmall?: boolean;
}) => {
  const { baseViews } = useContext(BaseViewsContext);
  const { weekStartsOn } = useContext(AccountContext);
  const usedDataTypes = useDataTypesOnReportCanvas(report.canvas);
  const getDatasetLabel = useGetDatasetLabel();

  const [datasets, setDataSet] = useState<string>('');
  const [scope, setScope] = useState<string>('');
  const [queryDate] = useState<string>(report.dateField || 'date');
  const [reportingWeek] = useState<string>(
    buildWeekStartsOnLabel(report.weekStartsOnOverride || weekStartsOn),
  );

  useEffect(() => {
    setDataSet(usedDataTypes.map(getDatasetLabel).join(', '));
  }, [getDatasetLabel, usedDataTypes]);

  useEffect(() => {
    setScope(
      report.scope
        .filter(filterPlateTypeCheckers.isFixed)
        .map((p) => p.fixedValue)
        .map((drillDown) =>
          getDrillDownDescription(
            drillDown,
            getDrillDownFieldName(drillDown, baseViews),
          ),
        )
        .join(', '),
    );
  }, [baseViews, report.scope]);

  return (
    <div style={{ marginLeft: -4 }}>
      <Tooltip
        content={
          <TooltipInner
            datasets={datasets}
            queryDate={queryDate}
            reportingWeek={reportingWeek}
            scope={scope}
          />
        }
        isAltTooltip
        hideTooltipOnClick
      >
        <InfoIconNoTooltip testId={'report-info'} isSmall={isSmall} />
      </Tooltip>
    </div>
  );
};

export default ReportInfoIcon;
