import React from 'react';
import styled from 'styled-components';
import Row from '../../../components/Common/Row';
import { NabTabLinkMargin, NavTabLink } from '../../../components/NavTab';

import ALL_SETS_ROUTES from './routes';
import TopBarTitleRow from '../TopBarTitleRow';
import PermissionGates from '../../../components/PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';

import { TOP_BAR_BOX_SHADOW_CSS } from '../../../components/ResourceTopBar';
import FeatureGate, { FEATURE_GATES } from '../../../components/FeatureGate';

const Wrapper = styled.div`
  background-color: white;
  ${TOP_BAR_BOX_SHADOW_CSS};
`;

const TopNavigator = () => (
  <Wrapper>
    <TopBarTitleRow />
    <Row style={{ padding: '0px 24px' }}>
      <div style={{ marginRight: NabTabLinkMargin }}>
        <NavTabLink title="Datasets" to={ALL_SETS_ROUTES.DATASETS} />
      </div>
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.DATA_MANAGEMENT.REUSABLE_METRICS_CONFIG}
      >
        <div style={{ marginRight: NabTabLinkMargin }}>
          <NavTabLink
            title="Metrics"
            to={ALL_SETS_ROUTES.METRICS}
            onClickEvent="Data Manager - Clicked Metrics"
          />
        </div>
        <div style={{ marginRight: NabTabLinkMargin }}>
          <NavTabLink
            title="Compound Metrics"
            to={ALL_SETS_ROUTES.COMPOUND_METRICS}
            onClickEvent="Data Manager - Clicked Compound Metrics"
          />
        </div>
      </PermissionGates.Has>
      <div style={{ marginRight: NabTabLinkMargin }}>
        <NavTabLink
          title="Search Data"
          to={ALL_SETS_ROUTES.SEARCH}
          onClickEvent="Data Manager - Clicked Search Raw Data"
        />
      </div>
      <FeatureGate featureName={FEATURE_GATES.ACTIVITY_COSTS}>
        <PermissionGates.Has
          requiredPermission={PERMISSIONS.DATA_MANAGEMENT.ACTIVITY_COSTS_VIEW}
        >
          <div style={{ marginRight: NabTabLinkMargin }}>
            <NavTabLink
              title="Costs"
              to={ALL_SETS_ROUTES.COSTS}
              onClickEvent="Data Manager - Clicked Costs"
            />
          </div>
        </PermissionGates.Has>
      </FeatureGate>
      <FeatureGate featureName={FEATURE_GATES.ENTITY_DETAILS}>
        <div style={{ marginRight: NabTabLinkMargin }}>
          <NavTabLink title="Entities" isBeta to={ALL_SETS_ROUTES.ENTITIES} />
        </div>
      </FeatureGate>
    </Row>
  </Wrapper>
);

export default TopNavigator;
