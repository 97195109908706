import aguid from 'aguid';

const keywordFilterToDrillDown = (
  keywordFilter: KeywordFilter,
): ReportDrillDownType => {
  return {
    id: aguid(),
    field: keywordFilter.field,
    fieldType: 'text',
    mode: 'plate',
    keywordValues: keywordFilter.values,
    isExclude: keywordFilter.exclude,
    keywordsExists: keywordFilter.exists,
  } as ReportDrillDownType;
};

export default keywordFilterToDrillDown;
