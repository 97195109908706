import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import OmniSearch from './OmniSearch';
import multiFieldSearch from '../../api/multiFieldSearch';
import ReportDrillDownsContext from '../../contexts/ReportDrillDownsContext';
import aguid from 'aguid';
import usePopup from '../../hooks/usePopup';
import toDrillDowns from '../Report/toReportDrillDown';
import VariableFiltersContext from '../../contexts/VariableFiltersContext';
import useFieldDefinitions from '../../hooks/useFieldDefinitions';
import GqlClientContext from '../../contexts/GqlClientContext';
import filterPlateTypeCheckers from '../../types/filterPlateTypeCheckers';
import useDateScope from '../../hooks/useDateScope';
import drillDownTypeCheckers from '../ReportDrillDownForm/drilldownTypeCheckers';

const DrillDownBuilder = ({
  dataTypes,
  onManualFilterChanged,
}: {
  dataTypes: string[];
  onManualFilterChanged?: () => void;
}) => {
  const { client } = useContext(GqlClientContext);
  const { drillDowns, setDrillDowns } = useContext(ReportDrillDownsContext);
  const { variableFilters } = useContext(VariableFiltersContext);
  const { fieldDefinitions } = useFieldDefinitions(dataTypes);
  const dateScope = useDateScope({});

  const { isOpen, open, close } = usePopup();

  const [searchText, setSearchText] = useState<string>('');
  const [searchFields, setSearchFields] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<MultiFieldSearchResults>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    open();
  };

  useEffect(() => {
    setSearchFields(
      fieldDefinitions
        .filter((f) => f.type === 'text' && f.field !== 'id')
        .map((f) => f.field),
    );
  }, [fieldDefinitions]);

  useEffect(() => {
    setIsLoading(true);
    if (searchText !== '') {
      multiFieldSearch({
        dataTypes,
        fields: searchFields,
        value: searchText,
        dateScope,
        client,
      }).then((results) => {
        setSearchResults(results);
        setIsLoading(false);
      });
    } else {
      setSearchResults(undefined);
      setIsLoading(false);
    }
  }, [client, dataTypes, dateScope, searchFields, searchText]);

  const onSearchItemClicked = (field: string, value: string) => {
    const ddrillDowns = toDrillDowns({
      plates: drillDowns,
      variableDrillDowns: variableFilters,
    });
    const existingDrillDown = ddrillDowns.find((d) => d.field === field);
    const isRemoving =
      selectedSearchResults[field] &&
      selectedSearchResults[field].includes(value);

    if (existingDrillDown) {
      const updatedDrillDown = (() => {
        const existingKeywordValues = (() => {
          if (drillDownTypeCheckers.isTextDrillDown(existingDrillDown)) {
            return existingDrillDown.keywordValues;
          }

          return [];
        })();
        if (isRemoving) {
          const wip = {
            ...existingDrillDown,
            keywordValues: existingKeywordValues.filter((v) => v !== value),
          };
          if (wip.keywordValues.length === 0) {
            return undefined;
          } else {
            return wip;
          }
        } else {
          return {
            ...existingDrillDown,
            keywordValues: [...existingKeywordValues, value],
          };
        }
      })();

      const updateDrillDownPlate = updatedDrillDown
        ? ({
            id: updatedDrillDown.id,
            type: 'Fixed',
            fixedValue: updatedDrillDown,
          } as FilterPlate)
        : undefined;

      if (updatedDrillDown) {
        setDrillDowns(
          drillDowns.map((d) => {
            if (
              updateDrillDownPlate &&
              filterPlateTypeCheckers.isFixed(d) &&
              d.fixedValue.field === field
            ) {
              return updateDrillDownPlate;
            }
            return d;
          }),
        );
      } else {
        setDrillDowns(
          drillDowns.filter((d) => {
            if (
              filterPlateTypeCheckers.isFixed(d) &&
              d.fixedValue.field === field
            ) {
              return false;
            }
            return true;
          }),
        );
      }
    } else {
      const newDrillDown = {
        id: aguid(),
        field,
        fieldType: 'text',
        mode: 'editing',
        keywordValues: [value],
      } as ReportDrillDownType;
      const newPlate = {
        id: newDrillDown.id,
        type: 'Fixed',
        fixedValue: newDrillDown,
      } as FilterPlate;
      setDrillDowns([...drillDowns, newPlate]);
    }
    if (onManualFilterChanged) {
      onManualFilterChanged();
    }
  };

  const selectedSearchResults = {} as MultiFieldSearchResults;
  drillDowns.forEach((d) => {
    if (
      filterPlateTypeCheckers.isFixed(d) &&
      drillDownTypeCheckers.isTextDrillDown(d.fixedValue)
    ) {
      if (selectedSearchResults[d.fixedValue.field]) {
        selectedSearchResults[d.fixedValue.field] = [
          ...selectedSearchResults[d.fixedValue.field],
          ...(d.fixedValue.keywordValues || []),
        ];
      } else {
        selectedSearchResults[d.fixedValue.field] =
          d.fixedValue.keywordValues || [];
      }
    }
  });

  return (
    <OmniSearch
      searchText={searchText}
      onSearchTextChanged={onSearchTextChanged}
      searchFields={searchFields}
      searchResults={searchResults}
      isLoading={isLoading}
      onSearchItemClicked={onSearchItemClicked}
      selectedSearchResults={selectedSearchResults}
      isOpen={isOpen}
      close={close}
      autoFocus={true}
    />
  );
};

export default DrillDownBuilder;
