import React, { ChangeEvent } from 'react';

import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';
import ShadowedList from 'components/ShadowedList';
import { LabelDiv } from 'screens/Boards/BoardCreationWizard';
import DatasetFieldPicker from '../../DatasetFieldPicker';
import EmptyVis from '../../Sections/EmptyVis';
import Properties from '../../Sections/Properties';
import WidgetFooter from '../../WidgetFooter';
import { WidgetWrapper } from '../MetricSlideoutWizard';

const PropertiesSlideoutWizard = ({
  saveWidget,
  title,
  onTitleChanged,
  fields,
  setFields,
  dataset,
  setDataset,
  isDisabled,
  filterInput,
  propertiesSection,
  close,
}: {
  saveWidget: () => void;
  title: string;
  onTitleChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  fields: string[];
  setFields: React.Dispatch<React.SetStateAction<string[]>>;
  dataset: string | undefined;
  setDataset: React.Dispatch<React.SetStateAction<string | undefined>>;
  isDisabled: boolean;
  filterInput: FilterInput | undefined;
  propertiesSection:
    | PerformanceBoardTypes.SlideOut.PropertiesSection
    | undefined;
  close: () => void;
}) => {
  return (
    <>
      <ShadowedList>
        <WidgetWrapper>
          <div style={{ marginBottom: 24 }}>
            <LabelDiv>
              <Typography.Body type="Label">Widget Title</Typography.Body>
            </LabelDiv>
            <Inputs.TextInput
              value={title}
              placeholder="Enter Title"
              onChange={onTitleChanged}
              maxLength={40}
              autoFocus
              data-testid={'title'}
            />
          </div>
          <DatasetFieldPicker
            fields={fields}
            setFields={setFields}
            dataset={dataset}
            setDataset={setDataset}
          />
          {isDisabled || !filterInput || !propertiesSection ? (
            <EmptyVis />
          ) : (
            <>
              <LabelDiv>
                <Typography.Body type="Label">Preview</Typography.Body>
              </LabelDiv>
              <Properties section={propertiesSection} />
            </>
          )}
        </WidgetWrapper>
      </ShadowedList>

      <WidgetFooter
        saveWidget={saveWidget}
        sectionType="Properties"
        isDisabled={isDisabled}
        close={close}
      />
    </>
  );
};

export default PropertiesSlideoutWizard;
