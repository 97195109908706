import React from 'react';

interface ReportDrillDownsContextType {
  drillDowns: FilterPlate[];
  setDrillDowns: React.Dispatch<React.SetStateAction<FilterPlate[]>>;
  netDrillDowns: ReportDrillDownType[];
  scope: FilterPlate[];
  setScope: React.Dispatch<React.SetStateAction<FilterPlate[]>>;
  netScope: ReportDrillDownType[];
  dataTypes?: string[];
  isForDashboardCard?: boolean;
  quickFilters: FilterInput[];
}

const ReportDrillDownsContext =
  React.createContext<ReportDrillDownsContextType>({
    drillDowns: [] as FilterPlate[],
    scope: [] as FilterPlate[],
    quickFilters: [] as FilterInput[],
    netScope: [] as ReportDrillDownType[],
    netDrillDowns: [] as ReportDrillDownType[],
  } as ReportDrillDownsContextType);

export default ReportDrillDownsContext;
