import React from 'react';
import Dropdown from '../Inputs/Dropdown';

const GeneralPeriodSelector = ({
  options,
  selectedPeriod,
  onDatePickerOpen,
  onDatePickerClose,
}: {
  options: DropdownOption[];
  selectedPeriod?: Period;
  onDatePickerOpen?: () => void;
  onDatePickerClose?: () => void;
}) => (
  <>
    <Dropdown
      onOpen={onDatePickerOpen}
      onClose={onDatePickerClose}
      selectedLabel={selectedPeriod ? selectedPeriod.label : undefined}
      options={options}
    />
  </>
);

export default GeneralPeriodSelector;
