import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';

import BaseViewsContext from 'contexts/BaseViewsContext';
import DatasetDefinitionsContext from 'contexts/DatasetDefinitionsContext';
import BoardContext from 'contexts/BoardContext';
import PerformanceBoardSettingsContext from '../../contexts/PerformanceBoardSettingsContext';
import useEntityField from 'components/TargetManager/hooks/useEntityField';
import { toPlates } from 'screens/Boards/BoardCreationWizard';
import isPerformanceBoard from 'isPerformanceBoard';
import useUpdateGeneral from '../hooks/useUpdateGeneral';
import fiToDrillDowns from 'screens/DataManager/Metrics/MetricPopup/MetricForm/NormalMetricForm/fiToDrillDowns';
import buildFilterInput from 'utils/buildFilterInput';
import toDrillDowns from 'components/Report/toReportDrillDown';
import BoardGeneralSettings from './BoardGeneralSettings';

const BoardGeneralSettingsContainer = ({
  board,
}: {
  board: PerformanceBoardTypes.Board;
}) => {
  const { datasets } = useContext(DatasetDefinitionsContext);
  const [title, setTitle] = useState<string>(board.name);
  const [boardFilters, setBoardFilters] = useState<FilterInput>(
    board.filterInput,
  );
  const entityField = useEntityField(board.dataType);
  const { hasUnsavedChanges, setHasUnsavedChanges } = useContext(
    PerformanceBoardSettingsContext,
  );
  const [defaultSort, setDefaultSort] = useState<SortField>(
    board.defaultSort
      ? {
          field: board.defaultSort.field,
          sort: board.defaultSort.direction,
        }
      : {
          field: entityField || '',
          sort: 'asc' as 'asc',
        },
  );
  const [drillDowns, setDrillDowns] = useState<FilterPlate[]>(() =>
    board
      ? toPlates(
          fiToDrillDowns(board.filterInput ? board.filterInput : {}, datasets),
        )
      : [],
  );

  useEffect(() => {
    const newBoardFilters = buildFilterInput({
      scopes: [],
      drillDowns: toDrillDowns({
        plates: drillDowns,
        variableDrillDowns: [],
      }),
    });

    const definedBoardFilters = _.omitBy(
      {
        ...newBoardFilters,
        keywords: newBoardFilters.keywords
          ? newBoardFilters.keywords.map((filter) => _.omitBy(filter, _.isNil))
          : undefined,
      },
      _.isNil,
    );

    setBoardFilters(definedBoardFilters);
  }, [drillDowns]);

  useEffect(() => {
    if (
      title !== board.name ||
      !_.isEqual(
        { direction: defaultSort.sort, field: defaultSort.field },
        board.defaultSort,
      ) ||
      !_.isEqual(boardFilters, board.filterInput)
    ) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [board, boardFilters, defaultSort, setHasUnsavedChanges, title]);

  const { baseViews } = useContext(BaseViewsContext);
  const baseView = baseViews[board.dataType];
  const updateGeneral = useUpdateGeneral();

  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setTitle(event.target.value);
  };

  const fieldOptions =
    baseView && baseView.fieldOrder
      ? baseView.fieldOrder.map((field) => ({
          label: field,
          value: field,
          isSelected: defaultSort.field === field,
          onSelected: () => {
            setDefaultSort({ ...defaultSort, field });
          },
        }))
      : undefined;

  const getSortCode = ({ sort }: { sort: string }) => {
    return sort === 'Ascending' ? ('asc' as 'asc') : ('desc' as 'desc');
  };

  const sortOptions = ['Ascending', 'Descending'].map((sort) => ({
    label: sort,
    value: sort,
    isSelected: defaultSort.sort === getSortCode({ sort }),
    onSelected: () => {
      setDefaultSort({ ...defaultSort, sort: getSortCode({ sort }) });
    },
  }));

  const handleSave = useCallback(() => {
    updateGeneral({
      name: title,
      field: defaultSort.field,
      direction: defaultSort.sort,
      filterInput: boardFilters,
    });
  }, [boardFilters, defaultSort.field, defaultSort.sort, title, updateGeneral]);

  return (
    <BoardGeneralSettings
      board={board}
      baseView={baseView}
      title={title}
      onTitleChanged={onTitleChanged}
      fieldOptions={fieldOptions}
      sortOptions={sortOptions}
      drillDowns={drillDowns}
      setDrillDowns={setDrillDowns}
      hasUnsavedChanges={hasUnsavedChanges}
      handleSave={handleSave}
    />
  );
};

const Gate = () => {
  const { board } = useContext(BoardContext);
  if (isPerformanceBoard(board)) {
    return <BoardGeneralSettingsContainer board={board} />;
  } else {
    return null;
  }
};

export default Gate;
