import { useCallback, useContext } from 'react';
import UsersContext from '../../../contexts/UsersContext';
import userTypeCheckers from '../../../contextProviders/UsersProvider/userTypeCheckers';

const useExportUsers = () => {
  const { allUsers } = useContext(UsersContext);

  const buildCsv = useCallback(() => {
    const headers = `email\r\n`;
    const emails = allUsers
      .filter(userTypeCheckers.isSignedUpOrPendingUser)
      .filter((u) => !u.isDeleted)
      .map((u) => u.email);

    const content = emails.join('\r\n');
    return `${headers}${content}`;
  }, [allUsers]);

  const onExportClicked = useCallback(() => {
    // Create a blob
    const blob = new Blob([buildCsv()], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'fleetops-users.csv');
    pom.click();
  }, [buildCsv]);

  return onExportClicked;
};

export default useExportUsers;
