import { useCallback, useContext, useEffect, useState } from 'react';
import useSavedFilter from './useSavedFilter';
import aggregateMetricMatrix from '../api/aggregateMetricMatrix';
import toFilterInput from '../toFilterInput';
import mergeFilterInputs from '../mergeFilterInputs';
import toDrillDowns from '../components/Report/toReportDrillDown';
import { toFilterPlate } from '../migrateScope';
import useMetric from './useMetric';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import GqlClientContext from '../contexts/GqlClientContext';
import useDateScope from './useDateScope';
import DateInputContext from '../contexts/DateInputContext';
import metricTypeCheckers from '../types/metricTypeCheckers';
import CompoundMetricsContext from '../contexts/CompoundMetricsContext';
import VariableFiltersContext from '../contexts/VariableFiltersContext';
import EntityDetailsContext from '../screens/EntityDetailsShow/EntityDetailsContext';
import useLockedDebouncedEffect from './useLockedDebouncedEffect';

interface GetMetricListItemArgs {
  client: ApolloClient<NormalizedCacheObject>;
  metrics: (Metrics.NormalMetric | Metrics.SpecialMetric)[];
  expressions: Expression2[];
  filterInput: FilterInput;
  currentDateScope: DateRangeInput;
  previousDateScope?: DateRangeInput;
  popupReportFilter: UnSavedFilter; // For the popup report filter
}

const createPopupReportFilter = ({
  savedFilter,
  comparison,
  dateField,
  variableDrillDowns,
  dateScope,
  dateRange,
  relativeDateRange,
  advancedRelativeDateRange,
  entityFilterPlates,
  isEntityFilterEnabled,
}: {
  savedFilter?: SavedFilter;
  comparison?: PersistedComparisonType;
  dateField: string;
  variableDrillDowns: VariableDrillDownType[];
  dateScope: DateRangeInput;
  dateRange?: DateRangeInput;
  relativeDateRange?: RelativeDateRange;
  advancedRelativeDateRange?: AdvancedRelativeDateRange;
  entityFilterPlates?: FilterPlate[];
  isEntityFilterEnabled?: boolean;
}): UnSavedFilter => {
  const savedFilterPlates = savedFilter ? savedFilter.scope : window.emptyArray;
  const scorePlates = [
    ...savedFilterPlates,
    ...(isEntityFilterEnabled && entityFilterPlates
      ? entityFilterPlates
      : window.emptyArray),
  ];

  return {
    drillDowns: toDrillDowns({
      plates: savedFilter ? savedFilter.drillDowns : window.emptyArray,
      variableDrillDowns,
    }).map(toFilterPlate),
    mustExistFilters: savedFilter ? savedFilter.mustExistFilters : undefined,
    mustNotExistFilters: savedFilter
      ? savedFilter.mustNotExistFilters
      : undefined,
    comparison,
    scope: toDrillDowns({
      plates: scorePlates,
      variableDrillDowns,
    }).map(toFilterPlate),
    dateScope,
    dateField,
    dateRange,
    relativeDateRange,
    advancedRelativeDateRange,
  };
};

const useMetricListItemArgs = ({
  item,
  comparison,
}: {
  item?: MetricListItemType;
  comparison?: PersistedComparisonType;
}) => {
  // Context
  const { client } = useContext(GqlClientContext);
  const { getCompoundMetricWithMetricDefs } = useContext(
    CompoundMetricsContext,
  );
  const { variableFilters } = useContext(VariableFiltersContext);
  const entityDetailsContext = useContext(EntityDetailsContext);
  const { dateField, dateRange, relativeDateRange, advancedRelativeDateRange } =
    useContext(DateInputContext);

  // Hooks
  const { savedFilter } = useSavedFilter(item ? item.savedFilterId : undefined);
  const metric = useMetric(item ? item.metricId : undefined);
  const currentDateScope = useDateScope({ isMetricListItem: true });
  const previousDateScope = useDateScope({
    comparison,
    isMetricListItem: true,
  });

  // Callbacks
  const getFilterInput = useCallback((): FilterInput => {
    if (!savedFilter || !item) {
      return {};
    }

    const baseFilter = toFilterInput(savedFilter, variableFilters);

    if (entityDetailsContext && item.isEntityFilterEnabled) {
      return mergeFilterInputs(baseFilter, entityDetailsContext.entityFilter);
    }

    return baseFilter;
  }, [entityDetailsContext, item, savedFilter, variableFilters]);

  const getMetrics = useCallback((): (
    | Metrics.NormalMetric
    | Metrics.SpecialMetric
  )[] => {
    if (!metric) {
      return [];
    }
    if (metricTypeCheckers.isCompoundMetric(metric)) {
      const withDefs = getCompoundMetricWithMetricDefs(metric.id);
      if (!withDefs) {
        return [];
      }
      return withDefs.metrics;
    }

    return [metric];
  }, [getCompoundMetricWithMetricDefs, metric]);
  const getExpressions = useCallback((): Expression2[] => {
    if (!metric) {
      return [];
    }
    if (metricTypeCheckers.isCompoundMetric(metric)) {
      return [
        {
          id: metric.id,
          expression: metric.expression,
        },
      ];
    }
    return [];
  }, [metric]);

  // State
  const [args, setArgs] = useState<GetMetricListItemArgs | undefined>();
  // Effects
  useEffect(() => {
    if (!metric || !savedFilter) {
      setArgs(undefined);
      return;
    }

    const popupReportFilter = createPopupReportFilter({
      savedFilter,
      dateScope: currentDateScope,
      comparison,
      dateField,
      variableDrillDowns: variableFilters,
      dateRange,
      relativeDateRange,
      advancedRelativeDateRange,
      entityFilterPlates: entityDetailsContext
        ? entityDetailsContext.entityFilterAsFilterPlates
        : window.emptyArray,
      isEntityFilterEnabled: item ? item.isEntityFilterEnabled : false,
    });

    setArgs({
      client,
      metrics: getMetrics(),
      expressions: getExpressions(),
      filterInput: getFilterInput(),
      currentDateScope,
      previousDateScope: comparison ? previousDateScope : undefined,
      popupReportFilter,
    });
  }, [
    advancedRelativeDateRange,
    client,
    comparison,
    currentDateScope,
    dateField,
    dateRange,
    entityDetailsContext,
    getExpressions,
    getFilterInput,
    getMetrics,
    item,
    metric,
    previousDateScope,
    relativeDateRange,
    savedFilter,
    variableFilters,
  ]);

  return args;
};

const useMetricListItem = (
  item?: MetricListItemType,
  isBonusPeriodMode?: boolean,
  comparison?: PersistedComparisonType,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [current, setCurrent] = useState<number | null | undefined>();
  const [previous, setPrevious] = useState<number | null | undefined>();
  const [popupReportFilter, setPopupReportFilter] = useState<
    UnSavedFilter | undefined
  >();
  const args = useMetricListItemArgs({ item, comparison });
  const handleResponse = useCallback(
    ({
      currentResponse,
      previousResponse,
      popupReportFilter,
    }: {
      currentResponse: MetricsResponse;
      previousResponse: MetricsResponse | undefined;
      popupReportFilter: UnSavedFilter;
    }) => {
      if (!item) {
        return;
      }
      setIsLoading(false);
      setCurrent(currentResponse[0][item.metricId]);
      setPrevious(
        previousResponse ? previousResponse[0][item.metricId] : undefined,
      );
      setPopupReportFilter(popupReportFilter);
    },
    [item],
  );

  const getMetricResponses = useCallback(
    async ({
      client,
      metrics,
      expressions,
      filterInput,
      currentDateScope,
      previousDateScope,
      popupReportFilter,
    }: GetMetricListItemArgs) => {
      setIsLoading(true);
      const currentMetricRequest = aggregateMetricMatrix({
        client,
        metrics,
        expressions,
        filterInput: [filterInput],
        dateScope: currentDateScope,
      });
      const previousMetricRequest = previousDateScope
        ? aggregateMetricMatrix({
            client,
            metrics,
            expressions,
            filterInput: [filterInput],
            dateScope: previousDateScope,
          })
        : Promise.resolve(undefined);

      const [currentResponse, previousResponse] = await Promise.all([
        currentMetricRequest,
        previousMetricRequest,
      ]);

      return {
        currentResponse,
        previousResponse,
        popupReportFilter,
      };
    },
    [],
  );

  useLockedDebouncedEffect({
    args,
    responseHandler: handleResponse,
    callback: getMetricResponses,
  });

  return {
    isLoading,
    current,
    currentFilterInput: args ? args.filterInput : undefined,
    previous,
    previousFilterInput: args ? args.filterInput : undefined,
    popupReportFilter,
    currentDateRange: args ? args.currentDateScope : undefined,
    previousDateRange: args ? args.previousDateScope : undefined,
  };
};

export default useMetricListItem;
