import useFieldLabel from './useFieldLabel';
import Column from '../../DatasetDefinitions/Column';
import Typography from 'kingpin/atoms/Typography';
import React from 'react';
import Tooltip from '../../../../components/Tooltip';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';
import useOriginMetric from '../../../../hooks/useOriginMetric';
import { Link } from 'react-router-dom';
import { getMetricLink } from '../../AllSets/routes';

const NormalFieldLabelColumn = ({
  metric,
}: {
  metric: Metrics.NormalMetric;
}) => {
  const originMetric = useOriginMetric(metric);
  const fieldLabel = useFieldLabel(metric);

  if (originMetric) {
    return (
      <Column style={{ flex: 2 }}>
        <Link to={getMetricLink(originMetric)}>
          <Typography.Body type="Body 12">{fieldLabel}</Typography.Body>
        </Link>
      </Column>
    );
  } else {
    return (
      <Column style={{ flex: 2 }}>
        <Tooltip content={fieldLabel}>
          <Typography.Body type="Body 12">{fieldLabel}</Typography.Body>
        </Tooltip>
      </Column>
    );
  }
};

const CompoundFieldLabelColumn = ({
  metric,
}: {
  metric: Metrics.CompoundMetric;
}) => {
  const fieldLabel = useFieldLabel(metric);

  return (
    <Column style={{ flex: 2 }}>
      <Tooltip content={fieldLabel}>
        <Typography.Body type="Body 12">{fieldLabel}</Typography.Body>
      </Tooltip>
    </Column>
  );
};

const FieldLabelColumn = ({
  metric,
}: {
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
}) => {
  if (metricTypeCheckers.isNormalMetric(metric)) {
    return <NormalFieldLabelColumn metric={metric} />;
  } else {
    return <CompoundFieldLabelColumn metric={metric} />;
  }
};

export default FieldLabelColumn;
