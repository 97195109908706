import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import {
  ColDef,
  Column,
  ICellRendererParams,
  IServerSideDatasource,
} from 'ag-grid-community';
import Cell from './V5Gadget/Matrix/Cell';

import Colors2 from '../theme/Colors2';
import BonusPeriodsContext from '../contexts/BonusPeriodsContext';
import Tooltip from './Tooltip';
import DriverSummarySection from './BoardSlideOut/DriverSummarySection';
import AnalyticsContext from '../contexts/AnalyticsContext';
import DriverScoreContext from '../contexts/DriverScoreContext';
import GridDataSourceContext from '../contexts/GridDataSourceContext';
import useDriverBonusFeedback, {
  buildDriverScoreExpectations,
} from '../hooks/useDriverBonusFeedback';
import CheckSrc from '../images/statusCheck.png';
import CrossSrc from '../images/statusCross.png';
import Icon from '../kingpin/atoms/Icon';

const Img = styled.img`
  width: 1em;
  height: 1em;
`;

const isDataSource = (
  ds: undefined | DataSource | IServerSideDatasource,
): ds is DataSource => {
  // @ts-ignore
  return ds && ds.documents;
};

const useHasTooltip = ({
  category,
  driver,
  bonusPeriod,
}: {
  category: string;
  driver: string;
  bonusPeriod?: BonusPeriod;
}) => {
  const { data } = useDriverBonusFeedback(category, driver, bonusPeriod);
  return data.length > 0;
};

const StatusTooltip = ({
  children,
  category,
  driver,
  bonusPeriod,
  onTooltipRead,
}: {
  children: JSX.Element | JSX.Element[];
  category: string;
  driver: string;
  bonusPeriod?: BonusPeriod;
  onTooltipRead: () => void;
}) => {
  const hasTooltip = useHasTooltip({
    category: category || '',
    driver,
    bonusPeriod,
  });

  if (hasTooltip && bonusPeriod) {
    return (
      <Tooltip
        onLongOpenClosed={onTooltipRead}
        isAltTooltip
        content={
          <DriverSummarySection
            category={category}
            driver={driver}
            bonusPeriod={bonusPeriod}
            isTooltip
          />
        }
      >
        <>{children}</>
      </Tooltip>
    );
  } else {
    return <>{children}</>;
  }
};

const StatusFlagCell = ({
  params,
  colDef,
  column,
}: {
  params: ICellRendererParams;
  colDef: ColDef;
  column: Column;
}) => {
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { dataSource } = useContext(GridDataSourceContext);

  const { value, node } = params;

  const category = colDef ? colDef.field : '';
  const driver = node.data.driver;

  const onTooltipRead = useCallback(() => {
    if (!selectedBonusPeriod || !category || !driver) {
      return;
    }

    trackEvent('Driver Bonus - Bonus summary tooltip opened', {
      category,
      driver,
      value,
    });
  }, [category, driver, selectedBonusPeriod, trackEvent, value]);

  if (!colDef || !params.column) {
    return null;
  }

  if (node.rowPinned) {
    if (isDataSource(dataSource)) {
      const field = column.getColId();
      const passed = dataSource.documents.filter((d) => d[field]).length;
      return <Cell>{passed.toString()}</Cell>;
    }
    return <Cell />;
  }

  if (!selectedBonusPeriod || !category || !driver) {
    return <Cell />;
  }

  if (value === undefined || value === null || value === '-') {
    return <Cell rightAlign>-</Cell>;
  }

  const expectations = buildDriverScoreExpectations(
    category,
    driver,
    selectedBonusPeriod,
    node.data,
  );
  const isUnsure = expectations.some((e) => e.actualValue === null);

  if (isUnsure) {
    return (
      // @ts-ignore
      <Cell rightAlign>
        <StatusTooltip
          onTooltipRead={onTooltipRead}
          driver={driver}
          bonusPeriod={selectedBonusPeriod}
          category={category}
        >
          <Icon icon="minus" color={Colors2.Secondary.warning} />
        </StatusTooltip>
      </Cell>
    );
  } else if (
    value === 0 ||
    value === '0' ||
    value === '-' ||
    value === null ||
    value === undefined
  ) {
    return (
      // @ts-ignore
      <Cell rightAlign>
        <StatusTooltip
          onTooltipRead={onTooltipRead}
          driver={driver}
          bonusPeriod={selectedBonusPeriod}
          category={category}
        >
          <Img src={CrossSrc} />
        </StatusTooltip>
      </Cell>
    );
  } else if (value === 1 || value === '1' || value === ' 1') {
    return (
      // @ts-ignore
      <Cell rightAlign>
        <StatusTooltip
          onTooltipRead={onTooltipRead}
          driver={driver}
          bonusPeriod={selectedBonusPeriod}
          category={category}
        >
          <Img src={CheckSrc} />
        </StatusTooltip>
      </Cell>
    );
  } else {
    // @ts-ignore
    return (
      <Cell rightAlign>
        <span>{value}</span>
      </Cell>
    );
  }
};

const Gate = (params: ICellRendererParams) => {
  const { colDef, column } = params;
  if (!colDef || !column) {
    return <Cell />;
  }
  return (
    <DriverScoreContext.Provider
      value={{
        driverScore: params.node.data,
        setDriverScore: () => {
          console.warn('setDriverScoreQuarter is unavailable');
        },
      }}
    >
      <StatusFlagCell params={params} colDef={colDef} column={column} />
    </DriverScoreContext.Provider>
  );
};

export default Gate;
