import React from 'react';

import useMetricUsageReport from '../../../../../hooks/useMetricUsageReport';
import ReportUsage from './ReportUsage';
import DashboardGadgetUsage from './DashboardGadgetUsage';
import ScorecardUsage from './ScorecardUsage';
import GoalsUsage from './Goals';
import ChartDefinitionUsage from './ChartDefinitionUsage';
import BoardsUsage from './BoardsUsage';
import CompoundMetricsUsage from './CompoundMetricsUsage';
import PerformanceUsage from './PerformanceUsage';
import metricTypeCheckers from '../../../../../types/metricTypeCheckers';

const UsageTab = ({
  metric,
}: {
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
}) => {
  const usageReport = useMetricUsageReport(metric);

  if (!usageReport) {
    return null;
  }

  const {
    chartDefinitions,
    reports,
    scorecards,
    goals,
    boards,
    dashboardGadgets,
    compoundMetrics,
    performanceConfigurations,
  } = usageReport;

  return (
    <div>
      <ReportUsage reports={reports} metric={metric} />
      <DashboardGadgetUsage
        dashboardGadgets={dashboardGadgets}
        metric={metric}
      />
      <ScorecardUsage scorecards={scorecards} metric={metric} />
      <GoalsUsage goals={goals} metric={metric} />
      <ChartDefinitionUsage
        chartDefinitions={chartDefinitions}
        metric={metric}
      />
      <BoardsUsage boards={boards} metric={metric} />
      {metricTypeCheckers.isNormalMetric(metric) && (
        <CompoundMetricsUsage
          compoundMetrics={compoundMetrics}
          metric={metric}
        />
      )}
      <PerformanceUsage
        performanceConfigs={performanceConfigurations}
        metric={metric}
      />
    </div>
  );
};

export default UsageTab;
