import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import * as aguid from 'aguid';
import { useNavigate } from 'react-router-dom';

import GaugeForm from './GaugeForm';
import { buildGadgetShow } from '../../navigation/appRoutes';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import STORE from '../../store';

const saveGadget = async (
  gadget: VisualisationDefinitions.Gauge,
  accountId: string,
) => {
  return STORE.visualisations
    .getGaugesRef({ accountId })
    .doc(gadget.id)
    .set(gadget);
};

const GaugeFormContainer = ({
  gauge,
  isGadgetBuilder,
  onChange,
}: {
  gauge?: VisualisationDefinitions.Gauge;
  isGadgetBuilder?: boolean;
  onChange?: React.Dispatch<
    React.SetStateAction<undefined | VisualisationDefinition>
  >;
}) => {
  const navigate = useNavigate();
  const { selectedAccountId } = useContext(AccountPickerContext);

  // Base
  const [id] = useState(gauge ? gauge.id : aguid());
  const [name, setName] = useState<string>(gauge ? gauge.name : '');
  const [description, setDescription] = useState<string>(
    gauge ? gauge.description : '',
  );
  // Gauge specific
  const [metricId, setMetricId] = useState<string | undefined>(
    gauge ? gauge.metricId : undefined,
  );
  const [metricDisplayName, setMetricDisplayName] = useState<string>(
    gauge ? gauge.metricDisplayName : '',
  );
  const [peerGroup, setPeerGroup] = useState<string | undefined>(
    gauge ? gauge.peerGroup : '',
  );
  const [entity, setEntity] = useState<string | undefined>(
    gauge ? gauge.entity : '',
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [draftGauge, setDraftGauge] = useState<
    VisualisationDefinitions.Gauge | undefined
  >();

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onMetricDisplayNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setMetricDisplayName(event.target.value);
  };

  const onDescriptionChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const isValid = name !== '' && metricId !== '' && !!entity && !!peerGroup;

  useEffect(() => {
    const newDraftGauge = ((): VisualisationDefinitions.Gauge | undefined => {
      if (
        name !== '' &&
        metricId !== '' &&
        !!metricId &&
        !!entity &&
        !!peerGroup
      ) {
        return {
          id,
          type: 'Gauge',
          name,
          description,
          metricId,
          metricDisplayName,
          peerGroup,
          entity,
        };
      }

      return undefined;
    })();

    setDraftGauge(newDraftGauge);
  }, [description, entity, id, metricDisplayName, metricId, name, peerGroup]);

  useEffect(() => {
    if (draftGauge && onChange) {
      onChange(draftGauge);
    }
  }, [draftGauge, onChange]);

  const onSave = useCallback(() => {
    if (draftGauge && isValid && isGadgetBuilder) {
      setIsLoading(true);
      saveGadget(draftGauge, selectedAccountId).then(() => {
        setIsLoading(false);
        navigate(buildGadgetShow(id));
      });
    }
  }, [draftGauge, navigate, id, isGadgetBuilder, isValid, selectedAccountId]);

  return (
    <GaugeForm
      isGadgetBuilder={isGadgetBuilder}
      name={name}
      onNameChanged={onNameChanged}
      description={description}
      onDescriptionChanged={onDescriptionChanged}
      metricId={metricId}
      setMetricId={setMetricId}
      metricDisplayName={metricDisplayName}
      onMetricDisplayNameChanged={onMetricDisplayNameChanged}
      setMetricDisplayName={setMetricDisplayName}
      peerGroup={peerGroup}
      setPeerGroup={setPeerGroup}
      entity={entity}
      setEntity={setEntity}
      isValid={isValid}
      onSave={onSave}
      isLoading={isLoading}
      draftGauge={draftGauge}
    />
  );
};

export default GaugeFormContainer;
