import React, { ChangeEvent } from 'react';
import Button from 'kingpin/atoms/Button';
import Row from 'components/Common/Row';
import Inputs from 'components/Inputs';
import Typography from 'kingpin/atoms/Typography';
import Dropdown from 'components/Inputs/Dropdown';
import ReportSelector from 'components/ConfigureDashboardGadget/ReportSelector';
import ManualTargets from '../ManualTargets';
import CurrencyTypeDropdown from 'components/FormattingInput/CurrencyTypeDropdown';
import Form from 'kingpin/forms/Form';
import FormHeader from '../../../../kingpin/forms/FormHeader';
import FormContent from '../../../../kingpin/forms/FormContent';

const ManualRowForm = ({
  manualKpi,
  close,
  isShowingAdvanced,
  onAdvancedToggled,
  formattingOptions,
  formatting,
  currencyType,
  setCurrencyType,
  saveRow,
  label,
  onNameChanged,
  reportDrillDownId,
  setReportDrillDownId,
  trackDrillDownAdded,
  targets,
  setTargets,
  setCanSaveKpi,
  isTargetsDisabled,
  setIsTargetsDisabled,
  isColorOrderReversed,
  setIsColorOrderReversed,
  setProrationOverride,
  dynamicTargetOverride,
  markHasUnsavedChanges,
  isValid,
}: {
  manualKpi?: Scorecards.ManualKpiRow;
  close: () => void;
  isShowingAdvanced: boolean;
  onAdvancedToggled: () => void;
  formattingOptions: {
    label: string;
    value: string;
    isSelected: boolean;
    onSelected: () => void;
  }[];
  formatting: Scorecards.ManualKpiRowFormat;
  currencyType: CurrencyType | undefined;
  setCurrencyType: React.Dispatch<
    React.SetStateAction<CurrencyType | undefined>
  >;
  saveRow: () => void;
  label: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  reportDrillDownId?: string;
  setReportDrillDownId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  trackDrillDownAdded: ({
    type,
    id,
    name,
  }: {
    type: 'Report' | 'Board';
    id: string;
    name: string;
  }) => void;
  targets: Scorecards.WeekTargets | Scorecards.IsoTargets;
  setTargets: React.Dispatch<
    React.SetStateAction<Scorecards.WeekTargets | Scorecards.IsoTargets>
  >;
  setCanSaveKpi: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTargetsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isTargetsDisabled: boolean;
  isColorOrderReversed: boolean;
  setIsColorOrderReversed: React.Dispatch<React.SetStateAction<boolean>>;
  setProrationOverride: (
    value: React.SetStateAction<boolean | undefined>,
  ) => void;
  dynamicTargetOverride: boolean | undefined;
  markHasUnsavedChanges: () => void;
  isValid: boolean;
}) => {
  return (
    <Form>
      <FormHeader
        title={manualKpi ? 'Edit Manual KPI' : 'Add A Manual KPI'}
        titleExtra={
          <Button
            label={isShowingAdvanced ? 'Go to Basic' : 'Go to Advanced'}
            onClick={onAdvancedToggled}
            type="Tertiary"
            size="Small"
          />
        }
        onClose={close}
      />
      <FormContent>
        <div style={{ marginBottom: 16 }}>
          <Typography.Body type="Label">Manual KPI Name:</Typography.Body>
          <Inputs.TextInput
            value={label}
            onChange={onNameChanged}
            data-testid="manual-name-input"
            inputSize="Small"
            width="100%"
          />
        </div>
        <div style={{ marginBottom: 16 }}>
          <Typography.Body type="Label">Formatting:</Typography.Body>
          <Dropdown options={formattingOptions} />
        </div>
        {formatting === 'Currency' && (
          <div style={{ marginBottom: 16 }}>
            <Typography.Body type="Label">Currency Type</Typography.Body>
            <CurrencyTypeDropdown
              currencyType={currencyType}
              setCurrencyType={setCurrencyType}
            />
          </div>
        )}
        <div style={{ marginBottom: 16 }}>
          <ReportSelector
            reportId={reportDrillDownId}
            setReportId={setReportDrillDownId}
            setBoardId={window.tokenFunction}
            label="Select drill down view (optional)"
            placeholder="Search available reports/boards"
            trackDrillDownAdded={trackDrillDownAdded}
            onChanged={markHasUnsavedChanges}
          />
        </div>
        <ManualTargets
          manualKpi={manualKpi}
          isShowingAdvanced={isShowingAdvanced}
          markHasUnsavedChanges={markHasUnsavedChanges}
          targets={targets}
          setTargets={setTargets}
          isTargetsDisabled={isTargetsDisabled}
          setIsColorOrderReversed={setIsColorOrderReversed}
          setProrationOverride={setProrationOverride}
          setIsTargetsDisabled={setIsTargetsDisabled}
          isColorOrderReversed={isColorOrderReversed}
          setCanSaveKpi={setCanSaveKpi}
          dynamicTargetOverride={dynamicTargetOverride}
        />
      </FormContent>

      <Row centerAlign spaceBetween style={{ marginTop: '24px' }}>
        <Button
          label={'Cancel'}
          onClick={close}
          type={'Tertiary'}
          size="Small"
        />
        <Button
          type="Primary"
          size="Small"
          onClick={saveRow}
          isDisabled={!isValid}
          label={manualKpi ? 'Save' : 'Add KPI'}
          testId="save-manual-form"
        />
      </Row>
    </Form>
  );
};

export default ManualRowForm;
