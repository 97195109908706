import { useContext, useEffect, useState } from 'react';

import CurrentUserContext from '../../contexts/CurrentUserContext';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import RolesContext from '../../contexts/RolesContext';
import useHasAccess from 'hooks/useHasAccess';
import STORE from '../../store';

const useReports = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reports, setReports] = useState<PersistedReportType[]>([]);
  const [allReports, setAllReports] = useState<PersistedReportType[]>([]);
  const currentUser = useContext(CurrentUserContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { currentPermissions } = useContext(RolesContext);
  const hasAccess = useHasAccess();

  useEffect(() => {
    const listener = STORE.getReportsRef({
      accountId: selectedAccountId,
    }).onSnapshot((snapshot) => {
      const data: PersistedReportType[] = [];
      snapshot.docs.forEach((d) => data.push(d.data()));
      setAllReports(data.filter((r) => !r.isHidden));
      setIsLoading(false);
    });
    return () => {
      listener();
    };
  }, [selectedAccountId]);

  useEffect(() => {
    const accessibleReports = allReports.filter((report) => {
      return hasAccess({
        access: report.access,
        resource: report,
      });
    });
    setReports(accessibleReports);
  }, [currentUser, allReports, currentPermissions, hasAccess]);

  return {
    reports,
    allReports,
    isLoading,
  };
};

export default useReports;
