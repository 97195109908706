const VIEW_ALL_PRIVATE: FleetOps.Permission =
  'fleetops.permissions.content_access_view_all_private';
const VIEW_ALL_USERS: FleetOps.Permission =
  'fleetops.permissions.content_access_view_all_users';
const VIEW_ALL_ADMIN_ONLY: FleetOps.Permission =
  'fleetops.permissions.content_access_view_all_admin_only';

const SET_ANY_AVAILABLE_TO_PRIVATE: FleetOps.Permission =
  'fleetops.permissions.content_access_set_any_available_to_private';
const SET_ANY_AVAILABLE_TO_ADMIN_ONLY: FleetOps.Permission =
  'fleetops.permissions.content_access_set_any_available_to_admin_only';
const SET_ANY_AVAILABLE_TO_USERS_OR_PUBLIC: FleetOps.Permission =
  'fleetops.permissions.content_access_set_any_available_to_users_or_public';

const CONTENT_ACCESS_PERMISSIONS = {
  VIEW_ALL_PRIVATE,
  VIEW_ALL_USERS,
  VIEW_ALL_ADMIN_ONLY,
  SET_ANY_AVAILABLE_TO_PRIVATE,
  SET_ANY_AVAILABLE_TO_ADMIN_ONLY,
  SET_ANY_AVAILABLE_TO_USERS_OR_PUBLIC,
};
Object.freeze(CONTENT_ACCESS_PERMISSIONS);

export default CONTENT_ACCESS_PERMISSIONS;
