import React from 'react';

import Typography from 'kingpin/atoms/Typography';
import TextInput from 'kingpin/atoms/TextInput';
import Button from 'kingpin/atoms/Button';
import {
  GadgetFormInner,
  GadgetFormInnerContent,
  GadgetFormInnerTopBar,
  GadgetFormWrapper,
} from 'components/V5GadgetForm/V5GadgetForm';
import Inputs from 'components/Inputs';
import Row from 'components/Common/Row';

import { PaceMatrixFormTypes } from './types';
import GroupByInput from '../GroupByInput';
import Preview from './Preview';
import LabeledSection from './LabeledSection';
import SortOrderInput from './SortOrderInput';

const EditingActions = ({
  onSave,
  onSaveAs,
  onDelete,
  isLoading,
}: {
  onSave: () => void;
  onSaveAs: () => void;
  onDelete: () => void;
  isLoading: boolean;
}) => (
  <Row spaceBetween>
    <Button
      size={'Small'}
      type={'Secondary'}
      label="Delete"
      onClick={onDelete}
      isLoading={isLoading}
    />
    <Row>
      <div style={{ marginRight: 8 }}>
        <Button
          size={'Small'}
          type={'Primary'}
          label="Save As"
          onClick={onSaveAs}
          isLoading={isLoading}
        />
      </div>
      <Button
        size={'Small'}
        type={'Primary'}
        label="Update"
        onClick={onSave}
        isLoading={isLoading}
      />
    </Row>
  </Row>
);

const NewActions = ({
  onSave,
  isLoading,
}: {
  onSave: () => void;
  isLoading: boolean;
}) => (
  <Row spaceBetween>
    <div />
    <Button
      size={'Small'}
      type={'Primary'}
      label="Save"
      onClick={onSave}
      isLoading={isLoading}
    />
  </Row>
);

const AGG_FUNC_GREEN_LIST: AggFunc[] = ['count', 'sum'];

const PaceMatrixForm = ({
  formState,
  isEditing,
  isGadgetBuilder,
  isLoading,
  onSave,
  onSaveAs,
  onDelete,
}: {
  formState: PaceMatrixFormTypes.FormState;
  isEditing: boolean;
  isGadgetBuilder: boolean;
  isLoading: boolean;
  onSave: () => void;
  onSaveAs: () => void;
  onDelete: () => void;
}) => (
  <GadgetFormWrapper>
    <GadgetFormInner isGadgetBuilder={isGadgetBuilder}>
      <GadgetFormInnerTopBar isGadgetBuilder={isGadgetBuilder}>
        <Typography.Header type="H4">Pace Matrix</Typography.Header>
      </GadgetFormInnerTopBar>
      <GadgetFormInnerContent isGadgetBuilder={isGadgetBuilder}>
        <LabeledSection label={'Name'}>
          <TextInput
            value={formState.name}
            onChange={formState.onNameChanged}
            placeholder="Name"
            data-testid={'pace-name'}
          />
        </LabeledSection>
        <LabeledSection label={'Description'}>
          <TextInput
            value={formState.description}
            onChange={formState.onDescriptionChanged}
            placeholder="Description"
          />
        </LabeledSection>
        <LabeledSection label={'Metric'}>
          <Inputs.MetricPicker
            metricId={formState.metricId}
            setMetricId={formState.setMetricId}
            aggFuncGreenList={AGG_FUNC_GREEN_LIST}
          />
        </LabeledSection>
        <LabeledSection label={'Metric Display Name'}>
          <TextInput
            value={formState.metricDisplayName || ''}
            onChange={formState.onMetricDisplayNameChanged}
            placeholder="Metric Display Name"
          />
        </LabeledSection>
        <LabeledSection label={'Group By Field'}>
          <GroupByInput
            groupBy={formState.groupByField}
            setGroupBy={formState.setGroupByField}
          />
        </LabeledSection>
        <LabeledSection label={'Interval'}>
          <Inputs.Dropdown options={formState.intervalOptions} />
        </LabeledSection>
        <LabeledSection label={'Totals Column'}>
          <Inputs.Toggle
            value={formState.hasTotalsCol}
            onChange={formState.setHasTotalsCol}
            testId={'pace-totals-toggle'}
          />
        </LabeledSection>
        <LabeledSection label={'Pace Column'}>
          <Inputs.Toggle
            value={formState.hasPaceCol}
            onChange={formState.setHasPaceCol}
          />
        </LabeledSection>
        <LabeledSection label={'Pin Total/Pace Columns'}>
          <Inputs.Toggle
            value={formState.isTotalsAndPacePinnedRight}
            onChange={formState.setIsTotalsAndPacePinnedRight}
          />
        </LabeledSection>
        <LabeledSection label={'Card Mode'}>
          <Inputs.Toggle
            value={formState.isCardMode}
            onChange={formState.setIsCardMode}
          />
        </LabeledSection>
        <SortOrderInput
          isCardMode={formState.isCardMode}
          sortOrder={formState.sortOrder}
          setSortOrder={formState.setSortOrder}
        />
        {isGadgetBuilder && (
          <div style={{ marginBottom: 32 }}>
            {isEditing && (
              <EditingActions
                isLoading={isLoading}
                onSaveAs={onSaveAs}
                onDelete={onDelete}
                onSave={onSave}
              />
            )}
            {!isEditing && <NewActions onSave={onSave} isLoading={isLoading} />}
          </div>
        )}
      </GadgetFormInnerContent>
    </GadgetFormInner>
    <Preview formState={formState} isGadgetBuilder={isGadgetBuilder} />
  </GadgetFormWrapper>
);

export default PaceMatrixForm;
