import { useCallback, useContext } from 'react';
import STORE from 'store';

import AccountPickerContext from 'contexts/AccountPickerContext';
import BoardContext from 'contexts/BoardContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import isPerformanceBoard from 'isPerformanceBoard';
import getTimeStamp from 'getTimeStamp';

const useUpdateGeneral = () => {
  const currentUser = useContext(CurrentUserContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { board } = useContext(BoardContext);

  const updateGeneral = useCallback(
    ({
      name,
      field,
      direction,
      filterInput,
    }: {
      name: string;
      field: string;
      direction: SortBy;
      filterInput: FilterInput;
    }) => {
      if (!isPerformanceBoard(board)) {
        return;
      }

      // Set visibility of the default sort field to true
      const columnOrder = board.columnOrder.map((c) => {
        const newColumn = { ...c };

        if (c.field === field) {
          newColumn.visible = true;
        }

        return newColumn;
      });

      const newBoard = {
        ...board,
        name,
        defaultSort: { field, direction },
        filterInput,
        columnOrder,
        updatedBy: currentUser.id,
        updatedOn: getTimeStamp(),
      };
      STORE.contentDistributions
        .getPerformanceBoardsRef({
          accountId: selectedAccountId,
        })
        .doc(newBoard.id)
        .set(newBoard);
    },
    [board, currentUser.id, selectedAccountId],
  );

  return updateGeneral;
};

export default useUpdateGeneral;
