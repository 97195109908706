import { useContext, useEffect, useState } from 'react';
import STORE from '../../store';
import AccountPickerContext from '../../contexts/AccountPickerContext';

const useUsers = () => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [users, setUsers] = useState<
    (
      | UserManagement.SignedUpUser
      | UserManagement.PendingUser
      | UserManagement.WallboardUser
    )[]
  >([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);

  useEffect(() => {
    let isActive = true;
    setUsers([]);
    setIsLoadingUsers(true);
    STORE.users
      .getUsersRef({ accountId: selectedAccountId })
      .onSnapshot((usersSnapshot) => {
        if (!isActive) {
          return;
        }

        const newUsers = usersSnapshot.docs.map((d) => d.data());
        setUsers(newUsers);
        setIsLoadingUsers(false);
      });

    return () => {
      isActive = false;
    };
  }, [selectedAccountId]);

  return {
    users,
    isLoadingUsers,
  };
};

export default useUsers;
