import React from 'react';
import Tooltip from '../../Tooltip';
import Cell from './Cell';
import Row from '../../Common/Row';
import Delta from '../../Gadget/Delta';
import DeltaTooltip from '../../Gadget/DeltaTooltip';

import getComparison from './getComparison';
import useValueFormatters from '../../../hooks/useValueFormatters';
import useObfuscator from '../../../hooks/useObfuscator';

const isComparison = (
  v: string | MatrixComparisonValue | boolean,
): v is MatrixComparisonValue => {
  // @ts-ignore
  return !!v && v.formatting !== undefined;
};

const NormalCellRenderer = ({
  value: v,
  colDef,
  isLeftAlign,
}: {
  value: string | MatrixComparisonValue | undefined | boolean | string[];
  colDef: {
    headerClass?: string | 'rightAlignedHeader';
    headerComponentParams?: {
      metric?: {
        id: string;
      };
      isComparisonCurrentValue?: boolean;
    };
    field?: string;
  };
  isLeftAlign?: boolean;
}) => {
  const { formatMetric } = useValueFormatters();
  const { isObfuscating, obfuscatedValue } = useObfuscator({
    value: typeof v === 'string' || Array.isArray(v) ? v : undefined,
    field:
      colDef.headerComponentParams &&
      colDef.headerComponentParams.metric &&
      colDef.headerComponentParams.metric.id === colDef.field
        ? undefined
        : colDef.headerComponentParams &&
            colDef.headerComponentParams.isComparisonCurrentValue
          ? undefined
          : colDef.field,
  });

  if (v === undefined || v === null) {
    return (
      <Cell
        rightAlign={
          !!colDef.headerClass && colDef.headerClass === 'rightAlignedHeader'
        }
      >
        <span>-</span>
      </Cell>
    );
  }

  if (Array.isArray(v)) {
    const text = isObfuscating ? obfuscatedValue : v.join(', ');
    const shouldTooltip = text.length && text.length > 14;
    return (
      <Cell rightAlign={isLeftAlign ? false : true}>
        <Tooltip content={shouldTooltip ? text : undefined}>
          <span>{text}</span>
        </Tooltip>
      </Cell>
    );
  }

  if (isComparison(v)) {
    const {
      value,
      previous,
      formatting,
      type,
      currentDateRange,
      previousDateRange,
      metric,
    } = v;

    if (previous === 0 && type === 'percentDelta') {
      return (
        <Cell rightAlign={isLeftAlign ? false : true}>
          <span>-</span>
        </Cell>
      );
    }

    if (type === 'percentDelta' || type === 'delta') {
      const { delta, isGood } = getComparison({
        value: value as number,
        previous: previous as number,
        formatting,
        type,
      });

      return (
        <Row centerAlign>
          {currentDateRange && previousDateRange && (
            <Tooltip
              content={
                <DeltaTooltip
                  metric={metric}
                  current={value as number}
                  previous={previous as number}
                  currentDateRange={currentDateRange}
                  previousDateRange={previousDateRange}
                />
              }
              isAltTooltip
            >
              <Delta
                isGood={isGood}
                delta={delta}
                isGrid
                isRaw={type === 'delta'}
                metricId={metric.id}
              />
            </Tooltip>
          )}
          {(!currentDateRange || !previousDateRange) && (
            <Delta
              isGood={isGood}
              delta={delta}
              isGrid
              isRaw={type === 'delta'}
              metricId={metric.id}
            />
          )}
        </Row>
      );
    } else {
      const formattedValue = formatMetric({ metricId: metric.id, value });

      return (
        <Cell rightAlign={isLeftAlign ? false : true}>
          <span>{formattedValue}</span>
        </Cell>
      );
    }
  } else if (typeof v === 'boolean') {
    return (
      <Cell rightAlign={isLeftAlign ? false : true}>
        <span>{v.toString()}</span>
      </Cell>
    );
  } else {
    const shouldTooltip = !!v && !!v.length && v.length > 14;
    const valueToShow = isObfuscating ? obfuscatedValue : v;
    return (
      <Cell rightAlign={isLeftAlign ? false : true}>
        <Tooltip content={shouldTooltip ? valueToShow : undefined}>
          <span>{valueToShow}</span>
        </Tooltip>
      </Cell>
    );
  }
};

export default NormalCellRenderer;
