import { useCallback, useContext } from 'react';
import relativeDateRangeToDateRange from 'relativeDateRangeToDateRange';
import AccountContext from 'contexts/AccountContext';
import getDateBuckets from 'screens/GoalShow/getDateBuckets';
import moment from 'moment';

const useGetLastQuarter = ({
  wizardState,
}: {
  wizardState: Costs.WizardState;
}) => {
  const { weekStartsOn } = useContext(AccountContext);

  const getLastQuarter = useCallback(() => {
    if (!wizardState) {
      return [];
    }
    const { startDate, endDate } = relativeDateRangeToDateRange({
      relativeDateRange: {
        interval: wizardState.config.basicStep.interval,
        n: wizardState.config.basicStep.interval === 'week' ? 13 : 3,
        type: 'last' as 'last',
        currentToDate: false,
      },
      startOfWeek: wizardState.config.basicStep.startOfWeek || weekStartsOn,
    });

    if (!startDate || !endDate) {
      const error = new Error();
      error.name = 'startDate or/and endDate undefined';
      throw error;
    }

    const startDates = getDateBuckets({
      startDate,
      endDate,
      weekStartsOn: wizardState.config.basicStep.startOfWeek || weekStartsOn,
      interval: wizardState.config.basicStep.interval,
    });

    if (wizardState.config.basicStep.interval === 'month') {
      return startDates.map((startDate) => ({
        value: startDate,
        label: moment(startDate).format("MMMM 'YY"),
      }));
    } else {
      return startDates.map((startDate) => {
        const startMoment = moment(startDate);
        const endMoment = startMoment.add(6, 'days');

        return {
          value: startDate,
          label: `${moment(startDate).format('DD')} - ${endMoment.format(
            "DD MMM 'YY",
          )}`,
        };
      });
    }
  }, [weekStartsOn, wizardState]);

  return getLastQuarter;
};

export default useGetLastQuarter;
