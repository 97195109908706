import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import isDateRangeValid from './isDateRangeValid';

const useRecentlyUsedRollingWindows = () => {
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { accountRef } = useContext(AccountPickerContext);
  const [rollingFilters, setRollingFilters] = useState<
    RecentlyUsedRollingWindow[]
  >([]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    let isActive = true;
    const listener = accountRef
      .collection('users')
      .doc(currentUserId)
      .collection('recentlyUsedRollingWindows')
      .orderBy('usedOn', 'desc')
      .limit(15)
      .onSnapshot((snapshot) => {
        if (!isActive) {
          return;
        }
        const data = [] as RecentlyUsedRollingWindow[];
        snapshot.docs.forEach((d) => {
          data.push({ ...d.data() } as RecentlyUsedRollingWindow);
        });

        const withoutDupes = data.reduce((result, current) => {
          if (result.some((r) => _.isEqual(r.value, current.value))) {
            return result;
          }
          return [...result, current];
        }, [] as RecentlyUsedRollingWindow[]);

        const withoutCorrupted = withoutDupes.filter((w) =>
          isDateRangeValid(w.value),
        );

        setRollingFilters(withoutCorrupted.slice(0, 5));
      });

    return () => {
      listener();
      isActive = false;
    };
  }, [accountRef, currentUserId]);

  return rollingFilters;
};

export default useRecentlyUsedRollingWindows;
