import React, { useState } from 'react';
import styled from 'styled-components';
import InlineDialog, { Placement } from '../../../components/InlineDialog';
import usePopup from '../../../hooks/usePopup';
import Button, { ButtonType } from '../Button';
import ContextMenuContext from './ContextMenuContext';
import Tooltip from '../../../components/Tooltip';
export { default as Option } from './Option';
export { default as Break } from './Break';
export { default as Info } from './Info';

export const ContextMenuDiv = styled.div<{ isHidden?: boolean }>`
  padding: 8px 4px;
  background-color: white;
  border-radius: 8px;
  box-shadow:
    0px 0px 0px 1px rgba(28, 40, 64, 0.04) inset,
    0px 4px 8px -4px rgba(28, 40, 64, 0.12),
    0px 4px 12px -2px rgba(28, 40, 64, 0.16);

  ${(props) => props.isHidden && `display: none;`}
`;

const ContextMenu = ({
  children,
  testId,
  placement,
  buttonType = 'Secondary',
  onOpen,
  onClose,
  icon,
  label,
  tooltip,
}: {
  children: JSX.Element | JSX.Element[];
  testId?: string;
  placement?: Placement;
  buttonType?: ButtonType;
  onOpen?: () => void;
  onClose?: () => void;
  icon?: Kingpin.Icon;
  label?: string;
  tooltip?: string;
}) => {
  const { isOpen, close, open } = usePopup();
  // I'm not mad on this pattern, but i'm under time pressure
  const [isHidden, setIsHidden] = useState(false);

  return (
    <ContextMenuContext.Provider
      value={{
        closeMenu: () => {
          close();
          if (onClose) {
            onClose();
          }
          setIsHidden(false);
        },
        hideMenu: () => {
          setIsHidden(true);
          if (onClose) {
            onClose();
          }
        },
      }}
    >
      <InlineDialog
        placement={placement}
        content={
          <ContextMenuDiv isHidden={isHidden}>{children}</ContextMenuDiv>
        }
        onClose={() => {
          close();
          setIsHidden(false);
          if (onClose) {
            onClose();
          }
        }}
        isOpen={isOpen}
      >
        <Tooltip content={tooltip}>
          <Button
            testId={testId}
            size={'Small'}
            type={buttonType}
            icon={icon || 'dots-menu'}
            label={label}
            onClick={() => {
              if (isOpen) {
                close();
                setIsHidden(false);
                if (onClose) {
                  onClose();
                }
              } else {
                open();
                setIsHidden(false);
                if (onOpen) {
                  onOpen();
                }
              }
            }}
          />
        </Tooltip>
      </InlineDialog>
    </ContextMenuContext.Provider>
  );
};

export default ContextMenu;
