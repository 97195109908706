import moment from 'moment';
import relativeDateRangeToDateRange from '../../../relativeDateRangeToDateRange';
import migrateRelativeDateRange from '../../../migrateRelativeDateRange';

const translateAdvancedFrom = (
  relativeDateInput: RelativeDateRange,
  weekStartsOn: WeekStartsOn,
) => {
  const today = moment().format('ddd').toUpperCase();

  if (
    weekStartsOn === today &&
    relativeDateInput.type === 'current' &&
    relativeDateInput.interval === 'week' &&
    relativeDateInput.currentToDate
  ) {
    return {
      type: 'last' as 'last',
      n: 1,
      interval: 'week' as 'week',
      currentToDate: false,
    };
  }

  return relativeDateInput;
};

const getCurrentDateRange = ({
  relativeDateRange,
  weekStartsOn,
  advancedRelativeDateRange,
  dateRange,
  selectedBonusPeriod,
  selectedPeriod,
  isScorecardPopupReport,
}: {
  relativeDateRange?: RelativeDateRange;
  weekStartsOn: WeekStartsOn;
  dateRange?: DateRangeInput;
  advancedRelativeDateRange?: AdvancedRelativeDateRange;
  selectedBonusPeriod?: BonusPeriod;
  selectedPeriod?: Period;
  isScorecardPopupReport?: boolean;
}) => {
  if (selectedPeriod && !isScorecardPopupReport) {
    const yesterday = moment().subtract({ day: 1 }).format('YYYY-MM-DD');
    const shouldUseYesterday =
      yesterday < selectedPeriod.endDate &&
      selectedPeriod.startDate <= yesterday;
    return {
      startDate: selectedPeriod.startDate,
      endDate: shouldUseYesterday ? yesterday : selectedPeriod.endDate,
    };
  } else if (selectedBonusPeriod && !isScorecardPopupReport) {
    return {
      startDate: selectedBonusPeriod.startDate,
      endDate: selectedBonusPeriod.endDate,
    };
  } else if (dateRange) {
    return dateRange;
  } else if (relativeDateRange) {
    return relativeDateRangeToDateRange({
      relativeDateRange: migrateRelativeDateRange(relativeDateRange),
      startOfWeek: weekStartsOn,
    });
  } else if (advancedRelativeDateRange) {
    const fromDateKey =
      advancedRelativeDateRange.from.type === 'next' ? 'endDate' : 'startDate';
    const toDateKey =
      advancedRelativeDateRange.from.type === 'next' ||
      advancedRelativeDateRange.to.type === 'next'
        ? 'endDate'
        : 'startDate';

    const from = relativeDateRangeToDateRange({
      relativeDateRange: translateAdvancedFrom(
        advancedRelativeDateRange.from,
        weekStartsOn,
      ),
      startOfWeek: weekStartsOn,
    });
    const to = relativeDateRangeToDateRange({
      relativeDateRange: advancedRelativeDateRange.to,
      startOfWeek: weekStartsOn,
    });

    return {
      startDate: from[fromDateKey],
      endDate: to[toDateKey],
    };
  } else {
    return undefined;
  }
};

export default getCurrentDateRange;
