import buildFilterInput from './utils/buildFilterInput';
import toDrillDowns from './components/Report/toReportDrillDown';

const toFilterInput = (
  savedFilter: SavedFilter,
  variableDrillDowns: VariableDrillDownType[],
): FilterInput =>
  buildFilterInput({
    scopes: toDrillDowns({ plates: savedFilter.scope, variableDrillDowns }),
    drillDowns: toDrillDowns({
      plates: savedFilter.drillDowns,
      variableDrillDowns,
    }),
  });

export default toFilterInput;
