import Typography from 'kingpin/atoms/Typography';
import ListItemWrapper from '../../../DatasetDefinitions/ListItemWrapper';
import Column from '../../../DatasetDefinitions/Column';
import { Link } from 'react-router-dom';
import { getMetricLink } from '../../../AllSets/routes';
import React from 'react';
import Card from '../../../../../components/Common/Card';

const CompoundMetricsUsage = ({
  compoundMetrics,
  metric,
}: {
  compoundMetrics: Metrics.CompoundMetric[];
  metric: Metrics.NormalMetric;
}) => (
  <>
    {compoundMetrics.length > 0 && (
      <div style={{ marginBottom: 24 }}>
        <Typography.Header type="H5">
          {`Compound Metrics (${compoundMetrics.length})`}
        </Typography.Header>
        <Card>
          {compoundMetrics.map((cm, index) => (
            <ListItemWrapper
              key={`${cm.id}-${metric.id}`}
              isLast={compoundMetrics.length - 1 === index}
            >
              <Column
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <Link to={getMetricLink(cm)} target="_blank">
                  <Typography.Body type="Body 12" color="#0041ea">
                    {cm.name}
                  </Typography.Body>
                </Link>
              </Column>
            </ListItemWrapper>
          ))}
        </Card>
      </div>
    )}
  </>
);

export default CompoundMetricsUsage;
