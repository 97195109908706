const userDisplayName = ({
  firstName,
  lastName,
  displayName,
}: {
  firstName?: string;
  lastName?: string;
  displayName?: string;
}) => (displayName ? displayName : `${firstName} ${lastName}`);

export default userDisplayName;
