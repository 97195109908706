import { useContext, useEffect, useState } from 'react';
import BaseViewsContext from '../contexts/BaseViewsContext';
import fiToDrillDowns from '../screens/DataManager/Metrics/MetricPopup/MetricForm/NormalMetricForm/fiToDrillDowns';
import { getFieldValue } from '../components/ReportDrillDown';
import DatasetDefinitionsContext from '../contexts/DatasetDefinitionsContext';
import toDrillDowns from '../components/Report/toReportDrillDown';

const getLabel = (
  drillDown: ReportDrillDownType,
  baseViews: { [dataset: string]: FleetOps.BaseView | undefined },
) => {
  if (drillDown.dataset) {
    const baseView = baseViews[drillDown.dataset];
    if (baseView) {
      const f = baseView.fields[drillDown.field];
      if (f && f.nameAlias) {
        return f.nameAlias;
      }
    }
  }
  return drillDown.field;
};

const useFilterDescription = (
  filterInput?: FilterInput,
  filterOverrides?: FilterPlate[],
) => {
  const { baseViews } = useContext(BaseViewsContext);
  const { datasets } = useContext(DatasetDefinitionsContext);
  const [filterDescription, setFilterDescription] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (!filterInput) {
      setFilterDescription(undefined);
    } else {
      const drills = fiToDrillDowns(filterInput, datasets);
      const overrideDrills = toDrillDowns({
        plates: filterOverrides ? filterOverrides : [],
        variableDrillDowns: [],
      });
      const netDrills = [...drills, ...overrideDrills];
      const newDescription = netDrills.reduce(
        (currentD, currentDrill, currentIndex) => {
          currentD += `${getLabel(currentDrill, baseViews)}: ${getFieldValue(
            currentDrill,
          )}`;

          if (currentIndex < netDrills.length - 1) {
            currentD += ' and ';
          } else if (currentIndex > netDrills.length - 1) {
            currentD += ', ';
          }
          return currentD;
        },
        '',
      );

      setFilterDescription(newDescription);
    }
  }, [baseViews, datasets, filterInput, filterOverrides]);

  return filterDescription;
};

export default useFilterDescription;
