import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import * as aguid from 'aguid';

import SingleMetricDateMatrixForm from './SingleMetricDateMatrixForm';
import { useNavigate } from 'react-router-dom';
import appRoutes, { buildGadgetShow } from '../../../navigation/appRoutes';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import STORE from '../../../store';

const saveGadget = async (
  gadget: VisualisationDefinitions.SingleMetricDateMatrix,
  accountId: string,
) => {
  return STORE.visualisations
    .getSingleMetricDateMatricesRef({ accountId })
    .doc(gadget.id)
    .set(gadget);
};

const SingleMetricDateMatrixFormContainer = ({
  gadget,
  onChange,
  isGadgetBuilder,
}: {
  gadget?: VisualisationDefinitions.SingleMetricDateMatrix;
  onChange?: React.Dispatch<
    React.SetStateAction<VisualisationDefinition | undefined>
  >;
  isGadgetBuilder?: boolean;
}) => {
  const navigate = useNavigate();

  const { selectedAccountId } = useContext(AccountPickerContext);
  const [id] = useState(gadget ? gadget.id : aguid());
  const [name, setName] = useState<string>(gadget ? gadget.name : '');
  const [description, setDescription] = useState<string>(
    gadget ? gadget.description : '',
  );
  const [metricId, setMetricId] = useState<string | undefined>(
    gadget ? gadget.metricId : undefined,
  );
  const [metricDisplayName, setMetricDisplayName] = useState<string>(
    gadget && gadget.metricDisplayName ? gadget.metricDisplayName : '',
  );
  const [interval, setInterval] = useState<FleetOps.Interval>(
    gadget ? gadget.interval : 'day',
  );
  const [clientSortBy, setClientSortBy] = useState<
    VisualisationDefinitions.ClientSortBy | undefined
  >(gadget ? gadget.clientSortBy : 'improvement (asc)');
  const [serverSortBy, setServerSortBy] =
    useState<VisualisationDefinitions.ServerSortBy>(
      gadget && gadget.serverSortBy && !gadget.serverSortBy.includes('change')
        ? gadget.serverSortBy
        : 'metric (asc)',
    );
  const [limit, setLimit] = useState<string>(
    gadget && gadget.limit ? gadget.limit.toString() : '20',
  );
  const [groupByField, setGroupByField] = useState<string | undefined>(
    gadget ? gadget.groupByField : undefined,
  );
  const [comparisonCellFormat, setComparisonCellFormat] =
    useState<MatrixCellType>(gadget ? gadget.comparisonCellFormat : 'delta');
  const [isSizedToFitOverridden, setIsSizedToFitOverridden] = useState<
    boolean | undefined
  >(gadget ? gadget.isSizedToFitOverridden : false);
  const [draftGadget, setDraftGadget] = useState<
    VisualisationDefinitions.SingleMetricDateMatrix | undefined
  >();

  const isPreviewReady = groupByField !== undefined && metricId !== undefined;
  const isValid = isPreviewReady && name !== '';
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onMetricDisplayNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setMetricDisplayName(event.target.value);
  };

  const onDescriptionChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const onLimitChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(event.target.value);
  };

  const onIsSizedToFitChange = (newValue: boolean) => {
    setIsSizedToFitOverridden(newValue);
  };

  useEffect(() => {
    const newDraftGadget = (() => {
      if (groupByField === undefined || metricId === undefined || name === '') {
        return undefined;
      } else {
        return {
          id,
          name,
          description,
          type: 'SingleMetricDateMatrix' as VisualisationDefinitions.GadgetType,
          metricId,
          metricDisplayName,
          interval,
          groupByField,
          limit: Number.parseInt(limit),
          serverSortBy,
          clientSortBy,
          comparisonCellFormat,
          isSizedToFitOverridden,
        };
      }
    })();

    setDraftGadget(newDraftGadget);
    if (onChange) {
      onChange(newDraftGadget);
    }
  }, [
    clientSortBy,
    comparisonCellFormat,
    description,
    groupByField,
    id,
    interval,
    isSizedToFitOverridden,
    limit,
    metricDisplayName,
    metricId,
    name,
    onChange,
    serverSortBy,
  ]);

  const onSave = useCallback(async () => {
    if (groupByField === undefined || metricId === undefined || name === '') {
      return;
    }
    const newDefinition = {
      id,
      name,
      description,
      type: 'SingleMetricDateMatrix' as VisualisationDefinitions.GadgetType,
      metricId,
      metricDisplayName,
      interval,
      groupByField,
      limit: Number.parseInt(limit),
      clientSortBy,
      serverSortBy,
      comparisonCellFormat,
      isSizedToFitOverridden,
    };

    if (!clientSortBy) {
      delete newDefinition['clientSortBy'];
    }
    setIsLoading(true);
    await saveGadget(newDefinition, selectedAccountId);
    setIsLoading(false);
    navigate(buildGadgetShow(id));
  }, [
    clientSortBy,
    comparisonCellFormat,
    description,
    groupByField,
    navigate,
    id,
    interval,
    isSizedToFitOverridden,
    limit,
    metricDisplayName,
    metricId,
    name,
    selectedAccountId,
    serverSortBy,
  ]);

  const onSaveAs = useCallback(async () => {
    const newId = aguid();
    if (groupByField === undefined || metricId === undefined || name === '') {
      return;
    }
    const newDefinition = {
      id: newId,
      name,
      description,
      type: 'SingleMetricDateMatrix' as VisualisationDefinitions.GadgetType,
      metricId,
      metricDisplayName,
      interval,
      groupByField,
      limit: Number.parseInt(limit),
      clientSortBy,
      serverSortBy,
      comparisonCellFormat,
      isSizedToFitOverridden,
    };

    if (!clientSortBy) {
      delete newDefinition['clientSortBy'];
    }
    setIsLoading(true);
    await saveGadget(newDefinition, selectedAccountId);
    setIsLoading(false);
    navigate(buildGadgetShow(newId));
  }, [
    clientSortBy,
    comparisonCellFormat,
    description,
    groupByField,
    navigate,
    interval,
    isSizedToFitOverridden,
    limit,
    metricDisplayName,
    metricId,
    name,
    selectedAccountId,
    serverSortBy,
  ]);

  const onDelete = useCallback(async () => {
    setIsLoading(true);
    await STORE.visualisations
      .getSingleMetricDateMatricesRef({ accountId: selectedAccountId })
      .doc(id)
      .delete();
    navigate(appRoutes.loggedIn.gadgetBuilderNew);
    setIsLoading(false);
  }, [selectedAccountId, navigate, id]);

  return (
    <SingleMetricDateMatrixForm
      name={name}
      onNameChanged={onNameChanged}
      description={description}
      onDescriptionChanged={onDescriptionChanged}
      metricId={metricId}
      setMetricId={setMetricId}
      interval={interval}
      setInterval={setInterval}
      clientSortBy={clientSortBy}
      setClientSortBy={setClientSortBy}
      serverSortBy={serverSortBy}
      setServerSortBy={setServerSortBy}
      groupBy={groupByField}
      setGroupBy={setGroupByField}
      limit={limit}
      onLimitChanged={onLimitChanged}
      draftGadget={draftGadget}
      comparisonCellFormat={comparisonCellFormat}
      setComparisonCellFormat={setComparisonCellFormat}
      isValid={isValid}
      isEditing={!!gadget}
      isLoading={isLoading}
      onSave={onSave}
      onDelete={onDelete}
      onSaveAs={onSaveAs}
      metricDisplayName={metricDisplayName}
      onMetricDisplayNameChanged={onMetricDisplayNameChanged}
      setMetricDisplayName={setMetricDisplayName}
      isSizedToFitOverridden={isSizedToFitOverridden}
      onIsSizedToFitChange={onIsSizedToFitChange}
      isGadgetBuilder={isGadgetBuilder}
    />
  );
};

export default SingleMetricDateMatrixFormContainer;
