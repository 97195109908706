import aguid from 'aguid';
import { DateTime } from 'luxon';
import { REPORTS_COLLECTION } from '../constants';
import firebase from 'firebase/compat/app';
import { CurrentUserContextType } from '../contexts/CurrentUserContext';

const createReport = async ({
  name,
  access,
  report,
  currentUser,
  dateField,
  accountRef,
}: {
  name: string;
  access: ResourceAccess;
  report?: PersistedReportType;
  currentUser: CurrentUserContextType;
  dateField: string;
  accountRef: firebase.firestore.DocumentReference;
}) => {
  const newReport =
    report ||
    ({
      version: '7',
      id: aguid(),
      name,
      visibleSlicerFields: [],
      scope: [],
      drillDowns: [],
      canvas: {
        cards: [],
      },
      relativeDateRange: {
        interval: 'day',
        n: 30,
        type: 'last',
        currentToDate: true,
      },
      access,
      dateField,
      createdBy: currentUser.id,
      createdOn: DateTime.utc().toISO(),
      updatedBy: currentUser.id,
      updatedOn: DateTime.utc().toISO(),
    } as PersistedReportType);

  await accountRef
    .collection(REPORTS_COLLECTION)
    .doc(newReport.id)
    .set(newReport);
  return newReport.id;
};

export default createReport;
