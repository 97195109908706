import React, { useContext } from 'react';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import toSentenceCase from 'services/toSentenceCase';
import formatDateLabel from 'components/V5Gadget/formatDateLabel';
import IntervalBadge from 'components/IntervalBadge';

import PerformanceWizardContext from '../PerformanceWizardContext';
import ColDiv from '../MetricsStep/ColDiv';
import useGetFieldLabel from '../../../../../../hooks/useGetFieldLabel';
import useGetDatasetLabel from '../../../../../../components/Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';
import { buildWeekStartsOnLabel } from '../../../../../../components/Report/WeekStartsOnOverrideSelector';

const DetailItem = ({
  label,
  children,
}: {
  label?: string;
  children: JSX.Element | JSX.Element[];
}) => (
  <Row style={{ marginBottom: 16 }}>
    <ColDiv flex={1}>
      <Typography.Body type="Body 12">{label}:</Typography.Body>
    </ColDiv>
    <ColDiv flex={3}>{children}</ColDiv>
  </Row>
);

const DetailsTab = () => {
  const { wizardState } = useContext(PerformanceWizardContext);
  const datasetStep = wizardState.config.datasetStep;
  const {
    name,
    entity,
    intervalLength,
    interval,
    startDate,
    entityDataTypes,
    entityWindowDateField,
    entityWindowLength,
    startOfWeek,
  } = datasetStep;
  const { getFieldLabel } = useGetFieldLabel();
  const getDatasetLabel = useGetDatasetLabel();
  if (!startDate) {
    return null;
  }

  return (
    <div style={{ paddingTop: 17 }}>
      <DetailItem label="Dataset Name">
        <Typography.Body type="Body 12">{name}</Typography.Body>
      </DetailItem>
      <DetailItem label="Entity">
        <Typography.Body type="Body 12">{`${toSentenceCase(
          entity,
        )}(s)`}</Typography.Body>
      </DetailItem>
      <DetailItem label="Entity Datasets">
        <Typography.Body type="Body 12">
          {entityDataTypes
            ? entityDataTypes.map(getDatasetLabel).join(', ')
            : '-'}
        </Typography.Body>
      </DetailItem>
      <DetailItem label="Entity Search Date Field">
        <Typography.Body type="Body 12">
          {entityDataTypes &&
          entityDataTypes.length > 0 &&
          entityWindowDateField
            ? getFieldLabel({
                field: entityWindowDateField,
                dataType: entityDataTypes[0],
              })
            : ''}
        </Typography.Body>
      </DetailItem>
      <DetailItem label="Entity Search Window">
        <Typography.Body type="Body 12">{entityWindowLength}</Typography.Body>
      </DetailItem>
      <DetailItem label="Interval">
        <IntervalBadge intervalLength={intervalLength} interval={interval} />
      </DetailItem>
      {interval === 'week' && (
        <DetailItem label="Start of Week">
          <Typography.Body type="Body 12">
            {startOfWeek ? buildWeekStartsOnLabel(startOfWeek) : '-'}
          </Typography.Body>
        </DetailItem>
      )}
      <DetailItem label="Start date">
        <Typography.Body type="Body 12">
          {formatDateLabel(
            startDate,
            interval,
            false,
            undefined,
            undefined,
            true,
          )}
        </Typography.Body>
      </DetailItem>
    </div>
  );
};

export default DetailsTab;
