import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import BoardContext from 'contexts/BoardContext';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import DragHandle from 'components/DragAndDropList/DragHandle';
import { RadioOptionItem } from 'components/RadioInputV2';
import Tooltip from 'components/Tooltip';
import Colors2 from 'theme/Colors2';
import isPerformanceBoard from 'isPerformanceBoard';
import useGetFieldLabel from 'hooks/useGetFieldLabel';
import DotsMenuSvg from '../../icons/DotsMenu.svg';
import { Col } from './FieldsGrid';
import ContextMenu, {
  Option,
} from '../../../../../../kingpin/atoms/ContextMenu';

const IconImg = styled.img`
  width: 16px;
  height: 16px;
`;

export const DotsIcon = () => <IconImg src={DotsMenuSvg} />;

const FieldItem = ({
  column,
  searchText,
  columnOrder,
  updateColumnsOrder,
}: {
  column: PerformanceBoardTypes.Column;
  searchText: string;
  columnOrder: PerformanceBoardTypes.Column[];
  updateColumnsOrder: ({
    newOrder,
  }: {
    newOrder: PerformanceBoardTypes.Column[];
  }) => void;
}) => {
  const { board } = useContext(BoardContext);
  const { getFieldLabel } = useGetFieldLabel();

  const onTopClicked = useCallback(() => {
    const newOrder = [column, ...columnOrder.filter((c) => c !== column)];
    updateColumnsOrder({ newOrder });
  }, [column, columnOrder, updateColumnsOrder]);

  const onBottomClicked = useCallback(() => {
    const newOrder = [...columnOrder.filter((c) => c !== column), column];
    updateColumnsOrder({ newOrder });
  }, [columnOrder, column, updateColumnsOrder]);

  const onFieldShow = useCallback(() => {
    const newOrder = columnOrder.map((c) => {
      const newColumn = { ...c };

      if (c.key === column.key) {
        newColumn.visible = true;
      }

      return newColumn;
    });
    updateColumnsOrder({ newOrder });
  }, [column.key, columnOrder, updateColumnsOrder]);

  const onFieldHide = useCallback(() => {
    const newOrder = columnOrder.map((c) => {
      const newColumn = { ...c };

      if (c.key === column.key) {
        newColumn.visible = false;
      }

      return newColumn;
    });
    updateColumnsOrder({ newOrder });
  }, [column.key, columnOrder, updateColumnsOrder]);

  const isDisabled = useCallback(() => {
    if (isPerformanceBoard(board)) {
      return board.defaultSort.field
        ? column.field === board.defaultSort.field
        : false;
    }
    return false;
  }, [board, column.field]);

  return (
    <>
      <Row
        centerAlign
        style={{
          padding: '5px 8px',
          border: `1px solid ${Colors2.Grey['8']}`,
          margin: '-1px 0',
        }}
      >
        <Col width={73} centerAlign>
          <Row centerAlign>
            <div
              style={{
                visibility: searchText === '' ? 'visible' : 'hidden',
                marginRight: '4px',
              }}
            >
              <DragHandle noMargin testId={`${column.field}-drag`} />
            </div>
            <Typography.Body type="Body 12">
              {columnOrder.indexOf(column) + 1}
            </Typography.Body>
          </Row>
        </Col>
        <Col width={225}>
          <Typography.Body type="Body 12">
            {column.isProgressVis
              ? `${getFieldLabel({
                  field: column.field,
                  dataType: board.dataType,
                })} (Progress Field)`
              : getFieldLabel({
                  field: column.field,
                  dataType: board.dataType,
                })}
          </Typography.Body>
        </Col>
        <Col width={73} style={{ justifyContent: 'center' }}>
          <Row style={{ justifyContent: 'center' }}>
            <Tooltip
              content={
                isDisabled()
                  ? 'This field must be displayed as it is being used to sort the board'
                  : undefined
              }
            >
              <RadioOptionItem
                isDisabled={isDisabled()}
                isSelected={!column.visible}
                onSelected={onFieldHide}
                text={''}
                noMargin
                testId={`hide-${column.field}-${column.isProgressVis}`}
              />
            </Tooltip>
          </Row>
        </Col>
        <Col width={73} style={{ justifyContent: 'center' }}>
          <Row style={{ justifyContent: 'center' }}>
            <Tooltip
              content={
                isDisabled()
                  ? 'This field must be displayed as it is being used to sort the board'
                  : undefined
              }
            >
              <RadioOptionItem
                isDisabled={isDisabled()}
                isSelected={column.visible}
                onSelected={onFieldShow}
                text={''}
                noMargin
                testId={`show-${column.field}-${column.isProgressVis}`}
              />
            </Tooltip>
          </Row>
        </Col>
        <Col width={40}>
          <ContextMenu testId={`${column.field}-move`}>
            <Option
              label={'Send To Top'}
              onClick={onTopClicked}
              icon="arrow-up"
            />
            <Option
              label={'Send To Bottom'}
              onClick={onBottomClicked}
              icon="arrow-down"
            />
          </ContextMenu>
        </Col>
      </Row>
    </>
  );
};

export default FieldItem;
