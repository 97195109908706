import React from 'react';
import useDataOptions from './useDatasetOptions';
import { LabelDiv } from 'screens/Boards/BoardCreationWizard';
import Typography from 'kingpin/atoms/Typography';
import Dropdown from 'components/Inputs/Dropdown';
import FieldsListHeading from './FieldsListHeading';
import FieldsList from './FieldsList';

const DatasetFieldPicker = ({
  fields,
  setFields,
  dataset,
  setDataset,
}: {
  fields: string[];
  setFields: React.Dispatch<React.SetStateAction<string[]>>;
  dataset?: string | undefined;
  setDataset: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const options = useDataOptions({
    setFields,
    dataset,
    setDataset,
  });

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <LabelDiv>
          <Typography.Body type="Label">Select A Dataset</Typography.Body>
        </LabelDiv>
        <Dropdown
          options={options}
          placeholder={'Select Dataset'}
          isDisabled={options.length === 0}
        />
      </div>
      {!!dataset && (
        <div style={{ marginBottom: 16 }}>
          <FieldsListHeading
            fields={fields}
            setFields={setFields}
            dataset={dataset}
          />
          <FieldsList fields={fields} setFields={setFields} />
        </div>
      )}
    </>
  );
};

export default DatasetFieldPicker;
