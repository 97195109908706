import buildFilterInput from '../buildFilterInput';
import toDrillDowns from '../../components/Report/toReportDrillDown';
import moment from 'moment';

const getFixedFilterInput = (goal: GeneralGoal) => {
  const { drillDowns, fixedStartDate, fixedEndDate } = goal;
  const today = moment().utc().format('YYYY-MM-DD');
  const endDateToUse = fixedEndDate > today ? today : fixedEndDate;

  const dateRange = (() => {
    if (!fixedStartDate || !endDateToUse) {
      throw new Error('Fixed goals must have start and end dates defined');
    }
    return {
      startDate: fixedStartDate,
      endDate: endDateToUse,
    };
  })();

  return {
    dateScope: dateRange as DateRangeInput,
    filterInput: buildFilterInput({
      scopes: [],
      drillDowns: toDrillDowns({
        plates: drillDowns,
        variableDrillDowns: [],
      }),
    }),
  };
};

export default getFixedFilterInput;
