import { useCallback, useContext } from 'react';
import PERMISSIONS from '../permissionDefinitions';
import CurrentUserContext from 'contexts/CurrentUserContext';
import ImpersonatorContext from 'contexts/ImpersonatorContext';
import RolesContext from 'contexts/RolesContext';
import WallboardContext from 'contexts/WallboardContext';

const useHasAccess = () => {
  const currentUser = useContext(CurrentUserContext);
  const { isWallboard } = useContext(WallboardContext);
  const { currentPermissions } = useContext(RolesContext);
  const { impersonatorId } = useContext(ImpersonatorContext);

  const hasAccess = useCallback(
    ({ access, resource }: { access: ResourceAccess; resource: Resource }) => {
      if (currentUser.isSuperAdmin) {
        return true;
      }

      if (currentUser.isWallboardUser || isWallboard) {
        return true;
      }
      const currentUserId = impersonatorId || currentUser.id;

      if (access.type === 'Public') {
        return true;
      } else if (access.type === 'Admin Only') {
        return currentPermissions.includes(
          PERMISSIONS.CONTENT_ACCESS.VIEW_ALL_ADMIN_ONLY,
        );
      } else if (access.type === 'Private') {
        return (
          resource.createdBy === currentUserId ||
          currentPermissions.includes(
            PERMISSIONS.CONTENT_ACCESS.VIEW_ALL_PRIVATE,
          )
        );
      } else if (access.type === 'Users' && access.userIds) {
        return (
          access.userIds.includes(currentUserId) ||
          resource.createdBy === currentUserId ||
          currentPermissions.includes(PERMISSIONS.CONTENT_ACCESS.VIEW_ALL_USERS)
        );
      } else {
        return true;
      }
    },
    [impersonatorId, currentUser, isWallboard, currentPermissions],
  );

  return hasAccess;
};

export default useHasAccess;
