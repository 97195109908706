import { useCallback, useEffect, useState } from 'react';
import { IPaceCalculateForMetricArgs } from '../types';
import toMetricInput from 'utils/metrics/toMetricInput';
import useBaseArgs from './useBaseArgs';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';
import useMetric from '../../../../hooks/useMetric';

const useMetricArgs = ({
  paceMatrix,
}: {
  paceMatrix: VisualisationDefinitions.PaceMatrix;
}): IPaceCalculateForMetricArgs | undefined => {
  const metric = useMetric(paceMatrix.metricId);
  const baseArgs = useBaseArgs({
    paceMatrix,
  });

  const getArgs = useCallback((): IPaceCalculateForMetricArgs | undefined => {
    if (!metric || !baseArgs || !metricTypeCheckers.isNormalMetric(metric)) {
      return;
    }

    return {
      ...baseArgs,
      metric: toMetricInput(metric),
    };
  }, [baseArgs, metric]);
  const [args, setArgs] = useState<IPaceCalculateForMetricArgs | undefined>(
    () => getArgs(),
  );
  useEffect(() => {
    setArgs(getArgs());
  }, [getArgs]);

  return args;
};

export default useMetricArgs;
