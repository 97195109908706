import React from 'react';
import { Link } from 'react-router-dom';

import Typography from 'kingpin/atoms/Typography';
import ListItemWrapper from '../../../DatasetDefinitions/ListItemWrapper';
import Column from '../../../DatasetDefinitions/Column';
import { buildShowGlobalKpiList } from '../../../../../navigation/appRoutes';
import Card from '../../../../../components/Common/Card';

const ScorecardUsage = ({
  scorecards,
  metric,
}: {
  scorecards: Scorecards.Scorecard[];
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
}) => (
  <>
    {scorecards.length > 0 && (
      <div style={{ marginBottom: 24 }}>
        <Typography.Header type="H5">{`Scorecards (${scorecards.length})`}</Typography.Header>
        <Card>
          {scorecards.map((sc, index) => (
            <ListItemWrapper
              key={`${sc.id}-${metric.id}`}
              isLast={scorecards.length - 1 === index}
            >
              <Column
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <Link to={buildShowGlobalKpiList(sc.id)} target="_blank">
                  <Typography.Body type="Body 12" color="#0041ea">
                    {sc.title}
                  </Typography.Body>
                </Link>
              </Column>
            </ListItemWrapper>
          ))}
        </Card>
      </div>
    )}
  </>
);

export default ScorecardUsage;
