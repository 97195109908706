import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import MetricPreview from './MetricPreview';
import withDateFilter from '../../../../hocs/withDateFIlter';
import GqlClientContext from '../../../../contexts/GqlClientContext';
import useDateScope from '../../../../hooks/useDateScope';
import useNetworkingEffect from '../../../../hooks/useNetworkingEffect';
import aggregateMetric from '../../../../api/aggregateMetric';
import toMetricInput from '../../../../utils/metrics/toMetricInput';
import aggregateCompoundMetric from '../../../../api/aggregateCompoundMetric';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';
import MetricOptionsContext from '../../../../contexts/MetricOptionsContext';
import isDefined from '../../../../isDefined';
import { LAST_30_DAYS_INCLUDING_TODAY } from '../../../../components/DateInput/constants';
import DateInputContext from '../../../../contexts/DateInputContext';
import CompoundMetricsContext from '../../../../contexts/CompoundMetricsContext';
import useValueFormatters from '../../../../hooks/useValueFormatters';

const BLANK_FILTERS = [{}];

const useMetricPreview = (metric?: Metrics.NormalMetric) => {
  const { client } = useContext(GqlClientContext);
  const [data, setData] = useState<string>('');
  const dateScope = useDateScope({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { formatMetric } = useValueFormatters();

  useNetworkingEffect(() => {
    if (!metric) {
      return;
    }
    let isActive = true;
    const filterInput = {
      keywords: [],
      ranges: [],
      booleanFilters: [],
      wildcardFilters: [],
    } as FilterInput;
    setIsLoading(true);
    aggregateMetric(
      toMetricInput(metric),
      [filterInput],
      dateScope,
      client,
    ).then((d) => {
      if (!isActive) {
        return;
      }
      const v = d[0][metric.id] as number | string | string[];
      setData(formatMetric({ value: v, draftMetric: metric }));
      setIsLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, [dateScope, client, metric, formatMetric]);

  return {
    data,
    isLoading,
  };
};

const useCompoundMetricPreview = (compoundMetric?: Metrics.CompoundMetric) => {
  const { client } = useContext(GqlClientContext);
  const { metricOptionsLookup } = useContext(MetricOptionsContext);

  const dateScope = useDateScope({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<string>('');
  const [def, setDef] = useState<
    Metrics.CompoundMetricWithMetricDefs | undefined
  >();
  const { formatMetric } = useValueFormatters();

  useEffect(() => {
    if (!compoundMetric) {
      return undefined;
    }

    const metrics = compoundMetric.metricIds
      .map((mid) => metricOptionsLookup[mid])
      .filter(isDefined)
      .filter(metricTypeCheckers.isNormalMetric);
    setDef({
      id: compoundMetric.id,
      name: compoundMetric.name,
      metrics,
      expression: compoundMetric.expression,
    });
  }, [compoundMetric, metricOptionsLookup]);

  useNetworkingEffect(() => {
    if (!def || !compoundMetric) {
      return;
    }

    setIsLoading(true);
    let isActive = true;

    aggregateCompoundMetric(def, BLANK_FILTERS, dateScope, client).then((d) => {
      if (!isActive) {
        return;
      }

      const v = d[0][compoundMetric.id];
      const formattedV = formatMetric({
        value: v,
        draftMetric: compoundMetric,
      });

      setData(formattedV);
      setIsLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, [client, compoundMetric, dateScope, def, formatMetric]);

  return {
    data,
    isLoading,
  };
};

const useData = (metric: Metrics.NormalMetric | Metrics.CompoundMetric) => {
  const metricPreview = useMetricPreview(
    metricTypeCheckers.isNormalMetric(metric) ? metric : undefined,
  );
  const compoundMetricPreview = useCompoundMetricPreview(
    metricTypeCheckers.isCompoundMetric(metric) ? metric : undefined,
  );

  if (metricTypeCheckers.isNormalMetric(metric)) {
    return metricPreview;
  } else {
    return compoundMetricPreview;
  }
};

const useSetDataTypes = (
  metric: Metrics.NormalMetric | Metrics.CompoundMetric,
) => {
  const { compoundMetricInputsLookup } = useContext(CompoundMetricsContext);
  const { setDataTypes } = useContext(DateInputContext);
  useEffect(() => {
    if (metricTypeCheckers.isNormalMetric(metric)) {
      setDataTypes([metric.dataType]);
    } else {
      const metrics = compoundMetricInputsLookup[metric.id];
      if (!metrics) {
        setDataTypes([]);
        return;
      }

      setDataTypes(_.uniq(metrics.map((m) => m.dataType)));
    }
  }, [compoundMetricInputsLookup, metric, setDataTypes]);
};

const MetricPreviewContainer = ({
  metric,
}: {
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
}) => {
  const { data, isLoading } = useData(metric);
  useSetDataTypes(metric);

  return <MetricPreview data={data} isLoading={isLoading} />;
};

export default withDateFilter(MetricPreviewContainer, {
  initialRelativeDateRange: LAST_30_DAYS_INCLUDING_TODAY,
});
