import { useCallback, useContext } from 'react';
import moment from 'moment';

import ScorecardContext from 'contexts/ScorecardContext';
import AccountContext from 'contexts/AccountContext';
import useMetric from 'hooks/useMetric';
import useIsDynamicTarget from './useIsDynamicTarget';
import { isKpiRow } from '../kpiTypeCheckers';
import isIsoTargets from 'types/scorecardDates/isIsoTargets';
import formatFloat from 'api/getChartSeries/formatFloat';
import { getSuccessThresholdIndex } from 'screens/ScorecardsIndex/constants';

export const useGetTargetBands = (
  kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow,
) => {
  const { toIsoDate, scorecard } = useContext(ScorecardContext);
  const metric = useMetric(isKpiRow(kpi) ? kpi.metricId : undefined);
  const { isDemoAccount, demoAccountNow } = useContext(AccountContext);

  const isDynamicTarget = useIsDynamicTarget(
    isKpiRow(kpi) ? kpi.metricId : undefined,
    kpi,
  );

  const getDynamicTargetMultiplier = useCallback(
    (period: Period) => {
      if (!isDynamicTarget) {
        return undefined;
      }

      const now = moment
        .utc(isDemoAccount && demoAccountNow ? demoAccountNow : undefined)
        .startOf('day')
        .format('YYYY-MM-DD');
      if (period.endDate < now) {
        return undefined;
      }

      const daysInPeriod =
        Math.abs(
          moment(period.startDate).diff(moment(period.endDate), 'days'),
        ) + 1;
      const daysLeftInPeriod =
        Math.abs(moment(now).diff(moment(period.endDate), 'days')) + 1;
      const newMultiplier = 1 - daysLeftInPeriod / daysInPeriod;
      return newMultiplier;
    },
    [demoAccountNow, isDemoAccount, isDynamicTarget],
  );

  const getTargetBandsAndLabels = useCallback(
    (period: Period, dynamicTargetMultiplier: number | undefined) => {
      const { targets, isTargetsDisabled } = kpi;
      if (isTargetsDisabled) {
        return {
          targetBandsForPeriod: [],
          labelBandsForPeriod: [],
        };
      }

      const newBands = (() => {
        if (isIsoTargets(targets)) {
          const allTargetDates = Object.keys(targets.targets).sort();
          if (allTargetDates.length === 0) {
            return;
          }

          let bandToUse = allTargetDates[0];
          allTargetDates.forEach((date) => {
            if (date > bandToUse && date <= period.startDate) {
              bandToUse = date;
            }
          });

          return targets.targets[bandToUse];
        } else {
          const allTargetDates = [] as Scorecards.WeekDate[];
          Object.entries(targets.targets).forEach(([year, weeks]) => {
            if (weeks) {
              Object.entries(weeks).forEach(([week]) => {
                allTargetDates.push({ week, year });
              });
            }
          });

          let bandToUse = allTargetDates[0];
          allTargetDates.forEach((date) => {
            if (
              toIsoDate(date) > toIsoDate(bandToUse) &&
              toIsoDate(date) <= period.startDate
            ) {
              bandToUse = date;
            }
          });

          const yearTarget = targets.targets[bandToUse.year];

          return yearTarget ? yearTarget[bandToUse.week] : undefined;
        }
      })();

      if (dynamicTargetMultiplier && newBands) {
        const dynamic = newBands.map((b) =>
          formatFloat(
            Number.parseFloat(b) * dynamicTargetMultiplier,
            metric ? metric.formatting.precision : 2,
          ),
        ) as number[];

        return {
          targetBandsForPeriod: dynamic,
          labelBandsForPeriod: dynamic.map((p) => p.toString(10)),
        };
      } else {
        return {
          targetBandsForPeriod: newBands ? newBands.map(parseFloat) : undefined,
          labelBandsForPeriod: newBands,
        };
      }
    },
    [kpi, metric, toIsoDate],
  );

  const getSuccessThreshold = useCallback(
    (period: Period, targetBandsForPeriod: number[] | undefined) => {
      if (!targetBandsForPeriod) {
        return undefined;
      }

      return targetBandsForPeriod[
        getSuccessThresholdIndex(
          scorecard.scoringBandId,
          kpi.isColorOrderReversed,
        )
      ];
    },
    [kpi.isColorOrderReversed, scorecard.scoringBandId],
  );

  const getTargetBands = useCallback(
    (period: Period) => {
      const dynamicTargetMultiplier = getDynamicTargetMultiplier(period);
      const { targetBandsForPeriod, labelBandsForPeriod } =
        getTargetBandsAndLabels(period, dynamicTargetMultiplier);
      const successThreshold = getSuccessThreshold(
        period,
        targetBandsForPeriod,
      );
      return {
        targetBandsForPeriod,
        labelBandsForPeriod,
        isDynamic: dynamicTargetMultiplier !== undefined,
        successThreshold,
      };
    },
    [getDynamicTargetMultiplier, getSuccessThreshold, getTargetBandsAndLabels],
  );

  return getTargetBands;
};
