import React, { useContext } from 'react';
import QuickFilters from './QuickFilters';
import BoardContext from '../../../contexts/BoardContext';

export const isQuickFilter = (
  f: SavedFilter | QuickFilter,
): f is QuickFilter => {
  // @ts-ignore
  return f.filter !== undefined;
};

const QuickFiltersContainer = ({
  onQuickFilterChanged,
}: {
  onQuickFilterChanged?: () => void;
}) => {
  const {
    board,
    quickFilters,
    selectedQuickFilters,
    setSelectedQuickFilters,
    selectedBaseQuickFilters,
    setSelectedBaseQuickFilters,
  } = useContext(BoardContext);
  const { quickFilterOrder } = board;
  const onFilterToggled = (filter: SavedFilter | QuickFilter) => {
    if (isQuickFilter(filter)) {
      if (!!selectedBaseQuickFilters && !!setSelectedBaseQuickFilters) {
        const isSelected = selectedBaseQuickFilters.some(
          (f) => f.name === filter.name,
        );
        if (isSelected) {
          setSelectedBaseQuickFilters((fs) =>
            fs.filter((f) => f.name !== filter.name),
          );
        } else {
          setSelectedBaseQuickFilters((fs) => [...fs, filter]);
        }
      }
    } else {
      const isSelected = selectedQuickFilters.some((f) => f.id === filter.id);
      if (isSelected) {
        setSelectedQuickFilters((fs) => fs.filter((f) => f.id !== filter.id));
      } else {
        setSelectedQuickFilters((fs) => [...fs, filter]);
      }
    }
    if (onQuickFilterChanged) {
      onQuickFilterChanged();
    }
  };

  const orderedFilters = quickFilterOrder
    .map((filterId) => quickFilters.find((f) => f.id === filterId))
    .filter((f) => !!f) as (SavedFilter | QuickFilter)[];
  const filtersNotInOrder = quickFilters.filter(
    (f) => !quickFilterOrder.includes(f.id),
  );
  orderedFilters.push(...filtersNotInOrder);

  return (
    <QuickFilters
      quickFilters={orderedFilters}
      onFilterToggled={onFilterToggled}
      selectedQuickFilters={[
        ...selectedQuickFilters,
        ...(selectedBaseQuickFilters ? selectedBaseQuickFilters : []),
      ]}
    />
  );
};

export default QuickFiltersContainer;
