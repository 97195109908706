import getMetrics from './getMetrics';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import getResponseTerms from './getResponseTerms';

const getAllCurrentAndPreviousTermResponses = async ({
  chartDef,
  usedMetrics,
  filterInput,
  isDashboardGadget,
  currentDateScope,
  previousDateScope,
  client,
  groupByField,
  comparison,
}: {
  chartDef: V5ChartDefinition;
  usedMetrics: Metrics.Metric[];
  filterInput: FilterInput;
  isDashboardGadget?: boolean;
  currentDateScope: DateRangeInput;
  comparison?: PersistedComparisonType;
  previousDateScope: DateRangeInput;
  client: ApolloClient<NormalizedCacheObject>;
  groupByField: string;
}) => {
  const currentPeriodMetrics = await getMetrics({
    chartDef,
    filterInput,
    usedMetrics,
    isDashboardGadget,
    dateScope: currentDateScope,
    client,
    isIgnoringLimit: true,
  });
  const currentTerms = getResponseTerms(
    currentPeriodMetrics,
    groupByField,
    chartDef.dimensionA,
  );
  const previousPeriodMetrics = await getMetrics({
    chartDef,
    filterInput,
    usedMetrics,
    isDashboardGadget,
    dateScope: previousDateScope,
    client,
    isIgnoringLimit: true,
  });
  const previousTerms = getResponseTerms(
    previousPeriodMetrics,
    groupByField,
    chartDef.dimensionA,
  );
  if (!!currentTerms && !!previousTerms) {
    const currentPeriodMissingTermMetrics = await (async () => {
      const termsToFindInCurrent = previousTerms.filter(
        (t) => !currentTerms.includes(t),
      );

      if (termsToFindInCurrent.length === 0) {
        return undefined;
      }

      const keywordFilter = {
        field: groupByField,
        values: termsToFindInCurrent,
      } as KeywordFilter;

      return getMetrics({
        chartDef,
        filterInput,
        usedMetrics,
        isDashboardGadget,
        dateScope: currentDateScope,
        client,
        limitedKeywordFilter: keywordFilter,
        isIgnoringLimit: true,
      });
    })();
    if (currentPeriodMissingTermMetrics) {
      currentPeriodMetrics.push(...currentPeriodMissingTermMetrics);
    }
    const previousPeriodMissingTermMetrics = await (async () => {
      const termsToFindInPrevious = currentTerms.filter(
        (t) => !previousTerms.includes(t),
      );

      if (termsToFindInPrevious.length === 0) {
        return undefined;
      }

      const keywordFilter = {
        field: groupByField,
        values: termsToFindInPrevious,
      } as KeywordFilter;

      return getMetrics({
        chartDef,
        filterInput,
        usedMetrics,
        isDashboardGadget,
        dateScope: previousDateScope,
        client,
        limitedKeywordFilter: keywordFilter,
        isIgnoringLimit: true,
      });
    })();
    if (previousPeriodMissingTermMetrics) {
      previousPeriodMetrics.push(...previousPeriodMissingTermMetrics);
    }
  }

  const totalsRequest = getMetrics({
    chartDef,
    filterInput,
    getTotals: true,
    usedMetrics,
    isDashboardGadget,
    dateScope: currentDateScope,
    client,
    isIgnoringLimit: true,
  });
  const totalsComparisonRequest =
    comparison && chartDef.series.some((s) => !!s.matrixCellType)
      ? getMetrics({
          chartDef,
          filterInput,
          getTotals: true,
          usedMetrics,
          isDashboardGadget,
          dateScope: previousDateScope,
          client,
          isIgnoringLimit: true,
        })
      : Promise.resolve([]);
  const [totalsResponse, totalsComparisonResponse] = await Promise.all([
    totalsRequest,
    totalsComparisonRequest,
  ]);

  const responses = [
    currentPeriodMetrics,
    totalsResponse,
    previousPeriodMetrics,
    totalsComparisonResponse,
  ];

  return responses;
};

export default getAllCurrentAndPreviousTermResponses;
