import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import Loading from 'components/Loading/Loading';
import ActivityGridContext from '../context/ActivityGridContext';
import useChanges from '../CostsShow/hooks/useChanges';
import COSTS_SET_ROUTES from 'screens/DataManager/CostsSet/routes';
import useCategories from '../CostsShow/hooks/useCategories';
import { CLOSED_NAV_WIDTH, OPENED_NAV_WIDTH } from 'navigation/styles';
import NavSideBarContext from 'contexts/NavSideBarContext';
import { useLocation } from 'react-router-dom';

const ActivityGridProvider = ({
  children,
  activityCosts,
}: {
  children: JSX.Element | JSX.Element[];
  activityCosts: Costs.PersistedCostModel | undefined;
}) => {
  const costScrollerRef = useRef<HTMLDivElement>(null);
  const costListDivRef = useRef<HTMLDivElement>(null);
  const costListDivParentScrollerRef = useRef<HTMLDivElement>(null);

  const [focusedInterval, setFocusedInterval] = useState<Period | undefined>();
  const [isPendingUnfocus, setIsPendingUnfocus] = useState<boolean>(false);
  const [hoveredCategory, setHoveredCategory] = useState<string | undefined>();

  const handleUnfocus = useCallback(() => {
    // if we haven't focused on another input which would set isPendingUnfocus to false
    if (isPendingUnfocus) {
      setFocusedInterval(undefined);
      setIsPendingUnfocus(false);
    }
  }, [isPendingUnfocus]);

  useEffect(() => {
    const timer = setTimeout(handleUnfocus, 50);
    return () => clearTimeout(timer);
  }, [handleUnfocus]);

  const {
    discardChanges,
    saveChanges,
    isValid,
    isSavingError,
    setIsSavingError,
    invalidIntervals,
    hasUnsavedChanges,
    isDiscardedChanges,
    getCostValue,
    getCpmValue,
    updateCostValue,
    getPersistableCosts,
    intervals,
    isLoading,
    setIsDiscardedChanges,
    checkIsIntervalEstimated,
    getMutatedCosts,
    checkIsCpmEstimated,
  } = useChanges(activityCosts);

  const { pathname } = useLocation();
  const { toName, isLoading: isLoadingCategories } = useCategories();
  const { isOpen: isNavOpen } = useContext(NavSideBarContext);

  if (
    (isLoading || isLoadingCategories) &&
    pathname !== COSTS_SET_ROUTES.ACTIVITY_BASE_NEW
  ) {
    return (
      <div
        style={{
          width: `calc(100vw - ${
            isNavOpen ? OPENED_NAV_WIDTH : CLOSED_NAV_WIDTH
          }px)`,
          height: '100vh',
          display: 'flex',
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <ActivityGridContext.Provider
      value={{
        costScrollerRef,
        costListDivRef,
        costListDivParentScrollerRef,
        intervals,
        getCostValue,
        getCpmValue,
        updateCostValue,
        getPersistableCosts,
        discardChanges,
        saveChanges,
        isDiscardedChanges,
        setIsDiscardedChanges,
        checkIsIntervalEstimated,
        getMutatedCosts,
        checkIsCpmEstimated,
        setIsPendingUnfocus,
        focusedInterval,
        setFocusedInterval,
        hasUnsavedChanges,
        hoveredCategory,
        setHoveredCategory,
        invalidIntervals,
        isSavingError,
        setIsSavingError,
        toName,
        isValid,
      }}
    >
      {children}
    </ActivityGridContext.Provider>
  );
};

export default ActivityGridProvider;
