import React, { useCallback, useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import Row from 'components/Common/Row';
import { TOP_BAR_BOX_SHADOW_CSS } from 'components/ResourceTopBar';
import ALL_SETS_ROUTES from '../AllSets/routes';
import COSTS_SET_ROUTES from './routes';
import { DATA_MANAGER_TOP_ACTION_DIV_ID } from '../AllSets/constants';
import CostsShowContext from '../Costs/context/CostsShowContext';
import ActivityGridContext from '../Costs/context/ActivityGridContext';
import useConfirmation from '../DatasetDefinitions/DatasetsIndex/PerformanceDatasetWizard/useConfirmation';
import useIntervals from '../Costs/CostsShow/hooks/useIntervals';
import useWarningConfirmation from 'components/ConfirmationModals/hooks/useWarningConfirmation';
import { ModalTransition } from '@atlaskit/modal-dialog';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import RolesContext from 'contexts/RolesContext';
import PERMISSIONS from 'permissionDefinitions';
import publishCostModel from '../Costs/api/publishCostModel';
import GqlClientContext from 'contexts/GqlClientContext';
import ToastContext from 'contexts/ToastContext';
import AnalyticsContext from 'contexts/AnalyticsContext';
import useDangerConfirmation from 'components/ConfirmationModals/hooks/useDangerConfirmation';
import useGetTMS from 'components/OrderDetailsTabs/hooks/useGetTMS';
import Tooltip from 'components/Tooltip';
import Icon from 'kingpin/atoms/Icon';
import Colors2 from 'theme/Colors2';

const TopNavWrapper = styled.div`
  ${TOP_BAR_BOX_SHADOW_CSS};
  padding: 0px 32px;
  padding-bottom: 8px;
  background-color: white;
`;

const GoBackButton = styled(Row)`
  align-items: center;
  margin-right: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const TopNavigator = () => {
  const navigate = useNavigate();
  const breadcrumbPrefix = 'Costs';

  const name = 'Activity Based Costs';
  const { costModel, refreshModel } = useContext(CostsShowContext);
  const { currentPermissions } = useContext(RolesContext);
  const { client } = useContext(GqlClientContext);
  const { showToast } = useContext(ToastContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const hasPublishPermission = currentPermissions.includes(
    PERMISSIONS.DATA_MANAGEMENT.ACTIVITY_COSTS_PUBLISH,
  );

  const { pathname } = useLocation();
  const {
    discardChanges,
    saveChanges,
    hasUnsavedChanges,
    getMutatedCosts,
    invalidIntervals,
    setIsSavingError,
  } = useContext(ActivityGridContext);

  const { getConfirmationThat, DangerConfirmation } = useWarningConfirmation({
    title: 'Unsaved Changes',
    continueText: 'Close without saving',
  });

  const { orders } = useGetTMS();

  const goBack = useCallback(() => {
    if (pathname === COSTS_SET_ROUTES.ACTIVITY_BASE_EDIT) {
      navigate(COSTS_SET_ROUTES.ACTIVITY_BASE);
      return;
    }

    if (!hasUnsavedChanges) {
      navigate(ALL_SETS_ROUTES.COSTS);
      return;
    }

    getConfirmationThat(
      'If you navigate away, all changes made will be lost.',
    ).then((isConfirmed) => {
      if (isConfirmed) {
        navigate(ALL_SETS_ROUTES.COSTS);
      }
    });
  }, [getConfirmationThat, hasUnsavedChanges, navigate, pathname]);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isPublishing, setIsPublishing] = useState<boolean>(false);

  const { startDateToPeriod } = useIntervals({ activityCosts: costModel });

  const { getConfirmation, ConfirmationModal } = useConfirmation({
    title: 'Costs for the following intervals will be saved',
    bodyComponent: (
      <div style={{ marginBottom: '16px' }}>
        {getMutatedCosts()
          .map((cost) =>
            startDateToPeriod({
              startDate: cost.startDate,
              interval: costModel ? costModel.interval : 'month',
            }),
          )
          .map((interval) => (
            <div style={{ display: 'flex' }} key={interval.label}>
              <Typography.Body type="Body 12" key={interval.label}>
                {interval.label}
              </Typography.Body>
            </div>
          ))}
      </div>
    ),
    confirmText: 'Save',
    noMarginBottom: true,
  });

  const {
    getConfirmationThat: getPublishConfirmation,
    DangerConfirmation: PublishConfirmation,
  } = useDangerConfirmation({
    title: `Publishing the cost model will rewrite the ${orders[0]} dataset from ${moment(costModel?.startDate).format("MMM DD 'YY")}. Are you sure you want to Publish changes now?`,
    checkboxText: `I understand the impact on the ${orders[0]} dataset as a result of publishing the cost model`,
  });

  const handleSave = useCallback(async () => {
    if (invalidIntervals.length !== 0) {
      setIsSavingError(true);
    } else {
      const isConfirmed = await getConfirmation();
      if (!isConfirmed) {
        return;
      }
      setIsSaving(true);
      await saveChanges();
      setIsSaving(false);
    }
  }, [getConfirmation, invalidIntervals.length, saveChanges, setIsSavingError]);

  const handlePublish = useCallback(async () => {
    if (invalidIntervals.length !== 0 || !costModel) {
      setIsSavingError(true);
    } else {
      getPublishConfirmation('').then(async (isConfirmed) => {
        if (isConfirmed) {
          setIsPublishing(true);
          await publishCostModel({
            costModelId: costModel.id,
            client,
          });
          await refreshModel();
          showToast('Cost Model Published');
          trackEvent('Costs - Published', {
            costModel: costModel.modelType,
          });
          setIsPublishing(false);
        }
      });
    }
  }, [
    client,
    costModel,
    getPublishConfirmation,
    invalidIntervals.length,
    refreshModel,
    setIsSavingError,
    showToast,
    trackEvent,
  ]);

  const openSettings = useCallback(() => {
    trackEvent('Costs - Settings opened', {
      costModel: costModel?.modelType,
    });
  }, [costModel, trackEvent]);

  return (
    <TopNavWrapper>
      <Row centerAlign spaceBetween style={{ marginTop: 10, marginBottom: 8 }}>
        <Row centerAlign spaceBetween>
          <Typography.Header type="H3">Data Manager</Typography.Header>
          <div id={DATA_MANAGER_TOP_ACTION_DIV_ID} />
        </Row>
        {costModel && (
          <Row centerAlign>
            <Link
              to={
                pathname === COSTS_SET_ROUTES.ACTIVITY_BASE_EDIT
                  ? COSTS_SET_ROUTES.ACTIVITY_BASE
                  : hasUnsavedChanges
                    ? '#'
                    : COSTS_SET_ROUTES.ACTIVITY_BASE_EDIT
              }
            >
              <Tooltip
                content={
                  hasUnsavedChanges
                    ? `You must ${costModel.isDraft ? 'Save Draft' : 'Update & Apply Costs'} or Discard Changes before you can edit settings`
                    : ''
                }
              >
                <Button
                  onClick={openSettings}
                  size="Small"
                  type="Tertiary"
                  label={
                    pathname === COSTS_SET_ROUTES.ACTIVITY_BASE_EDIT
                      ? 'Go To Costs Input'
                      : 'Settings'
                  }
                  icon={
                    pathname === COSTS_SET_ROUTES.ACTIVITY_BASE_EDIT
                      ? undefined
                      : 'settings-empty'
                  }
                  isDisabled={hasUnsavedChanges}
                />
              </Tooltip>
            </Link>
            {pathname !== COSTS_SET_ROUTES.ACTIVITY_BASE_EDIT && (
              <>
                <div
                  style={{
                    width: '1px',
                    height: '28px',
                    backgroundColor: '#D9D9D9',
                    marginRight: '24px',
                    marginLeft: '24px',
                  }}
                ></div>
                <div style={{ marginRight: '16px' }}>
                  <Button
                    type="Tertiary"
                    size="Small"
                    onClick={discardChanges}
                    label="Discard Changes"
                    isDisabled={!hasUnsavedChanges}
                  />
                </div>

                <div>
                  <Button
                    type="Primary"
                    size="Small"
                    onClick={handleSave}
                    label={
                      costModel.isDraft ? 'Save Draft' : 'Update & Apply Costs'
                    }
                    isDisabled={!hasUnsavedChanges}
                    isLoading={isSaving}
                  />
                </div>

                {costModel.isDraft && hasPublishPermission && (
                  <>
                    <div
                      style={{
                        width: '1px',
                        height: '28px',
                        backgroundColor: '#D9D9D9',
                        marginRight: '24px',
                        marginLeft: '24px',
                      }}
                    ></div>
                    <Button
                      type="Primary"
                      size="Small"
                      onClick={handlePublish}
                      label={'Publish'}
                      isLoading={isPublishing}
                    />
                  </>
                )}
              </>
            )}
          </Row>
        )}
      </Row>
      <Row centerAlign style={{ marginBottom: '8px' }}>
        <div style={{ marginRight: 8 }}>
          <GoBackButton onClick={goBack}>
            <div style={{ marginRight: '8px', display: 'flex' }}>
              <Icon color={Colors2.Secondary.info} icon={'arrow-left'} />
            </div>
            <Typography.Body
              type={'Button Text'}
              color={Colors2.Secondary.info}
            >
              Back
            </Typography.Body>
          </GoBackButton>
        </div>
        <Typography.Body type="Body 12">
          {costModel
            ? `${breadcrumbPrefix} / ${name} (${costModel.dateField}) - Starting from ${costModel.startDate}`
            : `${breadcrumbPrefix} / ${name}`}
        </Typography.Body>
      </Row>
      {ConfirmationModal}
      <ModalTransition>{DangerConfirmation}</ModalTransition>
      <ModalTransition>{PublishConfirmation}</ModalTransition>
    </TopNavWrapper>
  );
};

export default TopNavigator;
