import { ReactComponent as AccountCircle } from './assets/AccountCircle/24px.svg';
import { ReactComponent as AddIcon } from './assets/Add/24px.svg';
import { ReactComponent as AdminPanelSettings } from './assets/admin-panel-settings.svg';
import { ReactComponent as ArrowDown } from './assets/Arrows/Down/16px.svg';
import { ReactComponent as ArrowUp } from './assets/Arrows/Up/16px.svg';
import { ReactComponent as ArrowLeft } from './assets/Arrows/Left/24px.svg';
import { ReactComponent as ArrowRight } from './assets/Arrows/Right/24px.svg';
import { ReactComponent as BellIcon } from './assets/Bell/24px.svg';
import { ReactComponent as BillingCard } from './assets/Billing/Card/24px.svg';
import { ReactComponent as BillingCVC } from './assets/Billing/CVC/24px.svg';
import { ReactComponent as BrandingLarge } from './assets/Branding/large.svg';
import { ReactComponent as BrandingSmall } from './assets/Branding/small.svg';
import { ReactComponent as Building } from './assets/Building/24px.svg';
import { ReactComponent as Burger } from './assets/Burger/24px.svg';
import { ReactComponent as Calendar } from './assets/Calendar/24px.svg';
import { ReactComponent as ChartFilled } from './assets/Chart/Filled/24px.svg';
import { ReactComponent as ChartOutlined } from './assets/Chart/Outline/24px.svg';
import { ReactComponent as Checkmark } from './assets/Checkmark/24px.svg';
import { ReactComponent as ChevronDown } from './assets/Chevron/Down/24px.svg';
import { ReactComponent as ChevronUp } from './assets/Chevron/Up/24px.svg';
import { ReactComponent as ChevronLeft } from './assets/Chevron/Left/24px.svg';
import { ReactComponent as ChevronRight } from './assets/Chevron/Right/24px.svg';
import { ReactComponent as CircleCheckmark } from './assets/Circle Checkmark/24px.svg';
import { ReactComponent as Comment } from './assets/Comment/20px.svg';
import { ReactComponent as Copy } from './assets/Copy/24px.svg';
import { ReactComponent as CreateFile } from './assets/Create File/24px.svg';
import { ReactComponent as CreateTemplate } from './assets/Create Template/24px.svg';
import { ReactComponent as Cross } from './assets/Cross/24px.svg';
import { ReactComponent as Cup } from './assets/Cup/24px.svg';
import { ReactComponent as Database } from './assets/Database/24px.svg';
import { ReactComponent as Datasets } from './assets/Datasets/24px.svg';
import { ReactComponent as Dashboard } from './assets/Dashboard/24px.svg';
import { ReactComponent as DotsMenu } from './assets/Dots Menu/24px.svg';
import { ReactComponent as Drag } from './assets/Drag/24px.svg';
import { ReactComponent as EditFilled } from './assets/Edit/Filled/24px.svg';
import { ReactComponent as EditOutlined } from './assets/Edit/Outlined/24px.svg';
import { ReactComponent as Export } from './assets/Export/24px.svg';
import { ReactComponent as Eye } from './assets/Eye/24px.svg';
import { ReactComponent as File_Upload } from './assets/File_Upload/24px.svg';
import { ReactComponent as Filters } from './assets/Filters/24px.svg';
import { ReactComponent as FindInPage } from './assets/FindInPage/24px.svg';
import { ReactComponent as Flag } from './assets/Flag/24px.svg';
import { ReactComponent as Grid } from './assets/Grid/24px.svg';
import { ReactComponent as Help } from './assets/Help/24px.svg';
import { ReactComponent as Home } from './assets/Home/24px.svg';
import { ReactComponent as Info } from './assets/Info/24px.svg';
import { ReactComponent as Integration } from './assets/Integration/24px.svg';
import { ReactComponent as Invoice } from './assets/Invoice/24px.svg';
import { ReactComponent as KebabMenu } from './assets/Kebab Menu/24px.svg';
import { ReactComponent as Keyboard } from './assets/Keyboard/24px.svg';
import { ReactComponent as LeftPanelClose } from './assets/LeftPanelClose/24px.svg';
import { ReactComponent as LibraryAdd } from './assets/library-add.svg';
import { ReactComponent as Locale } from './assets/Locale/24px.svg';
import { ReactComponent as Mail } from './assets/Mail/24px.svg';
import { ReactComponent as Mention } from './assets/Mention/20px.svg';
import { ReactComponent as Minus } from './assets/Minus/24px.svg';
import { ReactComponent as Move } from './assets/Move/24px.svg';
import { ReactComponent as PersonSearch } from './assets/person-search.svg';
import { ReactComponent as NavigationGoals } from './assets/Navigation/Goals.svg';
import { ReactComponent as NavigationMetrics } from './assets/Navigation/Metrics.svg';
import { ReactComponent as NoteStackAdd } from './assets/NoteStackAdd/24px.svg';
import { ReactComponent as OpenLink } from './assets/Open Link/24px.svg';
import { ReactComponent as PushPin } from './assets/Push-pin/24px.svg';
import { ReactComponent as Refresh } from './assets/Refresh/24px.svg';
import { ReactComponent as Search } from './assets/Search/24px.svg';
import { ReactComponent as SettingsEmpty } from './assets/Settings/Empty/24px.svg';
import { ReactComponent as SettingsFilled } from './assets/Settings/Filled/24px.svg';
import { ReactComponent as Share } from './assets/Share/24px.svg';
import { ReactComponent as SLA } from './assets/SLA/24px.svg';
import { ReactComponent as StarEmpty } from './assets/Star/Empty/24px.svg';
import { ReactComponent as StarFilled } from './assets/Star/Filled/24px.svg';
import { ReactComponent as Subscription } from './assets/Subscription/24px.svg';
import { ReactComponent as Table } from './assets/Table/24px.svg';
import { ReactComponent as Updates } from './assets/Updates/24px.svg';
import { ReactComponent as VisibilityOff } from './assets/visibility_off/24px.svg';
import { ReactComponent as Warning } from './assets/Warning/24px.svg';
import { ReactComponent as Workspace } from './assets/Workspace/24px.svg';
import { ReactComponent as CloudDownload } from './assets/Cloud Download/cloud-download.svg';

import Colors2 from '../../../theme/Colors2';

export const IconMap: Record<
  Kingpin.Icon,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  'account-circle': AccountCircle,
  add: AddIcon,
  'admin-panel-settings': AdminPanelSettings,
  'arrow-down': ArrowDown,
  'arrow-up': ArrowUp,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  bell: BellIcon,
  'billing-card': BillingCard,
  'billing-csv': BillingCVC,
  'branding-large': BrandingLarge,
  'branding-small': BrandingSmall,
  building: Building,
  burger: Burger,
  calendar: Calendar,
  'chart-filled': ChartFilled,
  'chart-outlined': ChartOutlined,
  checkmark: Checkmark,
  'chevron-down': ChevronDown,
  'chevron-up': ChevronUp,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  circleCheckmark: CircleCheckmark,
  'cloud-download': CloudDownload,
  copy: Copy,
  comment: Comment,
  'create-file': CreateFile,
  'create-template': CreateTemplate,
  cross: Cross,
  cup: Cup,
  database: Database,
  datasets: Datasets,
  dashboard: Dashboard,
  'dots-menu': DotsMenu,
  drag: Drag,
  'edit-filled': EditFilled,
  'edit-outline': EditOutlined,
  export: Export,
  eye: Eye,
  fileUpload: File_Upload,
  filters: Filters,
  'find-in-page': FindInPage,
  flag: Flag,
  grid: Grid,
  help: Help,
  home: Home,
  info: Info,
  integration: Integration,
  invoice: Invoice,
  kebabMenu: KebabMenu,
  keyboard: Keyboard,
  'left-panel-close': LeftPanelClose,
  'library-add': LibraryAdd,
  locale: Locale,
  mail: Mail,
  mention: Mention,
  minus: Minus,
  move: Move,
  'navigation-goals': NavigationGoals,
  'navigation-metrics': NavigationMetrics,
  'note-stack-add': NoteStackAdd,
  'open-link': OpenLink,
  'person-search': PersonSearch,
  pushPin: PushPin,
  refresh: Refresh,
  search: Search,
  'settings-empty': SettingsEmpty,
  'settings-filled': SettingsFilled,
  share: Share,
  sla: SLA,
  'star - empty': StarEmpty,
  'start - filled': StarFilled,
  subscription: Subscription,
  table: Table,
  updates: Updates,
  visibilityOff: VisibilityOff,
  warning: Warning,
  workspace: Workspace,
};

const Icon = ({
  icon,
  color = Colors2.Grey['1'],
  width = 16,
  height = 16,
}: {
  icon: Kingpin.Icon;
  color?: string;
  width?: number;
  height?: number;
}) => {
  const IconComp = IconMap[icon];

  return <IconComp color={color} width={width} height={height} />;
};

export default Icon;
