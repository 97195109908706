import metricTypeCheckers from '../../../types/metricTypeCheckers';

const BASE = `/data-manager/datasets/:dataset`;

const FIELD_SETTINGS = `/data-manager/datasets/:dataset/fields`;

const buildBase = (dataset: string) => `/data-manager/datasets/${dataset}`;

const buildMetrics = (dataset: string) => `${buildBase(dataset)}/metrics`;

const buildCompoundMetrics = (dataset: string) =>
  `${buildBase(dataset)}/compound-metrics`;

export const getMetricLinkForDataset = (
  metric: Metrics.Metric,
  dataset: string,
) => {
  if (metricTypeCheckers.isCompoundMetric(metric)) {
    return `${buildCompoundMetrics(dataset)}?metricId=${metric.id}`;
  } else if (metricTypeCheckers.isNormalMetric(metric)) {
    return `${buildMetrics(dataset)}?metricId=${metric.id}`;
  } else {
    return '';
  }
};

const buildSearch = (dataset: string) =>
  `/data-manager/datasets/${dataset}/search`;

export const getValidationReportLink = ({
  dataset,
  reportId,
}: {
  dataset: string;
  reportId: string;
}) => `${buildBase(dataset)}/validation-reports/${reportId}`;

const buildFieldSettings = (dataset: string) => `${buildBase(dataset)}/fields`;

const SPECIFIC_SET_ROUTES = {
  BASE,
  buildBase,
  FIELD_SETTINGS,
  buildFieldSettings,
  METRICS: `${BASE}/metrics`,
  SHOW_METRIC: `${BASE}/metrics/:metricId`,
  buildMetrics,
  COMPOUND_METRICS: `${BASE}/compound-metrics`,
  SHOW_COMPOUND_METRIC: `${BASE}/compound-metrics/:compoundMetricId`,
  buildCompoundMetrics,
  SHOW_REPORT: `${BASE}/validation-reports/:reportId`,
  SHOW_SEARCH: `${BASE}/search`,
  buildSearch,
};

export default SPECIFIC_SET_ROUTES;
