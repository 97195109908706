import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import { Link } from 'react-router-dom';
import { buildReportShow } from '../../navigation/appRoutes';
import Colors2 from '../../theme/Colors2';

const VisualisationUsageDetails = ({
  reports,
}: {
  reports: PersistedReportType[];
}) => (
  <div
    style={{
      padding: '16px',
      minWidth: 230,
      pointerEvents: 'auto',
      maxHeight: 300,
      overflowY: 'auto',
    }}
    data-testid="MetricTooltip"
  >
    <Typography.Header type="H5">Reports</Typography.Header>
    {reports.map((report, index) => (
      <Link to={buildReportShow(report.id)} target={'_blank'} key={report.id}>
        <div
          style={{
            marginBottom: 4,
            backgroundColor: index % 2 === 0 ? 'white' : Colors2.Grey['8'],
          }}
        >
          <Typography.Body type="Link">{report.name}</Typography.Body>
        </div>
      </Link>
    ))}
    {reports.length === 0 && (
      <Typography.Body type="Body 12">None</Typography.Body>
    )}
  </div>
);

export default VisualisationUsageDetails;
