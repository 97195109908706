import React, { ChangeEvent } from 'react';

import Row from 'components/Common/Row';
import Inputs from 'components/Inputs';
import Dropdown from 'components/Inputs/Dropdown';
import Toggle from 'components/Inputs/Toggle';
import Typography from 'kingpin/atoms/Typography';
import ShadowedList from 'components/ShadowedList';
import { LabelDiv } from 'screens/Boards/BoardCreationWizard';
import IntervalPicker from '../../IntervalPicker/IntervalPicker';
import EmptyVis from '../../Sections/EmptyVis';
import Performance from '../../Sections/Performance';
import WidgetFooter from '../../WidgetFooter';
import { WidgetWrapper } from '../MetricSlideoutWizard';

const PerformanceSlideoutWizard = ({
  saveWidget,
  onTitleChanged,
  setWidgetDateRange,
  isDisabled,
  filterInput,
  dateScope,
  state,
  dispatch,
  isPerformanceFieldRemoved,
  fieldsOptions,
  close,
}: {
  saveWidget: () => void;
  onTitleChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  setWidgetDateRange: (
    newWidgetDateRange: PerformanceBoardTypes.SlideOut.SlideOutDateRange,
  ) => void;
  isDisabled: boolean;
  filterInput: FilterInput | undefined;
  dateScope: DateRangeInput | undefined;
  state: PerformanceWidgetReducer.WizardState;
  dispatch: React.Dispatch<PerformanceWidgetReducer.WizardAction>;
  isPerformanceFieldRemoved: boolean;
  fieldsOptions: DropdownOption[];
  close: () => void;
}) => {
  return (
    <>
      <ShadowedList>
        <WidgetWrapper>
          <div style={{ marginBottom: 24 }}>
            <LabelDiv>
              <Typography.Body type="Label">Widget Title</Typography.Body>
            </LabelDiv>
            <Inputs.TextInput
              value={state.title}
              placeholder="Enter Title"
              onChange={onTitleChanged}
              maxLength={40}
              autoFocus
              data-testid={'title'}
            />
          </div>
          <div style={{ marginBottom: 24 }}>
            <IntervalPicker
              widgetDateRange={state.widgetDateRange}
              setWidgetDateRange={setWidgetDateRange}
            />
          </div>
          <div style={{ width: '100%', marginBottom: 16 }}>
            <LabelDiv>
              <Typography.Body type="Label">
                Select A Performance Field
              </Typography.Body>
            </LabelDiv>
            <Dropdown
              options={fieldsOptions}
              placeholder={'Select Field'}
              isDisabled={fieldsOptions.length === 0}
            />
            {fieldsOptions.length === 0 && (
              <Typography.Body type="Body 12">
                No Performance Fields
              </Typography.Body>
            )}
          </div>
          <Row centerAlign style={{ marginBottom: 24 }}>
            <div style={{ marginRight: 8 }}>
              <Toggle
                value={state.showReasonCodes}
                onChange={(newValue) => {
                  dispatch({
                    type: 'SET SHOW REASON CODES' as 'SET SHOW REASON CODES',
                    payload: {
                      newShowReasonCode: newValue,
                    },
                  });
                }}
              />
            </div>
            <Typography.Body type="Body 12">Show Reason Codes</Typography.Body>
          </Row>
          {isDisabled ||
          !filterInput ||
          !dateScope ||
          !state.performanceSection ? (
            <EmptyVis isFieldRemoved={isPerformanceFieldRemoved} />
          ) : (
            <>
              <LabelDiv>
                <Typography.Body type="Label">Preview</Typography.Body>
              </LabelDiv>
              <Performance section={state.performanceSection} />
            </>
          )}
        </WidgetWrapper>
      </ShadowedList>

      <WidgetFooter
        saveWidget={saveWidget}
        sectionType="Performance Graph"
        isDisabled={isDisabled}
        close={close}
      />
    </>
  );
};

export default PerformanceSlideoutWizard;
