import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import Colors2 from 'theme/Colors2';
import BoardContext from 'contexts/BoardContext';
import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';
import Row from 'components/Common/Row';
import Button from 'kingpin/atoms/Button';
import Tooltip from 'components/Tooltip';
import { LabelDiv } from 'screens/Boards/BoardCreationWizard';
import ReasonCodesListItem from './ReasonCodesListItem';
import BoardSettingsHeader from '../BoardSettingsHeader/BoardSettingsHeader';
import useUpdateReasonCodes from '../hooks/useUpdateReasonCodes';
import useValidateText from 'hooks/useValidateText';
import TextInput from '../../../../../kingpin/atoms/TextInput';

const BoardReasonCodeSettings = () => {
  const { reasonCodes } = useContext(BoardContext);
  const [codeName, setCodeName] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [repeatedError, setRepeatedError] = useState<boolean>(false);
  const [isReasonCodeValid, setIsReasonCodeValid] = useState<boolean>(true);
  const updateReasonCodes = useUpdateReasonCodes();
  const validateText = useValidateText();

  const INVALID_REASON_CODE_TOOLTIP =
    'Your reason code contains illegal characters (":", ";", "<", ">").';

  useEffect(() => {
    if (codeName === undefined || codeName === null) {
      setIsReasonCodeValid(true);
      return;
    }

    setIsReasonCodeValid(validateText({ text: codeName }));
  }, [codeName, validateText]);

  const onCodeNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const newCode = event.target.value;
    setCodeName(newCode);
    setRepeatedError(
      reasonCodes.some((rc) => rc.code.toLowerCase() === newCode.toLowerCase()),
    );
  };

  const filteredCodes = reasonCodes.filter((c) =>
    c.code.toLowerCase().includes(searchText.toLowerCase()),
  );

  const handleAddCode = useCallback(() => {
    updateReasonCodes({ mode: 'add', addCode: codeName });
    setCodeName('');
  }, [codeName, updateReasonCodes]);

  const handleRemoveCode = useCallback(
    ({ code }: { code: ReasonCode }) => {
      updateReasonCodes({ mode: 'remove', code });
    },
    [updateReasonCodes],
  );

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ height: '232px' }}>
        <BoardSettingsHeader
          title="Reason Codes"
          description="Add reason codes to your dataset"
        />
        <div style={{ marginBottom: '24px' }}>
          <LabelDiv>
            <Typography.Body type="Label">New Reason Code</Typography.Body>
          </LabelDiv>
          <Row centerAlign>
            <Inputs.TextInput
              value={codeName}
              onChange={onCodeNameChanged}
              state={repeatedError ? 'Error' : undefined}
              maxLength={40}
              autoFocus
              data-testid={'title'}
              style={{ flex: '1' }}
              inputSize="Small"
            />
            <div style={{ marginLeft: '8px' }}>
              <Tooltip
                content={
                  isReasonCodeValid ? undefined : INVALID_REASON_CODE_TOOLTIP
                }
              >
                <Button
                  type="Tertiary"
                  size="Small"
                  onClick={handleAddCode}
                  label={'Add Code'}
                  testId={'add-reason-code'}
                  isDisabled={repeatedError || !isReasonCodeValid}
                />
              </Tooltip>
            </div>
          </Row>
          {repeatedError && (
            <Typography.Body type="Body 12">
              This reason codes already exsists
            </Typography.Body>
          )}
        </div>
        {reasonCodes.length !== 0 ? (
          <>
            <LabelDiv>
              <Typography.Body type="Label">
                Reason Codes{' '}
                <span style={{ color: Colors2.Grey['5'] }}>
                  ({filteredCodes.length})
                </span>
              </Typography.Body>
            </LabelDiv>
            <div style={{ marginBottom: '8px' }}>
              <TextInput
                value={searchText}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setSearchText(event.target.value);
                }}
                placeholder="Search Fields"
                icon="search"
                width="100%"
                autoFocus
                inputSize="Small"
              />
            </div>
          </>
        ) : (
          <Typography.Body type="Body 12">
            There are no Reason Codes yet
          </Typography.Body>
        )}
      </div>

      {filteredCodes.length !== 0 ? (
        <div style={{ flex: 1, overflowY: 'scroll' }}>
          {filteredCodes.map((rc) => (
            <ReasonCodesListItem
              code={rc}
              key={rc.id}
              handleRemove={() => handleRemoveCode({ code: rc })}
            />
          ))}
        </div>
      ) : reasonCodes.length !== 0 ? (
        <Typography.Body type="Body 12">
          There is no Reason Codes that match your criteria
        </Typography.Body>
      ) : null}
    </div>
  );
};

export default BoardReasonCodeSettings;
