import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import MetricsComponent from './Metrics';
import BoardContext from '../../../contexts/BoardContext';
import Loading from '../../Loading/Loading';
import dateRangeInputToAmericanLabel from '../../../dateRangeInputToAmericanLabel';
import isDefined from '../../../isDefined';
import MetricOptionsContext from '../../../contexts/MetricOptionsContext';
import Typography from 'kingpin/atoms/Typography';
import GqlClientContext from '../../../contexts/GqlClientContext';
import useNetworkingEffect from '../../../hooks/useNetworkingEffect';
import metricTypeCheckers from '../../../types/metricTypeCheckers';
import CompoundMetricsContext from '../../../contexts/CompoundMetricsContext';
import isPerformanceBoard from '../../../isPerformanceBoard';
import runAggregation from './runAggregation';

const useMetricList = (
  metricList: Metrics.Metric[],
  filterInput: FilterInput,
  dateScope: DateRangeInput,
) => {
  const { client } = useContext(GqlClientContext);
  const { normalMetrics: allNormalMetrics } = useContext(MetricOptionsContext);
  const { compoundMetricInputsLookup } = useContext(CompoundMetricsContext);
  const [data, setData] = useState<MetricsResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useNetworkingEffect(() => {
    let isActive = true;
    setIsLoading(true);
    const compoundMetrics = metricList.filter(
      metricTypeCheckers.isCompoundMetric,
    );
    const normalMetrics = metricList.filter(metricTypeCheckers.isNormalMetric);
    const metricsFromCompoundMetrics = _.uniqBy(
      compoundMetrics.reduce((metrics, cm) => {
        const newMetrics = compoundMetricInputsLookup[cm.id];
        if (newMetrics) {
          return [...metrics, ...newMetrics];
        }
        return metrics;
      }, [] as Metrics.NormalMetric[]),
      'id',
    );

    const queryMetrics = _.uniqBy(
      [...normalMetrics, ...metricsFromCompoundMetrics],
      'id',
    );
    const queryExpressions = compoundMetrics.map((m) => {
      return {
        id: m.id,
        expression: m.expression,
      } as Expression2;
    });

    if (queryMetrics.length === 0) {
      return;
    }
    runAggregation({
      client,
      metrics: queryMetrics,
      expressions: queryExpressions,
      filterInput: filterInput ? [filterInput] : [],
      dateScope,
    }).then((response) => {
      if (isActive) {
        setData(response);
        setIsLoading(false);
      }
    });

    return () => {
      isActive = false;
    };
  }, [allNormalMetrics, filterInput, metricList, dateScope, client]);

  return {
    data,
    isLoading,
  };
};

export const MetricsContainer = ({
  filterInput,
  dateScope,
  metricIds,
  title,
  homeViz,
}: {
  filterInput: FilterInput;
  dateScope: DateRangeInput;
  metricIds: string[];
  title: string;
  homeViz?: boolean;
}) => {
  const { metricOptions } = useContext(MetricOptionsContext);
  const [metrics, setMetrics] = useState<Metrics.Metric[]>([]);
  const { data, isLoading: isLoadingData } = useMetricList(
    metrics,
    filterInput,
    dateScope,
  );

  useEffect(() => {
    const ms = metricIds
      .map((mId) => metricOptions.find((m) => m.id === mId))
      .filter(isDefined);
    setMetrics(ms);
  }, [metricOptions, metricIds]);

  if (isLoadingData || !data) {
    return (
      <>
        <Typography.Header type="H5">{title}</Typography.Header>
        <Loading isSmall />
      </>
    );
  }

  const dateRange = dateRangeInputToAmericanLabel(dateScope);

  return (
    <MetricsComponent
      metrics={metrics}
      data={data}
      dateRange={dateRange}
      title={title}
      homeViz={homeViz}
    />
  );
};

const Gate = ({
  filterInput,
  dateScope,
  title,
}: {
  filterInput?: FilterInput;
  dateScope?: DateRangeInput;
  title: string;
}) => {
  const { board } = useContext(BoardContext);
  if (isPerformanceBoard(board)) {
    return null;
  }

  if (board.metricIds && !!filterInput && !!dateScope) {
    return (
      <MetricsContainer
        filterInput={filterInput}
        dateScope={dateScope}
        metricIds={board.metricIds}
        title={title}
      />
    );
  } else {
    return null;
  }
};

export default Gate;
