import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import AcceptInvite from './AcceptInvite';
import appRoutes from '../../navigation/appRoutes';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import Loading from '../../components/Loading';
import CloudFunctionClientContext from '../../contexts/CloudFunctionClientContext';

const parseQueryParams = () => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  return {
    token: params.get('token') as string,
    email: decodeURIComponent(params.get('email') as string),
    carrierName: params.get('carrierName') as string,
    userId: params.get('userId') as string,
  };
};

export const useOnPasswordChanged = ({
  setPassword,
  passwordConfirm,
  setReEnterPasswordError,
  setPasswordErrors,
}: {
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  passwordConfirm: string;
  setReEnterPasswordError: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setPasswordErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}) => {
  const onPasswordChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newPw = event.target.value;
      setPassword(newPw);
      const isValidPw =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9])[a-zA-Z\d\w\W]{10,}$/.test(
          newPw,
        );
      if (passwordConfirm !== '') {
        if (passwordConfirm !== newPw) {
          setReEnterPasswordError('The passwords do not match');
        } else {
          setReEnterPasswordError(undefined);
        }
      }

      if (isValidPw) {
        setPasswordErrors(undefined);
      } else {
        const isLongEnough = /^[a-zA-Z\d\w\W]{10,}$/.test(newPw);
        const hasOneCapital = /(?=.*[A-Z])/.test(newPw);
        const hasOneNumber = /(?=.*\d)/.test(newPw);
        const hasOneLowerCase = /(?=.*[a-z])/.test(newPw);

        const hasSpecialCharacter = /(?=.*[^a-zA-Z0-9])/.test(newPw);
        const errors = [];
        if (!isLongEnough) {
          errors.push('At least 10 characters in total');
        }
        if (!hasOneCapital) {
          errors.push('At least 1 uppercase character');
        }
        if (!hasOneNumber) {
          errors.push('At least 1 number');
        }
        if (!hasOneLowerCase) {
          errors.push('At least 1 lowercase character');
        }
        if (!hasSpecialCharacter) {
          errors.push('At least 1 special character');
        }
        setPasswordErrors(errors);
      }
    },
    [passwordConfirm, setPassword, setPasswordErrors, setReEnterPasswordError],
  );

  return onPasswordChanged;
};

export const useOnPasswordConfirmChanged = ({
  password,
  setPasswordConfirm,
  setReEnterPasswordError,
}: {
  password: string;
  setPasswordConfirm: React.Dispatch<React.SetStateAction<string>>;
  setReEnterPasswordError: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}) => {
  const onPasswordConfirmChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newConfirm = event.target.value;
      setPasswordConfirm(newConfirm);
      if (password !== newConfirm) {
        setReEnterPasswordError('The passwords do not match');
      } else {
        setReEnterPasswordError(undefined);
      }
    },
    [password, setPasswordConfirm, setReEnterPasswordError],
  );

  return onPasswordConfirmChanged;
};

const AcceptInviteContainer = () => {
  const { api } = useContext(CloudFunctionClientContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { token, email } = parseQueryParams();
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState<string>('');
  const [passwordErrors, setPasswordErrors] = useState<string[] | undefined>();
  const [reEnterPasswordError, setReEnterPasswordError] = useState<
    string | undefined
  >();
  const [isDisplayNameCustom, setIsDisplayNameCustomer] =
    useState<boolean>(false);
  const [isHidingPassword, setIsHidingPassword] = useState<boolean>(true);
  const onDisplayNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDisplayNameCustomer(true);
    setDisplayName(event.target.value);
  };

  const [firstName, setFirstName] = useState<string>('');
  const onFirstNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const [lastName, setLastName] = useState<string>('');
  const onLastNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');

  const onPasswordChanged = useOnPasswordChanged({
    passwordConfirm,
    setReEnterPasswordError,
    setPasswordErrors,
    setPassword,
  });

  const onPasswordConfirmChanged = useOnPasswordConfirmChanged({
    password,
    setReEnterPasswordError,
    setPasswordConfirm,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const onDisplayNameFocused = () => {
    if (!isDisplayNameCustom) {
      setDisplayName(`${firstName} ${lastName}`);
    }
  };

  useEffect(() => {
    trackEvent('Sign Ups - Invitation Screen Opened', {});
  }, [trackEvent]);

  const onSubmit = () => {
    setPasswordErrors(undefined);
    setReEnterPasswordError(undefined);
    setIsLoading(true);
    api
      .post<{ message?: string }, { message: string }>(`/invitations/accept`, {
        email,
        token,
        displayName,
        firstName,
        lastName,
        password,
      })
      .then((response) => {
        if (response.ok) {
          trackEvent('Sign Ups - Invitation Accepted');
          firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
              setIsLoading(false);
              navigate(appRoutes.home);
            });
        } else {
          setErrorMessage(
            response.data ? response.data.message : 'Something went wrong',
          );
          setIsLoading(false);
        }
      });
  };

  const isValid =
    displayName !== '' &&
    firstName !== '' &&
    lastName !== '' &&
    password !== '' &&
    password === passwordConfirm &&
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(password);

  return (
    <AcceptInvite
      email={email}
      displayName={displayName}
      onDisplayNameChanged={onDisplayNameChanged}
      onDisplayNameFocused={onDisplayNameFocused}
      firstName={firstName}
      onFirstNameChanged={onFirstNameChanged}
      lastName={lastName}
      onLastNameChanged={onLastNameChanged}
      password={password}
      onPasswordChanged={onPasswordChanged}
      passwordConfirm={passwordConfirm}
      onPasswordConfirmChanged={onPasswordConfirmChanged}
      onSubmit={onSubmit}
      isLoading={isLoading}
      isValid={isValid}
      errorMessage={errorMessage}
      passwordErrors={passwordErrors}
      passwordConfirmError={reEnterPasswordError}
      isHidingPassword={isHidingPassword}
      setIsHidingPassword={setIsHidingPassword}
    />
  );
};

const Gate = () => {
  const { api } = useContext(CloudFunctionClientContext);
  const { token } = parseQueryParams();
  const [isValidatingToken, setIsValidatingToken] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!api) {
      return;
    }
    api
      .get<{
        isValid: boolean;
        isExpired: boolean;
        isUsed: boolean;
      }>(`invitations/isValid/${token}`)
      .then((response) => {
        if (response.ok && response.data) {
          const { isExpired, isUsed } = response.data;
          if (isUsed) {
            navigate(appRoutes.alreadyAcceptedInvitation);
          } else if (isExpired) {
            navigate(appRoutes.expiredInvitation);
          }
        }
        setIsValidatingToken(false);
      });
  }, [api, navigate, token]);

  if (isValidatingToken) {
    return <Loading />;
  }

  return <AcceptInviteContainer />;
};

export default Gate;
