import React from 'react';
import { Link } from 'react-router-dom';

import Typography from 'kingpin/atoms/Typography';
import ListItemWrapper from '../../../DatasetDefinitions/ListItemWrapper';
import Column from '../../../DatasetDefinitions/Column';
import { buildShowBoard } from '../../../../../navigation/appRoutes';
import Card from '../../../../../components/Common/Card';

const BoardsUsage = ({
  boards,
  metric,
}: {
  boards: (PerformanceBoardTypes.Board | Board)[];
  metric: Metrics.CompoundMetric | Metrics.NormalMetric;
}) => (
  <>
    {boards.length > 0 && (
      <div style={{ marginBottom: 24 }}>
        <Typography.Header type="H5">{`Boards (${boards.length})`}</Typography.Header>
        <Card>
          {boards.map((b, index) => (
            <ListItemWrapper
              key={`${b.id}-${metric.id}`}
              isLast={boards.length - 1 === index}
            >
              <Column
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <Link to={buildShowBoard(b.id)} target="_blank">
                  <Typography.Body type="Body 12" color="#0041ea">
                    {b.name}
                  </Typography.Body>
                </Link>
              </Column>
            </ListItemWrapper>
          ))}
        </Card>
      </div>
    )}
  </>
);

export default BoardsUsage;
