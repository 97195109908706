import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import aggregateMetricMatrix from 'api/aggregateMetricMatrix';

const runAggregation = ({
  client,
  metric,
  filterInput,
  dateScope,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  metric: Metrics.NormalMetric;
  filterInput: FilterInput[];
  dateScope: DateRangeInput;
}) => {
  return aggregateMetricMatrix({
    client,
    metrics: [metric],
    expressions: [],
    filterInput,
    dateScope,
    trendByCalendarInterval: 'auto',
  });
};

export default runAggregation;
